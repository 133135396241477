import { DateInput } from '@smack/core/components/DataInput/DateInput';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';
import { getRecurenceEndOptions } from '../../Utils';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const EndRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;

  const handleChange = (val: Partial<Options>): void => {
    onChange(new RRule({ ...value?.origOptions, ...val }));
  };

  const getValue = (): number => {
    if (value?.options?.count) return 1;
    if (value?.options?.until) return 2;
    return 0;
  };

  const setValue = (val: number): void => {
    if (val === 0) handleChange({ until: null, count: null });
    if (val === 1) handleChange({ until: null, count: 1 });
    if (val === 2) handleChange({ until: new Date(), count: null });
  };

  return (
    <div>
      <div className="flex flex-wrap items-center mt-3 gap-2">
        <p className="mr-3 dark:text-text">{t('recurrenceInput.isEnding')}</p>
        <div className="mr-3">
          <SelectInput
            className="min-w-[150px]"
            value={getRecurenceEndOptions().find((v) => v.value === getValue())}
            onChange={(val): void => setValue(val?.value as number)}
            options={getRecurenceEndOptions()}
          />
        </div>
        {value?.options?.count && (
          <NumberInput
            size="small"
            value={value?.options.count}
            onChange={(val): void => {
              if (val) {
                handleChange({ count: val as number });
              }
            }}
            min={1}
          />
        )}
        {value?.options?.until && (
          <DateInput
            value={value?.options.until?.toISOString()}
            hidetime
            onChange={(val): void =>
              handleChange({ until: parseISO(val as string) })
            }
          />
        )}
        {value?.options?.count && (
          <p className="ml-3 dark:text-text">
            {t('recurrenceInput.executions')}
          </p>
        )}
      </div>
    </div>
  );
};
