import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { formatLongString } from '@smack/core/utils';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  title?: string;
  subtitle?: string;
  bellIcon?: boolean;
  envelopeIcon?: boolean;
  sendFail?: boolean;
  onDelete?: () => void;
  onDetails?: () => void;
}
/**
 * Reminder list element
 * @param props IProps
 * @returns JSX.Element
 */
export const ReminderElement = (props: IProps): JSX.Element => {
  const {
    subtitle,
    title,
    sendFail,
    onDelete,
    onDetails,
    envelopeIcon,
    bellIcon,
  } = props;
  const { t } = useTranslation();
  const [hover, setHover] = React.useState<boolean>(false);
  return (
    <div
      onMouseEnter={(): void => setHover(true)}
      onMouseLeave={(): void => setHover(false)}
      className="h-16 flex items-center  px-4 justify-between border-b border-border "
    >
      <div className="flex flex-col ml-3">
        <p className="uppercase dark:text-white truncate">
          {formatLongString(title, 32)}
        </p>
        <p
          className={`flex flex-row items-center gap-1 text-xs truncate ${
            sendFail ? 'text-red-600' : 'text-gray-600'
          }`}
        >
          {bellIcon && (
            <Icon className={'text-gray-500'} icon={{ name: 'bell' }} />
          )}
          {envelopeIcon && (
            <Icon className={'text-gray-500'} icon={{ name: 'envelope' }} />
          )}
          {formatLongString(subtitle, 44)}
        </p>
      </div>
      <div className="flex items-center">
        {onDetails && hover && (
          <Icon
            onClick={onDetails}
            icon={{ name: 'info-circle' }}
            className="text-lg text-gray-400 hover:text-gray-500 mr-1 cursor-pointer"
          />
        )}
        {onDelete && hover && (
          <button
            type="button"
            onClick={onDelete}
            className="flex items-center justify-center bg-gray-400 text-white mr-1 text-[0.60rem] rounded-full w-[19px] aspect-square hover:bg-gray-500"
          >
            <Icon icon={{ name: 'trash-can' }} />
          </button>
        )}
        {sendFail && (
          <Icon
            icon={{ name: 'circle-exclamation' }}
            className="text-lg text-red-500 rounded-full mr-1"
            title={t('reminders.sendingError')}
          />
        )}
      </div>
    </div>
  );
};
