import { ApiError } from '@smack/core/api/errors/ApiError/ApiError';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { User } from '@smack/core/api/models/users/User';
import { PermissionUser } from '@smack/core/api/models/users/User/PermissionUser';
import {
  CancelButton,
  IconButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { DeleteConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { spawnModal } from '@smack/core/utils/modal';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface IFormData {
  user: Option;
  rights: Option;
}

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: () => void;
  object: BaseObject;
  user?: PermissionUser;
}

export const SharedPermissionForm: React.FC<IProps> = ({
  open,
  setOpen,
  onSubmit,
  object,
  user,
}) => {
  const [t] = useTranslation();
  const {
    reset,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IFormData>();

  const [searchUser, setSearchUser] = useState('');
  const [userSearchOptions, setUserSearchOptions] = useState<Option[]>([]);
  const [isInfiniteScrollOver, setIsInfiniteScrollOver] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSearch = (value: string) => {
    setSearchUser(value);
    User.getUsers(searchUser, 10).then((users) => {
      setUserSearchOptions(users.map((u) => u.toOption()));
      setIsInfiniteScrollOver(users.length === 0);
    });
  };

  const handleInfiniteScroll = () => {
    if (!isInfiniteScrollOver) {
      setIsLoading(true);
      User.getUsers(searchUser, 10, undefined, userSearchOptions.length).then(
        (users) => {
          setUserSearchOptions([
            ...userSearchOptions,
            ...users.map((u) => u.toOption()),
          ]);
          setIsInfiniteScrollOver(users.length === 0);
          setIsLoading(false);
        },
      );
    }
  };

  const getDataFromForm = (
    data: IFormData,
  ): {
    user: number;
    read: boolean;
    write: boolean;
  } => {
    return {
      user: data.user.value as number,
      read: data.rights.value === 'read' || data.rights.value === 'write',
      write: data.rights.value === 'write',
    };
  };

  const handleOnFormSubmit = (data: IFormData): void => {
    const newData = getDataFromForm(data);
    if (user) {
      user
        .updateShareBaseObjectToUser(
          object.id,
          newData.read,
          newData.write,
          newData.user,
        )
        .then(() => {
          onSubmit();
          reset();
        })
        .catch(ApiError.toastAllErrorsOnCatchAxiosErrors);
    } else {
      PermissionUser.shareBaseObjectToUser(
        object.id,
        newData.read,
        newData.write,
        newData.user,
      )
        .then(() => {
          onSubmit();
          reset();
        })
        .catch(ApiError.toastAllErrorsOnCatchAxiosErrors);
    }
  };

  const rightsOptions = React.useMemo(() => {
    return [
      {
        label: t('rightsPage.read'),
        value: 'read',
      },
      {
        label: t('rightsPage.read-write'),
        value: 'write',
      },
    ];
  }, []);

  React.useEffect(() => {
    if (user) {
      reset({
        user: user.toOption(),
        rights: rightsOptions.find((r) => {
          if (user.sharedBaseobjectWrite) {
            return r.value === 'write';
          }
          if (user.sharedBaseobjectRead) {
            return r.value === 'read';
          }
          return false;
        }),
      });
    } else {
      reset({
        rights: rightsOptions[0],
      });
    }
  }, [user]);

  const handleDelete = React.useCallback((): void => {
    if (user?.sharedBaseobjectPermission) {
      spawnModal({
        render: ({ onClose: onAlertClose }) => {
          return (
            <DeleteConfirmAlert
              onCloseButton={onAlertClose}
              onOk={(): void => {
                user
                  .deleteShareBaseObjectToUser()
                  .then(() => {
                    toast.success(t('rightsPage.deleteSuccess'));
                    reset();
                    onSubmit();
                  })
                  .catch(() => toast.error(t('rightsPage.deleteError')));
              }}
            />
          );
        },
      });
    }
  }, [user]);

  return (
    <Modal
      customClassContent="min-w-[350px]"
      title={
        user ? t('rightsPage.editUserRight') : t('rightsPage.addUserRight')
      }
      open={open}
      icon={{ name: 'user' }}
      onClose={setOpen}
    >
      <form
        data-testid="shared-permission-form"
        onSubmit={handleSubmit(handleOnFormSubmit)}
      >
        <div className="flex flex-col gap-3">
          <Controller
            render={({ field }): React.ReactElement => (
              <SelectInput
                test-id={'user-select'}
                options={userSearchOptions}
                onSearch={handleSearch}
                onLastOptionVisible={handleInfiniteScroll}
                onMenuOpen={handleInfiniteScroll}
                isLoading={isLoading}
                className="!max-w-none"
                label={t('rightsPage.user')}
                {...field}
              />
            )}
            rules={{ required: true }}
            name={'user'}
            control={control}
          />
          {errors.user && (
            <span className="text-red-500 text-sm">
              {t('rightsPage.fieldRequired')}
            </span>
          )}
          <Controller
            render={({ field }): React.ReactElement => (
              <SelectInput
                options={rightsOptions}
                className="!max-w-none"
                label={t('rightsPage.rights')}
                {...field}
              />
            )}
            name={'rights'}
            defaultValue={rightsOptions[0]}
            rules={{ required: true }}
            control={control}
          />
          {errors.rights && (
            <span className="text-red-500 text-sm">
              {t('rightsPage.fieldRequired')}
            </span>
          )}
        </div>
        <div className="flex items-center justify-between mt-6 gap-3">
          {user ? (
            <IconButton onClick={handleDelete} icon={{ name: 'user-slash' }}>
              {t('rightsPage.delete')}
            </IconButton>
          ) : (
            <div />
          )}
          <div className="flex items-center gap-2">
            <CancelButton
              onClick={(): void => {
                reset();
                setOpen(false);
              }}
            />
            <SaveButton type="submit" />
          </div>
        </div>
      </form>
    </Modal>
  );
};
