import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Attribute,
  type FormValues,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { BaseObjectSubmitAttribute } from '@smack/core/components/ViewRenderer/interfaces';
import { AttributeType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import { DateUtils } from '@smack/core/utils/DateUtils';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { TypeRecurrenceUpdateChoice } from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import type { AxiosResponse } from 'axios';

export interface GroupDateTimeRange {
  deltaFromStartAt?: string;
  duration?: string;
}

export interface PatchRecurrenceField {
  dtstartParts: {
    second?: number;
    minute?: number;
    hour?: number;
    day?: number;
    month?: number;
    year?: number;
    weekday?: number;
    monthlyByday?: string;
  };
  duration?: string;
}

export class BaseObjectGroup {
  static addLink(
    baseObject: BaseObject,
    linkGroupId: number,
    data: IAddLinkFormOutput,
  ): Promise<AxiosResponse<void>[]> {
    if (!baseObject.baseobjectGroupId)
      return Promise.reject(new Error('No baseObject Group'));
    const parameters: Record<string, unknown> = {};
    if (data.baseObjectGroupChoice === TypeRecurrenceUpdateChoice.NEXT) {
      parameters.next = baseObject.id;
    }

    const datetimeRange: GroupDateTimeRange = {};

    if (
      baseObject.startAt &&
      data.datetimeRange?.dtend &&
      data.datetimeRange?.dtstart
    ) {
      datetimeRange.deltaFromStartAt = DateUtils.getDuration(
        baseObject.startAt ? new Date(baseObject.startAt) : new Date(),
        new Date(data.datetimeRange?.dtstart),
      );

      datetimeRange.duration = DateUtils.getDuration(
        new Date(data.datetimeRange?.dtstart),
        new Date(data.datetimeRange?.dtend),
      );
    }

    const promises: Promise<AxiosResponse<void>>[] = [];

    for (const object of data.baseObjects) {
      promises.push(
        RESTClient.post<void>(
          {
            linkGroupId: linkGroupId,
            sourceBaseobjectId: baseObject.id,
            targetBaseobjectId: object.id,
            datetimeRange,
            objectDependantLinkGroup: data.objectDependantLinkGroup,
          },
          `/objects/baseobject-groups/${baseObject.baseobjectGroupId}/links`,
          parameters,
        ),
      );
    }

    return Promise.all(promises);
  }

  static removeLink(
    baseObject: BaseObject,
    linkGroupId: number,
    recurrenceType: TypeRecurrenceUpdateChoice,
    targetBaseobject: number,
  ): Promise<void> {
    if (!baseObject.baseobjectGroupId)
      return Promise.reject(new Error('No baseObject Group'));

    const parameters: Record<string, unknown> = {
      targetBaseobject,
    };
    if (recurrenceType === TypeRecurrenceUpdateChoice.NEXT) {
      parameters.next = baseObject.id;
    }
    return RESTClient.delete(
      `/objects/baseobject-groups/${baseObject.baseobjectGroupId}/link-groups/${linkGroupId}/links`,
      parameters,
    );
  }

  static deleteBaseObjects(
    baseObject: BaseObject,
    recurrenceType: TypeRecurrenceUpdateChoice,
  ): Promise<void> {
    if (!baseObject.baseobjectGroupId)
      return Promise.reject(new Error('No baseObject Group'));
    const parameters: Record<string, unknown> = {};
    if (recurrenceType === TypeRecurrenceUpdateChoice.NEXT) {
      parameters.next = baseObject.id;
    }
    return RESTClient.delete(
      `/objects/baseobject-groups/${baseObject.baseobjectGroupId}/baseobjects`,
      parameters,
    );
  }

  static getPatchRecurrenceField(
    startAt?: string,
    endAt?: string,
  ): PatchRecurrenceField | undefined {
    if (!startAt || !endAt) return;
    const startDate = new Date(startAt);
    return {
      dtstartParts: {
        minute: startDate.getMinutes(),
        hour: startDate.getHours(),
      },
      duration: DateUtils.getDuration(startDate, new Date(endAt)),
    };
  }

  static async patchBaseObjectsRecurrences(
    baseObject: BaseObject,
    recurrenceType: TypeRecurrenceUpdateChoice,
    recurrenceData: {
      dtstart?: string;
      dtend?: string;
    },
  ): Promise<AxiosResponse<void>> {
    const attributes: Attribute[] = await Attribute.getByTypeAndCategoryId(
      baseObject.category?.id ?? 0,
      AttributeType.RECURRENCE,
    );
    const newAttributes: BaseObjectSubmitAttribute[] = [];
    for (const att of attributes) {
      newAttributes.push({
        id: att.id,
        values: [
          BaseObjectGroup.getPatchRecurrenceField(
            recurrenceData.dtstart,
            recurrenceData.dtend,
          ) as FormValues,
        ],
      });
    }
    return BaseObjectGroup.patchBaseObjects(baseObject, recurrenceType, {
      attributes: newAttributes,
    });
  }

  static patchBaseObjects(
    baseObject: BaseObject,
    recurrenceType: TypeRecurrenceUpdateChoice,
    data: Record<string, unknown>,
  ): Promise<AxiosResponse> {
    if (!baseObject.baseobjectGroupId)
      return Promise.reject(new Error('No baseObject Group'));
    const parameters: Record<string, unknown> = {};
    if (recurrenceType === TypeRecurrenceUpdateChoice.NEXT) {
      parameters.filters = {
        next: baseObject.id,
      };
    }
    return RESTClient.patch(
      {
        baseobjects: {
          ...parameters,
          ...data,
        },
      },
      `/objects/baseobject-groups/${baseObject.baseobjectGroupId}`,
    );
  }
}
