import { type VariantProps, cva } from 'class-variance-authority';

export const button = cva(
  'shadow-sm px-3 py-2 rounded font-medium text-xs cursor-pointer border flex items-center uppercase gap-2 transition duration-150',
  {
    variants: {
      variant: {
        default:
          'text-text bg-white dark:bg-neutral-700 dark:border-neutral-500 hover:bg-gray-50 hover:dark:bg-neutral-800',
        primary: 'text-white self-start',
      },
      disabled: {
        true: 'opacity-70 cursor-not-allowed',
        false: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      disabled: false,
    },
  },
);

export type ButtonVariantProps = VariantProps<typeof button>;
