import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import type { AppState } from '@smack/core/store';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

export interface INavigationTabs<T = unknown> {
  label: string;
  id: number;
  icon?: React.FC;
  views: T[];
}

interface IProps {
  navigation: ViewSection[];
  activeTab: ViewSection | undefined;
  setActiveTab: (item: ViewSection) => void;
  children: ReactNode;
}

export const Navigation = (props: IProps): JSX.Element => {
  const { settings } = useSelector((state: AppState) => state.App);
  const { navigation, activeTab, setActiveTab, children } = props;

  const defaultColor = settings?.color ?? settings?.defaultColor;
  const color = settings?.primaryBackgroundColor ?? defaultColor;

  return (
    <div className="w-full h-full flex flex-col">
      <div
        className="w-full h-12 flex items-center border-b border-border dark:border-neutral-500"
        style={{ minHeight: '45px' }}
      >
        {navigation.map((nav: ViewSection) => {
          const isActive = activeTab?.id === nav.id;
          return (
            <div
              data-testid={`navigation-tab-${nav.id}`}
              key={nav.id}
              onClick={() => {
                setActiveTab(nav);
              }}
              className={`cursor-pointer mx-3 text-[14px] border-b-2 ${
                isActive
                  ? 'font-medium border-blue-400'
                  : 'font-medium border-transparent'
              }`}
              style={{
                color: isActive ? color : settings?.defaultColor,
                borderBottom: isActive ? `2px solid ${color}` : '',
              }}
            >
              {nav.label}
            </div>
          );
        })}
      </div>
      <div
        data-testid={`section-content-${activeTab?.id}`}
        className="w-full h-full flex-grow flex flex-col"
      >
        {children}
      </div>
    </div>
  );
};
