import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AppState } from '@smack/core/store';
import type React from 'react';
import { useSelector } from 'react-redux';

interface IProps {
  title: string;
  icon: IconField;
  onClick: () => void;
  count?: number;
  active: boolean;
  buttons?: React.ReactNode;
}

export const GroupHeader: React.FC<IProps> = ({
  title,
  icon,
  onClick,
  active,
  count,
  buttons,
}) => {
  const theme = useSelector((state: AppState) => state.App.theme);
  return (
    <div className={'h-12'}>
      <div
        onClick={onClick}
        className={
          'h-12 flex items-center px-4 justify-between cursor-pointer border-b'
        }
      >
        <div className="flex items-center select-none dark:text-white">
          <Icon
            icon={icon}
            className="fa-fw text-lg text-gray-600 dark:text-gray-300"
          />

          <p className="pl-3 mr-2 uppercase  flex-grow text-gray-600 dark:text-gray-300">
            {title}
          </p>
          {count ? (
            <div
              style={{
                backgroundColor:
                  theme === 'light' ? '#f6f6f6' : 'rgb(60, 60, 60)',
              }}
              className="flex items-center justify-center w-5 h-5 rounded-full text-xs text-gray-600"
            >
              {count.toString()}
            </div>
          ) : null}
        </div>
        <div className="flex items-center">
          {buttons}
          <Icon
            icon={{ name: active ? 'chevron-up' : 'chevron-down' }}
            className="fa-fw text-lg text-gray-600 dark:text-gray-300"
          />
        </div>
      </div>
    </div>
  );
};
