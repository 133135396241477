import type { FeatureCollection } from 'geojson';
import { t } from 'i18next';
import type { GeoJSONSource } from 'maplibre-gl';

import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { IDetailAction } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/BaseObjectDetails/BaseObjectDetails';
import type { INavigationProps } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation';

export const GetNavigation = (
  object?: BaseObject | undefined,
  hasPreview?: boolean,
  simplifiedView?: boolean,
): INavigationProps => {
  if (!object) return { navigation: [] };

  const simplifiedNavigation = [
    { id: 'details', label: t('baseObjectPanel.mainMenu.details') },
    { id: 'medias', label: t('baseObjectPanel.mainMenu.medias') },
  ];

  const fullNavigation = [
    { id: 'details', label: t('baseObjectPanel.mainMenu.details') },
    { id: 'links', label: t('baseObjectPanel.mainMenu.links') },
    { id: 'medias', label: t('baseObjectPanel.mainMenu.medias') },
    { id: 'follows', label: t('baseObjectPanel.mainMenu.process') },
  ];

  const nav = {
    navigation: simplifiedView ? simplifiedNavigation : fullNavigation,
  };

  if (hasPreview) {
    nav.navigation.unshift({
      id: 'preview',
      label: t('baseObjectPanel.mainMenu.preview'),
    });
  }
  return nav;
};

export const GetActions = (
  object: BaseObject | undefined,
  onUpdate: () => void,
  onBookmark: () => void,
  onExpand: (() => void) | undefined,
  mobile: boolean,
  isBookmarkLoading = false,
): IDetailAction[] => {
  const actions: IDetailAction[] = [
    {
      label: t(
        object?.bookmark
          ? 'baseObjectPanel.removeFromFavorites'
          : 'baseObjectPanel.addToFavorites',
      ),
      icon: { name: 'star', familyStyle: object?.bookmark ? 'fas' : 'fal' },
      onClick: onBookmark,
      disabled: isBookmarkLoading,
    },
  ];
  if (object?.isWritable)
    actions.push({
      label: t('edit.edit'),
      icon: { name: 'pen' },
      onClick: onUpdate,
      disabled: false,
    });
  if (onExpand && !mobile) {
    actions.push({
      label: t('baseObjectPanel.fullScreen'),
      icon: { name: 'expand' },
      onClick: onExpand,
      disabled: false,
    });
  }

  return actions;
};

export const setGeom = (obj?: BaseObject): void => {
  const { MainMap } = window.Hyvilo.Utils;
  if (!MainMap || !MainMap?.isStyleLoaded()) return;
  const fc: FeatureCollection = obj?.geometry || {
    type: 'FeatureCollection',
    features: [],
  };
  const source = MainMap.getSource('focused-features');
  if (source) {
    (source as GeoJSONSource).setData(fc);
  } else {
    MainMap.addSource('focused-features', { type: 'geojson', data: fc });
    if (!MainMap.getLayer('focused-geometry')) {
      MainMap.addLayer(
        {
          id: 'focused-geometry',
          type: 'fill',
          source: 'focused-features',
          paint: {
            'fill-color': 'red',
            'fill-opacity': 0.5,
          },
          filter: ['==', '$type', 'Polygon'],
        },
        'root-layers',
      );
      MainMap.addLayer(
        {
          id: 'focused-linestring',
          type: 'line',
          source: 'focused-features',
          paint: {
            'line-color': 'red',
            'line-opacity': 1,
          },
          filter: ['==', '$type', 'LineString'],
        },
        'root-layers',
      );
    }
  }
};

export const printEventGeomOnMap = (obj?: BaseObject): void => {
  const { MainMap } = window.Hyvilo.Utils;
  if (!MainMap || !MainMap?.isStyleLoaded())
    setTimeout(() => printEventGeomOnMap(obj), 250);
  else setGeom(obj);
};
