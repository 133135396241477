import '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/BasePanel/index.scss';

interface IProps {
  children?: JSX.Element[] | JSX.Element;
  active: boolean;
  mobile?: boolean;
}

export const BasePanel = (props: IProps): JSX.Element => {
  const { children, active, mobile } = props;

  const getClassName = (): string => {
    if (!active) return 'detail-panel-closed';
    if (mobile) return 'detail-panel-expended-mobile';
    return 'detail-panel-expended';
  };
  return <div className={`detail-panel ${getClassName()}  `}>{children}</div>;
};

BasePanel.defaultProps = {
  mobile: false,
};
