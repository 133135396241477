import {
  type Category,
  CategoryLevel,
} from '@smack/core/api/models/categories/Category';
import type { ViewPreference } from '@smack/core/api/models/users/User/UserPreferences/UserPreferences';
import { usePreferencesManager } from '@smack/core/hooks/preferences/usePreferencesManager/usePreferencesManager';

import type { View } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const getCategoryIdFromViewCategoryMode = (
  view: View,
  setCategory?: Category,
  category?: Category,
): number | undefined => {
  if (view.categoryMode === CategoryLevel.Category) {
    return category?.id;
  }
  return setCategory?.id;
};

type OutputUseCategoryPreferencesByView = [
  preferences: ViewPreference | undefined,
  setPreferences: (pref: ViewPreference) => void,
];

export const useCategoryPreferencesByView =
  (): OutputUseCategoryPreferencesByView => {
    const preferencesManager = usePreferencesManager();
    const [activeView] = useActiveView();
    const [searchParams, setSearchParams] = useSearchParams();
    const preferenceId = searchParams.get('preferenceId');
    const [, setCategory, category] = useActiveCategories();
    const lastSyncedPreferenceIdRef = useRef<string | null>(null);
    const [hasRerender, setHasRerender] = useState({});

    const preferencesByView = useMemo(() => {
      if (!activeView || !preferencesManager) return undefined;
      const categoryId = getCategoryIdFromViewCategoryMode(
        activeView,
        setCategory,
        category,
      );
      if (!categoryId) return undefined;

      const isIdValid = preferenceId?.startsWith(
        preferencesManager.getIdPrefix(categoryId, activeView.id),
      );

      return preferencesManager.getCategoryPreferenceByView(
        categoryId,
        activeView.id,
        preferenceId && isIdValid ? preferenceId : undefined,
      );
    }, [
      category,
      preferencesManager,
      setCategory,
      activeView,
      preferenceId,
      hasRerender,
    ]);

    const debouncedSetSearchParams = useMemo(
      () =>
        debounce((newPreferenceId: string) => {
          setSearchParams(
            (search) => {
              search.set('preferenceId', newPreferenceId);
              return search;
            },
            { replace: true },
          );
        }, 300),
      [setSearchParams],
    );

    useEffect(() => {
      if (
        preferencesByView?.id &&
        preferencesByView.id !== lastSyncedPreferenceIdRef.current &&
        preferencesByView.id !== preferenceId
      ) {
        debouncedSetSearchParams(preferencesByView.id);
        lastSyncedPreferenceIdRef.current = preferencesByView.id;
      }

      return () => {
        debouncedSetSearchParams.cancel();
      };
    }, [preferencesByView?.id, preferenceId, debouncedSetSearchParams]);

    const setPreferences = useCallback(
      (pref: ViewPreference) => {
        if (!activeView || !preferencesManager) return;
        const categoryId = getCategoryIdFromViewCategoryMode(
          activeView,
          setCategory,
          category,
        );
        if (!categoryId) return;

        preferencesManager.setCategoryPreferenceByView(
          pref,
          categoryId,
          activeView.id,
        );
        setHasRerender({});
      },
      [preferencesManager, activeView, category, setCategory],
    );

    return [preferencesByView?.preferences, setPreferences];
  };
