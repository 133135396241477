import { InfoAlert } from '@smack/core/components/DataDisplay/Alerts/AlertModal/InfoAlert';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import React from 'react';
import { useTranslation } from 'react-i18next';

/**
 * ViewName when no sets available for user
 * @returns
 */
export const ServerError = (): JSX.Element => {
  const [t] = useTranslation();
  const navigate = useNavigation();

  const goHome = React.useCallback(() => navigate('/'), []);

  return (
    <div className="h-full w-full relative">
      <InfoAlert
        onClick={goHome}
        icon={{ name: 'face-scream' }}
        title={t('alerts.serverError.title')}
        text={t('alerts.serverError.description')}
        buttonText={t('alerts.serverError.buttonText')}
        isClosable={false}
      />
    </div>
  );
};
