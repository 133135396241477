import { Action } from '@smack/core/api/models/actions/Action/Action';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ActionDisplay } from '@smack/core/components/DataDisplay/Actions';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  object: BaseObject;
}

export const ActionsPage = (props: IProps): JSX.Element => {
  const { object } = props;
  const [actions, setActions] = React.useState<Action[]>([]);
  const { t } = useTranslation();

  const loadActions = (): void => {
    if (object?.category)
      Action.getActions(object.category.id, object.id).then(setActions);
  };

  React.useEffect((): void => {
    loadActions();
  }, []);

  return (
    <div className="absolute inset-0 flex flex-col ">
      <PageHeader actions={[]} title="Actions" />
      <div className="flex-grow overflow-y-auto">
        {actions?.map(
          (action): JSX.Element => (
            <ActionDisplay
              key={action.id}
              objectId={object.id}
              taskId=""
              action={action}
              reloadCallback={loadActions}
            />
          ),
        )}
        {actions.length === 0 && (
          <NoContentMessage icon={{ name: 'list' }} label={t('actions.none')} />
        )}
      </div>
    </div>
  );
};
