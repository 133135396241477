import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Group } from '@smack/core/api/models/users/Group';
import { PermissionUser } from '@smack/core/api/models/users/User/PermissionUser';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { BaseObjectListElement } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import {
  BaseObjectSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { GroupHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Rights/Components/GroupHeader';
import { GroupInfoModal } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Rights/Components/GroupInfoModal';
import { SharedPermissionForm } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Rights/Components/SharedPermissionForm/SharedPermissionForm';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

interface IProps {
  pageTitle: string;
  object: BaseObject;
}

const defaultGroups = ['groups', 'users'];

export const RightsPage: React.FC<IProps> = ({ pageTitle, object }) => {
  const [t] = useTranslation();
  const [isGroupFetching, setIsGroupFetching] = React.useState<boolean>(false);
  const [isUserFetching, setIsUserFetching] = React.useState<boolean>(false);
  const [activeGroup, setActiveGroup] = React.useState<string[]>(defaultGroups);
  const [open, setOpen] = React.useState<boolean>(false);
  const [updateUser, setUpdateUser] = React.useState<PermissionUser>();
  const [usersShared, setUsersShared] = React.useState<PermissionUser[]>([]);
  const [usersPermissionCategory, setUsersPermissionCategory] = React.useState<
    PermissionUser[]
  >([]);
  const [visualiseGroup, setVisualiseGroup] = React.useState<Group>();

  const groups = useAsyncMemo(
    async () => {
      if (!object.category?.id) return [];
      setIsGroupFetching(true);
      return Group.getGroupsFromCategoryPermissionId(
        object.category.id,
      ).finally(() => setIsGroupFetching(false));
    },
    [object],
    [],
  );

  const fetchUsers = (): void => {
    if (!object.category?.id) return;
    setIsUserFetching(true);
    PermissionUser.getUsersBySharedBaseObjectId(object.id).then(setUsersShared);
    PermissionUser.getUserByPermissionCategoryId(object.category.id)
      .then(setUsersPermissionCategory)
      .finally(() => setIsUserFetching(false));
  };

  React.useEffect(() => {
    fetchUsers();
  }, [object]);

  const handleGroupClick = (groupId: string): void => {
    if (activeGroup.includes(groupId)) {
      setActiveGroup(activeGroup.filter((id) => id !== groupId));
    } else {
      setActiveGroup([...activeGroup, groupId]);
    }
  };

  const getUserSubtitles = (user: PermissionUser): string => {
    let subtitle = t('rightsPage.noneRights');
    if (user.sharedBaseobjectRead && user.sharedBaseobjectWrite) {
      subtitle = t('rightsPage.read-write');
    } else if (user.sharedBaseobjectRead) {
      subtitle = t('rightsPage.read');
    } else if (user.sharedBaseobjectWrite) {
      subtitle = t('rightsPage.write');
    }
    return subtitle;
  };

  const getPermissionCategoryUserSubtitles = (user: PermissionUser): string => {
    let subtitle = t('rightsPage.category-noneRights');
    if (user.categoryManage) {
      subtitle = t('rightsPage.category-manage');
    } else if (user.categoryWrite || user.categoryShare) {
      subtitle = t('rightsPage.category-read-write');
    } else if (user.categoryRead) {
      subtitle = t('rightsPage.category-read');
    } else if (user.categoryPreview) {
      subtitle = t('rightsPage.category-preview');
    }
    return subtitle;
  };

  const getPermissionCategoryGroupSubtitles = (group: Group): string => {
    let subtitle = t('rightsPage.category-noneRights');

    const categoryId = object.category?.id;
    if (!categoryId) return subtitle;

    if (group?.manageableCategories?.includes(categoryId)) {
      subtitle = t('rightsPage.category-manage');
    } else if (
      group?.writableCategories?.includes(categoryId) ||
      group?.shareableCategories?.includes(categoryId)
    ) {
      subtitle = t('rightsPage.category-read-write');
    } else if (group?.readableCategories?.includes(categoryId)) {
      subtitle = t('rightsPage.category-read');
    } else if (group?.previewableCategories?.includes(categoryId)) {
      subtitle = t('rightsPage.category-preview');
    }
    return subtitle;
  };

  const handleEditUser = React.useCallback(
    (user: PermissionUser): void => {
      setUpdateUser(user);
      setOpen(true);
    },
    [updateUser, open],
  );

  const handleAddUser = React.useCallback((): void => {
    setUpdateUser(undefined);
    setOpen(true);
  }, [updateUser, open]);

  const handleSubmited = React.useCallback((): void => {
    setOpen(false);
    fetchUsers();
  }, [open]);

  const getGroupComponent = (): React.ReactNode => {
    if (isGroupFetching) {
      return (
        <LoaderSkeleton height={80} width="100%">
          <ListSkeleton iterations={1} component={BaseObjectSkeleton} />
        </LoaderSkeleton>
      );
    }
    if (!groups.length) {
      return (
        <NoContentMessage
          className={'!h-20 border-b'}
          label={t('rightsPage.no-content-groups')}
          icon={{ name: 'users' }}
        />
      );
    }
    return groups.map((group) => (
      <BaseObjectListElement
        id={group.id}
        key={group.id}
        title={group.label}
        subtitle={getPermissionCategoryGroupSubtitles(group)}
        icon={{ name: 'users', familyStyle: 'far' }}
        action={{
          icon: { name: 'eye' },
          label: t('rightsPage.more-information'),
          onClick: (): void => {
            setVisualiseGroup(group);
          },
        }}
        noOverlayActions={true}
      />
    ));
  };

  const getUsersComponent = (): React.ReactNode => {
    if (isUserFetching) {
      return (
        <LoaderSkeleton height={320} width="100%">
          <ListSkeleton iterations={4} component={BaseObjectSkeleton} />
        </LoaderSkeleton>
      );
    }
    if (!usersShared.length && !usersPermissionCategory.length) {
      return (
        <NoContentMessage
          className={'h-20 '}
          label={t('rightsPage.no-content-users')}
          icon={{ name: 'user' }}
        />
      );
    }
    const users: React.ReactNode[] = [];
    users.push(
      ...usersShared.map((user) => (
        <BaseObjectListElement
          id={user.id}
          key={`shared-${user.id}`}
          title={user.getFullName()}
          subtitle={getUserSubtitles(user)}
          icon={{ name: 'user', familyStyle: 'far' }}
          action={
            object.isWritable
              ? user.isSharedBaseobjectEditable
                ? {
                    icon: { name: 'pen' },
                    label: t('rightsPage.edit'),
                    onClick: (): void => handleEditUser(user),
                  }
                : {
                    icon: { name: 'lock' },
                    label: t('rightsPage.non-editable'),
                  }
              : undefined
          }
          noOverlayActions={true}
        />
      )),
    );
    users.push(
      ...usersPermissionCategory
        .filter((u) => u.hasAtLeastOneReadPermission())
        .map((user) => (
          <BaseObjectListElement
            id={user.id}
            key={`cat-${user.id}`}
            title={user.getFullName()}
            subtitle={getPermissionCategoryUserSubtitles(user)}
            icon={{ name: 'user', familyStyle: 'far' }}
            action={{
              icon: { name: 'info' },
              label: t('rightsPage.infoTooltip'),
            }}
            noOverlayActions={true}
          />
        )),
    );
    return users;
  };

  return (
    <div className="flex flex-col absolute inset-0">
      <PageHeader title={pageTitle} />
      <SharedPermissionForm
        user={updateUser}
        open={open}
        setOpen={setOpen}
        object={object}
        onSubmit={handleSubmited}
      />
      <GroupInfoModal
        categoryId={object.category?.id}
        open={!!visualiseGroup}
        setOpen={(): void => setVisualiseGroup(undefined)}
        group={visualiseGroup}
      />
      <div className="flex-grow overflow-y-auto">
        <GroupHeader
          title={t('rightsPage.groups')}
          icon={{ name: 'users', familyStyle: 'fal' }}
          onClick={(): void => handleGroupClick('groups')}
          count={groups.length}
          active={activeGroup.includes('groups')}
        />
        {activeGroup.includes('groups') && getGroupComponent()}
        <GroupHeader
          title={t('rightsPage.users')}
          icon={{ name: 'user', familyStyle: 'fal' }}
          onClick={(): void => handleGroupClick('users')}
          count={usersShared.length + usersPermissionCategory.length}
          buttons={
            object.isWritable ? (
              <Tooltip title={t('rightsPage.add')} offset={2}>
                <Icon
                  icon={{ name: 'plus-circle' }}
                  title={t('rightsPage.add')}
                  className={'text-gray-400 mr-3 text-xl hover:text-gray-500 '}
                  onClick={(e): void => {
                    e.stopPropagation();
                    handleAddUser();
                  }}
                />
              </Tooltip>
            ) : undefined
          }
          active={activeGroup.includes('users')}
        />
        {activeGroup.includes('users') && getUsersComponent()}
      </div>
    </div>
  );
};
