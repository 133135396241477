import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import {
  Category,
  type ICategory,
} from '@smack/core/api/models/categories/Category';
import {
  type IListElementBaseObject,
  ListElementBaseObject,
} from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import axios, { type AxiosResponse } from 'axios';

export interface IBookmark {
  id: number;
  baseobject?: IListElementBaseObject;
  category?: ICategory;
  userId?: number;
  createdAt?: string;
  modifiedAt?: string;
  lastReadAt?: string;
}

export enum BookmarkType {
  BASEOBJECT = 'BASEOBJECT',
  CATEGORY = 'CATEGORY',
}

export type CategoryBookmark = Omit<IBookmark, 'category' | 'baseobject'> & {
  category: Category;
  baseobject?: never;
};

export type BaseObjectBookmark = Omit<IBookmark, 'category' | 'baseobject'> & {
  category?: never;
  baseobject: ListElementBaseObject;
};

export class Bookmark {
  id: number;

  baseobject?: ListElementBaseObject;

  category?: Category;

  userId?: number;

  createdAt?: Date;

  modifiedAt?: Date;

  lastReadAt?: Date;

  constructor(data: IBookmark) {
    this.id = data.id;
    this.baseobject = data.baseobject
      ? new ListElementBaseObject(data.baseobject)
      : undefined;
    this.category = data.category ? new Category(data.category) : undefined;
    this.userId = data.userId;
    this.createdAt = data.createdAt ? new Date(data.createdAt) : undefined;
    this.modifiedAt = data.modifiedAt ? new Date(data.modifiedAt) : undefined;
    this.lastReadAt = data.lastReadAt ? new Date(data.lastReadAt) : undefined;

    this.delete = this.delete.bind(this);
  }

  touch(): Promise<void> {
    return RESTClient.put(undefined, `/bookmarks/bookmarks/${this.id}/touch`);
  }

  delete(): Promise<unknown> {
    return RESTClient.delete(`/bookmarks/bookmarks/${this.id}`).catch(
      (err): Promise<void> => {
        // If the bookmark is not found, resolve the promise anyway
        if (axios.isAxiosError(err) && err.response?.status === 404)
          return Promise.resolve();
        return Promise.reject(err);
      },
    );
  }

  static create(data: {
    baseobject?: number;
    category?: number;
  }): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        baseobject: data.baseobject,
        category: data.category,
      },
      '/bookmarks/bookmarks',
    );
  }

  static async getBookmarks(
    limit?: number,
    offset?: number,
    type?: BookmarkType.CATEGORY,
  ): Promise<IPagination<CategoryBookmark>>;

  static async getBookmarks(
    limit?: number,
    offset?: number,
    type?: BookmarkType.BASEOBJECT,
  ): Promise<IPagination<BaseObjectBookmark>>;
  static async getBookmarks(
    limit?: number,
    offset?: number,
  ): Promise<IPagination<IBookmark>>;

  static async getBookmarks(
    limit = 100,
    offset = 0,
    type?: BookmarkType,
  ): Promise<IPagination<IBookmark>> {
    const response = await RESTClient.get<
      AxiosResponse<IPagination<IBookmark>>
    >('/bookmarks/bookmarks', { type, limit, offset });
    if (import.meta.env.DEV) {
      response.data.results.forEach((bookmark) => {
        if (!bookmark.category && type === BookmarkType.CATEGORY)
          console.error(
            new Error(
              'A non-category bookmark was found in a filtered request for category bookmarks',
            ),
          );
        if (!bookmark.baseobject && type === BookmarkType.BASEOBJECT)
          console.error(
            new Error(
              'A non-baseobject bookmark was found in a filtered request for baseobjects bookmarks',
            ),
          );
      });
    }
    return response.data;
  }
}
