import { ProjectsEventSocketManager } from '@smack/core/api/clients/websocket/projects/ProjectsEventSocketManager';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Project } from '@smack/core/api/models/tasks/Project/Project';
import { BaseObjectListElement } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import type { AppState } from '@smack/core/store';
import { toggleRefetchProjectStatuses } from '@smack/core/store/projects/actions';
import {
  BaseObjectSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { CreateProjectModal } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Tasks/Components/CreateProjectModal';
import { MiniInlineProgressBar } from '@smack/core/views/oldViewsToSort/Views/Objects/Tasks/Components/MiniInlineProgressBar';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { useAsyncMemo } from 'use-async-memo';

interface IProps {
  object: BaseObject;
}

export interface ProjectCompletionStatusProps {
  activeTasks: number;
  completedTasks: number;
  projectId: number;
}

export const ProjectCompletionStatus = ({
  activeTasks,
  completedTasks,
  projectId,
}: ProjectCompletionStatusProps): React.ReactElement => {
  const [t] = useTranslation();
  const progress =
    activeTasks > 0 ? Math.floor((completedTasks / activeTasks) * 100) : 0;

  return (
    <MiniInlineProgressBar
      progress={progress}
      data-testid={`progression-bar-${projectId}`}
    >
      {t('tasks.completed_tasks_meter', {
        count: completedTasks,
        total: activeTasks,
      })}
    </MiniInlineProgressBar>
  );
};

export const TasksPage = (props: IProps): JSX.Element => {
  const { object } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [, setActiveView] = useActiveView();
  const { refetchProjectsStatuses } = useSelector(
    (state: AppState) => state.Projects,
  );
  const [projects, setProjects] = React.useState<Project[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [isCreatingProject, setIsCreatingProject] = React.useState(false);
  const templates = useAsyncMemo(
    () =>
      object.category
        ? Project.getTemplatesFromCategoryId(object.category?.id)
        : Promise.resolve([]),
    [object.category],
    [],
  );

  const reload = React.useCallback(() => {
    setIsLoading(true);
    Project.getFromBaseObject(object.id)
      .then((projects) => {
        setProjects(projects);
      })
      .finally(() => setIsLoading(false));
  }, [object.id]);

  React.useEffect(reload, [object.id]);

  React.useEffect(() => {
    if (refetchProjectsStatuses) {
      reload();
      dispatch(toggleRefetchProjectStatuses(false));
    }
  }, [refetchProjectsStatuses]);

  // @TODO See about this websocket as it does not seem to catch changes on all project status changes
  React.useEffect(() => {
    const sockets = projects.map(
      (project) =>
        new ProjectsEventSocketManager(project.id.toString(), () => reload()),
    );
    return () => {
      sockets.forEach((socket) => socket.disconnect());
    };
  }, [projects]);

  const handleClick = (taskId: number, baseObjectId: number): void => {
    setActiveView({
      view: ViewName.ObjectTask,
      params: {
        taskId: taskId,
        baseObjectId,
      },
    });
  };

  // TODO: Refactor this useMatch to avoid its use.
  const matchData = useMatch(
    '/modules/:module/sets/:set/objects/:object/follows/procedures/:projectId',
  );
  React.useLayoutEffect(() => {
    if (matchData?.params?.projectId && object.id) {
      handleClick(Number.parseInt(matchData.params.projectId), object.id);
    }
  }, []);

  const switchModal = React.useCallback(
    () =>
      setIsCreatingProject((isCreatingProjectState) => !isCreatingProjectState),
    [],
  );

  return (
    <>
      {templates.length ? (
        <CreateProjectModal
          baseobjectId={object.id}
          isOpen={isCreatingProject}
          setIsOpen={setIsCreatingProject}
          templates={templates}
          reload={reload}
        />
      ) : null}
      <div className="absolute top-0 bottom-[45px] right-0 left-0">
        <PageHeader
          actions={
            templates.length
              ? [
                  {
                    label: t('create.procedure'),
                    icon: { name: 'plus' },
                    onClick: switchModal,
                  },
                ]
              : []
          }
          title={t('baseObjectPanel.followMenu.projects')}
        />
        {isLoading ? (
          <LoaderSkeleton height={240} width="100%">
            <ListSkeleton iterations={3} component={BaseObjectSkeleton} />
          </LoaderSkeleton>
        ) : (
          <div className="h-full overflow-y-scroll no-scrollbar">
            {projects.length === 0 && (
              <NoContentMessage
                icon={{ name: 'tasks' }}
                label={t('tasks.none')}
              />
            )}
            {projects.map((project) => (
              <BaseObjectListElement
                id={project.id}
                key={project.id}
                title={project.label || ''}
                icon={project.status?.icon || { name: 'question' }}
                color={project.status?.color || 'gray'}
                subtitle={
                  <ProjectCompletionStatus
                    activeTasks={project.activeTasks ?? 0}
                    completedTasks={project.completedTasks ?? 0}
                    projectId={project.id}
                  />
                }
                bottomRight={{
                  value: t('tasks.projectCreationDate', {
                    creationDate: project.createdAt?.toLocaleDateString(),
                  }),
                  tooltip: t('tasks.createdOnAndAt', {
                    date: project.createdAt
                      ? format(new Date(project.createdAt), 'eeee dd MMMM yyyy')
                      : '',
                    time: project.createdAt
                      ? format(new Date(project.createdAt), 'hh:mm:ss a')
                      : '',
                  }),
                  rightIcon: { name: 'circle-question', familyStyle: 'far' },
                }}
                onClick={(): void => handleClick(project.id, object.id)}
                accessActionOnly={true}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
