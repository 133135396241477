import { useBaseObjectFormContextContext } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import { formatISO, parseISO } from 'date-fns';
import React from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import { type RRule, rrulestr } from 'rrule';
import { DateInput } from '../DateInput';
import { RRuleGenerator } from './Components/RRuleGenerator';

export interface RRuleInputValue {
  dtstart?: string;
  dtend?: string;
  rrule?: string;
  update?: string;
  updateFromSchedule?: string | number;
}
export interface IRRuleProps {
  label?: string; // label of the input
  value?: RRuleInputValue; // value of the input
  onChange?: (data: RRuleInputValue) => void; // onChange methods
  placeholder?: string; // placeholder of the input
  error?: string; // if error error in red
  type?: 'text' | 'password'; // html type of input
  helpText?: string; // gray test on the bottom of the input
  id?: string; // id use to html for
  preffix?: string; // to add a preffix on the left side
  datepickerProps?: Partial<ReactDatePickerProps>; // Customize react-datepicker behavior
}

/**
 * RRule input components
 * @param props
 * @returns JSX.ELEMENT
 */
export const RRuleInput = (props: IRRuleProps): JSX.Element => {
  const { t } = useTranslation();
  const { value, onChange, datepickerProps } = props;
  const { setFormRRuleInputValue } = useBaseObjectFormContextContext();

  /**
   * get the start and stop value
   *
   * @param {('dtstart' | 'dtend')} field
   * @return {*}  {(Date | undefined)}
   */
  const getDtvalue = (field: 'dtstart' | 'dtend'): string | undefined => {
    if (field === 'dtstart' && value?.dtstart) {
      return parseISO(value.dtstart).toISOString();
    }
    if (field === 'dtend' && value?.dtend) {
      return parseISO(value.dtend).toISOString();
    }
  };

  const handleChange = (data: RRuleInputValue): void => {
    if (onChange) onChange(data);
    if (setFormRRuleInputValue) setFormRRuleInputValue(data);
  };

  React.useEffect(() => {
    if (setFormRRuleInputValue) setFormRRuleInputValue(value || {});
  }, [value]);
  /**
   * return the rrule object from the string value
   *
   * @return {*}  {(RRule | undefined)}
   */
  const getRRuleValue = (): RRule | undefined => {
    if (!value?.rrule) return undefined;
    try {
      return rrulestr(value.rrule);
    } catch {
      return undefined;
    }
  };

  /**
   * handle input change
   *
   * @param {RRule} val
   */
  const changeRrule = (dtstart: string): string | undefined => {
    const val = getRRuleValue();
    if (val) {
      if (dtstart) {
        val.origOptions.dtstart = parseISO(dtstart);
      }
      return val.toString();
    }
  };

  /**
   * setter for the dates values
   *
   * @param {(Date | undefined)} val
   * @param {('dtstart' | 'dtend')} field
   * @return {*}  {void}
   */
  const setDtvalue = (
    val: string | undefined,
    field: 'dtstart' | 'dtend',
  ): void => {
    if (!onChange) return;
    if (field === 'dtstart') {
      let rrule: string | undefined = value?.rrule;
      if (val) {
        rrule = changeRrule(val);
      } else {
        rrule = undefined;
      }
      handleChange({
        ...value,
        rrule: rrule,
        dtstart: val ? formatISO(new Date(val)) : undefined,
      });
    }
    if (field === 'dtend') {
      let rrule: string | undefined = value?.rrule;
      if (!val) {
        rrule = undefined;
      }
      handleChange({
        ...value,
        rrule: rrule,
        dtend: val ? formatISO(new Date(val)) : undefined,
      });
    }
  };

  /**
   * setter for the rrule string value
   *
   * @param {string} [val]
   * @return {*}  {void}
   */
  const setRRule = (val?: string): void => {
    if (!onChange) return;
    if (value) {
      handleChange({ ...value, rrule: val });
    }
  };

  const getStart = (): Date | undefined => {
    if (value?.dtstart) {
      return new Date(value.dtstart);
    }
  };

  return (
    <div>
      <div className="grid grid-cols-2 gap-3 items-center">
        <DateInput
          label={
            value?.rrule
              ? t('dateRangePicker.startOfTheFirstOccurrence')
              : t('dateRangePicker.start')
          }
          value={getDtvalue('dtstart')}
          onChange={(data): void => setDtvalue(data, 'dtstart')}
          datepickerProps={datepickerProps}
        />
        <DateInput
          label={
            value?.rrule
              ? t('dateRangePicker.endOfTheFirstOccurrence')
              : t('dateRangePicker.end')
          }
          value={getDtvalue('dtend')}
          onChange={(data): void => setDtvalue(data, 'dtend')}
          datepickerProps={datepickerProps}
        />
      </div>
      {value?.dtend && value?.dtstart && (
        <div className="mt-2">
          <RRuleGenerator
            dtStart={getStart()}
            value={value?.rrule}
            onChange={setRRule}
          />
        </div>
      )}
    </div>
  );
};
