import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { User } from '@smack/core/api/models/users/User/index';
import type { IUser } from '@smack/core/store/users/types';
import type { AxiosResponse } from 'axios';

export interface IPermissionUser extends IUser {
  categoryManage?: boolean;
  categoryPreview?: boolean;
  categoryProcess?: boolean;
  categoryRead?: boolean;
  categoryShare?: boolean;
  categoryWrite?: boolean;
  sharedBaseobjectRead?: boolean;
  sharedBaseobjectWrite?: boolean;
  sharedBaseobjectPermission?: number;
  isSharedBaseobjectEditable?: boolean;
}

export class PermissionUser extends User {
  categoryManage?: boolean;

  categoryPreview?: boolean;

  categoryProcess?: boolean;

  categoryRead?: boolean;

  categoryShare?: boolean;

  categoryWrite?: boolean;

  sharedBaseobjectRead?: boolean;

  sharedBaseobjectWrite?: boolean;

  sharedBaseobjectPermission?: number;

  isSharedBaseobjectEditable?: boolean;

  constructor(data: IPermissionUser) {
    super(data);
    this.categoryManage = data.categoryManage;
    this.categoryPreview = data.categoryPreview;
    this.categoryProcess = data.categoryProcess;
    this.categoryRead = data.categoryRead;
    this.categoryShare = data.categoryShare;
    this.categoryWrite = data.categoryWrite;
    this.sharedBaseobjectRead = data.sharedBaseobjectRead;
    this.sharedBaseobjectWrite = data.sharedBaseobjectWrite;
    this.sharedBaseobjectPermission = data.sharedBaseobjectPermission;
    this.isSharedBaseobjectEditable = data.isSharedBaseobjectEditable;
  }

  hasAtLeastOneReadPermission(): boolean {
    return !!this.categoryRead || !!this.categoryPreview;
  }

  static getUsersBySharedBaseObjectId(
    sharedBaseObjectId: number,
  ): Promise<PermissionUser[]> {
    return RESTClient.get<{ data: { results: IPermissionUser[] } }>(
      `/users/users?shared-baseobject=${sharedBaseObjectId}`,
    ).then((res) => res.data.results.map((u) => new PermissionUser(u)));
  }

  static getUserByPermissionCategoryId(
    permissionCategoryId: number,
  ): Promise<PermissionUser[]> {
    return RESTClient.get<{ data: { results: IPermissionUser[] } }>(
      `/users/users?permission-category=${permissionCategoryId}`,
    ).then((res) => res.data.results.map((u) => new PermissionUser(u)));
  }

  static shareBaseObjectToUser(
    sharedBaseObjectId: number,
    read: boolean,
    write: boolean,
    user: number,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        baseobject: sharedBaseObjectId,
        read: read,
        write: write,
        user: user,
      },
      '/users/users-baseobjects-permissions',
    );
  }

  updateShareBaseObjectToUser(
    sharedBaseObjectId: number,
    read: boolean,
    write: boolean,
    user: number,
  ): Promise<AxiosResponse> {
    return RESTClient.patch(
      {
        baseobject: sharedBaseObjectId,
        read: read,
        write: write,
        user: user,
      },
      `/users/users-baseobjects-permissions/${
        this.sharedBaseobjectPermission ?? ''
      }`,
    );
  }

  deleteShareBaseObjectToUser(): Promise<void> {
    return RESTClient.delete(
      `/users/users-baseobjects-permissions/${
        this.sharedBaseobjectPermission ?? ''
      }`,
    );
  }
}
