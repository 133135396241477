import { t } from 'i18next';
import { Button } from './Components/Button';
import {
  IconButton,
  createAliasCustomColoredIconButton,
  createAliasIconButton,
} from './Components/IconButton';

export { Button, IconButton };

export const AcceptButton = createAliasIconButton(
  { name: 'check' },
  t('buttons.accept'),
);
export const AddButton = createAliasIconButton(
  { name: 'plus' },
  t('buttons.add'),
);
export const CancelButton = createAliasIconButton(
  { name: 'times' },
  t('buttons.cancel'),
);
export const CloseButton = createAliasIconButton(
  { name: 'times' },
  t('buttons.close'),
);
export const ModifyButton = createAliasCustomColoredIconButton(
  { name: 'pencil' },
  t('buttons.edit'),
);
export const CreateButton = createAliasCustomColoredIconButton(
  { name: 'plus' },
  t('buttons.create'),
);
export const DeleteButton = createAliasCustomColoredIconButton(
  { name: 'trash-can' },
  t('buttons.delete'),
);
export const PrintButton = createAliasIconButton(
  { name: 'print' },
  t('buttons.print'),
);
export const RefuseButton = createAliasIconButton(
  { name: 'times' },
  t('buttons.refuse'),
);
export const SaveButton = createAliasCustomColoredIconButton(
  { name: 'check' },
  t('buttons.save'),
);
export const ValidateButton = createAliasCustomColoredIconButton(
  { name: 'check' },
  t('buttons.validate'),
);
export const ConfirmButton = createAliasCustomColoredIconButton(
  { name: 'check' },
  t('buttons.confirm'),
);
export const ContinueButton = createAliasCustomColoredIconButton(
  { name: 'arrow-right' },
  t('buttons.continue'),
);
export const CommentButton = createAliasCustomColoredIconButton(
  { name: 'comment' },
  t('comments.comment'),
);
export const SeeObjectButton = createAliasCustomColoredIconButton(
  { name: 'link' },
  t('linkModal.seeObject'),
);
export const SendButton = createAliasCustomColoredIconButton(
  { name: 'send' },
  t('comments.send'),
);
export const AccessButton = createAliasIconButton(
  { name: 'arrow-up-right-from-square' },
  t('buttons.access'),
);

export const StatsButton = createAliasIconButton(
  { name: 'chart-mixed' },
  t('buttons.stats'),
);

////////// LOGIN RELATED BUTTON //////////
export const LoginButton = createAliasCustomColoredIconButton(
  { name: 'lock' },
  t('formLabel.login'),
);

export const MfaSendButton = createAliasCustomColoredIconButton(
  { name: 'envelope' },
  t('formLabel.sendMfaSecret'),
);

export const ResetPasswordButton = createAliasCustomColoredIconButton(
  { name: 'paper-plane-top' },
  t('formLabel.reset-password'),
);
/////////////////////////////////////////
