import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import { Bookmark } from '@smack/core/api/models/bookmarks/Bookmark';
import type { Category } from '@smack/core/api/models/categories/Category';
import type { IBaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { IListBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListBaseObject';

import type { IBaseObjectLog } from '@smack/core/api/models/objects/BaseObjectLog';
import { CommonBaseObject } from '@smack/core/api/models/objects/NewBaseObject';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import type { BaseObjectListElementProps } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import {
  type IFilters,
  getParamsFromFilters,
  queryParamsByMode,
} from '@smack/core/utils/Filters';
import type { AxiosResponse } from 'axios';

export interface IListElementBaseObject extends IListBaseObject {
  isUnavailable?: boolean;
  baseobjectGroupId?: number;
}

/**
 * ListElementBaseObject class for BaseObject Model.
 * Used for BaseObject listing.
 *
 */
export class ListElementBaseObject extends CommonBaseObject<IListElementBaseObject> {
  isUnavailable?: boolean;

  bookmark?: Bookmark;

  baseobjectGroupId?: number;

  constructor(data: IListElementBaseObject) {
    super(data);

    this.isUnavailable = data.isUnavailable;
    this.baseobjectGroupId = data.baseobjectGroupId;
    this.bookmark = data.bookmarkId
      ? new Bookmark({ id: data.bookmarkId })
      : undefined;
  }

  static getBaseObjectForListRepresentation(
    parentCategory?: Category,
    filters?: IFilters,
    offset?: number,
    signal?: AbortSignal,
    links?: {
      linkGroupId: number;
      sourceBaseObjectId?: number;
      includeLinkables?: boolean;
    },
    customParams?: Record<string | number, unknown>,
  ): Promise<IPagination<IListElementBaseObject>> {
    return RESTClient.get<AxiosResponse<IPagination<IListElementBaseObject>>>(
      '/objects/baseobjects?with-list-element-attributes=1',
      getParamsFromFilters(filters ?? {}, queryParamsByMode.standard, {
        parentCategory: parentCategory?.id,
        links,
        offset,
        additionalQueryParam: customParams ?? {},
      }),
      undefined,
      signal,
    ).then((res) => res.data);
  }

  static getRecentBaseObjects(
    limit?: number,
    offset?: number,
  ): Promise<IPagination<IBaseObjectLog>> {
    return RESTClient.get<AxiosResponse<IPagination<IBaseObjectLog>>>(
      '/objects/baseobject-logs/recent',
      { limit, offset },
    ).then((res) => res.data);
  }

  static async searchBaseObjects(
    search: string,
    abortSignal?: AbortSignal,
    limit?: number,
    offset?: number,
  ): Promise<IPagination<ListElementBaseObject>> {
    const res = await RESTClient.post<{
      count: number;
      next: string;
      previous: string;
      results: IListBaseObject[];
    }>(
      {
        limit,
        offset,
        q: search,
      },
      '/objects/baseobjects/search',
      undefined,
      undefined,
      abortSignal,
    );
    return {
      ...res.data,
      results: res.data.results.map((r) => new ListElementBaseObject(r)),
    };
  }

  /**
   * Get list element props
   *
   * @return {*}  {IListElementProps}
   * @memberof CommonBaseObject
   */
  getListElement(
    action?: IListElementAction,
    active = false,
  ): BaseObjectListElementProps {
    return {
      id: this.id,
      action,
      active,
      title: this.title,
      subtitle: this.subtitle,
      bottomLeft: this.bottomLeft,
      bottomRight: this.bottomRight,
      badge: this.badge,
      date: this.startAt,
      icon: this.category?.icon,
      link: this.frontEndpoint,
      key: this.id,
      isLoader: false,
      isForbidden: !this.category?.isReadable,
      color: this.color,
      isUnavailable: this.isUnavailable,
    };
  }

  /**
   * Get the base object for the panel header
   *
   * @static
   * @param {(number | string)} id
   * @return {*}  {Promise<BaseObject>}
   * @memberof BaseObject
   */
  static getListElementBaseObject(
    id: number | string,
    scheduleId?: number | string,
  ): Promise<ListElementBaseObject> {
    return RESTClient.get<{ data: { results: IListElementBaseObject } }>(
      `/objects/baseobjects/${id}?with-badge=1`,
      { schedule: scheduleId || null },
    ).then((res) => new ListElementBaseObject(res.data?.results));
  }

  async createBookmark(): Promise<void> {
    const response = await RESTClient.post<{ id: number }>(
      { baseobject: this.id },
      '/bookmarks/bookmarks',
    );
    this.bookmark = new Bookmark({ id: response.data.id });
  }

  async deleteBookmark(): Promise<void> {
    if (!this.bookmark) return Promise.reject('This object is not bookmarked');
    await this.bookmark.delete();
    this.bookmark = undefined;
  }

  touchBookmark(): Promise<void> {
    if (this.bookmark) return this.bookmark.touch();
    return Promise.reject('This object is not bookmarked');
  }

  static getBaseObjectFromKanbanColumnId(
    kanbanId: number,
    offset?: number,
    category?: Category,
    filters?: IFilters,
  ): Promise<IPagination<ListElementBaseObject>> {
    return RESTClient.get<{ data: { results: IBaseObject[] } }>(
      '/objects/baseobjects',
      {
        'kanban-column': kanbanId,
        'order-by': `KANBAN-COLUMN-${kanbanId}`,
        'with-thumbnail': 'True',
        'parent-category': category?.parentCategoryId,
        'category-in': [category?.id],
        'with-list-element-attributes': 1,
        limit: 10,
        offset: offset ?? 0,
        ...getParamsFromFilters(filters ?? {}, queryParamsByMode.standard),
      },
    ).then((res) => {
      return {
        ...res.data,
        results: res.data.results.map((r) => new ListElementBaseObject(r)),
      };
    });
  }
}
