import type { BaseObjectLog } from '@smack/core/api/models/objects/BaseObjectLog';
import { useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const BaseObjectLogDeleteFeed = (
  props: ILogFeedProps<BaseObjectLog>,
): JSX.Element => {
  const { log } = props;

  const { t } = useTranslation();

  return (
    <LogFeed {...props}>
      <span className="text-sm font-normal">
        {' '}
        {t('logs.baseobject.delete', {
          code: log.contentTitle,
        })}
      </span>
    </LogFeed>
  );
};
