import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { useState } from 'react';
import Column from './Column';
import { shoudlSectionBeChecked } from './utils';

const Section = (
  {
    section,
    hiddenColumns,
    columnPinning,
    onPinningChange,
    onDragEnd,
    onCheckboxChange,
  } /*: SectionProps*/,
) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { setNodeRef, attributes, listeners, transform, transition } =
    useSortable({
      id: section.id,
    });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8, // means that the pointer must move at least 8 pixels before being activated.
      },
    }),
    useSensor(KeyboardSensor),
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="section"
    >
      <div className="text-text w-full min-w-[430px]">
        <CheckboxInput
          data-testid={`section:${section.id}`}
          key={`section:${section.id}`}
          value={shoudlSectionBeChecked(section, hiddenColumns)}
          onChange={(value: boolean) =>
            onCheckboxChange(
              section.items.map((a) => a?.accessorKey.toString()) ?? [],
              !value,
            )
          }
          className={{
            container: 'p-2 last:border-0 pl-5',
            subContainer: '[&&]:pl-6',
            label: 'text-text',
            labelContainerClassName: 'flex grow self-center',
          }}
          label={section.header}
          prefixIcon={{
            name: 'grip-dots',
            className: '[&&]:pl-1',
          }}
          suffixElement={
            <Icon
              className={`absolute right-2 top-3 cursor-pointer transition-transform ease-linear ${
                isCollapsed ? 'rotate-180' : ''
              }`}
              icon={{ name: 'chevron-down' }}
              onClick={() => setIsCollapsed(!isCollapsed)}
            />
          }
        />
      </div>
      {!isCollapsed && (
        <DndContext sensors={sensors} onDragEnd={onDragEnd}>
          <SortableContext
            items={section.items.map((item) => item.id)}
            strategy={verticalListSortingStrategy}
          >
            {section.items.map((item) => (
              <Column
                key={item.id}
                column={item}
                hiddenColumns={hiddenColumns}
                onCheckboxChange={onCheckboxChange}
                columnPinning={columnPinning}
                onPinningChange={onPinningChange}
              />
            ))}
          </SortableContext>
        </DndContext>
      )}
    </div>
  );
};

export default Section;
