export const getAccessorKeys = (data): string[] => {
  const flattenedData = data.flatMap((section) =>
    section.items.map((item: { accessorKey: string }) => item.accessorKey),
  );

  return flattenedData;
};

export const shoudlSectionBeChecked = (section, hiddenColumns) => {
  const hiddenColumnsSet = new Set(hiddenColumns);
  return !section.items
    .map((a) => a?.accessorKey as string)
    .every((v) => hiddenColumnsSet.has(v));
};

export const shouldColumnBeChecked = (col, hidden) => {
  const hiddenColumnsSet = new Set(hidden);
  return !hiddenColumnsSet.has(col.accessorKey);
};

export const formatDataForDndKit = (data, columnOrder) => {
  // Reorder columns within each section based on columnOrder
  const reorderedSections = data.map((section) => {
    const orderedColumns = section.columns.sort((a, b) => {
      const indexA = columnOrder.indexOf(a.accessorKey);
      const indexB = columnOrder.indexOf(b.accessorKey);
      return indexA - indexB;
    });

    return {
      ...section,
      items: orderedColumns,
    };
  });

  // Now reorder the sections themselves based on the first column in columnOrder
  const orderedSectionsFinal = reorderedSections.sort((a, b) => {
    const firstColumnA = a.items[0];
    const firstColumnB = b.items[0];
    const indexA = columnOrder.indexOf(firstColumnA.accessorKey);
    const indexB = columnOrder.indexOf(firstColumnB.accessorKey);
    return indexA - indexB;
  });

  // Return the final ordered sections
  return orderedSectionsFinal.map((section) => ({
    id: `section-${section.header}`,
    type: 'section',
    header: section.header,
    isCollapsed: false,
    items: section.items.map((column) => ({
      id: `column-${column.accessorKey}`,
      type: 'column',
      ...column,
    })),
  }));
};
