import type { IPagination } from '@smack/core/api/clients/rest/RESTClient';
import { ApiError } from '@smack/core/api/errors/ApiError/ApiError';
import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  type IListElementBaseObject,
  ListElementBaseObject,
} from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import {
  BaseObjectListElement,
  type BaseObjectListElementProps,
} from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { usePagination } from '@smack/core/hooks/usePagination/usePagination';
import type { IFilters } from '@smack/core/utils/Filters';
import {
  BaseObjectSkeleton,
  ListSkeleton,
  LoaderSkeleton,
} from '@smack/core/utils/Loader';
import { InfiniteVirtualizedList } from '@smack/core/views/oldViewsToSort/Layouts/Objects/InfiniteVirtualizedList';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface UnlinkBaseObjectListProps {
  categoryId: number;
  filters?: IFilters;
  linkGroup: LinkGroup;
  onQuit?: () => void;
  onSubmit?: (object: BaseObject) => void;
}

export const UnlinkBaseObjectList: React.FC<UnlinkBaseObjectListProps> = ({
  categoryId,
  linkGroup,
  onSubmit,
  onQuit,
  filters,
}) => {
  const [baseObjectValue, setBaseObjectValue] = React.useState<BaseObject>();

  const [t] = useTranslation();

  const getBaseObjects = (): Promise<IPagination<IListElementBaseObject>> => {
    if (!categoryId) return Promise.reject(new Error('no Set'));

    return ListElementBaseObject.getBaseObjectForListRepresentation(
      undefined,
      { categories: [categoryId.toString()], ...filters },
      undefined,
      undefined,
      linkGroup
        ? {
            linkGroupId: linkGroup?.id,
          }
        : undefined,
    ).catch(ApiError.toastAllErrorsOnCatchAxiosErrors);
  };

  const { results, isInitialLoading, loadInitial, count, next } = usePagination(
    {
      query: getBaseObjects,
      transformer: (data: IListElementBaseObject) =>
        new ListElementBaseObject(data),
    },
  );

  React.useEffect(() => {
    if (categoryId) {
      loadInitial();
    }
  }, [filters, categoryId]);

  const renderButton = (
    listElementProps: BaseObjectListElementProps,
  ): JSX.Element => {
    const object = results.find((o) => o.id === listElementProps.id);
    if (!object) return <></>;
    return (
      <BaseObjectListElement
        {...listElementProps}
        active={listElementProps.id === baseObjectValue?.id}
        onClick={(): void => {
          setBaseObjectValue(object as BaseObject);
        }}
        link={undefined}
        noOverlayActions
      />
    );
  };

  const getObjectList = (): React.ReactNode => {
    if (!isInitialLoading && !results.length) {
      return (
        <NoContentMessage
          label={t('batchLinkForm.removeLink.noLinkToUnlink')}
          icon={{ name: 'search' }}
        />
      );
    }
    return (
      <InfiniteVirtualizedList
        objects={results.map((o) => o.getListElement())}
        renderListItem={renderButton}
        startIndex={0}
        loadMoreRows={next}
        rowCount={count}
      />
    );
  };

  const handleOnQuit = () => {
    onQuit?.();
  };
  const handleOnSubmit = () => {
    if (!baseObjectValue) return;
    onSubmit?.(baseObjectValue);
  };

  return (
    <div className="my-0 mx-auto  h-96 flex flex-col gap-3">
      <div className={'min-w-[400px] flex-grow flex flex-col '}>
        <h3 className="block  font-medium text-gray-800 dark:text-gray-200 mb-4">
          {t('batchLinkForm.removeLink.whatIstheObject')}
        </h3>
        <div className={'flex  flex-grow'}>
          {!isInitialLoading ? (
            getObjectList()
          ) : (
            <LoaderSkeleton height={240} width={400} className="overflow-clip">
              <ListSkeleton component={BaseObjectSkeleton} iterations={5} />
            </LoaderSkeleton>
          )}
        </div>
      </div>
      <div className="flex items-center justify-end gap-3">
        <CancelButton
          onClick={handleOnQuit}
          data-testid="UnlinkBaseObjectCancelButton"
        />
        <SaveButton
          onClick={handleOnSubmit}
          data-testid="unlinkBaseObjectSaveButton"
        />
      </div>
    </div>
  );
};
