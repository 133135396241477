import bbox from '@turf/bbox';
import {
  type GeoJSONSource,
  type LngLatLike,
  Map as MaplibreMap,
} from 'maplibre-gl';
import React from 'react';
import { useSelector } from 'react-redux';

// import scss
import '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/MiniMap/index.scss';
import 'maplibre-gl/dist/maplibre-gl.css';
import type { AppState } from '@smack/core/store';
import { MiniMapUtils } from '@smack/core/utils/MiniMapUtils';
import { buildMapStyle } from '@smack/core/views/oldViewsToSort/Views/Map/Utils/mapinit';
import type { Point } from 'geojson';

interface ISettings {
  zoom_min: number;
  zoom_max: number;
  zoom: number;
  center: [number, number];
}

interface IProps {
  mobile?: boolean;
  location: GeoJSON.Feature | undefined;
  geometry: GeoJSON.FeatureCollection | undefined;
}

export const MiniMap = (props: IProps): JSX.Element => {
  const { location, geometry, mobile } = props;
  const { basemaps } = useSelector((state: AppState) => state.Maps);
  const { settings } = useSelector((state: AppState) => state.App);

  const GetSettings = (): ISettings => {
    let setting: ISettings = {
      zoom_min: 1,
      zoom_max: 18,
      zoom: 15,
      center: [2.7450921314582715, 46.98344582546212],
    };
    if (settings) {
      setting = {
        zoom_min: settings.mapZoomMin || setting.zoom_min,
        zoom_max: settings.mapZoomMax || setting.zoom_max,
        zoom: settings.mapInitialZoom || setting.zoom,
        center: [
          settings.mapLocation?.coordinates[0] || setting.center[0],
          settings.mapLocation?.coordinates[1] || setting.center[1],
        ],
      };
    }
    const point = location as GeoJSON.Feature<Point> | undefined;
    if (point) {
      setting.center = [
        point?.geometry?.coordinates[0],
        point.geometry.coordinates[1],
      ];
    }
    return setting;
  };

  const setGeom = (): void => {
    const map = window.Hyvilo.Utils.MiniMap;
    if (!geometry || !map) return;

    if (geometry) {
      if (map.getSource('focused-features')) {
        (map.getSource('focused-features') as GeoJSONSource)?.setData(geometry); // NOSONAR
      } else {
        map.addSource('focused-features', { type: 'geojson', data: geometry });
        if (!map.getLayer('focused-geometry')) {
          map.addLayer({
            id: 'focused-geometry',
            type: 'fill',
            source: 'focused-features',
            paint: {
              'fill-color': 'red',
              'fill-opacity': 0.3,
            },
            filter: ['==', '$type', 'Polygon'],
          });
          map.addLayer({
            id: 'focused-linestring',
            type: 'line',
            source: 'focused-features',
            paint: {
              'line-color': 'red',
              'line-opacity': 1,
            },
            filter: ['==', '$type', 'LineString'],
          });
        }
      }
      try {
        map.fitBounds(
          [
            [bbox(geometry)[0], bbox(geometry)[1]],
            [bbox(geometry)[2], bbox(geometry)[3]],
          ],
          { padding: 30 },
        );
      } catch {
        // #endregion
      }
    }
  };

  const setPoint = (): void => {
    const map = window.Hyvilo.Utils.MiniMap;
    if (!location || !map) return;

    if (location) {
      MiniMapUtils.addMiniMapPoint(map, location);

      const point = location as GeoJSON.Feature<Point> | undefined;
      if (point) {
        map.flyTo({
          center: [
            point.geometry.coordinates[0],
            point.geometry.coordinates[1],
          ],
          zoom: 15,
        });
      }
      setGeom();
    }
  };

  const printEventGeomOnMiniMap = (): void => {
    const map = window.Hyvilo.Utils.MiniMap;
    if (!map || !map?.isStyleLoaded()) setTimeout(printEventGeomOnMiniMap, 250);
    else setPoint();
  };

  React.useEffect(() => {
    let previousCenter: LngLatLike | undefined = undefined;
    if (window.Hyvilo.Utils.MiniMap) {
      previousCenter = window.Hyvilo.Utils.MiniMap.getCenter();
      window.Hyvilo.Utils.MiniMap.remove();
      window.Hyvilo.Utils.MiniMap = null;
    }
    if (basemaps.length && settings) {
      const setting = GetSettings();
      if (previousCenter === undefined) previousCenter = setting.center;
      window.Hyvilo.Utils.MiniMap = new MaplibreMap({
        container: 'minimap',
        style: buildMapStyle(basemaps),
        minZoom: setting.zoom_min,
        maxZoom: 17.9,
        zoom: setting.zoom,
        center: previousCenter,
        interactive: false,
      });
      window.Hyvilo.Utils.MiniMap.on('load', () => {
        printEventGeomOnMiniMap();
      });
    }
  }, [location, basemaps, settings]);

  return <div style={{ width: mobile ? '100vw' : '400px' }} id="minimap" />;
};

MiniMap.defaultProps = {
  mobile: false,
};
