import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';

export interface IGroup {
  id: number;
  label: string;
  users: IUser[];
  previewableCategories?: number[];
  readableCategories?: number[];
  writableCategories?: number[];
  shareableCategories?: number[];
  manageableCategories?: number[];
  processableCategories?: number[];
}

export class Group {
  id: number;

  label: string;

  users: User[];

  previewableCategories?: number[];

  readableCategories?: number[];

  writableCategories?: number[];

  shareableCategories?: number[];

  manageableCategories?: number[];

  processableCategories?: number[];

  constructor(group: IGroup) {
    this.id = group.id;
    this.label = group.label;
    this.users = group.users.map((user) => new User(user));
    this.previewableCategories = group.previewableCategories;
    this.readableCategories = group.readableCategories;
    this.writableCategories = group.writableCategories;
    this.shareableCategories = group.shareableCategories;
    this.manageableCategories = group.manageableCategories;
    this.processableCategories = group.processableCategories;
  }

  static getGroupsFromCategoryPermissionId(
    categoryPermissionId: number,
  ): Promise<Group[]> {
    return RESTClient.get<{ data: { results: IGroup[] } }>(
      `/users/groups?permission-category=${categoryPermissionId}`,
    ).then((res) => res.data.results.map((g) => new Group(g)));
  }
}
