import { SaveButton } from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  setOpen: (val: boolean) => void;
  onSubmit: (value: string) => void;
}

export const LinkGroupForm = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { open, setOpen: realSetOpen, onSubmit } = props;
  const { control, formState, handleSubmit, reset } = useForm();

  const submitForm = (data: { [x: string]: string }): void => {
    onSubmit(data.label);
    reset();
  };

  const setOpen = (opened: boolean): void => {
    realSetOpen(opened);
    if (!opened) {
      reset();
    }
  };

  return (
    <Modal
      icon={{ name: 'stairs' }}
      open={open}
      onClose={setOpen}
      title={t('links.createFloor')}
    >
      <form onSubmit={handleSubmit(submitForm)} className="max-w-xs mx-auto">
        <Controller
          control={control}
          name={'label'}
          rules={{ required: true }}
          render={({ field }): JSX.Element => (
            <Input
              {...field}
              id="floor-creation-label"
              label={t('formInputLabels.name')}
              error={
                formState.errors.label ? t('formErrors.requiredField') : ''
              }
            />
          )}
        />

        <div className="mt-5 flex items-center justify-end">
          <SaveButton type="submit" />
        </div>
      </form>
    </Modal>
  );
};
