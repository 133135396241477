import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type useSetScheduleIdOutput = [number | undefined];

export const useScheduleId = (): useSetScheduleIdOutput => {
  const [searchPrams] = useSearchParams();

  const scheduleId = useMemo(() => {
    const schedule = searchPrams.get('schedule');
    if (!schedule) return;
    return Number.parseInt(schedule);
  }, [searchPrams]);

  return [scheduleId];
};
