import type { ISelectOption } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { t } from 'i18next';
import { type Frequency, RRule } from 'rrule';

export enum RecurenceNumber {
  FIRST = 1,
  SECOND = 2,
  THIRD = 3,
  FOURTH = 4,
  LAST = -1,
}

export enum RecurenceEnd {
  NEVER = 0,
  AFTER = 1,
  ONDATE = 2,
}

export const getWeekdayOfMonth = (date: Date): number => {
  const year = date.getFullYear();
  const month = date.getMonth();
  const firstDayOfMonth = new Date(year, month, 1);
  let count = 0;

  for (
    let day = firstDayOfMonth;
    day.getMonth() === month;
    day.setDate(day.getDate() + 1)
  ) {
    if (day.getDay() === date.getDay()) {
      count++;
      if (day.getDate() === date.getDate()) {
        return count;
      }
    }
  }

  return 0;
};

export const getRecurenceTypeOptions = (): ISelectOption[] => {
  return [
    {
      label: t('recurrence.capYears'),
      value: RRule.YEARLY,
    },
    {
      label: t('recurrence.capMonths'),
      value: RRule.MONTHLY,
    },
    {
      label: t('recurrence.capWeeks'),
      value: RRule.WEEKLY,
    },
    {
      label: t('recurrence.capDays'),
      value: RRule.DAILY,
    },
  ];
};

export const getRecurenceNumberStringOptions = (): ISelectOption[] => {
  return [
    {
      label: t('recurrence.first') ?? '',
      value: RecurenceNumber.FIRST,
    },
    {
      label: t('recurrence.second') ?? '',
      value: RecurenceNumber.SECOND,
    },
    {
      label: t('recurrence.third') ?? '',
      value: RecurenceNumber.THIRD,
    },
    {
      label: t('recurrence.fourth') ?? '',
      value: RecurenceNumber.FOURTH,
    },
    {
      label: t('recurrence.last') ?? '',
      value: RecurenceNumber.LAST,
    },
  ];
};

export const getRecurenceTypeDays = (): Option[] => {
  return [
    {
      label: t('recurrence.dayOfWeek.monday') ?? '',
      value: 0,
    },
    {
      label: t('recurrence.dayOfWeek.tuesday') ?? '',
      value: 1,
    },
    {
      label: t('recurrence.dayOfWeek.wednesday') ?? '',
      value: 2,
    },
    {
      label: t('recurrence.dayOfWeek.thursday') ?? '',
      value: 3,
    },
    {
      label: t('recurrence.dayOfWeek.friday') ?? '',
      value: 4,
    },
    {
      label: t('recurrence.dayOfWeek.saturday') ?? '',
      value: 5,
    },
    {
      label: t('recurrence.dayOfWeek.sunday') ?? '',
      value: 6,
    },
  ];
};

export const getDaysNumber = (): ISelectOption[] => {
  const output: ISelectOption[] = [];

  for (let i = 1; i < 32; i++) {
    output.push({
      label: i.toString(),
      value: i,
    });
  }
  return output;
};

export const getWeekdayByNumber = (weekday: number): string => {
  const weekdays = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
  return weekdays[weekday];
};

export const getRRuleNumber = (
  num: number[][] | number | number[] | string[] | undefined | null,
): number | string | undefined => {
  if (num && typeof num === 'number') {
    return num;
  }

  if (num && typeof num === 'object') {
    try {
      if (typeof num[0] === 'number') {
        return num[0];
      }
      return num[0][0];
    } catch {
      return undefined;
    }
  }
};

function translateFrequency(freq: Frequency, interval: number): string {
  switch (freq) {
    case RRule.DAILY:
      return interval === 1
        ? `${t('recurrence.every')} ${t('recurrence.days')}`
        : `${t('recurrence.every')} ${interval} ${t('recurrence.days')}`;
    case RRule.WEEKLY:
      return interval === 1
        ? `${t('recurrence.everyWeek')} ${t('recurrence.week')}`
        : `${t('recurrence.everyWeek')} ${interval} ${t('recurrence.week')}`;
    case RRule.MONTHLY:
      return interval === 1
        ? `${t('recurrence.every')} ${t('recurrence.month')}`
        : `${t('recurrence.every')} ${interval} ${t('recurrence.month')}`;
    case RRule.YEARLY:
      return interval === 1
        ? `${t('recurrence.every')} ${t('recurrence.year')}`
        : `${t('recurrence.every')} ${interval} ${t('recurrence.year')}`;
    default:
      return '';
  }
}

function translateByDay(byDay: number | string, nth: number): string {
  const weekdays = [
    t('recurrence.dayOfWeek.monday'),
    t('recurrence.dayOfWeek.tuesday'),
    t('recurrence.dayOfWeek.wednesday'),
    t('recurrence.dayOfWeek.thursday'),
    t('recurrence.dayOfWeek.friday'),
    t('recurrence.dayOfWeek.saturday'),
    t('recurrence.dayOfWeek.sunday'),
  ];

  if (typeof byDay === 'number') {
    const dayIndex = Math.abs(byDay);
    const weekday = weekdays[dayIndex];
    if (nth === 0) {
      return weekday;
    }
    if (nth === 1) {
      return `${t('recurrence.first')} ${weekday}`;
    }
    if (nth === 2) {
      return `${t('recurrence.second')} ${weekday}`;
    }
    if (nth === 3) {
      return `${t('recurrence.third')} ${weekday}`;
    }
    if (nth === 4) {
      return `${t('recurrence.fourth')} ${weekday}`;
    }
    if (nth === -1) {
      return `${t('recurrence.last')} ${weekday}`;
    }
    return `${nth}${t('recurrence.numbers')} ${weekday}`;
  }
  return byDay;
}

function translateByMonth(byMonth: number): string {
  const months = [
    t('recurrence.monthOfYear.january'),
    t('recurrence.monthOfYear.february'),
    t('recurrence.monthOfYear.march'),
    t('recurrence.monthOfYear.april'),
    t('recurrence.monthOfYear.may'),
    t('recurrence.monthOfYear.june'),
    t('recurrence.monthOfYear.july'),
    t('recurrence.monthOfYear.august'),
    t('recurrence.monthOfYear.september'),
    t('recurrence.monthOfYear.october'),
    t('recurrence.monthOfYear.november'),
    t('recurrence.monthOfYear.december'),
  ];
  return months[byMonth - 1];
}

export function getHumanReadableRecurence(rrule: RRule): string {
  const freqTranslation = translateFrequency(
    rrule.options.freq,
    rrule.options.interval,
  );

  let result = `${freqTranslation}`;

  if (rrule.options.byweekday && rrule.options.byweekday.length > 0) {
    if (rrule.options.byweekday.length === 7) {
      result += `,${t('recurrence.everyWeekday')}`;
    } else if (
      rrule.options.byweekday.length === 5 &&
      rrule.options.byweekday.every((n) => n <= 4)
    ) {
      result += `,${t('recurrence.fromMondayToFriday')}`;
    } else {
      const byDayText = rrule.options.byweekday.map((byDay) => {
        return translateByDay(
          byDay,
          rrule.options.bysetpos ? rrule.options.bysetpos[0] : 0,
        );
      });
      result += `, ${t('recurrence.on')} ${byDayText.join(', ')}`;
    }
  }

  if (rrule.options.bymonthday && rrule.options.bymonthday.length > 0) {
    result += `  ${t('recurrence.on')} ${rrule.options.bymonthday.join(', ')}`;
  }

  if (rrule.options.bymonth && rrule.options.bymonth.length > 0) {
    const byMonthText = rrule.options.bymonth.map((byMonth: number) => {
      return translateByMonth(byMonth);
    });
    result += ` ${t('recurrence.in')} ${byMonthText.join(', ')}`;
  }

  if (rrule.options.byyearday && rrule.options.byyearday.length > 0) {
    result += `, ${t('recurrence.on')} ${rrule.options.byyearday.join(', ')}`;
  }

  if (rrule.options.until) {
    const untilDate = rrule.options.until.toLocaleDateString(
      navigator.language,
      {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      },
    );

    result += `,  ${t('recurrence.until')} ${untilDate}`;
  }

  if (rrule.options.count) {
    result += `, ${t('recurrence.endAfter')} ${
      rrule.options.count
    } ${t('recurrence.occurrences')}`;
  }

  return result;
}
