import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup/BaseObjectGroup';
import { BaseObjectSchedule } from '@smack/core/api/models/objects/BaseObjectSchedule';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { DeleteConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { spawnModal } from '@smack/core/utils/modal';
import {
  RecurrenceUpdateChoiceObjectModal,
  TypeRecurrenceUpdateChoice,
} from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import { toast } from 'react-hot-toast';
import { getI18n } from 'react-i18next';

export const onDeleteRecurrence = (
  baseobject: BaseObject,
  deleteType: TypeRecurrenceUpdateChoice,
  afterDelete: () => void,
): void => {
  if (deleteType && baseobject.scheduleId && baseobject?.id) {
    setTimeout(() => {
      spawnModal({
        render: ({ onClose }): JSX.Element => {
          return (
            <DeleteConfirmAlert
              onCloseModal={onClose}
              onCloseButton={onClose}
              text={
                deleteType === TypeRecurrenceUpdateChoice.ALL
                  ? getI18n().t('delete.deleteAllRecurrence')
                  : undefined
              }
              onOk={(): void => {
                if (deleteType === TypeRecurrenceUpdateChoice.ALL) {
                  baseobject.delete().then(afterDelete);
                } else {
                  BaseObjectSchedule.patchBaseObjectSchedule(baseobject?.id, {
                    update:
                      deleteType === TypeRecurrenceUpdateChoice.THIS
                        ? 'REMOVE_ONE'
                        : 'REMOVE_FUTURE',
                    updateFromSchedule: baseobject.scheduleId,
                  }).then(afterDelete);
                }
              }}
              securityText={
                deleteType === TypeRecurrenceUpdateChoice.ALL
                  ? baseobject?.code
                  : undefined
              }
            />
          );
        },
      });
    }, 200);
  }
};

export const onDeleteBaseObjectFromGroup = (
  baseobject: BaseObject,
  deleteType: TypeRecurrenceUpdateChoice,
  afterDelete: () => void,
): void => {
  if (deleteType && baseobject?.id) {
    setTimeout(() => {
      spawnModal({
        render: ({ onClose }): JSX.Element => {
          return (
            <DeleteConfirmAlert
              onCloseModal={onClose}
              onCloseButton={onClose}
              text={
                deleteType === TypeRecurrenceUpdateChoice.ALL
                  ? getI18n().t('delete.deleteAllRecurrence')
                  : undefined
              }
              onOk={(): void => {
                if (deleteType === TypeRecurrenceUpdateChoice.THIS) {
                  baseobject
                    ?.delete()
                    .then(afterDelete)
                    .catch(() => {
                      toast(getI18n().t('errorMessage.baseobjectDeleteFail'));
                    });
                } else {
                  BaseObjectGroup.deleteBaseObjects(baseobject, deleteType)
                    .then(afterDelete)
                    .catch(() => {
                      toast(getI18n().t('errorMessage.baseobjectDeleteFail'));
                    });
                }
              }}
            />
          );
        },
      });
    }, 200);
  }
};

export const onClickDelete = (
  baseobject: BaseObject,
  afterDelete: () => void,
): void => {
  if (!baseobject) return;
  const schedule = baseobject.scheduleId;
  if ((baseobject?.rrule && schedule) || baseobject?.baseobjectGroupId) {
    spawnModal({
      render: ({ onClose }): JSX.Element => {
        return (
          <RecurrenceUpdateChoiceObjectModal
            title={getI18n().t('recurrenceChoiceObjectModal.deleteObjectTitle')}
            open={true}
            icon={{ name: 'trash-can' }}
            onClose={onClose}
            description={getI18n().t(
              'recurrenceChoiceObjectModal.ObjectDescription',
              {
                action: getI18n().t(
                  'recurrenceChoiceObjectModal.ObjectDescriptionDeleteAction',
                ),
              },
            )}
            onSave={(val): void => {
              if (baseobject?.baseobjectGroupId) {
                onDeleteBaseObjectFromGroup(baseobject, val, afterDelete);
              } else if (schedule) {
                onDeleteRecurrence(baseobject, val, afterDelete);
              }
            }}
          />
        );
      },
    });
  } else {
    spawnModal({
      render: ({ onClose }): JSX.Element => {
        return (
          <DeleteConfirmAlert
            onCloseModal={onClose}
            onCloseButton={onClose}
            onOk={(): void => {
              baseobject.delete().then(afterDelete);
            }}
          />
        );
      },
    });
  }
};
