import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import { useSearchParams } from '@smack/core/hooks/useSearchParams';
import type { AppState } from '@smack/core/store';
import {
  fetchFocusedBaseObject,
  setFocusedBaseObject,
} from '@smack/core/store/app/actions';
import { fetchCategoriesNotificationCounters } from '@smack/core/store/categories/actions';
import { setFullTitlePage } from '@smack/core/utils';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import { BaseObjectPanelContext } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/Context';
import FocusedObjectPanel from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/FocusedObjectPanel';
import { printEventGeomOnMap } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/Utils/utils';
import { BasePanel } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/BasePanel';
import { HeaderCarrousel } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Caroussel';
import { LeftPanelContainer } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/LeftPanelContainer';
import { NotFoundPage } from '@smack/core/views/oldViewsToSort/Views/NotFoundPage';
import { ServerError } from '@smack/core/views/oldViewsToSort/Views/ServerError';
import axios, { type AxiosError } from 'axios';
import React, { useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

interface IProps {
  mobile?: boolean;
  onQuit?: () => void;
  objectId?: string;
}

export const BaseObjectPanel = ({
  mobile,
  onQuit,
  objectId,
}: IProps): JSX.Element => {
  // Get object ID from the prop or from the URL params
  const { object: urlObject } = useParams();
  const object = objectId ?? urlObject;
  const [rootCategory] = useActiveCategories();
  const navigate = useNavigation();
  const dispatch = useDispatch();
  const { focusedObject } = useSelector((app: AppState) => app.App.module);
  const { settings } = useSelector((app: AppState) => app.App);
  const [carouselReloadIncrement, setCarouselReloadIncrement] =
    React.useState<number>(0);
  const [availableViews, setAvailableViews] =
    React.useState<BaseObjectView[]>();
  const hasPreview = useMemo(
    () =>
      !!availableViews &&
      availableViews.findIndex(
        (availableView) => availableView.usage === ViewUsage.PREVIEW,
      ) !== -1,
    [availableViews],
  );
  const [isCollapsed, setIsCollapsed] = React.useState(false);
  const [notFound, setNotFound] = React.useState(false);
  const [error, setError] = React.useState<AxiosError>();
  const [isLoadingBookmark, setIsLoadingBookmark] = React.useState(false);
  const [t] = useTranslation();
  const [params, setParams] = useSearchParams();
  const matomo = useMatomo();

  const reloadCarousel = (): void => {
    setCarouselReloadIncrement(carouselReloadIncrement + 1);
  };

  const flipCollapse = React.useCallback(() => {
    setIsCollapsed((collapsed) => !collapsed);
  }, []);

  const setPreviewObject = (): void => {
    if (focusedObject) {
      if (focusedObject.category?.id !== params.category) {
        setParams({
          ...params,
          category: focusedObject.category?.id.toString(),
        });
      }
      setFullTitlePage([
        focusedObject.label,
        rootCategory?.label,
        settings?.displayedLabel,
      ]);

      printEventGeomOnMap(focusedObject);
      if (settings?.isBaseobjectOnOpenCenter) {
        const { MainMap } = window.Hyvilo.Utils;
        focusedObject.flyTo(MainMap);
      }
      if (focusedObject.bookmark) focusedObject.touchBookmark();
      dispatch(fetchCategoriesNotificationCounters());
      matomo.trackEvent({
        category: 'BaseObject',
        action: 'openDetails',
        name: focusedObject.category?.label,
      });
    }
  };

  React.useEffect(() => {
    setPreviewObject();
    if (focusedObject) {
      printEventGeomOnMap(focusedObject);
    }
  }, [focusedObject]);

  React.useEffect(() => {
    return () => {
      printEventGeomOnMap();
      dispatch(setFocusedBaseObject());
    };
  }, []);

  const fetchBaseobject = (): void => {
    dispatch(setFocusedBaseObject());
    setAvailableViews(undefined);
    const schedule = params.schedule ?? undefined;
    if (object) {
      const objectId = Number.parseInt(object);
      BaseObjectView.getBaseObjectViewsByObjectId(objectId, schedule).then(
        setAvailableViews,
      );
      dispatch(
        fetchFocusedBaseObject(objectId, schedule, (err) => {
          if (import.meta.env.DEV) reportError(err);
          if (schedule) {
            navigate('../');
          } else if (
            axios.isAxiosError(err) &&
            (err.response?.status ?? 599) >= 500
          ) {
            setError(err);
          } else {
            setNotFound(true);
          }
        }),
      );
    }
  };

  const isBaseObjectLoading = (): boolean => {
    return !!(
      !focusedObject ||
      !availableViews ||
      (object && Number.parseInt(object) !== focusedObject.id)
    );
  };

  React.useEffect(() => {
    fetchBaseobject();
  }, [object, params.schedule]);

  if (!object) return <BasePanel mobile={mobile} active={false} />;

  if (error) return <ServerError />;

  if (notFound) return <NotFoundPage />;

  return (
    <LeftPanelContainer
      mobile={mobile}
      canCollapse
      collapse={isCollapsed}
      onchangeCollapse={flipCollapse}
    >
      <BaseObjectPanelContext.Provider
        value={{
          carouselReloadIncrement,
          reloadCarousel,
        }}
      >
        <HeaderCarrousel
          mobile={mobile}
          object={focusedObject}
          onClose={(): void => {
            if (onQuit) onQuit();
          }}
        />
        <FocusedObjectPanel
          focusedObject={focusedObject}
          isBaseObjectLoading={isBaseObjectLoading}
          setIsLoadingBookmark={setIsLoadingBookmark}
          isLoadingBookmark={isLoadingBookmark}
          fetchBaseobject={fetchBaseobject}
          mobile={mobile}
          toast={toast}
          hasPreview={hasPreview}
          simplifiedView={false}
        />
      </BaseObjectPanelContext.Provider>
    </LeftPanelContainer>
  );
};

export { BaseObjectPanelContext };

BaseObjectPanel.defaultProps = {
  mobile: false,
};
