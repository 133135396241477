import type {
  CalendarOptions,
  DateSelectArg,
  EventChangeArg,
  EventClickArg,
  EventSourceInput,
} from '@fullcalendar/core';
import type { ResourceInput } from '@fullcalendar/resource';
import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import {
  BaseObjectSchedule,
  type IBaseObjectSchedule,
  type RecurrenceDelete,
  type RecurrenceSave,
} from '@smack/core/api/models/objects/BaseObjectSchedule';
import type { ILinkDateRange } from '@smack/core/api/models/objects/Link/Link';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { ListBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListBaseObject';
import { onSaveRecurentObject } from '@smack/core/components/DataDisplay/Alerts/RecurenceAlert';
import { AvailableView } from '@smack/core/components/DataDisplay/CalendarTimeLine/enums';
import { ModalBaseObjectQuickForm } from '@smack/core/components/DataDisplay/Modals/ModalBaseObjectQuickForm/ModalBaseObjectQuickForm';
import type { RRuleInputValue } from '@smack/core/components/DataInput/RRuleInput';
import type { IUseNavigationOutput } from '@smack/core/hooks/useNavigation/useNavigation';
import { spawnModal } from '@smack/core/utils/modal';
import type { LinkCalendarModeManager } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/ModeManagers/type';
import { parseISO } from 'date-fns';
import i18next from 'i18next';
import { toast } from 'react-hot-toast';

export class RoomLink implements LinkCalendarModeManager {
  initialView = AvailableView.TIMELINE;

  availableViews = [AvailableView.CALENDAR, AvailableView.TIMELINE];

  async getEvents(
    baseObject: BaseObject,
    dateRange: ILinkDateRange,
    calendarView?: AvailableView,
    resources?: ResourceInput[],
    selectedCalendarLinkGroups?: number[],
    slotColor?: string,
  ): Promise<EventSourceInput> {
    const promises: Promise<{ baseobject: BaseObject; sourceId: number }[]>[] =
      [];

    if (calendarView === AvailableView.CALENDAR) {
      promises.push(
        BaseObject.getLinkedBaseObjectsForCalendarRepresentation(
          baseObject.id,
          dateRange.dtstart,
          dateRange.dtend,
        ),
      );
    } else {
      promises.push(
        ...(resources?.map((e) =>
          BaseObject.getBaseObjectsForTimelineRepresentation(
            Number.parseInt(e.id ?? ''),
            dateRange.dtstart,
            dateRange.dtend,
          ),
        ) ?? []),
      );
    }

    try {
      const results = await Promise.all(promises);
      return results.flat().map((e) => ({
        id: `${e.baseobject.id}-${e.baseobject.scheduleId}`,
        title: `${e.baseobject.title ?? ''} | ${e.baseobject.subtitle ?? ''}`,
        object: e.baseobject,
        link: e.baseobject.frontEndpoint,
        backgroundColor: slotColor,
        borderColor: slotColor,
        resourceId: e.sourceId.toString(),
        start: e.baseobject.startAt?.toISOString(),
        end: e.baseobject.endAt?.toISOString(),
      }));
    } catch (error) {
      return [];
    }
  }

  async getResources(baseObject: BaseObject): Promise<ResourceInput[]> {
    const array: ResourceInput[] = [];

    array.push({
      id: baseObject.id.toString(),
      title: baseObject.title,
    });

    const linkGroup = await this.getFloorLinkGroup(baseObject.id);

    if (!linkGroup) {
      return array;
    }

    const promises: Promise<{ group: string; baseobject: BaseObject }[]>[] =
      linkGroup.children?.map((e) =>
        this.getBaseObjectRoomFormCalendarRepresentation(e, baseObject.id),
      ) ?? [];

    const result = await Promise.all(promises);
    const objects = result.flat().map((e) => ({
      id: e.baseobject.id.toString(),
      title: e.baseobject.title,
      group: e.group,
    }));

    return array.concat(objects);
  }

  async onCalendarChangeDate(
    event: EventChangeArg,
    callback?: () => void,
  ): Promise<void> {
    const obj = event.event.extendedProps.object as ListBaseObject;
    const errorMessage = i18next.t('calendarTimeline.errors.changeDate');
    try {
      const { start, end } = event.event;
      const endOrStart = end ?? start;

      if (start) {
        const baseobject = await BaseObject.getBaseObject(
          obj.id,
          obj.scheduleId,
        );

        if (!baseobject.scheduleId) {
          return;
        }

        const updateSchedule = (
          value: RecurrenceSave | RecurrenceDelete,
        ): void => {
          if (!endOrStart) return;

          const data: IBaseObjectSchedule = {
            dtstart: parseISO(start.toISOString()),
            dtend: parseISO(endOrStart.toISOString()),
            update: value,
            updateFromSchedule: baseobject.scheduleId,
          };

          BaseObjectSchedule.patchBaseObjectSchedule(obj?.id, data).then(() =>
            callback?.(),
          );
        };

        if (baseobject.rrule) {
          onSaveRecurentObject(updateSchedule, () => {
            event.revert();
          });
        } else {
          if (!endOrStart) return;

          const data: IBaseObjectSchedule = {
            dtstart: parseISO(start.toISOString()),
            dtend: parseISO(endOrStart.toISOString()),
            update: 'REPLACE_ONE',
            updateFromSchedule: baseobject.scheduleId,
          };

          await BaseObjectSchedule.patchBaseObjectSchedule(baseobject.id, data);
          callback?.();
        }
      }
    } catch (error) {
      toast.error(errorMessage);
      event.revert();
    }
  }

  onCalendarEmptyClick(
    event: DateSelectArg,
    baseObject: BaseObject,
    callback?: () => void,
  ): void {
    const resource = event.resource ?? baseObject;
    const rrule = {
      dtstart: event.start.toISOString(),
      dtend: event.end.toISOString(),
    };

    this.handleSpawnForm(
      Number.parseInt(resource.id.toString()),
      rrule,
      callback,
    );
  }

  handleSpawnForm(
    linkId: number,
    initialRRule?: RRuleInputValue,
    callback?: () => void,
  ): void {
    spawnModal({
      render: ({ onClose }) => {
        return (
          <ModalBaseObjectQuickForm
            addModalOpened={true}
            setAddModalOpened={onClose}
            linkId={linkId}
            initialRRule={initialRRule}
            onObjectCreated={(data: {
              id: number;
              linkGroup?: LinkGroup;
            }): void => {
              if (!data.id || !data.linkGroup || !linkId) return;
              data.linkGroup
                .addLink(linkId, data.id)
                .then(() => {
                  callback?.();
                })
                .catch(() => {
                  toast.error(i18next.t('calendarTimeline.errors.link-event'));
                });
            }}
          />
        );
      },
    });
  }

  onClickEvent(
    arg: EventClickArg,
    _reloadCalendar: () => void,
    navigate: IUseNavigationOutput,
  ) {
    navigate(arg.event.extendedProps.link as string);
  }

  private async getFloorLinkGroup(
    baseObjectId: number,
  ): Promise<LinkGroup | null> {
    const resultResources =
      await LinkGroup.getFloorLinkGroupsFromObjectId(baseObjectId);
    return resultResources?.find((e) => e.populationType === 'FLOOR') ?? null;
  }

  private async getBaseObjectRoomFormCalendarRepresentation(
    e: LinkGroup,
    baseObjectId: number,
  ): Promise<{ group: string; baseobject: BaseObject }[]> {
    const result = await BaseObject.getBaseObjectRoomFormCalendarRepresentation(
      e,
      baseObjectId,
    );
    return result || [];
  }
  getCalendarOptions(
    baseObject?: BaseObject,
    callback?: () => void,
  ): CalendarOptions {
    return {};
  }
}
