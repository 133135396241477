import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { env } from '@smack/core/env';
import type { INavigationTabs } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation';
import { SubNavigation } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation/SubNavigation';
import { ActionsPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Actions';
import { RecommendationPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Recommendations';
import { ReminderPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Reminder';
import { RightsPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Rights';
import { TasksPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Tasks/Tasks';
import { TrackingPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Tracking/TrackingPage';
import { useTranslation } from 'react-i18next';

interface IProps {
  baseObject: BaseObject;
}

export const BaseObjectPanelFollowsRouter = (props: IProps): JSX.Element => {
  const { baseObject } = props;
  const { t } = useTranslation();

  const getTabs = (): INavigationTabs[] => {
    return [
      {
        id: 'comments',
        label: t('baseObjectPanel.followMenu.commentsAndProcess'),
        icon: { name: 'comments' },
        render: (): JSX.Element => <TrackingPage object={baseObject} />,
      },
      {
        id: 'procedures',
        label: t('baseObjectPanel.followMenu.projects'),
        icon: { name: 'diagram-subtask' },
        render: (): JSX.Element => <TasksPage object={baseObject} />,
      },
      {
        id: 'reminders',
        label: t('baseObjectPanel.followMenu.reminders'),
        icon: { name: 'bell' },
        render: (): JSX.Element => <ReminderPage object={baseObject} />,
      },
      {
        id: 'actions',
        label: t('baseObjectPanel.followMenu.actions'),
        icon: { name: 'list' },
        render: (): JSX.Element => <ActionsPage object={baseObject} />,
      },
      {
        id: 'rights',
        label: t('baseObjectPanel.followMenu.shareAndRights'),
        icon: { name: 'share-nodes', familyStyle: 'fal' },
        render: (): JSX.Element => (
          <RightsPage
            object={baseObject}
            pageTitle={t('baseObjectPanel.followMenu.shareAndRights')}
          />
        ),
      },
      ...(env.VITE_SHOW_RECOMMENDATION
        ? ([
            {
              id: 'recommendations',
              label: 'Recommandations',
              icon: 'lightbulb-on',
              render: (): JSX.Element => (
                <RecommendationPage categoryId={baseObject.category?.id} />
              ),
            },
          ] as INavigationTabs[])
        : []),
    ];
  };

  return <SubNavigation tabs={getTabs()} />;
};
