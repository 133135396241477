import React from 'react';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import { CloseIcon } from '../SelectInput/components/Icons';

interface EmailMultiInputProps {
  label?: string;
  placeholder?: string;
  value: string[];
  onChange: (value: string[]) => void;
  error?: string;
}

export const MultipleEmailInput = React.forwardRef(function EmailMultiInput(
  props: EmailMultiInputProps,
  ref: React.Ref<HTMLDivElement>,
) {
  const { label, placeholder, value, onChange, error } = props;

  return (
    <div ref={ref} className="w-80" data-testid="email-multi-input">
      {label && (
        <span className="block text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
          {label}
        </span>
      )}
      <ReactMultiEmail
        className="min-w-0 grow pl-2.5 py-2 pr-2 flex flex-wrap gap-1 bg-primary dark:bg-view"
        inputClassName="bg-primary dark:bg-view focus:ring-blue-400 focus:border-blue-400 border-gray-300 dark:border-neutral-500 rounded-l-md rounded-r-md sm:text-sm"
        emails={value}
        onChange={onChange}
        placeholder={placeholder}
        getLabel={(email, index, removeEmail) => {
          return (
            <div
              key={index}
              className="min-w-0 bg-gray-200 border dark:border-gray-600 rounded-sm flex space-x-1 items-center pl-1"
            >
              <p className="text-text text-sm truncate cursor-default select-none whitespace-normal">
                {email}
              </p>

              <div
                onClick={() => removeEmail(index)}
                className="flex h-full items-center px-1 cursor-pointer rounded-r-sm hover:bg-red-200 hover:text-red-600"
              >
                <CloseIcon className="w-3 h-3 mt-0.5" />
              </div>
            </div>
          );
        }}
      />

      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
});
