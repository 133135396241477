import type { Category } from '@smack/core/api/models/categories/Category';
import { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { NotificationCounter } from '@smack/core/api/models/objects/NotificationCounter/NotificationCounter';
import type { AppDispatch } from '@smack/core/store';
import {
  type CategoriesActionType,
  FETCH_NOTIFICATION_COUNTERS,
  FETCH_ROOT_CATEGORIES,
  FETCH_SET_CATEGORIES,
  FETCH_SUB_SET_CATEGORIES,
  RESET_SET_CATEGORIES,
  RESET_SUB_SET_CATEGORIES,
} from './types';

/**
 *  Setter for rootCategories state
 *
 * @exports
 * @param {Category[]} rootCategories
 * @return {*}  {CategoriesActionType}
 */
export const setRootCategories = (
  rootCategories: Category[],
): CategoriesActionType => {
  return {
    type: FETCH_ROOT_CATEGORIES,
    payload: rootCategories,
  };
};

/**
 *  fetchh root categories and set to the store
 * @returns AppDispatch
 */
export const fetchRootCategories =
  () =>
  (dispatch: AppDispatch): void => {
    ParentCategory.getRootCategories().then((res): void => {
      dispatch(setRootCategories(res));
    });
  };

export const setNotificationCounters = (
  notificationCounters: NotificationCounter[],
): CategoriesActionType => ({
  type: FETCH_NOTIFICATION_COUNTERS,
  payload: notificationCounters,
});

export const fetchCategoriesNotificationCounters =
  () =>
  (dispatch: AppDispatch): void => {
    BaseObject.getNotificationCounters().then((res): void => {
      dispatch(setNotificationCounters(res));
    });
  };

/**
 *  Setter for setCategories state
 *
 * @exports
 * @param {Category[]} setCategories
 * @return {*}  {CategoriesActionType}
 */
export const setSetCategories = (
  setCategories: ParentCategory[],
): CategoriesActionType => {
  return {
    type: FETCH_SET_CATEGORIES,
    payload: setCategories,
  };
};

/**
 * Reset subset categories
 */
export const resetSetCategories = (): CategoriesActionType => ({
  type: RESET_SET_CATEGORIES,
});

/**
 *  fetch set categories and set to the store
 * @returns AppDispatch
 */
export const fetchSetCategories =
  (rootCategory: ParentCategory) =>
  (dispatch: AppDispatch): void => {
    dispatch(resetSetCategories());
    ParentCategory.getChildCategories(rootCategory.id).then((res): void => {
      dispatch(setSetCategories(res.filter((set) => set.hasCategory === true)));
    });
  };

/**
 *  Setter for subSetCategories state
 *
 * @exports
 * @param {Category[]} subSetCategories
 * @return {*}  {CategoriesActionType}
 */
export const setSubSetCategories = (
  subSetCategories: SubCategory[],
): CategoriesActionType => {
  return {
    type: FETCH_SUB_SET_CATEGORIES,
    payload: subSetCategories,
  };
};

/**
 * Reset subset categories
 */
export const resetSubSetCategories = (): CategoriesActionType => ({
  type: RESET_SUB_SET_CATEGORIES,
});

/**
 *  fetch sub set categories and set to the store
 * @returns AppDispatch
 */
export const fetchSubSetCategories =
  (setCategory: SubCategory) =>
  (dispatch: AppDispatch): void => {
    dispatch(resetSubSetCategories());
    SubCategory.getChildCategories(setCategory.id).then((res): void => {
      dispatch(setSubSetCategories(res));
    });
  };
