import type React from 'react';

import '@smack/core/assets/styles/scss/index.scss';
import '@smack/core/utils/i18n/i18n';

import {
  MatomoProvider,
  createInstance as createMatomoInstance,
} from '@jonkoops/matomo-tracker-react';
import type { MatomoInstance } from '@jonkoops/matomo-tracker-react/lib/types';
import { ErrorBoundary, init as SentryInit } from '@sentry/react';
import { NoInternet } from '@smack/core/components/DataDisplay/NoInternet';
import { CustomerColorsProvider } from '@smack/core/context/CustomerColorsProvider';
import { env } from '@smack/core/env';
import store from '@smack/core/store';
import { LoginMessagesProvider } from '@smack/core/utils/LoginMessagesStorage';
import { ServiceWorkerReloader } from '@smack/core/utils/ServiceWorkerReloader';
import { CustomToaster } from '@smack/core/utils/toast';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { AppContainer } from './AppContainer';
import { FrontCrash } from './components/DataDisplay/FrontCrash';
export interface IAppProps {
  width: number;
}

interface IProps {
  App: React.FC<IAppProps>;
}

window.Hyvilo = {
  Utils: {
    DrawMode: null,
    Cluster: null,
    MainMap: null,
    MiniMap: null,
    Socket: undefined,
  },
};

export const AppProvider = ({ App }: IProps): JSX.Element => {
  let matomo: MatomoInstance | undefined;
  if (import.meta.env.PROD && env.VITE_SENTRY_DNS) {
    SentryInit({
      dsn: env.VITE_SENTRY_DNS,
      environment: import.meta.env.MODE,
      release: env.VITE_RELEASE_TAG,
      tracesSampleRate: 1.0,
      normalizeDepth: 0,
    });
  }
  if (env.VITE_MATOMO_URL) {
    matomo = createMatomoInstance({
      urlBase: env.VITE_MATOMO_URL,
      siteId: env.VITE_MATOMO_SITEID,
      linkTracking: false,
    });
  }

  const wrappedApp = (
    <ErrorBoundary fallback={<FrontCrash />}>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <CustomToaster />
          <ServiceWorkerReloader />
          <LoginMessagesProvider>
            <Provider store={store}>
              <CustomerColorsProvider>
                <NoInternet />
                <AppContainer App={App} />
              </CustomerColorsProvider>
            </Provider>
          </LoginMessagesProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </ErrorBoundary>
  );

  if (matomo) {
    return <MatomoProvider value={matomo}>{wrappedApp}</MatomoProvider>;
  }
  return wrappedApp;
};
