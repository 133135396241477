import type { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import { Trans, useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const TaskLogMessageFeed = (
  props: ILogFeedProps<TaskLog>,
): JSX.Element => {
  const [t] = useTranslation();
  const { log } = props;

  const messageName =
    log.content?.taskMessage?.label ||
    log.content?.taskMessage?.messageTemplate?.subject;

  return (
    <LogFeed {...props} icon={{ name: 'paper-plane' }}>
      <span className=" text-sm font-normal">
        <Trans
          t={t}
          i18nKey="logs.action.sentMessage"
          context={messageName ? 'withName' : 'withoutName'}
          values={{ message: messageName }}
        />
      </span>
    </LogFeed>
  );
};
