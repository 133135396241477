import React, { type CSSProperties } from 'react';
import { type ButtonVariantProps, button } from './button';

export interface CustomCSSProperties extends CSSProperties {
  '--button-bg-color'?: string;
  '--button-hover-color'?: string;
}

export type IButtonProps = React.PropsWithChildren<
  React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonVariantProps
>;

export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  function Button(
    {
      children,
      variant = 'default',
      disabled = false,
      className,
      ...htmlProps
    },
    ref,
  ) {
    return (
      <button
        ref={ref}
        {...htmlProps}
        type={htmlProps.type ?? 'button'}
        className={button({ variant, disabled, className })}
        disabled={disabled}
      >
        {children}
      </button>
    );
  },
);
