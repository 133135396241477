import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  type IReminderPost,
  Reminder,
} from '@smack/core/api/models/reminders/Reminder';
import {
  type IMessage,
  type IReminderRulePost,
  MessageSendStatus,
  MessageType,
  ReminderRule,
} from '@smack/core/api/models/reminders/ReminderRule';
import { Group } from '@smack/core/api/models/users/Group';
import { User } from '@smack/core/api/models/users/User';
import {
  CloseButton,
  ModifyButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { DeleteConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert';
import { DescriptionListElement } from '@smack/core/components/DataDisplay/DescriptionListElement';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { DateUtils } from '@smack/core/utils/DateUtils';
import {
  ListSkeleton,
  LoaderSkeleton,
  ReminderSkeleton,
} from '@smack/core/utils/Loader';
import { spawnModal } from '@smack/core/utils/modal';
import { setOnlineInterval } from '@smack/core/utils/onlineInterval';
import { ListHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/List/ListHeader';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { ReminderElement } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Reminder/components/ReminderElement/ReminderElement';
import {
  type IReminderFormOutput,
  ReminderForm,
} from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Reminder/components/ReminderForm';
import { format } from 'date-fns';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  object: BaseObject;
}
/**
 * Reminder Page components
 * @param props IPRops
 * @returns JSX.Element
 */
export const ReminderPage = (props: IProps): JSX.Element => {
  const { object } = props;
  const { t } = useTranslation();
  const [reminderFormvisible, setReminderFormVisible] =
    React.useState<boolean>(false);

  const [reminders, setReminders] = React.useState<Reminder[]>([]);
  const [reminderRules, setReminderRules] = React.useState<ReminderRule[]>([]);

  const [isRemindersLoading, setIsRemindersLoading] = React.useState(true);
  const [isReminderRulesLoading, setIsReminderRulesLoading] =
    React.useState(true);
  const [reminderPage, setReminderPage] = React.useState<
    Reminder | ReminderRule
  >();
  const pendingReminders = React.useMemo(
    () => reminders.filter((r) => !r.isSent),
    [reminders],
  );
  const sentReminders = React.useMemo(
    () => reminders.filter((r) => r.isSent),
    [reminders],
  );

  const [reminderRuleToUpdate, setReminderRuleToUpdate] =
    React.useState<ReminderRule>();
  const [reminderToUpdate, setReminderToUpdate] = React.useState<Reminder>();

  const [isReminderRulesTabOpen, setIsReminderRulesTabOpen] =
    React.useState(true);

  const [isSentRemindersTabOpen, setIsSentRemindersTabOpen] =
    React.useState(true);

  const toggleReminderRulesTab = React.useCallback(
    () =>
      setIsReminderRulesTabOpen(
        (isReminderRulesOpenState) => !isReminderRulesOpenState,
      ),
    [],
  );

  const toggleSentRemindersTab = React.useCallback(
    () =>
      setIsSentRemindersTabOpen(
        (isSentRemindersOpenState) => !isSentRemindersOpenState,
      ),
    [],
  );

  const closeForm = (): void => {
    setReminderFormVisible(false);
    setReminderRuleToUpdate(undefined);
    setReminderToUpdate(undefined);
  };

  const updateReminders = React.useCallback(
    async (silent = false) => {
      if (object?.id) {
        if (!silent) setIsRemindersLoading(true);
        const fetchedReminders = await Reminder.getFromBaseObjectId(
          object.id,
        ).finally(() => setIsRemindersLoading(false));
        setReminders(fetchedReminders);
        if (!silent && fetchedReminders.length > 10) {
          setIsSentRemindersTabOpen(false);
        }
      }
    },
    [object?.id],
  );

  const updateReminderRules = React.useCallback(async () => {
    if (object?.id) {
      setIsReminderRulesLoading(true);
      const fetchedReminderRules = await ReminderRule.getFromBaseObjectId(
        object.id,
      ).finally(() => setIsReminderRulesLoading(false));
      setReminderRules(fetchedReminderRules);
      if (fetchedReminderRules.length > 10) setIsReminderRulesTabOpen(false);
    }
  }, [object?.id]);

  React.useEffect(() => {
    updateReminders();
    updateReminderRules();
    const interval = setOnlineInterval(() => updateReminders(true), 60000);
    return () => clearInterval(interval);
  }, [object?.id]);

  /**
   * return description date of reminderrule
   *
   * @param {ReminderRule} reminderRule
   * @return {*}  {string}
   */
  const getTimeLabelForReminderRule = (reminderRule: ReminderRule): string => {
    let delayLabel = '';
    if (reminderRule.delayFromAttribute) {
      delayLabel = reminderRule.delayFromAttribute.label;
    }
    if (reminderRule.delayFromValue) {
      delayLabel = t('reminders.event.arbitraryDate', {
        date: format(
          reminderRule.delayFromValue,
          t('reminders.dateFormat').replace('YYYY', 'yyyy').replace('DD', 'dd'),
        ),
      });
    }

    if (!delayLabel) return '';

    const duration = reminderRule.delay
      ? DateUtils.getHumanizeDurationFromISO(reminderRule.delay)
      : '';
    return t('reminders.schedule', {
      context: reminderRule.delay?.startsWith('-') ? 'before' : 'after',
      // NOTE: always capitalize first letter
      duration: duration.substring(0, 1).toUpperCase() + duration.substring(1),
      event: delayLabel,
    });
  };

  /**
   * return group component
   *
   * @param {IconName} icon
   * @param {string} label
   * @param {() => unknown} [onClick]
   * @param isActive
   * @param count
   * @param {() => void} [onPlus]
   * @return {*}  {JSX.Element}
   */
  const getGroup = (
    icon: IconField,
    label: string,
    onClick: () => unknown,
    isActive: boolean,
    count = 0,
    onPlus?: () => void,
  ): JSX.Element => {
    return (
      <ListHeader
        className="border-b border-border"
        icon={icon}
        label={label}
        onClick={onClick}
        isActive={isActive}
        badge={count ? count.toString() : undefined}
      >
        {object?.isWritable && onPlus && (
          <Icon
            title={t('create.create')}
            icon={{ name: 'plus-circle' }}
            className={'text-gray-400 mr-3 text-xl hover:text-gray-500 '}
            onClick={(e): void => {
              e.stopPropagation();
              onPlus();
            }}
          />
        )}
      </ListHeader>
    );
  };

  const isReminderStatusFailed = (
    reminder: Reminder | ReminderRule,
  ): boolean => {
    return (
      reminder.messages?.some(
        (message) =>
          message.status === MessageSendStatus.ATTEMPT_FAILED ||
          message.status === MessageSendStatus.ATTEMPTS_EXCEEDED,
      ) || false
    );
  };

  /**
   * on reminder rule form submit
   *
   * @param {({
   *     [key: string]: string | number;
   *   })} data
   */
  const onSubmitReminderRule = (data: IReminderFormOutput): void => {
    const newdata: Partial<IReminderRulePost & IReminderPost> = {};
    newdata.subject = data.subject;
    newdata.content = data.content;

    const messages: IMessage<number, number>[] = [];
    const recipients = Array().concat(
      data.userRecipients ?? [],
      data.groupRecipients ?? [],
    );
    if (data.isWebappPush) {
      messages.push({
        type: MessageType.WEBAPP_PUSH,
        recipients: recipients,
      });
    }
    if (data.isMail) {
      messages.push({
        type: MessageType.EMAIL,
        recipients: recipients,
      });
    }
    newdata.messages = messages;

    if (data.recurrence) {
      newdata.delayFromValue = data.datetime;
      newdata.recurrence = data.recurrence;
    } else if (data.delayFromAttributeId) {
      newdata.delayFromAttributeId = data.delayFromAttributeId;
      newdata.delay = data.delay;
    } else {
      newdata.reminderDatetime = data.datetime;
    }

    if (!newdata.reminderDatetime) {
      if (reminderRuleToUpdate) {
        ReminderRule.patch(reminderRuleToUpdate.id, newdata).then(() => {
          setReminderFormVisible(false);
          setReminderRuleToUpdate(undefined);
          updateReminderRules();
        });
      } else if (object.id) {
        newdata.baseobjectId = object.id;
        ReminderRule.create(newdata)
          .then(() => {
            // Object ID && reminder to update:
            // Previous reminder "transformed" to reminder rule
            // -> Delete previous reminder
            if (!reminderToUpdate) return Promise.resolve();
            return Reminder.delete(reminderToUpdate.id);
          })
          .then(() => {
            setReminderFormVisible(false);
            updateReminderRules();
          });
      }
    } else if (reminderToUpdate) {
      Reminder.patch(reminderToUpdate.id, newdata)
        .then(() => {
          // Switch of reminder rule -> Delete current reminder
          return Reminder.delete(object.id);
        })
        .then(() => {
          setReminderFormVisible(false);
          setReminderToUpdate(undefined);
          updateReminders();
        });
    } else {
      // No reminder datetime -> Reminder rule
      if (object.id) {
        newdata.baseobjectId = object.id;
        Reminder.create(newdata)
          .then(() => {
            // Object ID && reminder rule to update:
            // Previous reminder rule "transformed" to classic reminder
            // -> Delete previous reminder rule
            if (!reminderRuleToUpdate) return Promise.resolve();
            return ReminderRule.delete(reminderRuleToUpdate.id);
          })
          .then(() => {
            setReminderFormVisible(false);
            updateReminders();
          });
      }
    }
  };

  /**
   * on patch reminder rule submit
   *
   * @param {ReminderRule} r
   */
  const onUpdateRule = (r: ReminderRule): void => {
    setReminderRuleToUpdate(r);
    setReminderFormVisible(true);
  };

  /**
   * on delete rule submit
   *
   * @param {ReminderRule} r
   */
  const onDeleteRule = (r: ReminderRule): void => {
    spawnModal({
      render: ({ onClose }) => {
        return (
          <DeleteConfirmAlert
            onOk={(): void => {
              ReminderRule.delete(r.id)
                .then(updateReminderRules)
                .finally(onClose);
            }}
            onCloseButton={onClose}
          />
        );
      },
    });
  };

  /**
   * on update Reminder submit
   *
   * @param {Reminder} r
   */
  const onUpdateReminder = (r: Reminder): void => {
    setReminderToUpdate(r);
    setReminderFormVisible(true);
  };

  const getRecipientsCount = (r: Reminder | ReminderRule): number => {
    const recipients =
      r.messages?.flatMap((message) => message.recipients) ?? [];

    return recipients.reduce((count, recipient) => {
      const userCount = recipient.user ? 1 : 0;
      const groupUserCount = recipient.group?.users?.length ?? 0;
      return count + userCount + groupUserCount;
    }, 0);
  };

  const isMessageType = (
    r: Reminder | ReminderRule,
    messageType: MessageType,
  ): boolean => {
    return !!r.messages?.some((message) => message.type === messageType);
  };

  /**
   * on delete reminder submit
   *
   * @param {Reminder} r
   */
  const onDeleteReminder = (r: Reminder): void => {
    spawnModal({
      render: ({ onClose }) => {
        return (
          <DeleteConfirmAlert
            onOk={(): void => {
              Reminder.delete(r.id)
                .then(() => updateReminders())
                .finally(onClose);
            }}
            onCloseButton={onClose}
          />
        );
      },
    });
  };

  const reminderLoader = (
    <LoaderSkeleton height={128} width="100%">
      <ListSkeleton iterations={2} component={ReminderSkeleton} />
    </LoaderSkeleton>
  );

  const getReminders = (): React.ReactNode[] => {
    const output: React.ReactNode[] = [];
    reminderRules.forEach((r) => {
      output.push(
        <ReminderElement
          key={r.id}
          title={r.subject}
          sendFail={isReminderStatusFailed(r)}
          envelopeIcon={isMessageType(r, MessageType.EMAIL)}
          bellIcon={isMessageType(r, MessageType.WEBAPP_PUSH)}
          onDetails={(): void => {
            setReminderPage(r);
          }}
          onDelete={
            !r.categoryId && object?.isWritable
              ? (): void => onDeleteRule(r)
              : undefined
          }
          subtitle={t('reminders.willBeSent', {
            date: getTimeLabelForReminderRule(r),
            usersCount: getRecipientsCount(r),
          })}
        />,
      );
    });
    pendingReminders.forEach((r) => {
      const sendFail = isReminderStatusFailed(r);
      let usercount = 0;
      for (const messages of r.messages ?? []) {
        usercount += messages.recipients.length ?? 0;
      }
      output.push(
        <ReminderElement
          key={r.id}
          title={r.subject}
          sendFail={sendFail}
          onDetails={(): void => setReminderPage(r)}
          envelopeIcon={isMessageType(r, MessageType.EMAIL)}
          bellIcon={isMessageType(r, MessageType.WEBAPP_PUSH)}
          onDelete={(): void =>
            object?.isWritable ? onDeleteReminder(r) : undefined
          }
          subtitle={t(
            sendFail
              ? 'reminders.shouldHaveBeenSent'
              : 'reminders.willBeSentOnDate',
            {
              date: format(r.reminderDatetime, t('reminders.dateFormat')),
              usersCount: getRecipientsCount(r),
            },
          )}
        />,
      );
    });

    return output;
  };

  const getInfoDetailFromReminder = (
    reminder?: Reminder | ReminderRule,
  ): { label: string; value: string }[] => {
    const output: { label: string; value: string }[] = [];
    if (!reminder) return output;
    const recipients: string[] = [];
    if (reminder.messages) {
      reminder.messages.forEach((m) => {
        if (m.recipients) {
          m.recipients.forEach((r) => {
            if (r.user) {
              const u = new User(r.user);
              if (!recipients.includes(u.getFullName())) {
                recipients.push(u.getFullName());
              }
            } else if (r.group) {
              const g = new Group(r.group);
              const label = t('reminders.infoModal.groupFormat', {
                group: g.label,
              });
              if (!recipients.includes(label)) {
                recipients.push(label);
              }
            }
          });
        }
      });
    }

    const messagesTypes: string[] = [];
    if (reminder.messages) {
      reminder.messages.forEach((m) => {
        if (m.type) {
          messagesTypes.push(m.type);
        }
      });
    }

    if (reminder instanceof ReminderRule) {
      output.push({
        label: t('reminders.infoModal.schedule'),
        value: getTimeLabelForReminderRule(reminder),
      });
    } else {
      if (reminder.isSent) {
        output.push({
          label: t('reminders.infoModal.sent'),
          value: reminder.sentAt
            ? format(reminder.sentAt, t('reminders.dateFormat'))
            : '',
        });
      } else {
        output.push({
          label: t('reminders.infoModal.schedule'),
          value: reminder.reminderDatetime
            ? format(reminder.reminderDatetime, t('reminders.dateFormat'))
            : '',
        });
      }
    }
    output.push({
      label: t('reminders.infoModal.recipients'),
      value: recipients.join(', '),
    });
    output.push({
      label: t('reminders.infoModal.sendType'),
      value: messagesTypes
        .map((m) => t(`reminders.messageType.${m}`))
        .join(', '),
    });

    output.push({
      label: t('reminders.infoModal.subject'),
      value: reminder.subject ?? '',
    });
    output.push({
      label: t('reminders.infoModal.content'),
      value: reminder.content ?? '',
    });
    return output;
  };

  return (
    <div className="absolute inset-0 flex flex-col">
      <PageHeader title={t('reminders.title')} actions={[]} />
      <Modal
        icon={{ name: 'bells' }}
        title={t('reminders.infoModal.title')}
        open={!!reminderPage}
        // NOTE: workaround to prevent closing both edit modal and details modal when closing only edit one
        noClickOutside={reminderFormvisible}
        onClose={(): void => setReminderPage(undefined)}
      >
        <div className={'min-w-[400px] max-w-[600px]'}>
          <dl>
            {getInfoDetailFromReminder(reminderPage).map((field) =>
              'displayLineIsTrue' in field &&
              !field.displayLineIsTrue ? null : (
                <DescriptionListElement
                  key={crypto.randomUUID()}
                  title={field.label}
                >
                  {field.value ? (
                    field.value
                  ) : (
                    <p className="text-gray-400">
                      {t('reminders.infoModal.noContent')}
                    </p>
                  )}
                </DescriptionListElement>
              ),
            )}
          </dl>
        </div>
        <div className="flex justify-end mt-3 gap-3">
          <CloseButton
            type="button"
            onClick={(): void => setReminderPage(undefined)}
          />
          {object?.isWritable &&
            !(reminderPage instanceof Reminder && reminderPage.isSent) && (
              <ModifyButton
                onClick={(): void => {
                  if (!reminderPage) return;
                  if (reminderPage instanceof ReminderRule) {
                    onUpdateRule(reminderPage);
                  } else {
                    onUpdateReminder(reminderPage);
                  }
                }}
              />
            )}
        </div>
      </Modal>

      <Modal
        icon={{ name: 'bells' }}
        color="#3EA6ED"
        iconColor="white"
        open={reminderFormvisible}
        onClose={(isOpen): void => {
          setReminderFormVisible(isOpen);
          if (!isOpen) closeForm();
        }}
        title={
          reminderToUpdate || reminderRuleToUpdate
            ? t('edit.reminder')
            : t('create.reminder')
        }
      >
        <ReminderForm
          baseObject={object}
          reminderRule={reminderRuleToUpdate}
          reminder={reminderToUpdate}
          onSubmit={onSubmitReminderRule}
          onClose={(): void => closeForm()}
        />
      </Modal>
      <div className="flex-grow  no-scrollbar overflow-y-auto">
        {getGroup(
          { name: 'bells' },
          t('reminders.reminders'),
          toggleReminderRulesTab,
          isReminderRulesTabOpen,
          getReminders().length,
          () => {
            setReminderFormVisible(true);
          },
        )}
        {isReminderRulesTabOpen ? (
          <>
            {getReminders()}
            {isReminderRulesLoading || isRemindersLoading
              ? reminderLoader
              : null}
            {!isReminderRulesLoading && getReminders().length === 0 && (
              <NoContentMessage
                className="h-20"
                icon={{ name: 'bells' }}
                label={t('reminders.noReminders')}
              />
            )}
          </>
        ) : null}

        {getGroup(
          { name: 'bell-slash' },
          t('reminders.sentReminders'),
          toggleSentRemindersTab,
          isSentRemindersTabOpen,
          sentReminders.length,
        )}
        {isSentRemindersTabOpen ? (
          <>
            {sentReminders.map((r) => {
              const sendFail = isReminderStatusFailed(r);
              return (
                <ReminderElement
                  key={r.id}
                  title={r.subject || object.title}
                  sendFail={sendFail}
                  onDetails={(): void => setReminderPage(r)}
                  envelopeIcon={isMessageType(r, MessageType.EMAIL)}
                  bellIcon={isMessageType(r, MessageType.WEBAPP_PUSH)}
                  subtitle={
                    r.sentAt
                      ? t(
                          sendFail
                            ? 'reminders.wasPartiallySent'
                            : 'reminders.wasSent',
                          {
                            date: format(r.sentAt, t('reminders.dateFormat')),
                            usersCount: getRecipientsCount(r),
                          },
                        )
                      : ''
                  }
                />
              );
            })}
            {isRemindersLoading ? reminderLoader : null}
            {!isRemindersLoading && sentReminders.length === 0 && (
              <NoContentMessage
                className="h-20"
                icon={{ name: 'bell-slash' }}
                label={t('reminders.noSentReminders')}
              />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};
