import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';

export type RecurrenceSave = 'REPLACE_ALL' | 'REPLACE_FUTURE' | 'REPLACE_ONE';
export type RecurrenceDelete = 'REMOVE_ALL' | 'REMOVE_FUTURE' | 'REMOVE_ONE';

/**
 *  interface of ITask log content
 *
 * @export
 * @interface IBaseObjectLogContent
 */
export interface IBaseObjectSchedule {
  dtstart?: Date;
  dtend?: Date;
  rrule?: string;
  update?: RecurrenceSave | RecurrenceDelete;
  updateFromSchedule?: number;
}

/**
 *  class Task log
 *
 * @export
 * @class BaseObjectLog
 * @extends {Log}
 */
export class BaseObjectSchedule {
  dtstart?: Date;

  dtend?: Date;

  rrule?: string;

  update?: RecurrenceSave | RecurrenceDelete;

  updateFromSchedule?: number;

  /**
   * // Creates an instance of BaseObjectLog.
   * @param {IBaseObjectLog} data
   * @memberof BaseObjectLog
   */
  constructor(data: IBaseObjectSchedule) {
    this.dtstart = data.dtstart;
    this.dtend = data.dtend;
    this.rrule = data.rrule;
    this.update = data.update;
    this.updateFromSchedule = data.updateFromSchedule;
  }

  static patchBaseObjectSchedule(
    baseObjectId: number,
    data: IBaseObjectSchedule,
  ): Promise<{ id: number }> {
    return RESTClient.patch<{ results: { id: number } }>(
      data,
      `/objects/baseobjects/${baseObjectId}/schedule`,
    ).then((res) => res.data.results);
  }
}
