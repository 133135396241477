import { DefaultGeoJSON } from '@smack/core/api/models/categories/Category';
import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import type { IDisplayedAttributeFilter } from '@smack/core/api/models/categories/DisplayedAttributeFilter';
import type { IDisplayedAttributeVisualization } from '@smack/core/api/models/categories/DisplayedAttributeVisualization';
import {
  getDefaultSort,
  setDefaultFilters,
} from '@smack/core/store/app/actions';
import type { IFilters } from '@smack/core/utils/Filters';
import type { MapEventUtils } from '@smack/core/utils/MapEventUtils';
import {
  type IContextualProps,
  type IMenuItem,
  MenuSection,
} from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import { t } from 'i18next';
import { getSortOptions } from './SortOptions';

/**
 * Return list of period for filters
 * @returns: { id: string, label: string }[]
 */
const getPeriodList = (): {
  id: 'TODAY' | 'THIS_WEEK' | 'THIS_MONTH' | 'THIS_WEEKEND';
  label: string;
}[] => {
  return [
    { id: 'TODAY', label: t('filters-function.today') },
    { id: 'THIS_WEEK', label: t('filters-function.this_week') },
    { id: 'THIS_WEEKEND', label: t('filters-function.this_weekend') },
    { id: 'THIS_MONTH', label: t('filters-function.this_month') },
  ];
};

const buildListAttributeFilters = (
  displayedAttributeFilters: IDisplayedAttributeFilter[],
): IMenuItem => {
  return {
    id: 'attribute-filter',
    label: t('filtersList.attributes'),
    icon: { name: 'filter-list' },
    type: 'filter',
    section: MenuSection.FILTER,
    isQuick: false,
    subMenu: {
      multiselection: false,
      items: displayedAttributeFilters
        .map<IMenuItem>((categoryFilters) => {
          return {
            id: `attribute-filter-${categoryFilters.id}`,
            label: categoryFilters.label ?? '',
            type: 'filter',
            icon: categoryFilters.icon ?? { name: 'list' },
            subMenu: {
              multiselection: false,
              items: (categoryFilters.listFilters ?? [])
                ?.map<IMenuItem>((listFilter) => {
                  return {
                    id: `attribute-filter-${categoryFilters.id}-${listFilter.attributeId}`,
                    label: listFilter.label,
                    icon: listFilter.icon ?? { name: 'list' },
                    type: 'filter',
                    position: listFilter.position,
                    subMenu: {
                      multiselection: true,
                      items: listFilter.choices.map<IMenuItem>((choice) => {
                        return {
                          id: `attribute-filter=${categoryFilters.id}-${listFilter.attributeId}-${choice.id}`,
                          label: choice.label,
                          type: 'filter',
                          color: choice.color,
                        };
                      }),
                    },
                  };
                })
                .filter((listFilter) => listFilter.subMenu?.items.length),
            },
          };
        })
        .map<IMenuItem | undefined>(
          (categoryFilters, i, allCategoryFilters) => {
            if (categoryFilters.subMenu?.items.length) return categoryFilters;
            if (
              i === 0 &&
              allCategoryFilters.every(
                (otherCategoryFilter) =>
                  otherCategoryFilter.subMenu?.items.length,
              )
            )
              return {
                id: 'no-category-filter',
                label: t('filtersList.noFilterableAttributes'),
                type: 'filter',
                icon: { name: 'tags' },
              };
            return undefined;
          },
        )
        .filter(
          (categoryFilters): categoryFilters is IMenuItem =>
            categoryFilters !== undefined,
        ),
    },
  };
};

const buildQuickAttributeFilters = (
  displayedAttributeFilters: IDisplayedAttributeFilter[],
): IMenuItem[] => {
  let quickFilters: IMenuItem[] = [];
  displayedAttributeFilters.forEach((categoryFilters) => {
    quickFilters = [
      ...quickFilters,
      ...(categoryFilters.quickFilters ?? [])
        .map<IMenuItem>((quickFilter) => {
          return {
            id: `attribute-filter=${categoryFilters.id}-${quickFilter.attributeId}`,
            label: quickFilter.label,
            icon: quickFilter.icon ?? { name: 'list' },
            type: 'filter',
            section: MenuSection.FILTER,
            isQuick: true,
            position: quickFilter.position,
            subMenu: {
              multiselection: true,
              items: quickFilter.choices.map<IMenuItem>((choice) => {
                return {
                  id: `attribute-filter=${categoryFilters.id}-${quickFilter.attributeId}-${choice.id}`,
                  label: choice.label,
                  type: 'filter',
                  color: choice.color,
                };
              }),
            },
          };
        })
        .filter((quickFilter) => quickFilter.subMenu?.items.length),
    ];
  });
  return quickFilters;
};

interface IGetFiltersListOptions {
  filters: IFilters | undefined;
  setFilters: (filters: IFilters) => void;
  categories?: SubCategory[];
  displayedAttributeVisualizations?: IDisplayedAttributeVisualization[];
  displayedAttributeFilters?: IDisplayedAttributeFilter[];
  rootCategory?: ParentCategory;
  setCategory?: ParentCategory;
  mapEvents?: MapEventUtils;
  filterOptions?: {
    isPeriodFilterAvailable?: boolean;
  };
}

export const getFiltersList = (
  options: IGetFiltersListOptions,
): IContextualProps => {
  const {
    filters,
    setFilters,
    displayedAttributeFilters,
    displayedAttributeVisualizations,
    setCategory,
    categories,
    mapEvents,
    filterOptions,
  } = options;
  const { DrawMode } = window.Hyvilo.Utils;

  const periodFilters =
    setCategory?.hasSubcategoryCalendarFilterAvailable ||
    filterOptions?.isPeriodFilterAvailable
      ? [true, false].map<IMenuItem>((quick) => {
          return {
            id: 'period',
            label: t('filtersList.period'),
            type: 'filter',
            icon: { name: 'clock' },
            section: MenuSection.FILTER,
            isQuick: quick,
            subMenu: {
              multiselection: false,
              items: getPeriodList().map<IMenuItem>((s) => {
                return {
                  id: `period=${s.id}`,
                  label: s.label,
                  type: 'filter',
                };
              }),
            },
          };
        })
      : [];
  const filterList: IContextualProps = {
    filters: filters ?? {},
    setFilters,
    menuItems: {
      items: [
        {
          id: 'sort',
          label: t('filtersList.sortBy'),
          icon: { name: 'sort' },
          type: 'sort',
          section: undefined,
          subMenu: {
            items: getSortOptions(() =>
              setFilters({
                ...filters,
                ...getDefaultSort(),
              }),
            ),
          },
        },
        ...periodFilters,
      ],
    },
  };

  if (setCategory?.displayMapShapeDefault === DefaultGeoJSON.Point) {
    filterList.menuItems.items.push({
      id: 'show-geom=',
      label: t('filtersList.showFootprints'),
      type: 'visualize',
      icon: { name: 'draw-polygon' },
      section: MenuSection.GEOMETRY,
    });
  }
  const categoryFilter: IMenuItem[] | undefined = categories
    ? [true, false].map<IMenuItem>((quick) => {
        return {
          id: 'category',
          label: t('filtersList.categories'),
          icon: { name: 'tags' },
          section: MenuSection.FILTER,
          type: 'filter',
          isQuick: quick,
          subMenu: {
            items: (categories ?? []).map<IMenuItem>((s) => {
              return {
                id: `category=${s.id}`,
                label: s.label,
                type: 'filter',
                icon: s.icon,
              };
            }),
          },
        };
      })
    : undefined;

  const notificationFilters: IMenuItem[] = [
    {
      id: 'notification-viewed=',
      label: t('filtersList.viewedNotifications'),
      type: 'filter',
      icon: { name: 'eye' },
      section: MenuSection.FILTER,
    },
    {
      id: 'report-state=',
      label: t('filtersList.processedNotifications'),
      type: 'filter',
      icon: { name: 'check' },
      section: MenuSection.FILTER,
    },
  ];

  if (categories?.some((c) => c.isNotification)) {
    filterList.menuItems.items.push(...notificationFilters);
  }

  if (categoryFilter) filterList.menuItems.items.push(...categoryFilter);
  const visualize: IMenuItem[] | undefined = displayedAttributeVisualizations
    ? [
        {
          id: 'visualize-category=',
          label: t('filtersList.category'),
          icon: { name: 'tags' },
          type: 'visualize',
          isDefault: true,
          section: MenuSection.VISUALIZE,
        },
        {
          id: 'visualize-attribute',
          label: t('filtersList.attributes'),
          icon: { name: 'filter-list' },
          type: 'visualize',
          section: MenuSection.VISUALIZE,
          subMenu: {
            multiselection: false,
            items: [
              ...displayedAttributeVisualizations
                .map((visualization): IMenuItem => {
                  return {
                    id: `visualize-attribute-${visualization.id}`,
                    label: visualization.label ?? '',
                    icon: visualization.icon ?? { name: 'list' },
                    type: 'visualize',
                    subMenu: {
                      multiselection: false,
                      items: visualization.visualizationChoices?.map(
                        (visualizationChoice): IMenuItem => {
                          return {
                            id: `visualize-attribute=${visualization.id}-${visualizationChoice.attributeId}`,
                            label: visualizationChoice.label,
                            icon: visualizationChoice.icon,
                            type: 'visualize',
                          };
                        },
                      ),
                    },
                  };
                })
                .map<IMenuItem | undefined>(
                  (visualization, i, allVisualizations) => {
                    if (visualization.subMenu?.items?.length)
                      return visualization;
                    if (
                      i === 0 &&
                      allVisualizations.every(
                        (otherVisualization) =>
                          otherVisualization.subMenu?.items?.length,
                      )
                    )
                      return {
                        id: 'no-category-visualize',
                        label: t('filtersList.noVisualizableAttributes'),
                        type: 'visualize',
                        icon: { name: 'tags' },
                      };
                    return undefined;
                  },
                )
                .filter(
                  (visualization): visualization is IMenuItem =>
                    visualization !== undefined,
                ),
            ],
          },
        },
      ]
    : undefined;
  if (visualize) filterList.menuItems.items.push(...visualize);

  const attributeFilter: IMenuItem[] | undefined =
    displayedAttributeFilters?.length
      ? [
          buildListAttributeFilters(displayedAttributeFilters),
          ...buildQuickAttributeFilters(displayedAttributeFilters),
        ].sort(
          (a, b) =>
            (a.position ?? Number.POSITIVE_INFINITY) -
            (b.position ?? Number.POSITIVE_INFINITY),
        )
      : undefined;
  if (attributeFilter) filterList.menuItems.items.push(...attributeFilter);

  const areaFilters = setCategory?.hasSubcategoryMapFilterAvailable
    ? [true, false].map<IMenuItem>((quick) => {
        return {
          id: 'zone',
          label: t('filtersList.area'),
          icon: { name: 'map-marker-alt' },
          isQuick: quick,
          type: 'filter',
          section: MenuSection.FILTER,
          subMenu: {
            items: [
              {
                id: 'zone=draw',
                label: t('filtersList.defineAnArea'),
                icon: { name: 'draw-polygon' },
                type: 'filter',
              },
              {
                id: 'zone=screen',
                label: t('filtersList.areaDisplayedOnScreen'),
                icon: { name: 'desktop' },
                type: 'filter',
              },
              ...(displayedAttributeFilters
                ?.flatMap((value) => value.spatialFilters ?? [])
                .map<IMenuItem>((filteringLayer) => {
                  return {
                    id: `zone=${filteringLayer.id?.toString()}` || '',
                    label: `${filteringLayer.label}`,
                    type: 'filter',
                    filteringLayer,
                    mapEvents,
                  };
                }) ?? []),
            ],
          },
        };
      })
    : [];
  filterList.menuItems.items.push(
    ...[
      ...areaFilters,
      {
        id: 'delete',
        isQuick: false,
        label: t('filtersList.defaultValues'),
        type: 'action',
        icon: { name: 'undo' as const },
        section: MenuSection.RESTORE,
        onClick: (): void => {
          setFilters({ ...setDefaultFilters(setCategory) });

          try {
            DrawMode?.deleteAll();
          } catch (err) {
            if (err instanceof TypeError) return;
          }
        },
      } satisfies IMenuItem,
    ],
  );

  return filterList;
};
