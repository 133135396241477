import { RESTClient } from '../clients/rest/RESTClient';
import type { MessageRecipientIndication } from '../models/notifications/MessageRecipient';

export enum EmailNotificationTemplateType {
  SHARE_MEDIA = 'SHARE_MEDIA',
}

export interface EmailNotificationApiInput {
  subject?: string;
  message?: string;
  recipients: {
    indication: MessageRecipientIndication;
    user?: number;
    email?: string;
  }[];
  attachmentMediaUuids: string[];
  templateType: EmailNotificationTemplateType;
}

export class EmailNotification {
  static validate(
    data: Pick<EmailNotificationApiInput, 'attachmentMediaUuids'>,
  ) {
    return RESTClient.post(data, '/notifications/emails/validation');
  }

  static send(data: EmailNotificationApiInput) {
    return RESTClient.post(data, '/notifications/emails');
  }
}
