import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { add } from 'date-fns';
import { t } from 'i18next';
import React from 'react';

const DEFAULT_ISO_VALUE = 'P0D';

interface IProps {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const getTypeDelay = (): Option[] => {
  return [
    { label: t('recurrence.minutes'), value: 'minutes' },
    { label: t('recurrence.hours'), value: 'hours' },
    { label: t('recurrence.capDays'), value: 'days' },
  ];
};

/**
 * return select option of moment delay
 *
 * @return {*}  {ISelectOption[]}
 */
const getMomentDelay = (): Option[] => {
  return [
    { label: t('recurrence.before'), value: 'before' },
    { label: t('recurrence.capAfter'), value: 'after' },
  ];
};

const determineMomentDelay = (
  getMomentDelay: () => Option[],
  value?: string,
): Option => {
  const isNegative = value ? value?.startsWith('-') : true;
  const targetValue = isNegative ? 'before' : 'after';
  return (
    getMomentDelay().find((option) => option.value === targetValue) ||
    getMomentDelay()[0]
  );
};

export const ReminderDurationField: React.FC<IProps> = ({
  value,
  onChange,
  disabled = false,
}) => {
  const [selectedType, setSelectedType] = React.useState<Option>(
    getTypeDelay()[0],
  );
  const [momentType, setMomentType] = React.useState<Option>(
    determineMomentDelay(getMomentDelay, value),
  );

  const handleChange = (v: string | undefined, momenttype: string): void => {
    const isNegative = v?.startsWith('-');
    const value = v ? (isNegative ? v.substring(1) : v) : DEFAULT_ISO_VALUE;
    const result = momenttype === 'before' ? `-${value}` : value;

    onChange(result);
  };

  const getParsedValue = () => {
    if (!value)
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    const isNegative = value?.startsWith('-');
    const parsedDate = isNegative
      ? DateUtils.convertDurationToObject(value.substring(1))
      : DateUtils.convertDurationToObject(value);
    return parsedDate;
  };

  React.useEffect(() => {
    const val = getParsedValue();

    const { days, hours, minutes } = val;

    if (days > 0) {
      setSelectedType(getTypeDelay()[2]);
    } else if (hours > 0) {
      setSelectedType(getTypeDelay()[1]);
    } else if (minutes > 0) {
      setSelectedType(getTypeDelay()[0]);
    }
    setMomentType(determineMomentDelay(getMomentDelay, value));
  }, [value]);

  const getSelectedNumber = (): number => {
    const parsedValue = getParsedValue();
    if (selectedType.value === 'minutes') {
      return parsedValue.minutes;
    }

    if (selectedType.value === 'hours') {
      return parsedValue.hours;
    }

    if (selectedType.value === 'days') {
      return parsedValue.days;
    }

    return 0;
  };

  const handleNumberDelayChange = (n: number, type: string): void => {
    const baseDate = new Date();
    let newDate: Date | undefined = undefined;

    // Add the specified duration to the base date
    if (type === 'minutes') {
      newDate = add(baseDate, { minutes: n });
    } else if (type === 'hours') {
      newDate = add(baseDate, { hours: n });
    } else if (type === 'days') {
      newDate = add(baseDate, { days: n });
    } else if (type === 'month') {
      newDate = add(baseDate, { months: n });
    }

    const duration = DateUtils.getDuration(baseDate, newDate ?? new Date());
    handleChange(duration, momentType.value as string);
  };

  return (
    <>
      <NumberInput
        min={0}
        size="xSmall"
        disabled={disabled}
        value={getSelectedNumber()}
        onChange={(n): void =>
          handleNumberDelayChange(n as number, selectedType.value as string)
        }
      />
      <SelectInput
        className="w-[150px]"
        options={getTypeDelay()}
        isClearable={false}
        disabled={disabled}
        value={getTypeDelay().find((v) => v.value === selectedType.value)}
        onChange={(v): void => {
          setSelectedType(v as Option);
          handleNumberDelayChange(getSelectedNumber(), v?.value as string);
        }}
      />

      <SelectInput
        className="w-[150px]"
        isClearable={false}
        options={getMomentDelay()}
        value={momentType}
        disabled={disabled}
        onChange={(v): void => {
          setMomentType(v as Option);
          handleChange(value, v?.value as string);
        }}
      />
    </>
  );
};
