import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { BaseObjectView } from '@smack/core/api/models/views/BaseObjectView/BaseObjectView';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import { CloseButton } from '@smack/core/components/Actions/Buttons/Button';
import { DataDisplayHeader } from '@smack/core/components/DataDisplay/Headers/DataDisplayHeader';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import type { ICellType } from '@smack/core/components/DataDisplay/Table/Table';
import { ViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRenderer';
import { setModuleStore } from '@smack/core/store/app/actions';
import { Navigation } from '@smack/core/views/oldViewsToSort/Views/Objects/FullScreen/components/Navigation';
import type { ColumnDef } from '@tanstack/react-table';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

export interface FullScreenProps {
  baseObject: BaseObject;
  onClose?: () => void;
}

export interface TabsContentDefinition {
  columns: ColumnDef<ICellType>[];
  data: ICellType[];
  id: number;
  linkGroupId: number;
  total: number;
}

export const FullScreen: React.FC<FullScreenProps> = ({
  baseObject,
  onClose,
}) => {
  const dispatch = useDispatch();
  const { id: baseObjectId, scheduleId } = baseObject;
  const [tabs, setTabs] = useState<ViewSection[]>([]);
  const [activeTab, setActiveTab] = useState<ViewSection>();

  useEffect(() => {
    if (!baseObjectId) return;

    BaseObjectView.getBaseObjectViewByViewType(
      ViewUsage.FULLSCREEN,
      baseObjectId,
      scheduleId,
    )
      .then(({ viewSections }) => {
        setTabs(viewSections);
        setActiveTab(viewSections[0]);
        dispatch(
          setModuleStore({
            disableSubNavRouting: true,
          }),
        );
      })
      .catch(() => toast.error(t('process.action.error')));
  }, [baseObjectId]);

  return (
    <div
      data-testid="FullScreenContainer"
      className="flex flex-col flex-grow bg-view border-l border-gray-100 dark:border-gray-600"
    >
      <div className="flex flex-col flex-grow">
        <DataDisplayHeader
          color={baseObject?.color ?? ''}
          title={baseObject?.title}
          icon={baseObject?.category?.icon}
          actions={[<CloseButton key={'quit'} onClick={onClose} />]}
        />
        <Navigation
          navigation={tabs}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        >
          <div
            className="flex flex-col flex-grow overflow-y-scroll"
            style={{ maxHeight: 'calc(100vh - 200px)' }}
          >
            {activeTab?.viewElements.length ? (
              activeTab?.viewElements.map((viewElement, index) => (
                <ViewElementRenderer
                  key={`content-${viewElement.id}`}
                  viewElement={viewElement}
                  props={{ baseObject }}
                />
              ))
            ) : (
              <div
                className="w-full h-full flex items-center justify-center"
                style={{ marginTop: '4rem' }}
              >
                <NoContentMessage
                  icon={{ name: 'face-monocle' }}
                  label={t('fullscreenView.noViewConfigured')}
                />
              </div>
            )}
          </div>
        </Navigation>
      </div>
    </div>
  );
};
