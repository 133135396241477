import { useMatomo } from '@jonkoops/matomo-tracker-react';
import {
  type Action,
  ActionHandlerType,
} from '@smack/core/api/models/actions/Action/Action';
import { File } from '@smack/core/api/models/medias/File';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { formatRelative, parseISO } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../Actions/Buttons/Button';

interface IProps {
  objectId: number | string;
  taskId: number | string;
  action: Action;
  reloadCallback: () => void;
  hideLastLine?: true;
}

export const ActionDisplay = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const {
    objectId,
    taskId,
    action,
    reloadCallback,
    hideLastLine = false,
  } = props;
  const matomo = useMatomo();
  const [loadingAction, setLoadingAction] = React.useState<boolean>();
  const [, setActiveView] = useActiveView();

  const onClickAction = (): void => {
    if (
      [
        ActionHandlerType.GENERATE_SUPERSET_GUEST_TOKEN,
        ActionHandlerType.GENERATE_OBJECT_RESTRICTED_SUPERSET_GUEST_TOKEN,
      ].includes(action.handlerType)
    ) {
      setActiveView({
        view: ViewName.Dashboard,
        params: {
          dashboardDisplayAction: action,
          baseobjectId: objectId,
        },
      });
      return;
    }
    setLoadingAction(true);
    matomo.trackEvent({
      category: 'Action',
      action: 'triggerAction',
      name: action.label,
    });

    const triggerPromise: Promise<unknown> = taskId
      ? action.triggerActionFromTask(taskId)
      : action.triggerActionFromBaseObject(objectId);

    triggerPromise
      .then(reloadCallback)
      .finally(() => setLoadingAction(undefined));
  };

  const getButton = (): React.ReactNode => {
    if (loadingAction) {
      return (
        <>
          <Icon animation={'pulse'} icon={{ name: 'spinner-third' }} />
          {t('dataDisplayActions.running')}
        </>
      );
    }
    const lastLog = action.logs.at(0);
    if (lastLog?.content?.success !== undefined) {
      return lastLog?.content?.success ? (
        <>
          <Icon icon={{ name: 'circle-check' }} className="text-green-600" />
          {t('dataDisplayActions.finished')}
        </>
      ) : (
        <>
          <Icon icon={{ name: 'circle-xmark' }} className="text-red-500" />
          {t('dataDisplayActions.error')}
        </>
      );
    }
    return (
      <>
        <Icon icon={{ name: 'play-circle' }} />
        {t('dataDisplayActions.start')}
      </>
    );
  };

  const getGeneratedFile = (): React.ReactNode => {
    const lastLog = action.logs.at(0);
    if (!lastLog?.content?.generatedFile) return null;
    const file = new File(lastLog.content.generatedFile);
    return (
      <Icon
        icon={{ name: 'down-to-line' }}
        onClick={file.open.bind(file)}
        className="text-xl cursor-pointer text-gray-400 hover:text-gray-500"
      />
    );
  };

  return (
    <div
      key={action.id}
      className={`min-h-[64px] w-full
      flex gap-1 items-center justify-between 
      px-2 border-b border-gray-300 dark:border-neutral-700
      ${hideLastLine ? 'last:border-b-0' : ''}`}
    >
      <div className="flex flex-col">
        <p className="text-text">{action.label}</p>
        <p className="flex flex-row gap-1 text-xs text-gray-600">
          {action.logs.length > 0 ? (
            <>
              {t('dataDisplayActions.lastExecution')}{' '}
              {formatRelative(
                parseISO(action.logs.at(0)?.logAt as string),
                new Date(),
                { locale: navigator.language.startsWith('fr') ? fr : enUS },
              ).toLowerCase()}
              {action.logs.at(0)?.user?.firstName &&
                action.logs.at(0)?.user?.lastName && (
                  <div>
                    <div className="absolute">
                      <Tooltip
                        title={`${t('dataDisplayActions.executedBy')} ${
                          action.logs.at(0)?.user?.firstName || ''
                        } ${action.logs.at(0)?.user?.lastName || ''}`}
                      >
                        <Icon icon={{ name: 'circle-question' }} />
                      </Tooltip>
                    </div>
                  </div>
                )}
            </>
          ) : (
            t('dataDisplayActions.neverExecuted')
          )}
        </p>
      </div>
      <div className="flex items-center gap-3">
        {getGeneratedFile()}
        <Button
          disabled={loadingAction}
          onClick={onClickAction}
          className="flex gap-2"
        >
          {getButton()}
        </Button>
      </div>
    </div>
  );
};
