import '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/index.scss';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import type { AppState } from '@smack/core/store';
import { BaseObjectPanelMediasRouter } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/Medias';
import { useState } from 'react';
import { useSelector } from 'react-redux';

export interface INavigationTabs {
  label: string;
  id: string;
  icon?: IconField;
  img?: string;
  render: () => JSX.Element;
}

interface INavigation {
  label: string;
  id: string;
}

interface IProps {
  navigation: INavigation[];
  baseObject: BaseObject;
  hasPreview?: boolean;
}

export const NonRoutedContent = (props: IProps): JSX.Element => {
  const { navigation, baseObject, hasPreview } = props;
  const [activeTab, setActiveTab] = useState(navigation[0].id);
  const { module, settings } = useSelector((state: AppState) => state.App);

  const defaultColor = settings?.color ?? settings?.defaultColor;
  const color = settings?.primaryBackgroundColor ?? defaultColor;

  return (
    <div
      data-testid="nonRoutedElementPreview"
      className="w-full h-full flex items-center justify-center flex-col min-h-0"
    >
      <div className="w-full h-12 min-h-[48px] max-h-[48px] flex justify-center items-center border-b border-border">
        {navigation.map((nav) => {
          const isActive = activeTab === nav.id;
          return (
            <div
              key={nav.id}
              onClick={() => setActiveTab(nav.id)}
              className={`cursor-pointer mx-3 text-[14px] border-b-2 ${
                isActive
                  ? 'font-medium border-blue-400'
                  : 'font-medium dark:text-gray-400 border-hidden'
              }`}
              style={{
                color: isActive ? color : settings?.defaultColor,
                borderBottom: isActive ? `2px solid ${color}` : '',
              }}
            >
              {nav.label}
            </div>
          );
        })}
      </div>
      {activeTab === 'preview' && hasPreview && (
        <AutoBaseObjectView
          data-testid="nonRoutedPreviewContent"
          baseObject={baseObject}
          viewUsage={ViewUsage.PREVIEW}
        />
      )}
      {activeTab === 'details' && (
        <AutoBaseObjectView
          data-testid="nonRoutedDetailsContent"
          scheduleId={module.activeScheduleId ?? undefined}
          baseObject={baseObject}
          viewUsage={ViewUsage.DETAILS}
        />
      )}
      {activeTab === 'medias' && (
        <BaseObjectPanelMediasRouter
          data-testid="nonRoutedMediasContent"
          baseObject={baseObject}
        />
      )}
    </div>
  );
};
