import type { ChecklistLog } from '@smack/core/api/models/tasks/ChecklistLog';
import { Trans, useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const ChecklistLogCreatedItemFeed = (
  props: ILogFeedProps<ChecklistLog>,
): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'check-square', familyStyle: 'far' }}>
      <span className="text-sm font-normal">
        {' '}
        <Trans
          t={t}
          i18nKey="logs.action.createdChecklistItem"
          values={{
            checklist: props.log.content?.checklist?.label,
            checklistItem: props.log.content?.createdChecklistItemLabel,
          }}
        />
      </span>
    </LogFeed>
  );
};
