import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import { viewList } from '@smack/core/hooks/views/ViewList/ViewList';
import type { View } from '@smack/core/hooks/views/types';
import React from 'react';
import { useParams } from 'react-router-dom';
export const useAvailableViews = (
  category?: ParentCategory | SubCategory,
  objectParams?: boolean,
  isMobile?: boolean,
): View[] => {
  const { object } = useParams();

  return React.useMemo(() => {
    if (!category) return [];
    const output =
      viewList.filter((v) => {
        const hasFocusedBaseObjectObject = !!(object ?? objectParams);
        if (!v.mobile && isMobile) return false;
        return v.isAvailable?.(category, hasFocusedBaseObjectObject);
      }) ?? [];
    if (category.displayViewDefault) {
      output.sort((a, b) => {
        if (a.id === category.displayViewDefault) {
          return -1;
        }
        if (b.id === category.displayViewDefault) {
          return 1;
        }
        return 0;
      });
    }
    return output;
  }, [category, object]);
};
