import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import type { ViewLayoutComponent } from '@smack/core/components/ViewRenderer/interfaces';
import type { AppState } from '@smack/core/store';
import type { INavigationTabs } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation';
import { SubNavigation } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation/SubNavigation';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const BaseObjectDetailsLayout: ViewLayoutComponent = ({
  renderedSections,
  view,
}) => {
  const settings = useSelector((state: AppState) => state.App.settings);

  const defaultColor = settings?.color ?? settings?.defaultColor;
  const color = settings?.primaryBackgroundColor ?? defaultColor;

  const tabs = useMemo<INavigationTabs[]>(() => {
    return renderedSections.map(([viewSection, renderedViewElements]) => ({
      id: viewSection.id.toString(),
      label: viewSection.label,
      icon: viewSection.icon,
      render: () => (
        <div className="absolute inset-0 flex flex-col">
          <PageHeader actions={[]} title={viewSection.label} />
          <div className="grow w-full overflow-y-auto no-scrollbar">
            {renderedViewElements.map(([viewElement, node]) => {
              return (
                <div
                  key={viewElement.id}
                  className="flex min-h-[44px] items-center border-b border-border px-3 justify-between gap-4"
                >
                  <p
                    style={{ color: color }}
                    title={viewElement.label}
                    className="text-sm text-blue-500 font-light"
                  >
                    {viewElement.label}
                  </p>
                  <div className="flex min-w-0 flex-grow justify-end text-end text-text flex-wrap gap-1 py-2">
                    {node}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ),
    }));
  }, [view]);

  return <SubNavigation tabs={tabs} />;
};

BaseObjectDetailsLayout.Error = () => {
  const [t] = useTranslation();

  return (
    <div className="h-full w-full">
      <DangerAlert title={t('viewErrors.viewNotAvailable')}>
        {t('viewErrors.viewNotAvailableDescription')}
      </DangerAlert>
    </div>
  );
};
