import {
  Action,
  type IAction,
} from '@smack/core/api/models/actions/Action/Action';
import {
  Attribute,
  type FormValue,
  type IAttributeApiOutput,
  type IAttributeValue,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import {
  DisplayedButton,
  type IDisplayedButton,
} from '@smack/core/api/models/categories/DisplayedButton';
import type { Link } from '@smack/core/api/models/objects/Link/Link';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { attributeFieldsByType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/AttributeFieldsByType';
import { defaultGetValueForFormField } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/common';
import type { ValidationRules } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';
import type { ReactNode } from 'react';
import type { ControllerRenderProps, FieldError } from 'react-hook-form';

export enum ViewElementType {
  ATTRIBUTE = 'ATTRIBUTE',
  DISPLAYED_BUTTON = 'DISPLAYED_BUTTON',
  OBJECT_LINK_GROUPS = 'OBJECT_LINK_GROUPS',
  LINK_FIELD = 'LINK_FIELD',
  OBJECT_PROJECTS = 'OBJECT_PROJECTS',
  SUPERSET_DASHBOARD = 'SUPERSET_DASHBOARD',
  VIEW = 'VIEW',
  MEDIAS = 'MEDIAS',
}

export enum ViewElementTypeViewUsage {
  OTHER = 'OTHER',
}

export enum ViewElementTypeViewStyle {
  TABLE = 'TABLE',
  PAGE = 'PAGE',
}

export interface FieldProps {
  controllerProps?: Partial<ControllerRenderProps>;
  error?: FieldError;
  parameters?: Record<string, unknown>;
  categoryId?: number;
  baseObject?: BaseObject;
  link?: Link;
  viewUsage?: ViewUsage;
  viewElement?: ViewElement;
  viewSection?: ViewSection;
  standaloneField?: {
    onSubmission?: () => void;
  };
}

export interface IViewElement {
  id: number;
  type: ViewElementType;
  position: number;
  fieldType: DisplayedFieldType;
  fieldTypeParameters?: Record<string, unknown>;
  helpText?: string;
  prefix?: string;
  prefixIcon?: IconField;
  suffix?: string;
  suffixIcon?: IconField;
  attribute?: IAttributeApiOutput;
  button?: IDisplayedButton;
  linkGroupId?: number;
  values?: IAttributeValue[];
  tplProjectId?: number;
  action?: IAction;
  view?: View;
}

interface View {
  id: number;
  label: string;
  usage: string;
  style: string;
  allowsObjectCreation: boolean;
  categoryId: number;
  linkGroupId: number;
  accessCategoryId: number;
  viewElementId: number;
}

export class ViewElement {
  id: number;

  type: ViewElementType;

  position: number;

  fieldType: DisplayedFieldType;

  fieldTypeParameters?: Record<string, unknown>;

  helpText?: string;

  prefix?: string;

  prefixIcon?: IconField;

  suffix?: string;

  suffixIcon?: IconField;

  attribute?: Attribute;

  button?: DisplayedButton;

  linkGroupId?: number;

  tplProjectId?: number;

  values?: IAttributeValue[];

  action?: Action;

  view?: View;

  constructor(data: IViewElement) {
    this.id = data.id;
    this.type = data.type;
    this.position = data.position;
    this.fieldType = data.fieldType;
    this.fieldTypeParameters = data.fieldTypeParameters;
    this.helpText = data.helpText;
    this.prefix = data.prefix;
    this.prefixIcon = data.prefixIcon;
    this.suffix = data.suffix;
    this.suffixIcon = data.suffixIcon;
    this.attribute = data.attribute ? new Attribute(data.attribute) : undefined;
    this.button = data.button ? new DisplayedButton(data.button) : undefined;
    this.linkGroupId = data.linkGroupId;
    this.tplProjectId = data.tplProjectId;
    this.values = data.values;
    this.view = data.view;
    this.action = data.action ? new Action(data.action) : undefined;
  }

  get label() {
    if (this.type === ViewElementType.DISPLAYED_BUTTON) {
      return this.button?.label ?? this.prefix;
    }
    if (this.type === ViewElementType.ATTRIBUTE) {
      return this.attribute?.label ?? this.prefix;
    }
    return this.prefix;
  }

  get icon(): IconField | undefined {
    if (this.type === ViewElementType.DISPLAYED_BUTTON) {
      return this.prefixIcon?.name ? this.prefixIcon : { name: 'bolt' };
    }
    if (this.type === ViewElementType.ATTRIBUTE) {
      return this.attribute?.icon ?? this.prefixIcon;
    }
    return this.prefixIcon;
  }

  get prefixNode(): ReactNode {
    if (!this.prefix && !this.prefixIcon) return null;
    return (
      <>
        {this.prefixIcon ? (
          <Icon inline className="fa-fw pr-1" icon={this.prefixIcon} />
        ) : null}
        {this.prefix ?? null}
      </>
    );
  }

  get suffixNode(): ReactNode {
    if (!this.suffix && !this.suffixIcon) return null;
    return (
      <>
        {this.suffix ?? null}
        {this.suffixIcon ? (
          <Icon inline className="fa-fw pl-1" icon={this.suffixIcon} />
        ) : null}
      </>
    );
  }

  get transparent() {
    // FIXME get from API
    return (
      this.fieldType === DisplayedFieldType.EDIT_LINK_BLOCK ||
      this.type === ViewElementType.OBJECT_LINK_GROUPS ||
      this.type === ViewElementType.OBJECT_PROJECTS
    );
  }

  get required() {
    return this.attribute?.isRequired ?? false;
  }

  fieldTypeIsFormField(): boolean {
    const formFieldFromType = attributeFieldsByType.find(
      (a) => a.type === this.fieldType,
    );
    return !formFieldFromType?.isAReadOnlyField;
  }

  getFieldTypeDefaultValue(): unknown {
    const formFieldFromType = attributeFieldsByType.find(
      (a) => a.type === this.fieldType,
    );
    return formFieldFromType?.defaultValue ?? null;
  }

  getFieldTypeValidationRules(): ValidationRules | undefined {
    const formFieldFromType = attributeFieldsByType.find(
      (a) => a.type === this.fieldType,
    );
    return formFieldFromType?.validationRules;
  }

  getValueForForm() {
    if (!this.attribute)
      throw new Error(
        'ViewElement.getValueForForm() can only be called on attribute view elements',
      );
    const formFieldFromType = attributeFieldsByType.find(
      (a) => a.type === this.fieldType,
    );
    if (!formFieldFromType)
      return defaultGetValueForFormField(this.attribute, this.values);
    return formFieldFromType.getValueForFormField(
      this.attribute,
      this.values,
    ) as FormValue;
  }

  getValueIfExist(): string | null {
    return this.values?.at(0)?.value ?? null;
  }
}
