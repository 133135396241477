import { LogAction } from '@smack/core/api/models/logs/Log';
import type { BaseObjectLog } from '@smack/core/api/models/objects/BaseObjectLog';
import { BaseObjectLogCreationFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/BaseObjectLogsFeed/CreationFeed';
import { BaseObjectLogDeleteFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/BaseObjectLogsFeed/DeleteFeed';
import { BaseObjectLogUpdateFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/BaseObjectLogsFeed/UpdateFeed';
import { LogFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/LogsFeed';
import type React from 'react';

interface IProps {
  log: BaseObjectLog;
  first: boolean;
  last: boolean;
}

export const BaseObjectLogFeed: React.FC<IProps> = ({ log, first, last }) => {
  const logProps = {
    log: log,
    first,
    last,
  };
  switch (log.action) {
    case LogAction.CREATE:
      return <BaseObjectLogCreationFeed {...logProps} />;
    case LogAction.UPDATE:
      return <BaseObjectLogUpdateFeed {...logProps} />;

    case LogAction.DELETE:
      return <BaseObjectLogDeleteFeed {...logProps} />;

    default:
      return <LogFeed {...logProps} />;
  }
};
