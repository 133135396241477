import type { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const TaskLogStatusFeed = (
  props: ILogFeedProps<TaskLog>,
): JSX.Element => {
  const { log } = props;
  const getStatus = (old: boolean): JSX.Element => {
    const status = old
      ? log.content?.oldTaskStatus
      : log.content?.newTaskStatus;

    if (status) {
      return (
        <span className=" border rounded-xl w-fit px-2 py-1 inline-flex mx-1">
          <span className="text-sm mr-3">{status.label}</span>
          <span
            style={{ backgroundColor: status.color || 'gray' }}
            className="h-5 w-5 flex items-center justify-center rounded-full  "
          >
            {
              <Icon
                className="text-xs text-white"
                icon={status.icon || { name: 'question' }}
              />
            }
          </span>
        </span>
      );
    }
    return <>Non défini</>;
  };

  const getcontent = (): JSX.Element => {
    if (log.content?.oldTaskStatus) {
      return (
        <span className=" text-sm font-normal">
          {' '}
          a changé le statut de la tâche de {getStatus(true)} à{' '}
          {getStatus(false)}
        </span>
      );
    }
    return (
      <span className=" text-sm font-normal">
        {' '}
        a défini le statut de la tâche à {getStatus(false)}
      </span>
    );
  };

  return (
    <LogFeed {...props} icon={{ name: 'tag' }}>
      {getcontent()}
    </LogFeed>
  );
};
