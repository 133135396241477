import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import {
  GetActions,
  GetNavigation,
} from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/BaseObjectPanel/Utils/utils';
import { BaseObjectDetails } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/BaseObjectDetails/BaseObjectDetails';
import { DetailPanelNavigation } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation';
import { AutoLinkGroups } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/AutoLinkGroups/AutoLinkGroups';
import { ObjectAsNotificationContentPanel } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/ReportContent';
import { BaseObjectPanelRouter } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes';
import { NonRoutedContent } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/NonRoutedContent';
import type { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface IfocusedObjectPanelProps {
  focusedObject: BaseObject | undefined;
  isBaseObjectLoading: () => boolean;
  setIsLoadingBookmark: (loading: boolean) => void;
  isLoadingBookmark: boolean;
  fetchBaseobject: () => void;
  mobile?: boolean;
  toast: typeof toast;
  hasPreview: boolean;
  simplifiedView: boolean;
}

const FocusedObjectPanel = (props: IfocusedObjectPanelProps) => {
  const {
    focusedObject,
    isBaseObjectLoading,
    setIsLoadingBookmark,
    isLoadingBookmark,
    fetchBaseobject,
    mobile,
    toast,
    hasPreview,
    simplifiedView,
  } = props;
  const [t] = useTranslation();
  const [, setActiveView, setLastViewAsCurrent] = useActiveView();

  return focusedObject && !isBaseObjectLoading() ? (
    <>
      <BaseObjectDetails
        simplifiedView={simplifiedView}
        actions={GetActions(
          focusedObject,
          () => {
            setActiveView({
              view: ViewName.UpdateForm,
              params: {
                onClose: setLastViewAsCurrent,
                onSuccessfulFormSubmission: fetchBaseobject,
                scheduleId: focusedObject.scheduleId,
                baseObjectId: focusedObject.id,
              },
            });
          },
          () => {
            setIsLoadingBookmark(true);
            (focusedObject?.bookmark
              ? focusedObject.deleteBookmark()
              : focusedObject.createBookmark()
            )
              .catch(() => toast.error(t('bookmarks.error')))
              .finally(() => setIsLoadingBookmark(false));
          },
          () => {
            setActiveView({
              view: ViewName.FullScreen,
              params: {
                onClose: setLastViewAsCurrent,
                baseObject: focusedObject,
              },
            });
          },
          mobile || false,
          isLoadingBookmark,
        )}
        baseObject={focusedObject}
      />
      <div className="flex-shrink-0 max-h-56 overflow-y-auto">
        <AutoLinkGroups baseObject={focusedObject} />
      </div>
      <ObjectAsNotificationContentPanel
        reload={fetchBaseobject}
        object={focusedObject}
      />
      {simplifiedView ? (
        <NonRoutedContent
          key={focusedObject.id}
          {...GetNavigation(focusedObject, hasPreview, simplifiedView)}
          baseObject={focusedObject}
          hasPreview={hasPreview}
        />
      ) : (
        <DetailPanelNavigation
          key={focusedObject.id}
          {...GetNavigation(focusedObject, hasPreview)}
        >
          <BaseObjectPanelRouter
            baseObject={focusedObject}
            hasPreview={hasPreview}
          />
        </DetailPanelNavigation>
      )}
    </>
  ) : (
    <LoaderSkeleton height="130">
      {/* Left-side */}
      <circle cx="25" cy="35" r="15" />
      <rect x="50" y="15" width="100" height="15" rx="8" ry="8" />
      <rect x="50" y="35" width="100" height="10" rx="4" ry="4" />
      <rect x="50" y="50" width="100" height="10" rx="4" ry="4" />
      {/* Right-side */}
      <rect x="calc(100% - 80px)" y="15" width="70" height="15" rx="8" ry="8" />
      <circle cx="calc(100% - 70px)" cy="45" r="10" />
      <circle cx="calc(100% - 45px)" cy="45" r="10" />
      <circle cx="calc(100% - 20px)" cy="45" r="10" />
      {/* Navigation */}
      {['- 120px', '- 70px', '- 20px', '+ 30px', '+ 80px'].map((offset) => (
        <rect
          key={offset}
          x={`calc(50% ${offset})`}
          y="100"
          height="10"
          width="40"
          rx="4"
          ry="4"
        />
      ))}
      <rect x="calc(50% - 120px)" y="114" height="1" width="40" />
      <rect x="0" y="128" height="1" width="100%" />
    </LoaderSkeleton>
  );
};

export default FocusedObjectPanel;
