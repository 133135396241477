import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { Group } from '@smack/core/api/models/tasks/Group';
import type { Project } from '@smack/core/api/models/tasks/Project/Project';
import { type Status, StatusType } from '@smack/core/api/models/tasks/Status';
import type { Task } from '@smack/core/api/models/tasks/Task';
import { iconDropDownItem } from '@smack/core/components/DataDisplay/DropDowns/iconDropDownItems';
import type { HeaderLabel } from '@smack/core/components/DataDisplay/Labels/IconLabel';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { differenceInMilliseconds } from 'date-fns';
import { t } from 'i18next';

type ProjectComponent = Project | Task | Group;

/**
 * get text date from the status / start_at attributes of a project component
 * @param projectComponent Project | Task | Group
 * @returns string  - undefined
 */
export const getTextDateFromStatus = (
  projectComponent?: ProjectComponent,
): string | undefined => {
  if (projectComponent?.status?.statusType === StatusType.ONGOING) {
    return DateUtils.getFromNowText(
      projectComponent?.startAt,
      `${projectComponent?.status?.label || ''} depuis`,
      true,
    );
  }
  if (projectComponent?.status?.isCompleted) {
    return DateUtils.getFromNowText(
      projectComponent?.endAt,
      projectComponent?.status?.label,
    );
  }
};

/**
 * get text duration from the projectStatus / project start_at
 * @param project Project | Task | Group
 * @returns string  - undefined
 */
export const getTextDurationFromStatus = (
  project?: ProjectComponent,
): HeaderLabel | undefined => {
  if (project?.status?.statusType === StatusType.UNDETERMINED) {
    const duration = DateUtils.getHumanizeDuration(project.duration);
    if (duration) {
      return {
        label: t('tasks.estimatedDuration'),
        icon: { name: 'hourglass-end' },
        trigger: 'changeDeadline',
      };
    }
  }
  if (!project?.deadlineAt) return;
  const date = new Date(project.deadlineAt);
  const diff = differenceInMilliseconds(date, new Date());
  const humanizedDiff = DateUtils.getHumanizeDurationFromMilliseconds(diff);
  if (diff > 0) {
    return {
      label: t('tasks.endAt', { context: 'future', diff: humanizedDiff }),
      icon: { name: 'hourglass-end' },
      trigger: 'changeDeadline',
      rightIcon: { name: 'edit' },
      tooltip: date.toLocaleString(),
    };
  }
  return {
    label: t('tasks.endAt', { context: 'past', diff: humanizedDiff }),
    icon: { name: 'fire' },
    trigger: 'changeDeadline',
    textColor: 'red',
    rightIcon: { name: 'edit' },
    tooltip: date.toLocaleString(),
  };
};

/**
 * get the header Icon Label Props
 * @param object BaseObject
 * @param project Project | Task | Group
 * @returns
 * { icon: IconName; label: string }[]
 */
export const getHeaderLabels = (
  object?: BaseObject,
  project?: ProjectComponent,
): HeaderLabel[] => {
  const output: HeaderLabel[] = [];
  if (object?.address)
    output.push({ icon: { name: 'map-marker' }, label: object?.address || '' });
  const textdate = getTextDateFromStatus(project);
  if (textdate) {
    output.push({
      icon: { name: 'calendar-clock' },
      label: textdate,
    });
  }
  if (!textdate && project?.status) {
    output.push({
      icon: project?.status?.icon || { name: 'clock' },
      label: project?.status.label || '',
    });
  }
  const duration = getTextDurationFromStatus(project);
  if (duration) {
    output.push(duration);
  }
  return output;
};

/**
 * get the status list in JSX.ELEMENT[]
 * @returns JSX.ELEMENT[]
 */
export const getStatusesDropDownItems = (
  status: Status[],
  onChangeStatus: (s: Status) => void,
): JSX.Element[] => {
  const output = status.map((s) => {
    return iconDropDownItem({
      icon: s.icon || { name: 'question' },
      iconCircleColor: s.color || 'gray',
      label: s.label,
      onClick: (): void => {
        onChangeStatus(s);
      },
    });
  });

  output.unshift(
    iconDropDownItem({
      icon: { name: 'arrow-alt-down' },
      iconColor: 'gray',
      label: t('tasks.markTaskAs') || '',
      className: 'text-gray-500 font-medium border-b',
    }),
  );

  return output;
};
