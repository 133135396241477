import type { IAttributeValue } from '@smack/core/api/models/categories/Attribute/Attribute';
import {
  DateTimeParametersValidationSchema,
  MediaInputParametersValidationSchema,
  NumberParametersValidationSchema,
  PhoneParametersValidationSchema,
  TextAreaParametersValidationSchema,
  TextParametersValidationSchema,
  TimeParametersValidationSchema,
  TimeSelectParametersValidationSchema,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/ParametersValidationSchema/ParametersValidationSchema';
import {
  addAffixesFromViewElement,
  defaultGetValueForFormField,
  defaultNoValue,
  defaultValueToAttributeValues,
  getAdditionalParametersForAttribute,
  getAttributeChoicesAsSelectOption,
  getErrorMessageFromErrorField,
  getProgressBarInputColor,
  getValueForLinks,
  getValueForMedia,
  getValueForSelect,
  renderValueAsDeferredModal,
  selectValueToAttributeValues,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/common';
import {
  DetailSubmissionType,
  type IAttributeFields,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/type';

import {
  AttributeType,
  attributeValueFromType,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';

import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import { DrawingPad } from '@smack/core/components/Actions/DrawingPad';
import { MediaSource } from '@smack/core/components/Actions/MediaSource';
import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';
import { BadgesOverflowWrapper } from '@smack/core/components/DataDisplay/Badges/BadgesOverflowWrapper';
import {
  type ILocationDisplay,
  LocationDisplay,
} from '@smack/core/components/DataDisplay/LocationDisplay/LocationDisplay';
import { ProgressBar } from '@smack/core/components/DataDisplay/ProgressBar/ProgressBar';
import { AddressInput } from '@smack/core/components/DataInput/AddressInput/AddressInput';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { ClearableRadioInput } from '@smack/core/components/DataInput/ClearableRadioInput';
import { DateInput } from '@smack/core/components/DataInput/DateInput';
import {
  type IInputProps,
  Input,
} from '@smack/core/components/DataInput/Input/Input';
import { LinkListInput } from '@smack/core/components/DataInput/LinkListInput/LinkListInput';
import { LinkSelect } from '@smack/core/components/DataInput/LinkSelect/LinkSelect';
import { LocationInput } from '@smack/core/components/DataInput/LocationInput/LocationInput';
import {
  type IMediaInputParameters,
  MediaInput,
} from '@smack/core/components/DataInput/MediaInput';
import { MultipleLinkSelect } from '@smack/core/components/DataInput/MultipleLinkSelect/MultipleLinkSelect';
import {
  type INumberInputProps,
  NumberInput,
} from '@smack/core/components/DataInput/NumberInput/NumberInput';
import {
  type IPhoneInputProps,
  PhoneInput,
} from '@smack/core/components/DataInput/PhoneInput/PhoneInput';
import {
  type IPlusMinusInputProps,
  PlusMinusInput,
} from '@smack/core/components/DataInput/PlusMinusInput/PlusMinusInput';
import { RRuleInput } from '@smack/core/components/DataInput/RRuleInput';
import { RecurrenceInput } from '@smack/core/components/DataInput/RecurrenceInput';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import { TextAreaInput } from '@smack/core/components/DataInput/TextAreaInput';
import {
  type ITimeInputProps,
  TimeInput,
} from '@smack/core/components/DataInput/TimeInput/TimeInput';
import {
  type ITimeSelectInputProps,
  TimeSelectInput,
} from '@smack/core/components/DataInput/TimeSelectInput';
import { WysiwygInput } from '@smack/core/components/DataInput/WysiwygInput/WysiwygInput';
import {
  defaultValidationRules,
  phoneValidateRules,
} from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/ValidationRules/ValidationRules';
import { LinksGroupContent } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/LinksGroupContent/LinksGroupContent';
import type React from 'react';
import type { ReactDatePickerProps } from 'react-datepicker';

export const attributeFieldsByType: IAttributeFields[] = [
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_ADDRESS,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <AddressInput
          error={getErrorMessageFromErrorField(props?.error)}
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: (att, attributeValues): boolean => {
      return !!attributeValues?.at(0)?.value;
    },
    type: DisplayedFieldType.EDIT_CHECKBOX,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <CheckboxInput
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_DATE,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <DateInput
          datepickerProps={getAdditionalParametersForAttribute<ReactDatePickerProps>(
            props?.parameters,
            DateTimeParametersValidationSchema,
          )}
          hidetime
          {...props?.controllerProps}
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          error={getErrorMessageFromErrorField(props?.error)}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_DATETIME,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <DateInput
          datepickerProps={getAdditionalParametersForAttribute<ReactDatePickerProps>(
            props?.parameters,
            DateTimeParametersValidationSchema,
          )}
          {...props?.controllerProps}
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          error={getErrorMessageFromErrorField(props?.error)}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_LOCATION,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <LocationInput
          categoryId={props?.categoryId}
          baseobjectId={props?.baseObject?.id}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.GEOMETRIES,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <LocationInput
          categoryId={props?.categoryId}
          baseobjectId={props?.baseObject?.id}
          {...props?.controllerProps}
        />
      );
    },
  },

  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_NUMBER,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <NumberInput
          prefix={props?.viewElement?.prefixNode}
          step={attribute?.type === AttributeType.FLOAT ? 'any' : undefined}
          suffix={props?.viewElement?.suffixNode}
          {...getAdditionalParametersForAttribute<INumberInputProps>(
            props?.parameters,
            NumberParametersValidationSchema,
          )}
          {...props?.controllerProps}
          error={getErrorMessageFromErrorField(props?.error)}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_RADIO,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <ClearableRadioInput
          {...props?.controllerProps}
          choices={[
            { label: 'Oui', value: true },
            { label: 'Non', value: false },
          ]}
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_RECURRENCE,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <RRuleInput
          error={getErrorMessageFromErrorField(props?.error)}
          id={`input${attribute.id}`}
          datepickerProps={getAdditionalParametersForAttribute<ReactDatePickerProps>(
            props?.parameters,
            DateTimeParametersValidationSchema,
          )}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_RECURRENCE_SIMPLIFIED,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <RecurrenceInput
          error={getErrorMessageFromErrorField(props?.error)}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_DATE_RANGE,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <RecurrenceInput
          error={getErrorMessageFromErrorField(props?.error)}
          withRRule={false}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: selectValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: getValueForSelect,
    type: DisplayedFieldType.EDIT_SELECT,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <SelectInput
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          options={getAttributeChoicesAsSelectOption(attribute.choices)}
          {...props?.controllerProps}
          multiple={attribute.isMultiple}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_TEXT,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: '',
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <Input
          error={getErrorMessageFromErrorField(props?.error)}
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          icon={{
            icon: {
              name:
                attribute.type === AttributeType.EMAIL ? 'envelope' : undefined,
            },
          }}
          {...getAdditionalParametersForAttribute<IInputProps>(
            props?.parameters,
            TextParametersValidationSchema,
          )}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_TEXTAREA,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: '',
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <TextAreaInput
          {...getAdditionalParametersForAttribute<unknown>(
            props?.parameters,
            TextAreaParametersValidationSchema,
          )}
          error={getErrorMessageFromErrorField(props?.error)}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: getValueForMedia,
    type: DisplayedFieldType.EDIT_MEDIA,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <MediaInput
          {...props?.controllerProps}
          parameters={getAdditionalParametersForAttribute<IMediaInputParameters>(
            props?.parameters,
            MediaInputParametersValidationSchema,
          )}
          categoryId={props?.categoryId}
          multiple={attribute.isMultiple}
        >
          <MediaSource
            source="drop"
            dropzoneProps={{
              className: 'min-w-lg',
              multiple: attribute.isMultiple,
              allowedMimetypes: (attribute.allowedMimetypes ?? []).map(
                (mimetype) => mimetype.extension,
              ),
            }}
          />
        </MediaInput>
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_TIME,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <TimeInput
          error={getErrorMessageFromErrorField(props?.error)}
          prefix={props?.viewElement?.prefixNode}
          suffix={props?.viewElement?.suffixNode}
          {...getAdditionalParametersForAttribute<ITimeInputProps>(
            props?.parameters,
            TimeParametersValidationSchema,
          )}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_TIMESELECT,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <TimeSelectInput
          error={getErrorMessageFromErrorField(props?.error)}
          {...getAdditionalParametersForAttribute<ITimeSelectInputProps>(
            props?.parameters,
            TimeSelectParametersValidationSchema,
          )}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_PHONE,
    validationRules: phoneValidateRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <PhoneInput
          {...props?.controllerProps}
          error={getErrorMessageFromErrorField(props?.error)}
          {...getAdditionalParametersForAttribute<IPhoneInputProps>(
            props?.parameters,
            PhoneParametersValidationSchema,
          )}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_WYSIWYG,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return <WysiwygInput fullscreen {...props?.controllerProps} />;
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: getValueForLinks,
    type: DisplayedFieldType.EDIT_LINK_SELECT,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      if (!attribute.linkGroup) return null;
      if (attribute.isMultiple) {
        return (
          <MultipleLinkSelect
            linkGroup={attribute.linkGroup}
            {...props?.parameters}
            {...props?.controllerProps}
          />
        );
      }
      return (
        <LinkSelect
          linkGroup={attribute.linkGroup}
          {...props?.parameters}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: getValueForLinks,
    type: DisplayedFieldType.EDIT_LINK_BLOCK,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      if (props?.viewUsage === ViewUsage.PREVIEW) {
        if (!props.baseObject || !attribute.linkGroup) return null;
        return (
          <LinksGroupContent
            object={props.baseObject}
            groups={[attribute.linkGroup]}
            autoOpen={
              props.viewSection
                ? props.viewSection.viewElements.filter(
                    (element) =>
                      element.fieldType === DisplayedFieldType.EDIT_LINK_BLOCK,
                  ).length === 1
                : false
            }
          />
        );
      }
      return (
        <LinkListInput
          multiple={attribute.isMultiple}
          linkGroupId={attribute.linkGroupId}
          objectId={props?.baseObject?.id}
          {...props?.parameters}
          {...props?.controllerProps}
        />
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: getValueForMedia,
    type: DisplayedFieldType.EDIT_MEDIA_WITH_CAMERA,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <MediaInput
          {...props?.controllerProps}
          parameters={getAdditionalParametersForAttribute<IMediaInputParameters>(
            props?.parameters,
            MediaInputParametersValidationSchema,
          )}
          categoryId={props?.categoryId}
          error={getErrorMessageFromErrorField(props?.error)}
        >
          <MediaSource
            source="drop"
            showSwitchButton
            dropzoneProps={{
              className: 'min-w-lg',
              multiple: attribute.isMultiple,
              allowedMimetypes: (attribute.allowedMimetypes ?? []).map(
                (mimetype) => mimetype.extension,
              ),
            }}
            snapshotCameraProps={{
              filename: attribute.label,
            }}
          />
        </MediaInput>
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.EDIT_PLUS_MINUS,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <div className="h-9 w-40">
          <PlusMinusInput
            {...getAdditionalParametersForAttribute<IPlusMinusInputProps>(
              props?.parameters,
              NumberParametersValidationSchema,
            )}
            {...props?.controllerProps}
            error={getErrorMessageFromErrorField(props?.error)}
          />
        </div>
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    detailSubmissionType: DetailSubmissionType.Button,
    getValueForFormField: getValueForMedia,
    type: DisplayedFieldType.EDIT_SIGNATURE,
    validationRules: defaultValidationRules,
    isAReadOnlyField: false,
    defaultValue: null,
    render: (attribute, attributeValues, props): React.ReactNode => {
      return (
        <MediaInput
          {...props?.controllerProps}
          categoryId={props?.categoryId}
          error={getErrorMessageFromErrorField(props?.error)}
        >
          <DrawingPad filename={attribute.label} />
        </MediaInput>
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    getValueForFormField: (): void => {},
    type: DisplayedFieldType.READ_TEXT,
    detailSubmissionType: DetailSubmissionType.None,
    isAReadOnlyField: true,
    render: (attribute, attributeValues, props): React.ReactNode => {
      const renderedValueFromType = attributeValueFromType.find(
        (a) => a.type === attribute.type,
      );

      if (!renderedValueFromType || !attributeValues) return defaultNoValue;
      if (attribute.shouldRenderMultipleValues(attributeValues)) {
        return addAffixesFromViewElement(
          renderedValueFromType.renderMultipleValues(
            attributeValues,
            props?.viewUsage,
          ),
          props?.viewElement,
        );
      }
      const firstValue = attributeValues.at(0);
      if (!firstValue) return defaultNoValue;

      if (firstValue.deferredValue)
        return renderValueAsDeferredModal(attribute, attributeValues, props);

      return addAffixesFromViewElement(
        renderedValueFromType.renderValue(firstValue, props?.viewUsage),
        props?.viewElement,
      );
    },
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    getValueForFormField: (): void => {},
    type: DisplayedFieldType.READ_BADGE,
    detailSubmissionType: DetailSubmissionType.None,
    isAReadOnlyField: true,
    render: (attribute, attributeValues, props): React.ReactNode => {
      if (!attributeValues) return defaultNoValue;
      if (attribute.shouldRenderMultipleValues(attributeValues)) {
        return (
          <BadgesOverflowWrapper
            className={
              props?.viewUsage === ViewUsage.DETAILS
                ? 'justify-end flex-wrap'
                : ''
            }
            minBadgeCount={props?.viewUsage === ViewUsage.DETAILS ? 10 : 1}
          >
            {attributeValues.map((v): React.ReactNode => {
              return (
                <Badge
                  className="min-w-[50px]"
                  key={v?.id}
                  icon={v?.icon}
                  color={v?.color ?? '#E8EAED'}
                >
                  {v?.formattedValue?.toString() ?? v?.value?.toString() ?? ''}
                </Badge>
              );
            })}
          </BadgesOverflowWrapper>
        );
      }
      const firstValue = attributeValues.at(0);
      if (firstValue?.value) {
        return (
          <Badge
            containerProps={{
              'aria-label': 'attribute-value-badge',
            }}
            icon={firstValue?.icon}
            color={firstValue?.color}
          >
            {firstValue?.formattedValue ?? firstValue?.value}
          </Badge>
        );
      }
      return defaultNoValue;
    },
  },
  {
    type: DisplayedFieldType.READ_RICH_TEXT,
    valueToAttributeValue: defaultValueToAttributeValues,
    getValueForFormField: defaultGetValueForFormField,
    detailSubmissionType: DetailSubmissionType.None,
    isAReadOnlyField: true,
    render: renderValueAsDeferredModal,
  },
  {
    valueToAttributeValue: defaultValueToAttributeValues,
    getValueForFormField: defaultGetValueForFormField,
    type: DisplayedFieldType.READ_LOCATION,
    detailSubmissionType: DetailSubmissionType.None,
    isAReadOnlyField: true,
    render: (attribute, attributeValues, props): React.ReactNode => {
      const attributeValue =
        attributeValues?.[0] as IAttributeValue<ILocationDisplay>;
      return <LocationDisplay value={attributeValue?.value} />;
    },
  },
  {
    type: DisplayedFieldType.READ_PROGRESS_BAR,
    valueToAttributeValue: defaultValueToAttributeValues,
    getValueForFormField: defaultGetValueForFormField,
    detailSubmissionType: DetailSubmissionType.None,
    isAReadOnlyField: true,
    render: (attribute, attributeValues, props): React.ReactNode => {
      const value: string | undefined = attributeValues?.[0]?.value;
      const progress: number = value ? Number.parseFloat(value) : 0;
      const displayParameters = props?.viewElement?.fieldTypeParameters;
      const color: string | undefined = displayParameters
        ? getProgressBarInputColor(progress, displayParameters)
        : undefined;
      return (
        <div className="w-36">
          <ProgressBar progress={progress} color={color} />
        </div>
      );
    },
  },
];
