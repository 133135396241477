import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';

interface ILeftPanelContainer {
  children: JSX.Element[] | JSX.Element;
  mobile?: boolean;
  canCollapse?: boolean;
  collapse?: boolean;
  onchangeCollapse?: () => void;
}

/**
 * Return Generic container for left panels
 * @param props ILeftPanelContainer
 * @returns JSX.Element
 */
export const LeftPanelContainer = (props: ILeftPanelContainer): JSX.Element => {
  const { children, mobile, canCollapse, collapse, onchangeCollapse } = props;
  return (
    <div
      className={`${
        mobile
          ? 'w-full min-w-full'
          : collapse && canCollapse
            ? 'w-[0px] min-w-[0px] max-w-[0px]'
            : 'w-[400px] min-w-[400px]'
      } flex flex-col z-[60] bg-primary`}
    >
      {canCollapse && (
        <div className="relative">
          <div
            onClick={onchangeCollapse}
            className="absolute top-[20px] right-[-20px] w-5 h-[42px] rounded-r bg-white dark:bg-gray-200 z-30 flex items-center justify-center cursor-pointer"
          >
            <Icon
              icon={{ name: collapse ? 'chevron-right' : 'chevron-left' }}
            />
          </div>
        </div>
      )}
      <div
        className={`h-full flex flex-col ${collapse ? 'overflow-hidden' : ''}`}
      >
        {children}
      </div>
    </div>
  );
};
