import { generateColors } from '@smack/core/utils/ColorUtils';

export const DEFAULT_THRESHOLD_COLORS = {
  min: 'rgb(212, 54, 71)',
  avg: 'rgb(246, 151, 23)',
  max: 'rgb(52, 163, 123)',
};

export interface IProgressBarProps {
  progress: number;
  color?: string;
  animated?: boolean;
}

const colorFromProgress = (progress: number) => {
  if (progress < 50) return DEFAULT_THRESHOLD_COLORS.min;
  if (progress < 75) return DEFAULT_THRESHOLD_COLORS.avg;
  return DEFAULT_THRESHOLD_COLORS.max;
};

export const ProgressBar: React.FC<IProgressBarProps> = ({
  progress,
  color,
  animated,
}) => {
  const cleanedProgress = Number.isNaN(progress) ? 0 : progress;
  const cleanedColor = color ?? colorFromProgress(progress);

  return (
    <div className="w-full h-5 flex items-center relative overflow-hidden text-sm leading-4 bg-gray-100 dark:bg-neutral-600 rounded-full">
      {animated ? (
        <div
          style={{
            backgroundColor: generateColors(cleanedColor).secondary,
          }}
          className={
            'opacity-20 absolute left-0 top-0 bottom-0 animate-infinite-fill mix-blend-darken'
          }
        />
      ) : null}
      <span className="absolute right-2 text-text">
        {`${cleanedProgress.toLocaleString(undefined, {
          maximumFractionDigits: 2,
        })}%`}
      </span>
      <div
        aria-valuenow={cleanedProgress}
        aria-valuemax={100}
        aria-valuemin={0}
        data-testid={'progress-bar'}
        className="h-full transition-width ease-in-out duration-300"
        style={{
          width: `${Math.min(100, Math.max(0, cleanedProgress))}%`,
          backgroundColor: cleanedColor,
        }}
      />
    </div>
  );
};
