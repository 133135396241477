export enum DisplayedFieldType {
  // FormFields
  EDIT_ADDRESS = 'EDIT_ADDRESS',
  EDIT_CHECKBOX = 'EDIT_CHECKBOX',
  EDIT_DATE = 'EDIT_DATE',
  EDIT_DATETIME = 'EDIT_DATETIME',
  EDIT_DATE_RANGE = 'EDIT_DATE_RANGE',
  EDIT_LOCATION = 'EDIT_LOCATION',
  GEOMETRIES = 'GEOMETRIES',
  EDIT_MULTISELECT = 'EDIT_MULTISELECT',
  EDIT_NUMBER = 'EDIT_NUMBER',
  EDIT_RADIO = 'EDIT_RADIO',
  EDIT_RECURRENCE = 'EDIT_RECURRENCE',
  EDIT_RECURRENCE_SIMPLIFIED = 'EDIT_RECURRENCE_SIMPLIFIED',
  EDIT_SELECT = 'EDIT_SELECT',
  EDIT_TEXT = 'EDIT_TEXT',
  EDIT_TEXTAREA = 'EDIT_TEXTAREA',
  EDIT_MEDIA = 'EDIT_MEDIA',
  EDIT_MEDIA_MULTISELECT = 'EDIT_MEDIA_MULTISELECT',
  EDIT_TIME = 'EDIT_TIME',
  EDIT_TIMESELECT = 'EDIT_TIMESELECT',
  EDIT_PHONE = 'EDIT_PHONE',
  EDIT_WYSIWYG = 'EDIT_WYSIWYG',
  EDIT_LINK_SELECT = 'EDIT_LINK_SELECT',
  EDIT_LINK_BLOCK = 'EDIT_LINK_BLOCK',
  EDIT_MEDIA_WITH_CAMERA = 'EDIT_MEDIA_WITH_CAMERA',
  EDIT_PLUS_MINUS = 'EDIT_PLUS_MINUS',
  EDIT_NUMBER_DISPLAY = 'EDIT_NUMBER_DISPLAY',
  EDIT_SIGNATURE = 'EDIT_SIGNATURE',
  // Readonly Fields
  READ_RICH_TEXT = 'READ_RICH_TEXT',
  READ_TEXT = 'READ_TEXT',
  READ_LOCATION = 'READ_LOCATION',
  READ_PROGRESS_BAR = 'READ_PROGRESS_BAR',
  READ_BADGE = 'READ_BADGE',
}
