import { Category } from '@smack/core/api/models/categories/Category';
import { BaseObjectGroup } from '@smack/core/api/models/objects/BaseObjectGroup/BaseObjectGroup';
import { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { CloseButton } from '@smack/core/components/Actions/Buttons/Button';
import { DataDisplayHeader } from '@smack/core/components/DataDisplay/Headers/DataDisplayHeader';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import type {
  BaseObjectFormOnSubmit,
  BaseObjectFormViewRenderForwardedRef,
} from '@smack/core/components/ViewRenderer/interfaces';
import { spawnModal } from '@smack/core/utils/modal';
import { CreateTimeSeriesBaseObjectModal } from '@smack/core/views/oldViewsToSort/Layouts/Modal/CreateTimeSeriesBaseObjectModal/CreateTimeSeriesBaseObjectModal';
import { DuplicateBaseObjectModal } from '@smack/core/views/oldViewsToSort/Layouts/Modal/DuplicateBaseObjectModal/DuplicateBaseObjectModal';
import { TypeRecurrenceUpdateChoice } from '@smack/core/views/oldViewsToSort/Layouts/Modal/RecurrenceUpdateChoiceObjectModal';
import {
  BaseObjectViewContext,
  BaseObjectViewContextProvider,
} from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/context/BaseObjectViewContext';
import { getActionsButtons } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/getActionsButtons';
import { onClickDelete } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/onClickDelete';
import { onQuit } from '@smack/core/views/oldViewsToSort/Views/Objects/Forms/common/onQuit';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAsyncMemo } from 'use-async-memo';

export interface UpdateFormProps {
  baseObjectId?: number;
  scheduleId?: number;
  onClose?: () => void;
  onSuccessfulFormSubmission?: (output?: { id: number }) => void;
}

export const UpdateForm: React.FC<UpdateFormProps> = ({
  baseObjectId,
  onClose,
  scheduleId,
  onSuccessfulFormSubmission,
}) => {
  const formRef = React.useRef<BaseObjectFormViewRenderForwardedRef>(null);
  const [baseobject, setBaseobject] = React.useState<BaseObject>();
  const navigate = useNavigate();
  const fetchBaseObject = () => {
    if (!baseObjectId) return;
    BaseObject.getBaseObject(baseObjectId, scheduleId).then(setBaseobject);
  };

  React.useEffect(() => {
    fetchBaseObject();
  }, [baseObjectId]);

  const category = useAsyncMemo(() => {
    if (baseobject?.category?.id) {
      return Category.getCategory(baseobject?.category?.id);
    }
  }, [baseobject]);

  const onClickSubmit = (): void => {
    formRef.current?.formRef?.requestSubmit();
  };

  const handleQuit = () => {
    onQuit(formRef.current, onClose, onClickSubmit);
  };

  const handleOnSuccessfulFormSubmission = (data?: { id: number }) => {
    onSuccessfulFormSubmission?.(data);
    onClose?.();
  };

  const onSubmit: BaseObjectFormOnSubmit = (attributes, recurrenceType) => {
    if (!baseobject) return Promise.reject();
    if (
      baseobject.baseobjectGroupId &&
      recurrenceType &&
      recurrenceType !== TypeRecurrenceUpdateChoice.THIS
    ) {
      return BaseObjectGroup.patchBaseObjects(baseobject, recurrenceType, {
        attributes,
      }).then(() => handleOnSuccessfulFormSubmission({ id: baseobject.id }));
    }
    return BaseObject.patchBaseObject(baseobject.id, { attributes }).then(
      handleOnSuccessfulFormSubmission,
    );
  };

  return (
    <BaseObjectViewContextProvider>
      <BaseObjectViewContext.Consumer>
        {({ areActionButtonsReady }) => (
          <div
            data-testid={'update-form'}
            className="flex flex-col flex-grow bg-view"
          >
            <DataDisplayHeader
              icon={baseobject?.category?.icon}
              color={baseobject?.category?.color ?? ''}
              title={baseobject?.title ?? ''}
              actions={[
                ...(category
                  ? getActionsButtons(category, {
                      onClickSubmit,
                      onClickDelete: baseobject?.isWritable
                        ? () => onClickDelete(baseobject, () => navigate('.')) // we are not in the baseobject router so navigate in the actual route
                        : undefined,
                      onClickDuplicate: baseobject
                        ? (): void => {
                            spawnModal({
                              render: ({ onClose }): JSX.Element => {
                                return (
                                  <DuplicateBaseObjectModal
                                    open={true}
                                    setOpen={onClose}
                                    baseObject={baseobject}
                                  />
                                );
                              },
                            });
                          }
                        : undefined,
                      onClickCreateTimeSeries: baseobject
                        ? (): void => {
                            spawnModal({
                              render: ({ onClose }): JSX.Element => {
                                return (
                                  <CreateTimeSeriesBaseObjectModal
                                    open={true}
                                    setOpen={onClose}
                                    baseObject={baseobject}
                                  />
                                );
                              },
                            });
                          }
                        : undefined,
                      isLoading: !areActionButtonsReady,
                    })
                  : []),
                <CloseButton key="quit" onClick={handleQuit} />,
              ]}
            />
            <div className={'flex flex-grow p-6 overflow-y-auto'}>
              <AutoBaseObjectView
                ref={formRef}
                viewUsage={ViewUsage.FORM}
                categoryId={baseobject?.category?.id}
                baseObject={baseobject}
                scheduleId={scheduleId}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        )}
      </BaseObjectViewContext.Consumer>
    </BaseObjectViewContextProvider>
  );
};
