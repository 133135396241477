import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  label: React.ReactNode;
  subLabel?: React.ReactNode;
  moreInformation?: React.ReactNode;
  icon: IconField;
  color?: string;
  onClick?: () => void;
  className?: string;
}

export const NoContentMessage = (props: IProps): JSX.Element => {
  const { icon, label, subLabel, onClick, className, color, moreInformation } =
    props;
  const [moreInfo, setMoreInfo] = React.useState(false);
  const [t] = useTranslation();
  return (
    <div
      data-testid="no-content-message"
      onClick={onClick}
      className={`h-full w-full flex flex-col items-center justify-center  ${
        onClick ? 'hover:text-gray-700 cursor-pointer' : ''
      } ${color ? `text-[${color}]` : 'text-gray-500'} ${className || ''}`}
    >
      <div className="flex flex-col items-center justify-center px-2">
        <Icon color={color} icon={icon} className="  text-2xl" />
        <p
          style={color ? { color } : {}}
          className="uppercase mt-2  text-md text-center "
        >
          {label}
        </p>
        {subLabel ? (
          <p className="text-xs font-light text-center">{subLabel}</p>
        ) : null}
        {moreInformation && <div>{t('more')}</div>}
      </div>
    </div>
  );
};
