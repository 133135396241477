import { Category } from '@smack/core/api/models/categories/Category';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { SelectProps } from '@smack/core/components/DataInput/SelectInput/components/Select';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAsyncMemo } from 'use-async-memo';

interface CategoriesChoicesSelectProps
  extends Omit<SelectProps, 'options' | 'value' | 'onChange'> {
  value?: Category | null;
  onChange: (category: Category | null) => void;
  categories?: Category[];
  parentCategoryId?: number;
}

const categoryToOption = (cat: Category): Option => {
  const breadcrumbLabels = cat.breadcrumbs?.split(' > ') || [];
  const setLabel = breadcrumbLabels[1] ? `(${breadcrumbLabels[1]})` : '';

  return {
    label: `${cat.label} ${setLabel}`,
    color: cat.color,
    icon: cat.icon,
    value: cat.id,
  };
};

export const CategoriesChoicesSelect: React.FC<
  CategoriesChoicesSelectProps
> = ({ value, onChange, categories, parentCategoryId, ...selectProps }) => {
  const [t] = useTranslation();
  const fetchedCategories: Category[] =
    useAsyncMemo(() => {
      if (categories) return Promise.resolve(categories);
      if (parentCategoryId) {
        return Category.getChildCategories(parentCategoryId);
      }
      return Category.getFinalChildCategories();
    }, [categories, parentCategoryId]) ?? [];

  const getOnCategoryClicked = (optionId: number): Category | null => {
    if (!optionId) return null;
    return (
      fetchedCategories.find((s) => s.id.toString() === optionId.toString()) ??
      null
    );
  };

  const selectedOption = React.useMemo(
    () => (value ? categoryToOption(value) : null),
    [value],
  );

  const optionsCategories = React.useMemo(
    () => fetchedCategories.map(categoryToOption),
    [fetchedCategories],
  );

  return (
    <SelectInput
      width={'fluid'}
      placeholder={t('linkableCategoriesChoicesSelect.categoryLabel')}
      {...selectProps}
      value={selectedOption}
      onChange={(newValue): void => {
        if (Array.isArray(newValue)) return;
        onChange(getOnCategoryClicked(newValue?.value as number));
      }}
      options={optionsCategories}
    />
  );
};
