/**
 *
 *  TASKRULE MODEL
 *
 */

import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  type ITaskAttributeModification,
  TaskAttributeModification,
} from '@smack/core/api/models/tasks/TaskAttributeModification';
import {
  type ITaskReminderRule,
  TaskReminderRule,
} from '@smack/core/api/models/tasks/TaskReminderRule';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { parseISO } from 'date-fns';
import type { Status } from '../Status';
import type { Task } from '../Task';
import {
  type ITaskBaseObjectCloner,
  TaskBaseObjectCloner,
} from '../TaskBaseObjectCloner';
import type { ITaskMessage } from '../TaskMessage';
import { TaskMessage } from '../TaskMessage';

/**
 * Interface for the task rule model
 *
 * @export
 * @interface ITaskRule
 */
export interface ITaskRule {
  id: number;
  label?: string;
  description?: string;
  icon?: IconField;
  color?: string;
  fromTask?: Task;
  fromStatus?: Status;
  toTask?: Task;
  toStatus?: Status;
  lastExecAt?: string;
  taskMessage?: ITaskMessage;
  taskReminderRule?: ITaskReminderRule;
  taskAttributeModification?: ITaskAttributeModification;
  taskBaseobjectCloner?: ITaskBaseObjectCloner;
}

/**
 * Class for the task rule model
 *
 * @export
 * @class TaskRule
 */
export class TaskRule {
  id: number;

  fromTask?: Task;

  fromStatus?: Status;

  toTask?: Task;

  toStatus?: Status;

  taskMessage?: TaskMessage;

  taskReminderRule?: TaskReminderRule;

  taskAttributeModification?: TaskAttributeModification;

  taskBaseobjectCloner?: TaskBaseObjectCloner;

  lastExecAt?: Date;

  constructor(data: ITaskRule) {
    this.id = data.id;
    this.fromTask = data.fromTask;
    this.fromStatus = data.fromStatus;
    this.toTask = data.toTask;
    this.toStatus = data.toStatus;
    if (data.taskMessage) this.taskMessage = new TaskMessage(data.taskMessage);
    if (data.taskReminderRule)
      this.taskReminderRule = new TaskReminderRule(data.taskReminderRule);
    if (data.taskAttributeModification)
      this.taskAttributeModification = new TaskAttributeModification(
        data.taskAttributeModification,
      );
    if (data.taskBaseobjectCloner) {
      this.taskBaseobjectCloner = new TaskBaseObjectCloner(
        data.taskBaseobjectCloner,
      );
    }
    if (data.lastExecAt) {
      this.lastExecAt = parseISO(data.lastExecAt);
    }
  }

  /**
   * Get all rules from the project Id
   * @static
   * @param {number} projectId
   * @return {*}  {Promise<TaskRule[]>}
   * @memberof TaskRule
   */
  static getRulesFromProjectId(projectId: number): Promise<TaskRule[]> {
    return RESTClient.get<{ data: { results: ITaskRule[] } }>(
      `/tasks/projects/${projectId}/task-rules`,
    ).then((res) => res.data?.results.map((data) => new TaskRule(data)) || []);
  }

  static getRulesFromTaskId(taskId: number): Promise<TaskRule[]> {
    return RESTClient.get<{ data: { results: ITaskRule[] } }>(
      `/tasks/tasks/${taskId}/task-rules`,
    ).then((res) => res.data?.results.map((data) => new TaskRule(data)) || []);
  }
}
