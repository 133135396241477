import type { BaseObjectLog } from '@smack/core/api/models/objects/BaseObjectLog';
import { useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const BaseObjectLogUpdateFeed = (
  props: ILogFeedProps<BaseObjectLog>,
): JSX.Element => {
  const { log } = props;

  const { t } = useTranslation();

  return (
    <LogFeed {...props}>
      <span className="text-sm font-normal">
        {' '}
        {log.content?.commentId ? (
          <>
            {t('logs.baseobject.commentCreation', {
              code: log.content?.baseobject?.code,
            })}
          </>
        ) : (
          <>
            {t('logs.baseobject.update', {
              code: log.content?.baseobject?.code,
            })}
          </>
        )}
      </span>
    </LogFeed>
  );
};
