/**
 * Reminder Model
 */

import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { IUser } from '@smack/core/store/users/types';
import type { IGroup } from '../../users/Group';
import {
  type IMessage,
  type IReminderRule,
  ReminderRule,
} from '../ReminderRule';

/**
 * reminder model interface
 *
 * @export
 * @interface IReminder
 */
export interface IReminder {
  id: number;
  subject?: string;
  content?: string;
  reminderDatetime: string;
  isSent?: boolean;
  sentAt?: string;
  reminderRule?: IReminderRule;
  messages?: IMessage[];
}

export interface ReminderApiInput
  extends Partial<Omit<IReminder, 'recipients' | 'extraRecipientAttribute'>> {
  id?: never;
  isSent?: never;
  sentAt?: never;
  reminderRule?: never;
  extraRecipientAttribute?: string | number;
  messages?: IMessage[];
  baseobject?: string | number;
}

export interface IReminderPost extends Pick<IReminder, 'subject' | 'content'> {
  messages?: IMessage<number, number>[];
  baseobjectId?: number;
  reminderDatetime?: string;
}

export class Reminder {
  id: number;

  subject?: string;

  content?: string;

  reminderDatetime: Date;

  isSent?: boolean;

  sentAt?: Date;

  reminderRule?: ReminderRule;

  messages?: IMessage[];

  recipients: {
    user?: IUser;
    group?: IGroup;
  }[] = [];

  constructor(data: IReminder) {
    this.id = data.id;
    this.subject = data.subject;
    this.content = data.content;
    this.reminderDatetime = new Date(data.reminderDatetime);
    this.isSent = data.isSent;
    if (data.sentAt) {
      this.sentAt = new Date(data.sentAt);
    }
    if (data.reminderRule) {
      this.reminderRule = new ReminderRule(data.reminderRule);
    }

    if (data.messages) {
      this.messages = data.messages;
    }
  }

  static getFromBaseObjectId(id: number): Promise<Reminder[]> {
    return RESTClient.get<{ data: { results: IReminder[] } }>(
      `/reminders/reminders?baseobject=${id}`,
    ).then((res) => res.data.results.map((r) => new Reminder(r)));
  }

  static create(data: IReminderPost): Promise<Reminder> {
    return RESTClient.post<{ results: IReminder }>(
      data,
      '/reminders/reminders',
    ).then((res) => new Reminder(res.data.results));
  }

  static patch(id: number, data: IReminderPost): Promise<Reminder> {
    return RESTClient.patch<{ results: IReminder }>(
      data,
      `/reminders/reminders/${id}`,
    ).then((res) => new Reminder(res.data.results));
  }

  static delete(id: number): Promise<void> {
    return RESTClient.delete<void>(`/reminders/reminders/${id}`);
  }
}
