import type { ChecklistItemLog } from '@smack/core/api/models/tasks/ChecklistItemLog';
import { Trans, useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const ChecklistItemLogModificationFeed = (
  props: ILogFeedProps<ChecklistItemLog>,
): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'check' }}>
      <span className="text-sm font-normal">
        {' '}
        <Trans
          t={t}
          i18nKey="logs.action.modifiedChecklistItem"
          values={{
            checklistItem: props.log.content?.checklistItem?.label,
            checklist: props.log.content?.checklistItem?.checklist?.label,
          }}
        />
      </span>
    </LogFeed>
  );
};
