import { NavLink, useSearchParams } from 'react-router-dom';

import '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation/index.scss';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';

import type { AppState } from '@smack/core/store';
import { addSearchParams } from '@smack/core/utils/URLs';
import { useSelector } from 'react-redux';

export interface INavigationTabs {
  label: string;
  id: string;
  icon?: IconField;
  img?: string;
  render: () => JSX.Element;
}

interface INavigation {
  label: string;
  id: string;
}

export interface INavigationProps {
  navigation: INavigation[];
  children?: JSX.Element;
}

export const DetailPanelNavigation = (props: INavigationProps): JSX.Element => {
  const { settings } = useSelector((state: AppState) => state.App);
  const { navigation, children } = props;

  const defaultColor = settings?.color ?? settings?.defaultColor;
  const color = settings?.primaryBackgroundColor ?? defaultColor;
  const [searchParams] = useSearchParams();
  return (
    <div
      data-testid="routedElementPreview"
      className="w-full h-full flex items-center justify-center flex-col min-h-0"
    >
      <div className="w-full h-12 min-h-[48px] max-h-[48px] flex justify-center items-center border-b border-border ">
        {navigation.map((nav) => {
          return (
            <NavLink key={nav.label} to={addSearchParams(nav.id, searchParams)}>
              {({ isActive }): JSX.Element => (
                <div className="cursor-pointer">
                  <div
                    className={`mx-3 text-[14px] border-b-2 ${
                      isActive
                        ? 'text-blue-400 font-medium  border-blue-400'
                        : 'text-gray-500 font-medium dark:text-gray-400 border-hidden'
                    }`}
                    style={{
                      color: isActive ? color : settings?.defaultColor,
                      borderBottom: isActive ? `2px solid ${color}` : '',
                    }}
                  >
                    {nav.label}
                  </div>
                </div>
              )}
            </NavLink>
          );
        })}
      </div>
      {children}
    </div>
  );
};
