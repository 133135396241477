import {
  LinkGroup,
  PopulationType,
} from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { useAvailableViews } from '@smack/core/hooks/views/useAvailableViews/useAvailableViews';
import { setCalendarLinkGroups } from '@smack/core/store/objects/actions';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import {
  type IPageHeaderPropsAction,
  PageHeader,
} from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import { LinkGroupForm } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/LinkGroupForm';
import { LinksGroupContent } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links/Components/LinksGroupContent/LinksGroupContent';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

/**
 * props interface for the link page
 *
 * @interface IProps
 */
interface IProps {
  pageTitle: string;
  object: BaseObject;
  groups: LinkGroup[];
  reload: () => void;
  parentGroup: LinkGroup;
}

/**
 * LinksPage component
 *
 * @param {IProps} props
 * @return {*}  {JSX.Element}
 */
export const LinksPage = (props: IProps): JSX.Element => {
  const dispatch = useDispatch();
  const { pageTitle, groups, object, parentGroup, reload } = props;
  const { t } = useTranslation();
  const [, setView] = useActiveView();
  const [isGroupFormOpen, setIsGroupFormOpen] = React.useState(false);
  const [isReordering, setIsReordering] = React.useState(false);
  const [rootCategory, setCategory, subCategory] = useActiveCategories();
  const availableViews = useAvailableViews(
    subCategory ?? setCategory ?? rootCategory,
    !!object,
  );
  const hasConfiguredEmptyGroup = (): boolean => {
    return !!object.category?.isEmptyLinkGroupHidden;
  };
  const [isHidingEmptyGroups, setIsHidingEmptyGroups] = React.useState(
    hasConfiguredEmptyGroup(),
  );

  const GetHeaderAction = (): IPageHeaderPropsAction[] => {
    const output: IPageHeaderPropsAction[] = [];
    if (groups.length && hasConfiguredEmptyGroup()) {
      output.push({
        label: isHidingEmptyGroups
          ? t('links.showEmptyGroup')
          : t('links.hideEmptyGroup'),
        icon: {
          name: isHidingEmptyGroups ? 'eye' : 'eye-slash',
          familyStyle: 'fas',
        },
        onClick: () => setIsHidingEmptyGroups(!isHidingEmptyGroups),
      });
    }
    const timeSensitive = groups
      .filter((g) => g.isTimeSensitive)
      .map((g) => g.id);
    if (
      timeSensitive.length &&
      availableViews.find((v) => v.id === ViewName.ObjectCalendar)
    ) {
      output.push({
        label: t('links.displayCalendar'),
        onClick: () => {
          dispatch(setCalendarLinkGroups(object.id, timeSensitive));
          setView({
            view: ViewName.ObjectCalendar,
            params: {},
          });
        },
        icon: { name: 'calendar', familyStyle: 'fas' },
      });
    }

    if (!object?.isWritable) {
      return output;
    }
    if (parentGroup.populationType === PopulationType.FLOOR) {
      if (isReordering) {
        return [
          ...output,
          {
            label: t('links.saveFloorsOrder'),
            onClick: () => setIsReordering(false),
            icon: { name: 'save', familyStyle: 'fas' },
          },
        ];
      }
      return [
        ...output,
        {
          label: t('links.createFloor'),
          onClick: (): void => {
            setIsGroupFormOpen(true);
          },
          icon: { name: 'plus', familyStyle: 'fas' },
        },
        // There is no need for reordering if there is one or no floors
        ...((parentGroup.children?.length || 0) > 1
          ? [
              {
                label: t('links.reorderFloors'),
                onClick: () => setIsReordering(true),
                icon: {
                  name: 'reorder' as const,
                  familyStyle: 'fas' as const,
                },
              },
            ]
          : []),
      ];
    }
    return output;
  };

  const onGroupCreate = (label: string): void => {
    LinkGroup.createFloorLinkGroup(object.id, label, parentGroup.id).then(
      () => {
        setIsGroupFormOpen(false);
        reload();
        toast.success(t('links.floorCreated'));
      },
    );
  };

  return (
    <div className="flex flex-col absolute inset-0">
      <PageHeader actions={GetHeaderAction()} title={pageTitle} />
      <LinkGroupForm
        open={isGroupFormOpen}
        setOpen={setIsGroupFormOpen}
        onSubmit={onGroupCreate}
      />
      <LinksGroupContent
        key={parentGroup.id}
        isReordering={isReordering}
        groups={groups}
        showOnMap
        object={object}
        isHidingEmptyGroups={isHidingEmptyGroups}
        height="calc(100% - 45px)"
        reload={reload}
        setIsReordering={setIsReordering}
        populationType={parentGroup.populationType}
        setIsHidingEmptyGroups={setIsHidingEmptyGroups}
      />
    </div>
  );
};
