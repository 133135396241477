import type { ListElementBaseObject } from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import { IconRoundedButton } from '@smack/core/components/Actions/Buttons/IconRoundedButton/IconRoundedButton';
import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import { LeftIconContainer } from '@smack/core/components/DataDisplay/Lists/ListElementsArrayView/Components/LeftIconContainer';
import { formatLongString } from '@smack/core/utils';

interface IProps {
  baseObject: ListElementBaseObject;
  actions: IDetailAction[];
  simplifiedView: boolean;
}

export interface IDetailAction {
  label: string;
  icon: IconField;
  onClick: () => void;
  disabled: boolean;
}

export const BaseObjectDetails = (props: IProps): JSX.Element => {
  const { baseObject, actions, simplifiedView } = props;

  return (
    <div className="py-2 border-neutral-200 dark:border-neutral-700">
      <div className="flex items-center px-3 gap-3">
        <div className="flex flex-col items-center">
          <LeftIconContainer
            color={baseObject.color ?? ''}
            icon={baseObject.category?.icon}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="w-full flex justify-between items-center">
            <div className="text-text max-w-[200px] ">
              <div
                title={baseObject.title}
                className="text-m font-medium truncate"
              >
                {baseObject.title}
              </div>
              {baseObject.subtitle && (
                <div className="text-sm">
                  {formatLongString(baseObject.subtitle, 27)}
                </div>
              )}
              <p className="text-sm text-gray-600 dark:text-gray-400">
                {baseObject.category?.label}
              </p>
            </div>
            <div>
              {baseObject.badge && (
                <Badge
                  icon={baseObject.badge?.icon}
                  className="ml-auto"
                  color={baseObject.badge?.color || ''}
                  maxWidth="11ch"
                >
                  {baseObject.badge.value || ''}
                </Badge>
              )}
              {!simplifiedView ? (
                <div
                  data-testid="baseObjectDetailsIconsContainer"
                  className="flex items-center mt-2"
                >
                  {actions.map((action) => (
                    <IconRoundedButton
                      key={action.label}
                      icon={action.icon}
                      onClick={action.onClick}
                      title={action.label}
                      className="ml-1"
                      position="top-start"
                      disabled={action.disabled}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
