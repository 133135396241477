import type React from 'react';
import { useContext } from 'react';

import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { SelectContext } from './SelectProvider';

interface SearchInputProps {
  placeholder?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  isLoading?: boolean;
  onClickChevron?: () => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  placeholder = '',
  value = '',
  onChange,
  name = '',
  isLoading,
  onClickChevron,
}) => {
  const { classNames } = useContext(SelectContext);
  return (
    <div
      className={
        classNames?.searchContainer
          ? classNames.searchContainer
          : 'relative py-1 px-2.5'
      }
    >
      <div className={'absolute top-4 left-5 text-gray-500 dark:text-gray-300'}>
        <Icon
          animation={isLoading ? 'spin' : undefined}
          icon={isLoading ? { name: 'spinner' } : { name: 'search' }}
        />
      </div>
      <input
        // biome-ignore lint/a11y/noAutofocus:
        autoFocus
        className={
          classNames?.searchBox
            ? classNames.searchBox
            : 'w-full py-2 pl-8 text-sm dark:bg-neutral-600 text-gray-500 placeholder:dark:text-gray-300 bg-gray-100 border border-gray-200 dark:border-neutral-500 rounded focus:border-2 focus:border-blue-400 focus:ring-0 focus:outline-none'
        }
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        name={name}
      />
      <div
        className={
          'absolute top-4 right-5 text-gray-500 dark:text-gray-300 flex items-center gap-2'
        }
      >
        {value && (
          <Icon
            onClick={(): void =>
              onChange({
                target: { value: '' },
              } as React.ChangeEvent<HTMLInputElement>)
            }
            className={'cursor-pointer'}
            icon={{ name: 'times' }}
          />
        )}
        {onClickChevron && (
          <Icon
            onClick={onClickChevron}
            icon={{ name: 'chevron-down' }}
            className={'cursor-pointer'}
          />
        )}
      </div>
    </div>
  );
};

export default SearchInput;
