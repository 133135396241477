import { LinkRecommendation } from '@smack/core/api/models/recommendations/LinkRecommendation';
import { NoContentMessage } from '@smack/core/components/DataDisplay/NoContentMessage';
import { setModuleStore } from '@smack/core/store/app/actions';
import { PageHeader } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/PageHeader';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

interface IProps {
  categoryId?: number;
}

export const RecommendationPage = (props: IProps): JSX.Element => {
  const { t } = useTranslation();
  const { categoryId } = props;
  const dispatch = useDispatch();
  const { object } = useParams();
  const [recommendations, setRecommendations] = React.useState<
    LinkRecommendation[]
  >([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  React.useEffect(() => {
    if (categoryId) {
      LinkRecommendation.getLinkRecommendationByCategoryId(categoryId)
        .then((res) => {
          setLoading(false);
          setRecommendations(res);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [categoryId]);

  const onClickRecommendation = (recommendation: LinkRecommendation): void => {
    if (!object) return;
    dispatch(
      setModuleStore({
        recommendation: {
          id: recommendation.id,
          baseObjectId: Number.parseInt(object),
        },
      }),
    );
  };

  return (
    <div className="flex flex-col h-full w-full">
      <PageHeader actions={[]} title="Recommandations" />
      {recommendations.length && !loading ? (
        <div className="flex-grow overflow-y-auto">
          {recommendations?.map(
            (recommendation): JSX.Element => (
              <div
                className="w-full uppercase h-12 border-b border-border px-4 cursor-pointer bg-third flex items-center dark:text-white"
                onClick={(): void => onClickRecommendation(recommendation)}
                key={recommendation.id}
              >
                {recommendation.label}
              </div>
            ),
          )}
        </div>
      ) : (
        <NoContentMessage
          icon={{ name: 'lightbulb-on' }}
          label={t('recommendationPage.noRecommendation')}
          className="my-6"
        />
      )}
    </div>
  );
};
