import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ImageMediasGrid } from '@smack/core/components/DataDisplay/Medias/ImageMediasGrid/ImagesMediasGrid';
import { MediasTree } from '@smack/core/components/DataDisplay/Medias/MediasTree/MediasTree';
import type { INavigationTabs } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation';
import { SubNavigation } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation/SubNavigation';
import { useTranslation } from 'react-i18next';

interface IProps {
  baseObject: BaseObject;
}

export const BaseObjectPanelMediasRouter = (props: IProps): JSX.Element => {
  const { baseObject } = props;
  const { t } = useTranslation();
  const getMediaTabs = (): INavigationTabs[] => {
    return [
      {
        id: 'documents',
        label: t('medias.documents'),
        icon: { name: 'list-tree' },
        render: (): JSX.Element => (
          <MediasTree
            parentObject={baseObject.mediasManager}
            canEdit={baseObject?.isWritable}
            // will be limited to the screen size inside the component
            containerClassName="absolute top-0 bottom-0 right-0 left-0"
            title={t('medias.documents')}
            legacyHeader
          />
        ),
      },
      {
        id: 'images',
        label: t('medias.images'),
        icon: { name: 'file-image' },
        render: (): JSX.Element => (
          <ImageMediasGrid
            parentObject={baseObject.mediasManager}
            className="absolute top-0 bottom-0 right-0 left-0"
            title={t('medias.images')}
            legacyHeader
          />
        ),
      },
    ];
  };

  return <SubNavigation tabs={getMediaTabs()} />;
};
