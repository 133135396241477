import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { EmitterLoading } from '@smack/core/components/DataDisplay/Table/emiter';
import { usePrevious } from '@smack/core/hooks/usePrevious/usePrevious';
import {
  type ColumnPinning,
  getCommonPinningStyles,
} from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectDataTable/utils';
import {
  type ColumnDef,
  type ColumnSizingState,
  type Row,
  type RowData,
  type Updater,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { cx } from 'class-variance-authority';
import React, { type FC, useState } from 'react';

export type ICellType = {
  frontEndpoint?: string;
  isRowActive?: string | number;
  baseObject?: BaseObject;
  [key: string | number]: unknown;
};

export type IHeaderFilters = Record<string, React.ReactNode>;

export type IHeaderSort = Record<string, React.ReactNode>;

export type TableLoadMoreType = 'previous' | 'next';

export interface TableProps {
  columns: ColumnDef<ICellType>[];
  data: ICellType[];
  filters?: IHeaderFilters;
  sort?: IHeaderSort;
  onRowClick?: (e: React.MouseEvent<HTMLDivElement>, row: Row<RowData>) => void;
  loadMore?: (type: TableLoadMoreType) => void;
  isFetching?: boolean;
  isPreviousComplete?: boolean;
  isNextComplete?: boolean;
  noContent?: React.ReactNode;
  hiddenColumns?: string[];
  columnOrder?: string[];
  columnPinning?: ColumnPinning;
  onScrollEnd?: (firstVisibleRow: Row<ICellType>) => void;
  onResizingColumn?: (columnWidths: Record<string, number>) => void;
}

export const Table: FC<TableProps> = ({
  columns,
  data,
  sort,
  filters,
  onRowClick,
  loadMore,
  isFetching,
  isNextComplete = true,
  isPreviousComplete = true,
  hiddenColumns = [],
  columnOrder = [],
  onResizingColumn,
  noContent,
  onScrollEnd,
  columnPinning,
}: TableProps) => {
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);
  const scrollRef = React.useRef<Row<ICellType>>();
  const previousData = usePrevious(data);
  const columnVisibility = {};
  for (const key of hiddenColumns) {
    columnVisibility[key] = false;
  }
  const [columnSizing, setColumnSizing] = useState<Updater<ColumnSizingState>>(
    {},
  );
  const onColumnSizingChange = (columnsSize: Updater<ColumnSizingState>) => {
    setColumnSizing(columnsSize);
    onResizingColumn?.(columnSizing as Record<string, number>);
  };

  const table = useReactTable<ICellType>({
    data,
    columns,
    state: {
      columnVisibility,
      columnSizing: columnSizing as ColumnSizingState,
      columnOrder,
      columnPinning: columnPinning ?? { left: [], right: [] },
    },
    defaultColumn: {
      size: 200,
      minSize: 50,
      maxSize: 1000,
    },
    columnResizeMode: 'onChange',
    onColumnSizingChange: onColumnSizingChange,
    getCoreRowModel: getCoreRowModel(),
  });

  const getFilter = (id: string): React.ReactNode => {
    return filters?.[id] ?? null;
  };

  const getSort = (id: string): React.ReactNode => {
    return sort?.[id] ?? null;
  };

  const { rows } = table.getRowModel();

  const getFirstVisibleRow = (): Row<ICellType> | undefined => {
    if (!scrollContainerRef.current) return;
    const scrollContainer = scrollContainerRef.current;

    const scrollTop = scrollContainer.scrollTop;

    // Get the first visible row based on the scrollTop
    return rows.find((row) => {
      const rowElement = document.getElementById(row.id);
      return rowElement && rowElement.offsetTop >= scrollTop;
    });
  };

  const handleScroll = () => {
    const firstVisibleRow = getFirstVisibleRow();
    if (firstVisibleRow && onScrollEnd) {
      onScrollEnd(firstVisibleRow);
    }
  };

  React.useEffect(() => {
    const scrollContainer = scrollContainerRef.current;
    if (!scrollContainer) return;

    if (scrollRef.current && !isFetching) {
      const offset = data?.length - previousData?.length;
      scrollContainer?.scrollTo({ behavior: 'instant', top: offset * 40 });
      scrollRef.current = undefined;
    }

    scrollContainer.addEventListener('scrollend', handleScroll);
    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener('scrollend', handleScroll);
      }
    };
  }, [data]);

  const onLoadMore = (type: TableLoadMoreType): void => {
    if (type === 'previous' && scrollContainerRef.current) {
      scrollRef.current = getFirstVisibleRow();
      loadMore?.(type);
    } else {
      loadMore?.(type);
      scrollRef.current = undefined;
    }
  };

  return (
    <div
      data-testid={'Table'}
      ref={scrollContainerRef}
      className="h-full w-full  bg-view  overflow-scroll shadow border ring-black dark:border-gray-600 ring-opacity-5 md:rounded-lg"
    >
      <div style={{ width: table.getTotalSize() }}>
        <div className="w-full bg-primary dark:bg-neutral-800 sticky top-0 m-0 z-[2]">
          {table.getHeaderGroups().map((headerGroup) => (
            <div className="flex" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <div
                  className={cx(
                    'relative px-3 py-4 overflow-hidden text-left text-sm truncate border-b',
                    header.subHeaders.length
                      ? 'text-gray-700 bg-gray-300/10 uppercase'
                      : 'dark:text-white font-medium',
                  )}
                  key={header.id}
                  style={{
                    width: header.getSize(),
                    ...(columnPinning
                      ? getCommonPinningStyles(
                          header.column,
                          header.getSize(),
                          columnPinning,
                          table,
                        )
                      : {}),
                  }}
                >
                  <div className="flex items-center justify-between">
                    <p className="truncate">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </p>

                    <div className="flex gap-2 items-center justify-center">
                      {getSort(header.id)}
                      {getFilter(header.id)}
                    </div>
                  </div>
                  <div
                    data-testid={'resize-handle'}
                    onMouseDown={header.getResizeHandler()}
                    onTouchStart={header.getResizeHandler()}
                    className="bg-gray-300 w-1 absolute top-0 right-0 bottom-0 touch-none z-30 translate-x-1/2 cursor-col-resize"
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="h-full divide-secondary">
          {!isPreviousComplete ? (
            <EmitterLoading
              onScreen={() => onLoadMore('previous')}
              show={isFetching}
            />
          ) : null}
          {rows.length ? (
            <>
              {rows.map((row, index) => {
                return (
                  <div
                    role={'presentation'}
                    key={`${row.id}-${index} `}
                    id={row.id}
                    onClick={(event) => onRowClick?.(event, row)}
                    className={`flex w-fit cursor-pointer bg-primary hover:bg-gray-100 h-10  hover:dark:bg-neutral-700 z-[1] ${
                      index && index % 2 !== 0
                        ? 'bg-view dark:bg-neutral-700 '
                        : ''
                    } ${
                      (row.original as Record<string, boolean>).isRawActive
                        ? '!bg-blue-200 hover:!bg-blue-200'
                        : ''
                    }`}
                  >
                    {row.getVisibleCells().map((cell, index) => (
                      <div
                        className="whitespace-nowrap h-full flex items-center  overflow-hidden px-3  text-sm text-gray-500 dark:text-gray-100"
                        key={`${cell.id}-${index}`}
                        style={{
                          width: `${cell.column.getSize()}px`,
                          ...(columnPinning
                            ? getCommonPinningStyles(
                                cell.column,
                                cell.column.getSize(),
                                columnPinning,
                                table,
                              )
                            : {}),
                        }}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
              {!isNextComplete ? (
                <EmitterLoading
                  onScreen={() => {
                    onLoadMore('next');
                  }}
                  show={isFetching}
                />
              ) : null}
            </>
          ) : (
            <>{noContent}</>
          )}
        </div>
      </div>
    </div>
  );
};
