/**
  MINI MAP EVENT UTILITY CLASSE
*/

import type { GeoJSONSource, Map as MapLibreMap } from 'maplibre-gl';

export class MiniMapUtils {
  static addMiniMapPoint(
    map: MapLibreMap | null,
    feature: GeoJSON.Feature<GeoJSON.Geometry>,
  ): void {
    const source: GeoJSONSource = map?.getSource(
      'minimap-point',
    ) as GeoJSONSource;
    if (!source) {
      map?.addSource('minimap-point', {
        type: 'geojson',
        data: feature,
      });
    } else {
      source.setData(feature);
    }

    const layer = map?.getLayer('minimap-point');
    if (!layer) {
      map?.addLayer({
        id: 'minimap-point',
        type: 'circle',
        source: 'minimap-point',
        paint: {
          'circle-radius': 5,
          'circle-color': 'red',
        },
      });
    }
  }
}
