import {
  Action,
  type IAction,
} from '@smack/core/api/models/actions/Action/Action';
import {
  IconButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IDisplayedButtonComponentProps } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/type';
import type React from 'react';
import ReactToPrint from 'react-to-print';

/**
 * DisplayedButton interface
 *
 * @export
 * @interface IDisplayedButton
 */
export enum DisplayedButtonActionType {
  SAVE = 'SAVE',
  SAVE_AND_ACTION = 'SAVE_AND_ACTION',
  PRINT = 'PRINT',
  DUPLICATE = 'DUPLICATE',
  DELETE = 'DELETE',
  SHOW_DASHBOARD = 'SHOW_DASHBOARD',
  CREATE_TIME_SERIES = 'CREATE_TIME_SERIES',
}

/**
 * DisplayedButton interface
 *
 * @export
 * @interface IDisplayedButton
 */
export interface IDisplayedButton {
  id: number;
  label: string;
  icon: IconField;
  color: string;
  actionType: DisplayedButtonActionType;
  action?: IAction;
  isInForm: boolean;
  isInSheet: boolean;
}

/**
 * DisplayedButton model
 *
 * @export
 * @class DisplayedButton
 */
export class DisplayedButton implements IDisplayedButton {
  id: number;

  label: string;

  icon: IconField;

  color: string;

  actionType: DisplayedButtonActionType;

  action?: Action;

  isInForm: boolean;

  isInSheet: boolean;

  constructor(data: IDisplayedButton) {
    this.id = data.id;
    this.label = data.label;
    this.icon = data.icon;
    this.color = data.color;
    this.actionType = data.actionType;
    this.action = data.action ? new Action(data.action) : undefined;
    this.isInForm = data.isInForm;
    this.isInSheet = data.isInSheet;
  }

  getIconButton = (
    props: IDisplayedButtonComponentProps,
    onClick?: ((displayedButton: DisplayedButton) => void) | (() => void),
  ): React.ReactElement => {
    return (
      <IconButton
        key={this.id}
        icon={this.icon}
        onClick={onClick ? (): void => onClick(this) : undefined}
        isLoading={props.isLoading}
        disabled={props.isLoading}
      >
        {this.label}
      </IconButton>
    );
  };

  /**
   *
   * @param props
   * @param onClick
   * @returns a button colored with the customer's colors
   */
  getSaveButton = (
    props: IDisplayedButtonComponentProps,
    onClick?: ((displayedButton: DisplayedButton) => void) | (() => void),
  ): React.ReactElement => {
    return (
      <SaveButton
        key={this.id}
        onClick={onClick ? (): void => onClick(this) : undefined}
        isLoading={props.isLoading}
        disabled={props.isLoading}
      />
    );
  };

  getButtonComponent(props: IDisplayedButtonComponentProps): React.ReactNode {
    const buttons = {
      [DisplayedButtonActionType.SAVE]: props.onClickSubmit
        ? this.getSaveButton(props, props.onClickSubmit)
        : null,
      [DisplayedButtonActionType.SAVE_AND_ACTION]: this.getIconButton(
        props,
        (): void => {
          if (props.addPostSubmitCallbacks) {
            const actionCallback = (res?: { id?: number }): void => {
              if (res?.id && this.action)
                this.action.triggerActionFromBaseObject(res.id);
            };
            props.addPostSubmitCallbacks?.(actionCallback);
          }
          props.onClickSubmit?.();
        },
      ),
      [DisplayedButtonActionType.PRINT]: (
        <ReactToPrint
          key={'print'}
          trigger={(): React.ReactElement => this.getIconButton(props)}
          content={(): HTMLDivElement | null => props.printRef?.current ?? null}
        />
      ),
      [DisplayedButtonActionType.DUPLICATE]: props.onClickDuplicate
        ? this.getIconButton(props, props.onClickDuplicate)
        : undefined,
      [DisplayedButtonActionType.DELETE]: props.onClickDelete
        ? this.getIconButton(props, props.onClickDelete)
        : undefined,
      [DisplayedButtonActionType.SHOW_DASHBOARD]: this.getIconButton(
        props,
        props.onClickShowDashboard,
      ),
      [DisplayedButtonActionType.CREATE_TIME_SERIES]:
        props.onClickCreateTimeSeries
          ? this.getIconButton(props, props.onClickCreateTimeSeries)
          : undefined,
    };

    return buttons[this.actionType];
  }
}
