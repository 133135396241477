import { FiltersBadge } from '@smack/core/components/DataDisplay/Badges/FiltersBadge';
import type { AppState } from '@smack/core/store';
import {
  getDefaultDaterange,
  setDefaultFilters,
} from '@smack/core/store/app/actions';
import {
  isActive,
  toggleFilter,
} from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import type { Attribute } from '@smack/core/api/models/categories/Attribute/Attribute';
import type { Category } from '@smack/core/api/models/categories/Category';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { AttributeType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import { DateUtils } from '@smack/core/utils/DateUtils';
import {
  FilterFunctionChoice,
  type IFilters,
  getDateTimeRangeFromFilterFunctionValue,
} from '@smack/core/utils/Filters';
import {
  ContextualMenu,
  type IMenuItem,
  type IMenuItemType,
} from '@smack/core/views/oldViewsToSort/Layouts/Objects/MenuFilter/Components/Contextual';
import { format } from 'date-fns';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface Props {
  filterItems: IMenuItem[];
  attributes?: Attribute[];
  className?: string;
  containerClassName?: string;
  inline?: boolean;
  isTabular?: boolean;
  showItemLimit?: number;
  beforeSeparator?: string;
  filters: IFilters | undefined;
  setFilters: (filters: IFilters) => void;
  resetFilters?: boolean;
  category?: Category;
  hideIfEmpty?: boolean;
}

interface IActiveFilter {
  label: string;
  actions: {
    icon?: IconField;
    onClick?: () => void;
  }[];
}

function formatFilterFunctionValue(
  filterFunction: string,
  filterFunctionValue: string,
  attributeType?: AttributeType,
) {
  if (filterFunction === 'between') {
    const { startDate, endDate } =
      getDateTimeRangeFromFilterFunctionValue(filterFunctionValue);
    if (!startDate || !endDate) return filterFunctionValue;
    return DateUtils.getDateRangeLocalString(startDate, endDate, false, false);
  }
  if (
    ['gte', 'lte', 'eq'].includes(filterFunction) &&
    (attributeType === AttributeType.DATE ||
      attributeType === AttributeType.DATETIME)
  ) {
    return new Date(filterFunctionValue).toLocaleDateString(undefined);
  }
  return filterFunctionValue;
}

export const ActiveFiltersDisplay: React.FC<Props> = ({
  showItemLimit = 3,
  filterItems,
  className,
  containerClassName = '',
  inline,
  isTabular,
  attributes,
  beforeSeparator,
  filters = {} as IFilters,
  category,
  setFilters,
  resetFilters,
  hideIfEmpty,
}) => {
  const { calendarApi } = useSelector((state: AppState) => state.App.module);
  const separator = ' : ';

  const getItemBadge = (item: IMenuItem, rootLabel?: string): string => {
    const labelWithSection = item.label;
    return rootLabel
      ? `${rootLabel}${separator}${labelWithSection}`
      : labelWithSection;
  };

  const getTriggerAction = (item: IMenuItem): (() => void) | undefined => {
    // Prevent to re-apply default attribute when clicking on icon action button
    if (item.type === 'sort') return () => {};
    // Main visualisation badge without sub menu -> No action
    if (item.type === 'visualize') {
      // Omit the item with ID 'visualize-category=' (always present)
      // -> Check the number of visualization attributes to display the action icon
      const visualizeAttributesCount: number | undefined = filterItems.find(
        ({ id }) => id === 'visualize-attribute',
      )?.subMenu?.items?.length;
      if (!visualizeAttributesCount) return undefined;
    }
    if (item.id.includes('='))
      // The item is switchable
      return () => {
        if (!item.id || !filters) return;
        toggleFilter(item, filters, setFilters, false);
      };
    if (item.isDefault) return undefined;
    if (item.onReset) return item.onReset;
    return item.onClick;
  };

  const getFiltersFromMenu = (
    items: IMenuItem[],
    rootLabel?: string,
    type: IMenuItemType = 'filter',
  ): IActiveFilter[] => {
    const activeFilters: IActiveFilter[] = [];
    items
      .filter((i) => i.type === type)
      .forEach((item) => {
        const childLabel = getItemBadge(
          item,
          type === 'sort' ? undefined : rootLabel,
        );

        const childAction = getTriggerAction(item);
        if (item.subMenu?.items) {
          activeFilters.push(
            ...getFiltersFromMenu(item.subMenu?.items, childLabel, type),
          );
        } else if (isActive(item.id, filters)) {
          // Always display for sort, for visualize if is has no attribute
          const hasContextualMenuForVisualisation: boolean =
            item.type === 'visualize' && item.id.endsWith('=');
          const hasContextualMenu: boolean =
            item.type === 'sort' || hasContextualMenuForVisualisation;
          activeFilters.push({
            label: childLabel,
            // No child action -> no icon (empty actions)
            actions: childAction
              ? [
                  {
                    icon: {
                      name: hasContextualMenu ? 'chevron-right' : 'times',
                    },
                    onClick: childAction,
                  },
                ]
              : [],
          });
        }
      });
    return activeFilters;
  };

  const [t] = useTranslation();

  const [showAllFilters, setShowAllFilters] = useState(false);

  const toggleShowAllFilters = React.useCallback(
    () => setShowAllFilters((showAllFiltersState) => !showAllFiltersState),
    [],
  );

  const getActiveFilters = (): IActiveFilter[] => {
    const output: IActiveFilter[] = [];
    if (filters?.search)
      output.push({
        label: `${t('activeFilters.textualResearch')}${separator}${
          filters.search
        }`,
        actions: [
          {
            icon: { name: 'times' },
            onClick: () => setFilters({ ...filters, search: null }),
          },
        ],
      });

    if (
      filters?.available?.isAvailableDtStart &&
      filters?.available?.isAvailableDtEnd
    ) {
      output.push({
        label: `${t(
          'activeFilters.availableFromPeriod',
        )} ${DateUtils.getDateRangeLocalString(
          new Date(filters.available.isAvailableDtStart),
          new Date(filters.available.isAvailableDtEnd),
          false,
        )}`,
        actions: [
          {
            icon: { name: 'times' },
            onClick: () => setFilters({ ...filters, available: undefined }),
          },
        ],
      });
    }

    if (isTabular) {
      Object.entries(filters?.attributes ?? {}).forEach(
        ([attribute, filter]) => {
          const attributeObject = attributes?.find(
            (a) => a.id.toString() === attribute.toString(),
          );
          const filterFunction = FilterFunctionChoice.find(
            (f) => f.value === filter?.filterFunction,
          );

          if (
            filterFunction?.requiredValue &&
            filter?.filterFunctionValue &&
            filter?.filterFunction &&
            attributeObject
          ) {
            output.push({
              label: `${attributeObject.label}${separator}${i18next.t(
                `filters-function.${filter?.filterFunction}`,
              )}${separator}${formatFilterFunctionValue(
                filter.filterFunction,
                filter.filterFunctionValue,
                attributeObject.type,
              )}`,
              actions: [
                {
                  icon: { name: 'times' },
                  onClick: (): void => {
                    setFilters({
                      ...filters,
                      attributes: {
                        ...filters?.attributes,

                        [attributeObject.id]: {
                          ...filters?.attributes?.[attribute],
                          filterFunction: undefined,
                          filterFunctionValue: undefined,
                        },
                      },
                    });
                  },
                },
              ],
            });
          } else if (
            filterFunction &&
            !filterFunction.requiredValue &&
            attributeObject
          ) {
            output.push({
              label: `${attributeObject.label}${separator}${i18next.t(
                `filters-function.${filterFunction.value}`,
              )}`,
              actions: [
                {
                  icon: { name: 'times' },
                  onClick: (): void => {
                    setFilters({
                      ...filters,
                      attributes: {
                        ...filters?.attributes,

                        [attributeObject.id]: {
                          ...filters?.attributes?.[attribute],
                          filterFunction: undefined,
                          filterFunctionValue: undefined,
                        },
                      },
                    });
                  },
                },
              ],
            });
          }

          if (typeof filter?.valuesAllSelected === 'string') {
            const values = filter.values;
            const isAllSelected = filter.valuesAllSelected;
            const choices = attributeObject?.choices;
            const noneValueLabel = i18next.t('objectTable.noneValueLabel');
            if (isAllSelected === '1' && !values?.length) return;
            output.push({
              label: `${attributeObject?.label ?? ''}${separator}${
                isAllSelected === '1'
                  ? t('activeFilters.everythingExcept') + separator
                  : ''
              }${
                values
                  ? values
                      .map(
                        (v) =>
                          choices?.find((c) => c.id.toString() === v.toString())
                            ?.label ?? v,
                      )
                      .join(', ')
                      .replace('_EMPTY_', noneValueLabel)
                  : t('activeFilters.nothing')
              } `,
              actions: [
                {
                  icon: { name: 'times' },
                  onClick: (): void => {
                    if (!attributeObject) {
                      setFilters({
                        ...filters,
                        attributes: {},
                      });
                    } else {
                      setFilters({
                        ...filters,
                        attributes: {
                          ...filters?.attributes,

                          [attributeObject?.id]: {
                            ...filters?.attributes?.[attribute],
                            valuesAllSelected: '1',
                            values: undefined,
                          },
                        },
                      });
                    }
                  },
                },
              ],
            });
          }
        },
      );
    }

    if (!filters?.period && filters?.daterange)
      output.push({
        label: t('activeFilters.periodFrom', {
          startDate: format(new Date(filters.daterange.start), 'dd/MM/yyyy'),
          endDate: format(new Date(filters.daterange.stop), 'dd/MM/yyyy'),
        }),
        actions: [
          {
            icon: { name: 'arrow-rotate-left' },
            onClick: (): void => {
              calendarApi?.gotoDate(
                getDefaultDaterange().daterange?.start ?? new Date(),
              );
              setFilters({
                ...filters,
                ...getDefaultDaterange(),
              });
            },
          },
          {
            icon: { name: 'times' },
            onClick: (): void => {
              setFilters({
                ...filters,
                daterange: undefined,
              });
            },
          },
        ],
      });

    output.push(...getFiltersFromMenu(filterItems));
    output.sort((a, b) => (a.label < b.label ? -1 : 1));
    return output;
  };

  const getActiveSort = (): IActiveFilter[] => {
    const output: IActiveFilter[] = [];
    if (isTabular && filters?.attributeSort) {
      Object.entries(filters?.attributeSort || {}).forEach(
        ([attribute, filter]) => {
          const attributeObject = attributes?.find(
            (a) => a.id.toString() === attribute.toString(),
          );

          if (filter && attributeObject) {
            const sort =
              filter === 'asc'
                ? t('activeFilters.ascending')
                : t('activeFilters.descending');
            output.push({
              label: `${attributeObject.label}${separator}${sort} `,
              actions: [
                {
                  icon: { name: 'times' },
                  onClick: (): void => {
                    const newFilters = { ...filters };
                    if (newFilters.attributeSort?.[attributeObject.id]) {
                      delete newFilters.attributeSort[attributeObject.id];
                    }
                    setFilters(newFilters);
                  },
                },
              ],
            });
          }
        },
      );
    }
    output.push(...getFiltersFromMenu(filterItems, undefined, 'sort'));
    output.sort((a, b) => (a.label < b.label ? -1 : 1));
    return output;
  };

  const getActiveVisualisation = (): IActiveFilter[] => {
    const output: IActiveFilter[] = [];
    output.push(...getFiltersFromMenu(filterItems, undefined, 'visualize'));
    output.sort((a, b) => (a.label < b.label ? -1 : 1));
    return output;
  };

  const fullList = [
    ...getActiveFilters(),
    ...getActiveSort(),
    ...getActiveVisualisation(),
  ];

  if (hideIfEmpty && !fullList.length) return null;

  const isFilterListLong = fullList.length > showItemLimit;

  return (
    <div className={className}>
      {!isFilterListLong || (isFilterListLong && showAllFilters) ? (
        <div
          className={`flex flex-wrap items-center max-h-80 overflow-y-auto py-1 gap-1 ${containerClassName}`}
        >
          {beforeSeparator && (
            <p className="text-gray-500">
              {fullList.length ? beforeSeparator : ''}
            </p>
          )}
          {getActiveFilters().length ? (
            <>
              <p className="text-gray-500 text-sm ">
                {t('activeFilters.filterBy')} :
              </p>
              {getActiveFilters().map((activeFilter, i) => (
                <FiltersBadge
                  // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
                  key={i}
                  className={{ container: 'mx-1' }}
                  label={activeFilter.label}
                  actions={activeFilter.actions}
                />
              ))}
            </>
          ) : null}
          {getActiveSort().length ? (
            <>
              <p className="text-gray-500 text-sm ">
                {t('activeFilters.sortBy')} :
              </p>
              {getActiveSort().map((activeFilter, i) => (
                <FiltersBadge
                  // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
                  key={i}
                  label={activeFilter.label}
                  className={{ container: 'mx-1' }}
                  actions={activeFilter.actions}
                >
                  {(active: boolean): React.JSX.Element | undefined =>
                    active ? (
                      <ContextualMenu
                        isOpen={active}
                        menuItems={{
                          items: filterItems
                            .filter(({ type }) => type === 'sort')
                            .flatMap(({ subMenu }) => subMenu?.items ?? []),
                        }}
                        filters={filters}
                        setFilters={setFilters}
                      >
                        {/* FIXME: workaround to anchor floating menu to the badge
                          (wrapping <ContextualMenu /> around <FiltersBadge /> does not work)
                        */}
                        {({ htmlRef }): React.JSX.Element => (
                          <div ref={htmlRef} />
                        )}
                      </ContextualMenu>
                    ) : undefined
                  }
                </FiltersBadge>
              ))}
            </>
          ) : null}
          {getActiveVisualisation().length ? (
            <>
              <p className="text-gray-500 text-sm ">
                {t('activeFilters.visualizeBy')} :
              </p>
              {getActiveVisualisation().map((activeFilter, i) => (
                <FiltersBadge
                  // biome-ignore lint/suspicious/noArrayIndexKey: FIXME
                  key={i}
                  label={activeFilter.label}
                  actions={activeFilter.actions}
                  className={{ container: 'mx-1' }}
                >
                  {(active: boolean): React.JSX.Element | undefined =>
                    active ? (
                      <ContextualMenu
                        isOpen={active}
                        menuItems={{
                          items: filterItems
                            .filter(({ type }) => type === 'visualize')
                            .flatMap(({ subMenu }) => subMenu?.items ?? []),
                        }}
                        filters={filters}
                        setFilters={setFilters}
                      >
                        {/* FIXME: workaround to anchor floating menu to the badge
                          (wrapping <ContextualMenu /> around <FiltersBadge /> does not work)
                        */}
                        {({ htmlRef }): React.JSX.Element => (
                          <div ref={htmlRef} />
                        )}
                      </ContextualMenu>
                    ) : undefined
                  }
                </FiltersBadge>
              ))}
            </>
          ) : null}
          {resetFilters && Object.keys(filters).length ? (
            <>
              <p className="text-gray-500 text-sm">/</p>
              <FiltersBadge
                className={{
                  container: 'mx-1 !bg-gray-200',
                  label: '!text-gray-500',
                  icon: '!text-gray-500',
                }}
                label={t('activeFilters.resetFilters')}
                onClick={(): void => setFilters(setDefaultFilters(category))}
                actions={[
                  {
                    icon: { name: 'arrow-rotate-left' },
                  },
                ]}
              />
            </>
          ) : (
            <></>
          )}
        </div>
      ) : null}
      {isFilterListLong ? (
        <button
          type="button"
          onClick={toggleShowAllFilters}
          className={`flex w-full items-center justify-between bg-blue-100 dark:bg-blue-500 dark:bg-opacity-20 py-1 px-2 gap-3 ${
            inline ? 'rounded-full ' : ''
          }${showAllFilters ? 'mt-1 border-t' : ''}`}
        >
          <span
            className={`${
              inline ? 'text-xs' : 'text-sm'
            } font-bold text-blue-500`}
          >
            {showAllFilters
              ? t('filters.close')
              : t('filters.open', { count: fullList.length })}
          </span>
          <Icon
            className="text-xs text-blue-500"
            icon={
              inline
                ? { name: showAllFilters ? 'chevrons-left' : 'chevrons-right' }
                : { name: showAllFilters ? 'chevrons-up' : 'chevrons-down' }
            }
          />
        </button>
      ) : null}
    </div>
  );
};
