import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import type { INavigationTabs } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation';
import { SubNavigation } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Components/Navigation/SubNavigation';
import { LinksPage } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Links';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  baseObject: BaseObject;
}

export const BaseObjectPanelLinksRouter = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { baseObject } = props;
  const [linkGroups, setLinkGroups] = React.useState<LinkGroup[]>([]);
  const [isInitializing, setIsInitializing] = React.useState(true);

  const setGroup = (): void => {
    LinkGroup.getLinkGroupsFromBaseObjectId(baseObject.id).then(
      (fetchedLinkGroups) => {
        setLinkGroups(fetchedLinkGroups);
        setIsInitializing(false);
      },
    );
  };

  React.useEffect(() => {
    if (baseObject.id) {
      setIsInitializing(true);
      setGroup();
    }
  }, [baseObject]);

  const getTabs = (): INavigationTabs[] => {
    return linkGroups?.map((l) => {
      return {
        id: `${l.id}`,
        label: l.label || '',
        icon: l.icon,
        render: () => (
          <LinksPage
            reload={setGroup}
            pageTitle={l.label || ''}
            object={baseObject}
            groups={l.children || []}
            parentGroup={l}
          />
        ),
      };
    });
  };

  if (isInitializing) {
    return (
      <div className="h-full w-full">
        <LoaderSkeleton height={90} width="100%">
          <rect x="0" y="0" height="45" width="45" />
          {[0, 45, 90].map(
            (
              offset, // Icons
            ) => (
              <circle key={offset} cx={68 + offset} cy="21" r="12" />
            ),
          )}
          <rect x="0" y="44" height="45" width="100%" />
        </LoaderSkeleton>
      </div>
    );
  }

  if (!linkGroups.length) {
    return (
      <div
        className={
          'h-full w-full flex flex-col items-center justify-center text-gray-500 hover:text-gray-700'
        }
      >
        <div className="flex flex-col items-center justify-center cursor-pointer text-gray-500 hover:text-gray-600">
          <Icon icon={{ name: 'link' }} className="  text-2xl" />
          <p className="uppercase mt-2  text-md text-center ">
            {t('links.noLinkGroupCOnfigured')}
          </p>
        </div>
      </div>
    );
  }

  return <SubNavigation tabs={getTabs()} />;
};
