import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import {
  ApiError,
  type ApiErrorOutput,
} from '@smack/core/api/errors/ApiError/ApiError';
import type { AxiosError, AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

interface UsePaginationOutput<T, D = T> {
  count: number;
  next: () => void;
  previous: () => void;
  results: D[];
  isInitialLoading: boolean;
  isNextLoading: boolean;
  isNextComplete: boolean;
  isPreviousLoading: boolean;
  isPreviousComplete: boolean;
  isInitialHasBeenLoadOnce: boolean;
  loadInitial: () => void;
}

export interface UsePaginationProps<T, D = T> {
  query: () => Promise<IPagination<T>>;
  transformer?: (data: T) => D;
}

export const usePagination = <T, D = T>({
  query,
  transformer,
}: UsePaginationProps<T, D>): UsePaginationOutput<T, D> => {
  const [results, setResults] = useState<D[]>([]);
  const [previousUrl, setPreviousUrl] = useState<string>();
  const [nextUrl, setNextUrl] = useState<string>();
  const [count, setCount] = useState<number>(0);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isPreviousLoading, setIsPreviousLoading] = useState(false);
  const [isInitialHasBeenLoadOnce, setIsInitialHasBeenLoadOnce] =
    useState(false);

  const transformResult = (items: T[]): D[] => {
    return transformer
      ? items.map(transformer)
      : ((items as unknown as D[]) ?? []);
  };

  const loadInitial = async () => {
    setIsInitialLoading(true);
    setResults([]);
    try {
      const paginationResult = await query();
      if (!paginationResult) return;
      setCount(paginationResult.count ?? 0);
      setNextUrl(paginationResult.next);
      setPreviousUrl(paginationResult.previous);
      setResults(transformResult(paginationResult.results));
      setIsInitialHasBeenLoadOnce(true);
      setIsInitialLoading(false);
    } catch (err) {
      ApiError.interceptCanceledErrors(
        err as AxiosError<ApiErrorOutput>,
        ApiError.toastAllErrorsOnCatchAxiosErrors,
      );
    }
  };

  const next = useCallback(() => {
    if (!nextUrl || isNextLoading) return;
    setIsNextLoading(true);
    RESTClient.get<AxiosResponse<IPagination<T>>>(nextUrl, undefined, true)
      .then((paginationResult) => {
        setResults((prevResults) => [
          ...prevResults,
          ...transformResult(paginationResult.data.results),
        ]);
        setNextUrl(paginationResult.data.next);
      })
      .finally(() => {
        setIsNextLoading(false);
      });
  }, [nextUrl, transformer]);

  const previous = useCallback(() => {
    if (!previousUrl || isPreviousLoading) return;
    setIsPreviousLoading(true);
    RESTClient.get<AxiosResponse<IPagination<T>>>(previousUrl, undefined, true)
      .then((paginationResult) => {
        setResults((prevResults) => [
          ...transformResult(paginationResult.data.results),
          ...prevResults,
        ]);
        setPreviousUrl(paginationResult.data.previous);
      })
      .finally(() => {
        setIsPreviousLoading(false);
      });
  }, [previousUrl, transformer]);

  return {
    results,
    next,
    previous,
    count,
    isInitialLoading,
    isNextLoading,
    isPreviousLoading,
    loadInitial,
    isInitialHasBeenLoadOnce,
    isPreviousComplete: !previousUrl,
    isNextComplete: !nextUrl,
  };
};
