import { Category } from '@smack/core/api/models/categories/Category';
import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { CategoriesChoicesSelect } from '@smack/core/components/DataInput/CategoriesChoicesSelect/CategoriesChoicesSelect';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  value?: Category | null;
  linkId: number;
  onLinkGroupClicked: (linkgroup: LinkGroup) => void;
  onCategoryClicked: (category?: Category | null) => void;
}

const linkGroupToOption = (linkgroup: LinkGroup): Option => ({
  label: linkgroup.label || linkgroup.id.toString(),
  color: linkgroup.color,
  icon: linkgroup.icon,
  value: linkgroup.id,
});

export const LinkableCategoriesChoicesSelect = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { linkId, onCategoryClicked, onLinkGroupClicked } = props;
  const [linkGroupValue, setLinkGroupValue] = React.useState<Option | null>(
    null,
  );
  const [linkGroups, setLinkGroups] = React.useState<LinkGroup[]>([]);
  const [categoryValue, setCategoryValue] = React.useState<Category | null>();
  const [categories, setCategories] = React.useState<Category[]>([]);

  const linkGroupSelectedIsCategory = React.useMemo(() => {
    const linkSelected = linkGroups.find(
      (s) => s.id.toString() === linkGroupValue?.value.toString(),
    );
    return linkSelected?.populationCategory?.isCategory || false;
  }, [linkGroupValue, linkGroups]);

  const onLinkGroupSelected = (option?: Option | Option[] | null): void => {
    if (!option || Array.isArray(option)) return;
    setLinkGroupValue(option);
    const linkSelected = linkGroups.find(
      (s) => s.id.toString() === option.value.toString(),
    );
    if (!linkSelected) return;
    onLinkGroupClicked(linkSelected);
    if (linkSelected?.populationCategory?.isCategory) {
      Category.getCategory(linkSelected.populationCategory.id).then(
        onCategoryClicked,
      );
    } else {
      if (linkSelected?.populationCategory?.id) {
        Category.getChildCategories(linkSelected?.populationCategory?.id).then(
          setCategories,
        );
      }
    }
  };

  React.useEffect(() => {
    LinkGroup.getChildrenExplicitLinkGroupsFromObjectId(props.linkId).then(
      setLinkGroups,
    );
  }, [linkId]);

  const optionLinkGroups = React.useMemo(
    () => linkGroups.map(linkGroupToOption),
    [linkGroups],
  );

  const onSelectedCategory = (category: Category | null): void => {
    if (!category) return;
    setCategoryValue(category);
    onCategoryClicked(category);
  };
  return (
    <>
      <DataFieldWrapper
        label={t('linkableCategoriesChoicesSelect.linkGroupLabel')}
      >
        <SelectInput
          value={linkGroupValue}
          isClearable
          onChange={onLinkGroupSelected}
          placeholder={t('linkableCategoriesChoicesSelect.linkGroupLabel')}
          options={optionLinkGroups}
        />
      </DataFieldWrapper>
      {linkGroupValue && !linkGroupSelectedIsCategory && (
        <DataFieldWrapper
          label={t('linkableCategoriesChoicesSelect.categoryLabel')}
        >
          <CategoriesChoicesSelect
            onChange={onSelectedCategory}
            value={categoryValue}
            categories={categories}
          />
        </DataFieldWrapper>
      )}
    </>
  );
};
