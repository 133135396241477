import { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import type { BaseObjectBottomAttributes } from '@smack/core/api/models/objects/NewBaseObject';
import {
  type IListElementBaseObject,
  ListElementBaseObject,
} from '@smack/core/api/models/objects/NewBaseObject/ListElementBaseObject';
import { Loader } from '@smack/core/components/Actions/Loader';
import { ListingHeader } from '@smack/core/components/DataDisplay/Headers/ListingHeader/ListingHeader';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { BaseObjectListElement } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { useBaseObjectFormContextContext } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import { usePagination } from '@smack/core/hooks/usePagination/usePagination';
import { spawnModal } from '@smack/core/utils/modal';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import { AddLinks } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinks';
import { InfiniteVirtualizedList } from '@smack/core/views/oldViewsToSort/Layouts/Objects/InfiniteVirtualizedList';
import { LinkModal } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/Components/LinkModal/LinkModal';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Value = InputLink | InputLink[] | null;

interface IProps {
  linkGroupId?: number;
  value?: Value;
  objectId?: number;
  onChange?: (data: Value) => void;
  filterAvailable?: boolean;
  multiple?: boolean;
  className?: string;
}

interface ListLinksWithBaseObject {
  baseObject: ListElementBaseObject;
  link: InputLink;
}

export const LinkListInput: React.FC<IProps> = ({
  linkGroupId,
  value,
  onChange,
  objectId,
  multiple,
  filterAvailable,
  className,
}) => {
  const { formRRuleInputValue } = useBaseObjectFormContextContext();
  const [linkGroup, setLinkGroup] = React.useState<LinkGroup>();

  const [open, setOpen] = React.useState<boolean>(false);
  const [t] = useTranslation();
  React.useEffect(() => {
    if (!linkGroupId) return;
    LinkGroup.getLinkGroup(linkGroupId).then(setLinkGroup);
  }, [linkGroupId]);

  const handleChange = (val: InputLink | InputLink[] | null): void => {
    onChange?.(val);
  };

  const getValueCount = (): number => {
    if (!Array.isArray(value)) {
      return value ? 1 : 0;
    }
    return value.length;
  };

  const getFiltersFromFormContext = (): Record<string, unknown> => {
    let output = {};
    if (
      filterAvailable &&
      formRRuleInputValue?.dtstart &&
      formRRuleInputValue?.dtend
    ) {
      output = {
        'source-dates[dtstart]': formRRuleInputValue.dtstart,
        'source-dates[dtend]': formRRuleInputValue.dtend,
      };
      if (formRRuleInputValue.rrule)
        output['source-dates[rrule]'] = formRRuleInputValue.rrule;
    }

    return output;
  };

  const getBaseObjects = () => {
    let idToQuery: number[] = [];
    if (!value || (Array.isArray(value) && value.length === 0)) {
      return Promise.resolve({
        results: [],
      });
    }
    if (Array.isArray(value)) {
      idToQuery = value.map((val) => val.targetBaseobjectId);
    } else {
      idToQuery = [value.targetBaseobjectId] as number[];
    }
    if (!idToQuery?.length)
      return Promise.resolve({
        results: [],
      });
    return ListElementBaseObject.getBaseObjectForListRepresentation(
      undefined,
      {},
      0,
      undefined,
      undefined,
      {
        ids: idToQuery,
        'with-schedule': false,
      },
    );
  };

  const { results, isInitialLoading, loadInitial, next } = usePagination({
    query: getBaseObjects,
    transformer: (data: IListElementBaseObject) =>
      new ListElementBaseObject(data),
  });

  React.useEffect(() => {
    loadInitial();
  }, [value]);

  const handleSubmitLinkModal = (val: IAddLinkFormOutput): void => {
    const newValues: InputLink[] = [];
    for (const baseobject of val.baseObjects) {
      newValues.push({
        targetBaseobjectId: baseobject.id,
        targetBaseObjectTitle: baseobject.title,
        linkGroupId: val.linkGroup.id,
        attributes: val.attributes,
        weight: val.weight,
        datetimeRange: val.datetimeRange,
      });
    }

    if (multiple) {
      const oldValue: InputLink[] = Array.isArray(value) ? value : [];
      handleChange([...oldValue, ...newValues]);
    } else {
      handleChange(newValues[0]);
    }
    setOpen(false);
  };

  const removeLink = (index: number) => {
    if (Array.isArray(value)) {
      handleChange(value?.filter((_v, i) => i !== index) ?? []);
    } else {
      handleChange(null);
    }
  };

  const getDateLocalString = (date: InputLink['datetimeRange']): string => {
    if (!date?.dtend || !date?.dtstart) return '';
    const dateToString = (date: Date) =>
      date.toLocaleDateString(navigator.language, {
        month: 'numeric',
        day: 'numeric',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
      });
    return `${dateToString(new Date(date.dtstart))} - ${dateToString(new Date(date.dtend))}`;
  };

  const getHeight = () => {
    const rowHeight = 80;
    return rowHeight * getValueCount();
  };

  const getBottomLeft = (
    inputLink: InputLink,
  ): BaseObjectBottomAttributes | undefined => {
    if (!linkGroup?.isWeighted || !linkGroup?.isTimeSensitive) return;
    return {
      value: linkGroup?.isWeighted
        ? inputLink.weight?.toString()
        : getDateLocalString(inputLink.datetimeRange),
    };
  };

  const getBottomRight = (
    inputLink: InputLink,
  ): BaseObjectBottomAttributes | undefined => {
    if (!linkGroup?.isTimeSensitive) return;
    return {
      value: getDateLocalString(inputLink.datetimeRange),
    };
  };

  const getObjectFromValues = (): ListLinksWithBaseObject[] => {
    const output: ListLinksWithBaseObject[] = [];
    if (!value) return output;
    const links = Array.isArray(value) ? value : [value];
    for (const link of links) {
      const object = results.find((v) => v.id === link.targetBaseobjectId);
      if (object)
        output.push({
          link,
          baseObject: object,
        });
    }

    return output;
  };

  const onUpdate = (inputLink: InputLink, index: number): Promise<void> => {
    if (Array.isArray(value)) {
      handleChange([...value].map((v, i) => (i === index ? inputLink : v)));
    } else {
      handleChange(inputLink);
    }
    return Promise.resolve();
  };

  const onUpdateLink = (inputLink: InputLink, index: number) => {
    if (!linkGroup) return;

    spawnModal({
      render: ({ onClose }): JSX.Element => {
        return (
          <LinkModal
            link={inputLink}
            linkGroup={linkGroup}
            open={true}
            confirmDelete={false}
            onClose={onClose}
            onDeleteLink={() => {
              if (inputLink.id) removeLink(index);
              onClose?.();
              return Promise.resolve();
            }}
            onUpdateLink={(link) => onUpdate(link, index)}
          />
        );
      },
    });
  };

  return (
    <div
      className={` max-w-[400px] w-full border border-border  flex flex-col rounded-md   ${
        className ?? ''
      }`}
    >
      {open && (
        <AddLinks
          linkGroup={linkGroup}
          sourceBaseObjectId={objectId}
          onSubmit={handleSubmitLinkModal}
          filterParameters={getFiltersFromFormContext()}
          open={open}
          onQuit={() => setOpen(false)}
          onClose={setOpen}
        />
      )}
      <ListingHeader
        icon={linkGroup?.icon}
        headerTitle={linkGroup?.label ?? ''}
        count={getValueCount() ?? 0}
      >
        <Tooltip title={t('links.addLink')} offset={2}>
          <Icon
            title={t('links.addLink')}
            icon={{ name: 'plus-circle' }}
            className={'text-gray-400 mr-3 text-xl hover:text-gray-500 '}
            onClick={(): void => {
              setOpen(true);
            }}
          />
        </Tooltip>
      </ListingHeader>
      <div
        style={{
          height: getHeight(),
        }}
        className={'max-h-52 h-52 w-[400px] overflow-y-auto border-border'}
      >
        <Loader isDataLoaded={!isInitialLoading}>
          <InfiniteVirtualizedList
            objects={getObjectFromValues()}
            renderListItem={(
              listLinksWithBaseObject: ListLinksWithBaseObject,
              index,
            ) => {
              return (
                <BaseObjectListElement
                  {...listLinksWithBaseObject.baseObject.getListElement()}
                  link={undefined}
                  noOverlayActions
                  bottomLeft={getBottomLeft(listLinksWithBaseObject.link)}
                  bottomRight={getBottomRight(listLinksWithBaseObject.link)}
                  action={
                    linkGroup?.isWeighted || linkGroup?.isTimeSensitive
                      ? {
                          icon: { name: 'pen' },
                          label: t('links.editLink'),
                          onClick: () =>
                            onUpdateLink(
                              listLinksWithBaseObject.link,
                              index ?? 0,
                            ),
                        }
                      : {
                          icon: { name: 'unlink' },
                          label: t('links.removeLink'),
                          onClick: () => removeLink(index ?? 0),
                        }
                  }
                />
              );
            }}
            startIndex={0}
            loadMoreRows={next}
          />
        </Loader>
      </div>
    </div>
  );
};
