import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AxiosResponse } from 'axios';
import { parseISO } from 'date-fns';
import { RESTClient } from '../../../clients/rest/RESTClient';
import { type IStatus, Status } from '../Status';
import { type ITask, Task } from '../Task';

/**
 * GROUP MODEL
 */

export interface IGroup {
  id: number;
  label?: string;
  description?: string;
  icon?: IconField;
  color?: string;
  startAt?: string;
  deadlineAt?: string;
  endAt?: string;
  duration?: string;
  position?: number;
  status?: IStatus;
  tasks?: ITask[];
}

export type IGroupPatchData = Omit<Partial<IGroup>, 'id' | 'status' | 'tasks'>;

export class Group {
  id: number;

  label?: string;

  description?: string;

  icon?: IconField;

  color?: string;

  startAt?: Date;

  deadlineAt?: Date;

  endAt?: Date;

  position?: number;

  status?: Status;

  tasks?: Task[];

  duration?: string;

  constructor(data: IGroup) {
    this.id = data.id;
    this.label = data.label;
    this.description = data.description;
    this.icon = data.icon;
    this.color = data.color;
    this.duration = data.duration;
    this.position = data.position;

    if (data.status) {
      this.status = new Status(data.status);
    }

    if (data.tasks) {
      this.tasks = data.tasks.map((task) => new Task(task));
    }

    this.startAt = data.startAt ? parseISO(data.startAt) : undefined;
    this.deadlineAt = data.deadlineAt ? parseISO(data.deadlineAt) : undefined;
    this.endAt = data.endAt ? parseISO(data.endAt) : undefined;
  }

  static createGroup(
    projectId: number,
    label: string,
    position = 1,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      {
        project: projectId,
        label,
        position,
      },
      '/tasks/groups',
    );
  }

  patch(data: IGroupPatchData): Promise<AxiosResponse> {
    return RESTClient.patch(data, `/tasks/groups/${this.id}`);
  }
}
