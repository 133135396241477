import type { LinkGroup } from '@smack/core/api/models/categories/LinkGroup';
import type { InputLink } from '@smack/core/api/models/objects/Link/Link';
import {
  CloseButton,
  DeleteButton,
  SeeObjectButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { UnlinkConfirmAlert } from '@smack/core/components/DataDisplay/Alerts/ConfirmAlert/Components/UnlinkAlert';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import {
  type IModalProps,
  Modal,
} from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { LinkBaseObjectInput } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/Components/LinkBaseObjectInput/LinkBaseObjectInput';
import { LinkDateRangeInput } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/Components/LinkDateRangeInput/LinkDateRangeInput';
import { LinkWeightInput } from '@smack/core/views/oldViewsToSort/Views/Objects/ObjectLinkCalendar/Components/LinkWeightInput/LinkWeightInput';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface LinkModalProps extends Omit<IModalProps, 'children'> {
  link: InputLink;
  linkGroup: LinkGroup;
  confirmDelete?: boolean;
  linkUrl?: string;
  onUpdateLink?: (link: InputLink) => Promise<void>;
  onDeleteLink?: (link: InputLink) => Promise<void>;
}

export const LinkModal: React.FC<LinkModalProps> = ({
  link,
  linkGroup,
  linkUrl,
  onUpdateLink,
  confirmDelete = true,
  onDeleteLink,
  ...modalProps
}) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const [title, setTitle] = React.useState<string>(
    link.targetBaseObjectTitle ?? '',
  );
  const handleClose = () => {
    modalProps.onClose(false);
  };
  const [isDeleting, setIsDeleting] = React.useState(false);

  const onClickSeeObject = () => {
    if (!linkUrl) return;
    navigate(linkUrl);
    handleClose();
  };

  const handleUpdateLink = (inputLink: Partial<InputLink>): Promise<void> => {
    return (
      onUpdateLink?.({
        targetBaseObjectTitle: link.targetBaseObjectTitle,
        targetBaseobjectId: link.targetBaseobjectId,
        linkGroupId: linkGroup.id,
        weight: link.weight,
        datetimeRange: link.datetimeRange,
        id: link.id,
        ...inputLink,
      }) || Promise.reject(new Error('no update method provided'))
    );
  };

  return (
    <Modal {...modalProps} title={title} icon={{ name: 'link' }}>
      {isDeleting ? (
        <UnlinkConfirmAlert
          onCloseModal={() => setIsDeleting(false)}
          onCloseButton={() => setIsDeleting(false)}
          onOk={(): void => {
            onDeleteLink?.(link)
              .then(() => {
                setIsDeleting(false);
              })
              .catch(() => {
                toast.error(t('errorMessage.linkDeleteFail'));
              });
          }}
        />
      ) : null}
      <div data-testid={'LinkModal'} className={'w-[600px]'}>
        <DataFieldWrapper label={t('linkModal.objectName')}>
          <LinkBaseObjectInput
            isWritable={!!onUpdateLink}
            link={link}
            linkGroup={linkGroup}
            onChange={(inputLink) =>
              handleUpdateLink?.(inputLink).then(() => {
                setTitle(inputLink.targetBaseObjectTitle ?? '');
              })
            }
          />
        </DataFieldWrapper>
        {linkGroup?.isTimeSensitive ? (
          <DataFieldWrapper
            label={
              linkGroup.datetimeRangeLabel || t('linkModal.validityPeriod') // not using ?? cause it may be an empty string
            }
          >
            <LinkDateRangeInput
              isWritable={!!onUpdateLink}
              onChange={(inputLink) => handleUpdateLink?.(inputLink)}
              link={link}
            />
          </DataFieldWrapper>
        ) : null}
        {linkGroup?.isWeighted ? (
          <DataFieldWrapper
            label={linkGroup.weightLabel || t('linkModal.weight')} // not using ?? cause it may be an empty string
          >
            <LinkWeightInput
              link={link}
              isWritable={!!onUpdateLink}
              onChange={(inputLink) => handleUpdateLink?.(inputLink)}
            />
          </DataFieldWrapper>
        ) : null}
      </div>
      <div className=" flex items-center justify-end gap-3">
        <CloseButton onClick={handleClose} />
        {onDeleteLink ? (
          <DeleteButton
            onClick={() =>
              confirmDelete ? setIsDeleting(true) : onDeleteLink?.(link)
            }
          />
        ) : null}
        {linkUrl ? <SeeObjectButton onClick={onClickSeeObject} /> : null}
      </div>
    </Modal>
  );
};
