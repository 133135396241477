import type { ChecklistLog } from '@smack/core/api/models/tasks/ChecklistLog';
import { Trans, useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const ChecklistLogRenamedFeed = (
  props: ILogFeedProps<ChecklistLog>,
): JSX.Element => {
  const [t] = useTranslation();

  return (
    <LogFeed {...props} icon={{ name: 'list-check' }}>
      <span className="text-sm font-normal">
        {' '}
        <Trans
          t={t}
          i18nKey="logs.action.renamedChecklist"
          values={{
            oldName: props.log.content?.checklistFieldOldValue,
            newName: props.log.content?.checklistFieldNewValue,
          }}
        />
      </span>
    </LogFeed>
  );
};
