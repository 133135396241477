import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';
import { parseISO } from 'date-fns';
import type { IComment } from '../Comment/type';

/**
 * Comment Update Model
 */

export interface ICommentUpdate {
  id: number;
  content: string;
  comment?: IComment;
  user?: IUser;
  createdAt: string;
  modifiedAt: string;
  updatedAt: string;
}

export interface ICommentUpdateDataInput extends Pick<IComment, 'content'> {
  users?: number[];
  mentionAllUsers?: boolean;
  mentionAllUsersThatCanReadObject?: boolean;
}

export class CommentUpdate {
  id: number;

  content: string;

  comment?: IComment;

  user?: IUser;

  createdAt: Date;

  modifiedAt: Date;

  updatedAt: Date;

  constructor(data: ICommentUpdate) {
    this.id = data.id;
    this.content = data.content;
    this.comment = data.comment;
    this.user = data.user;
    this.createdAt = parseISO(data.createdAt);
    this.modifiedAt = parseISO(data.modifiedAt);
    this.updatedAt = parseISO(data.updatedAt);
  }

  /**
   * Gets the user object from the comment update.
   * @returns {User | undefined} - the user object.
   */
  getUser(): User | undefined {
    if (this.user) return new User(this.user);
  }

  /**
   * Fetch all Comment Updates
   * @param {number} userId
   * @param {number} commentId
   * @returns Promise<CommentUpdate[]>
   */
  static getCommentUpdates(
    userId?: number,
    commentId?: number,
  ): Promise<CommentUpdate[]> {
    return RESTClient.get<{ data: { results: ICommentUpdate[] } }>(
      '/comments/comment-updates',
      { user: userId, comment: commentId },
    ).then((res) => res?.data?.results.map((r) => new CommentUpdate(r)));
  }
}
