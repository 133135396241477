import type { Log } from '@smack/core/api/models/logs/Log';
import { IconFeed } from '@smack/core/components/DataDisplay/Feeds/IconFeed';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import { Tooltip } from '@smack/core/components/DataDisplay/Tooltip';
import { DateUtils } from '@smack/core/utils/DateUtils';
import { Trans, useTranslation } from 'react-i18next';

/**
 * log feed components interface
 *
 * @export
 * @interface ILogFeedProps
 */
export interface ILogFeedProps<T = Log> {
  log: T;
  icon?: IconField;
  first?: boolean;
  last?: boolean;
  hideLine?: boolean;
  children?: JSX.Element;
}

/**
 * A component that displays a feed of logs.
 * @param {IProps} props - The props for the component.
 * @returns A JSX element.
 */
export const LogFeed = (props: ILogFeedProps): JSX.Element => {
  const [t] = useTranslation();
  const {
    log,
    first,
    last,
    icon = { name: 'clipboard-list-check' },
    children,
    hideLine,
  } = props;

  const user = log.getUser()
    ? log.getUser()?.getFullName()
    : t('userPanel.hyviloBot');
  const relativeDate = DateUtils.getFromNowText(log.logAt);
  const realDate = DateUtils.getDateString(log.logAt);

  return (
    <div className="w-full h-full">
      <IconFeed
        hideLineBefore={first}
        hideLineAfter={last}
        icon={icon}
        roundColor="#F3F4F6"
        hideLine={hideLine}
        iconColor="gray"
      >
        <div className="py-5 px-2 h-full ">
          <span className="text-sm font-medium text-gray-500 dark:text-gray-400">
            <Trans
              t={t}
              i18nKey="logs.content"
              values={{ user, relativeDate, realDate }}
              components={{
                primary: <span className="text-text" />,
                small: (
                  <Tooltip
                    title={DateUtils.getDateTimeStringFull(log.logAt)}
                    containerProps={{
                      className: 'inline-flex text-sm font-normal',
                    }}
                  />
                ),
                muted: <span className="pl-1 text-gray-400" />,
                icon: (
                  <span className="inline-flex items-center">
                    &nbsp;
                    <Icon
                      icon={{ name: 'circle-question', familyStyle: 'far' }}
                    />
                    &nbsp;
                  </span>
                ),
                action: <>{Array.isArray(children) ? children : [children]}</>,
              }}
            />
          </span>
        </div>
      </IconFeed>
    </div>
  );
};
