import type { AppState } from '@smack/core/store';
import { generateDuotone } from '@smack/core/utils/ColorUtils';
import type React from 'react';
import { createContext, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

interface CustomerColors {
  customerColors: {
    buttonLinkBgColor: string;
    buttonLinkBgHoverColor: string;
    headerColor: string;
    textColor: string;
  };
}

const CustomerColorsContext = createContext<CustomerColors | undefined>(
  undefined,
);

export const CustomerColorsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const customer = useSelector((state: AppState) => state.App.settings);

  const colors = useMemo(() => {
    const [primary, secondary] = generateDuotone(
      customer?.color ?? customer?.defaultColor,
      0.5,
    );

    const customerColors = {
      buttonLinkBgColor: primary,
      buttonLinkBgHoverColor: secondary,

      headerColor: primary,
      textColor: '#000',
    };

    return { customerColors };
  }, [customer]);

  return (
    <CustomerColorsContext.Provider value={colors}>
      {children}
    </CustomerColorsContext.Provider>
  );
};

export const useCustomerColors = () => {
  const context = useContext(CustomerColorsContext);
  if (!context) {
    throw new Error(
      'useCustomerColors must be used within a CustomerColorsProvider',
    );
  }
  return context;
};
