import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';
import { parseISO } from 'date-fns';
import { CommentUpdate } from '../CommentUpdate';
import type { IComment } from './type';

/**
 * Comment Model
 */

export interface ICommentDataInput extends Pick<IComment, 'content'> {
  users?: number[];
  mentionAllUsers?: boolean;
  mentionAllUsersThatCanReadObject?: boolean;
}

export class Comment {
  id: number;
  comment?: string;
  user?: IUser;
  lastModifiedBy?: IUser;
  isPublish: boolean;
  updateCount: number;
  createdAt: Date;
  modifiedAt: Date;
  updates: CommentUpdate[];
  recipients: User[];

  constructor(data: IComment) {
    this.id = data.id;
    this.comment = data.content;
    this.user = data.user;
    this.lastModifiedBy = data.lastModifiedBy;
    this.isPublish = data.isPublish;
    this.updateCount = data.updateCount;
    this.createdAt = parseISO(data.createdAt);
    this.modifiedAt = parseISO(data.modifiedAt);
    this.updates = [];
    this.recipients = [];
  }

  /**
   * Gets the user object from the comment.
   * @returns {User | undefined} - the user object comment.
   */
  getUser(): User | undefined {
    if (this.user) return new User(this.user);
  }

  /**
   * Gets the user object of the user which last modified the comment.
   * @returns {User | undefined} - the user object.
   */
  getLastModifiedBy(): User | undefined {
    if (this.lastModifiedBy) return new User(this.lastModifiedBy);
  }

  /**
   * Fetch Comment from is Id
   * @param id number
   * @returns Promise<Comment>
   */
  static getComment(id: number): Promise<Comment> {
    return RESTClient.get<{ data: { results: IComment } }>(
      `/comments/comments/${id}`,
    ).then((res) => new Comment(res?.data?.results));
  }

  /**
   * Fetch all Comments
   * @returns Promise<Comment[]>
   */
  static getComments(): Promise<Comment[]> {
    return RESTClient.get<{ data: { results: IComment[] } }>(
      '/comments/comments',
    ).then((res) => res?.data?.results.map((r) => new Comment(r)));
  }

  /**
   * create a comment
   * @param data {
   *  comment: string (the content of the comment)
   * }
   * @returns Promise<Comment>
   */
  static create(data: { comment: string }): Promise<Comment> {
    return RESTClient.post<{ results: IComment }>(
      data,
      '/comments/comments',
    ).then((res) => new Comment(res.data.results));
  }

  /**
   * Update the content of the comment
   * @param content the new content of the comment
   * @returns Promise<void>
   */
  updateContent(content: string): Promise<void> {
    return RESTClient.patch<{ results: IComment }>(
      { content: content },
      `/comments/comments/${this.id}`,
    ).then((res) => {
      const updatedComment = res.data.results;
      this.comment = updatedComment.content;
      this.lastModifiedBy = updatedComment.lastModifiedBy;
      this.modifiedAt = parseISO(updatedComment.modifiedAt);
      this.updateCount = (this.updateCount ?? 0) + 1;
    });
  }

  /**
   * Returns the count of recipients of the current comment.
   *
   * @returns A `Promise` that resolves the number of recipients.
   */
  async getRecipientCount(): Promise<number> {
    return RESTClient.get<{ data: { count: number } }>(
      `/comments/comments/${this.id}/recipients`,
    ).then((res) => res?.data?.count);
  }

  /**
   * Returns the paginated list of recipients of the current comment.
   *
   * @param limit - The number of recipients to fetch.
   * @param offset - The index of the starting point.
   * @returns
   *   A `Promise` that resolves with the list of users
   *   marked as recipients of the comment.
   */
  async getPaginatedRecipients(limit: number, offset: number): Promise<User[]> {
    return RESTClient.get<{
      data: { results: { user: IUser; email: string }[] };
    }>(`/comments/comments/${this.id}/recipients`, {
      limit,
      offset,
    }).then((res) => res?.data?.results.map(({ user }) => new User(user)));
  }

  async getUpdates(): Promise<CommentUpdate[]> {
    if (this.updates.length === this.updateCount) return this.updates;
    this.updates = await CommentUpdate.getCommentUpdates(undefined, this.id);
    return this.updates;
  }

  /**
   * create a comment for a baseobject
   * @param data {
   *  comment: string (the content of the comment),
   * }
   * @param taskId string ( the task id )
   * @returns Promise<Comment>
   */
  static createFromBaseObject(
    data: {
      content: string;
      users?: number[];
    },
    baseObjectId: number,
  ): Promise<Comment> {
    return RESTClient.post<{ results: IComment }>(
      data,
      `/objects/baseobjects/${baseObjectId}/comments`,
    ).then((res) => new Comment(res.data.results));
  }
}
