import type { AppState } from '@smack/core/store';
import type { IAppSettings } from '@smack/core/store/app/types';
import { LoaderSkeleton } from '@smack/core/utils/Loader';
import { copyVersionTag, getVersionTag } from '@smack/core/utils/VersionUtils';
import { ServiceWorkerReloadModal } from '@smack/core/views/oldViewsToSort/Layouts/Modal/ServiceWorkerReloadModal/ServiceWorkerReloadModal';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export const Splash: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [t] = useTranslation();
  const [isReloadModalOpen, setIsReloadModalOpen] = useState(false);

  const customer = useSelector<AppState, IAppSettings | undefined>(
    (state) => state.App.settings,
  );
  const isCustomerLoading = customer?.settingsLoading;

  const loginLogo = customer?.loginLogo || '/images/BaseApp/hyvilo.png';
  const loginBackgroundImage =
    customer?.loginBackgroundImage || '/images/BaseApp/city.jpg';

  return (
    <div className="w-screen h-screen flex">
      <div className="relative min-w-[100vw] sm:min-w-[400px] w-[400px] h-full flex flex-col justify-center items-center">
        {isCustomerLoading ? (
          <LoaderSkeleton width={200} height={150} className="mx-auto">
            <rect x={50} y={25} height={100} width={100} rx={8} />
          </LoaderSkeleton>
        ) : (
          <img
            src={loginLogo}
            alt={customer?.displayedLabel ?? 'Logo'}
            className="mx-auto w-[200px] h-[150px] object-contain"
          />
        )}

        {children}

        <p
          className="absolute bottom-0 left-0 p-2 opacity-25 hover:opacity-100 transition-opacity text-xs text-text"
          onClick={copyVersionTag}
        >
          {getVersionTag()}
        </p>

        <button
          type="button"
          className="absolute bottom-0 right-0 p-2 opacity-25 hover:opacity-100 transition-opacity text-xs text-text"
          onClick={() => setIsReloadModalOpen(true)}
        >
          {t('reloadApp')}
        </button>
      </div>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.2),rgba(0,0,0,0.2)),url(${loginBackgroundImage})`,
          filter: isCustomerLoading ? 'grayscale(100%)' : '',
          opacity: isCustomerLoading ? '0.5' : '1',
        }}
        className="hidden sm:block h-full w-full bg-cover bg-center bg-no-repeat"
      />

      <ServiceWorkerReloadModal
        open={isReloadModalOpen}
        setOpen={setIsReloadModalOpen}
      />
    </div>
  );
};
