import type {
  DateSelectArg,
  DatesSetArg,
  EventChangeArg,
  EventSourceInput,
} from '@fullcalendar/core';
import type FullCalendar from '@fullcalendar/react';
import {
  type ILinkDateRange,
  Link,
} from '@smack/core/api/models/objects/Link/Link';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { Loader } from '@smack/core/components/Actions/Loader';
import { CalendarTimeline } from '@smack/core/components/DataDisplay/CalendarTimeLine/CalendarTimeLine';
import { AvailableView } from '@smack/core/components/DataDisplay/CalendarTimeLine/enums';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IEventInputWithExtraProps } from '@smack/core/views/oldViewsToSort/Views/Objects/Calendar/Utils';
import React from 'react';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

interface IProps {
  dateRange?: ILinkDateRange;
  onSelectRange?: (data: ILinkDateRange) => void;
  onEventChangeDate?: (data: ILinkDateRange) => void;
  baseObject?: BaseObject;
  sourceBaseObject?: BaseObject;
}

export const LinkCalendar: React.FC<IProps> = ({
  dateRange,
  baseObject,
  onSelectRange,
  onEventChangeDate,
  sourceBaseObject,
}) => {
  const [isLoaded, setIsLoaded] = React.useState<boolean>(true);
  const [links, setLinks] = React.useState<Link[]>([]);
  const [t] = useTranslation();
  const calendar = React.useRef<FullCalendar>(null);
  const getLinks = (range?: ILinkDateRange): void => {
    if (!range) return;

    if (!baseObject && !sourceBaseObject) return;
    setIsLoaded(false);
    Link.getLinksFromBaseObjectId(
      (baseObject?.id ?? sourceBaseObject?.id) as number,
      range,
    )
      .then((res) => setLinks(res))
      .finally(() => setIsLoaded(true));
  };

  const getEvents = (): EventSourceInput => {
    const output: EventSourceInput = links
      ?.filter((l) => !!l.datetimeRange?.dtstart)
      .map((link: Link) => {
        const input: Partial<IEventInputWithExtraProps> = {
          title: `${link.baseobject.title ?? ''} | ${
            link.baseobject.subtitle ?? ''
          }`,
          // Ensured to have value thanks to .filter() above
          start: new Date(link.datetimeRange?.dtstart as string),
          backgroundColor: '#ff4f4f',
          className: 'fullcalendar-event',
          id: link.id.toString(),
        };
        if (link.datetimeRange?.dtend)
          input.end = new Date(link.datetimeRange.dtend);

        return input;
      });

    // New link preview
    if (baseObject && dateRange) {
      output.push({
        id: '0',
        title: t('calendarTimeline.newLink'),
        start: new Date(dateRange.dtstart),
        end: new Date(dateRange.dtend),
        backgroundColor: sourceBaseObject?.color,
      });
    }

    // Current object in the background
    if (sourceBaseObject?.startAt && sourceBaseObject?.endAt) {
      output.push({
        id: sourceBaseObject.id.toString(),
        start: new Date(sourceBaseObject.startAt),
        end: new Date(sourceBaseObject.endAt),
        backgroundColor: sourceBaseObject?.color,
        display: 'background',
      });
    }
    return output;
  };

  const initialDate = React.useMemo(() => {
    const startAt = sourceBaseObject?.startAt
      ? new Date(sourceBaseObject?.startAt)
      : undefined;
    const output = dateRange ? new Date(dateRange.dtstart) : startAt;

    return output ?? new Date();
  }, [dateRange]);

  React.useEffect(() => {
    calendar.current?.getApi().gotoDate(initialDate);
    calendar.current?.getApi().scrollToTime({
      hours: initialDate.getHours() - 2 || 0,
    });
  }, [dateRange]);

  const getCurrentDateRange = (): ILinkDateRange | undefined => {
    const calendarApi = calendar.current?.getApi();
    if (!calendarApi) return undefined;
    const start = calendarApi.view.activeStart;
    const end = calendarApi.view.activeEnd;
    return {
      dtstart: start.toISOString(),
      dtend: end.toISOString(),
    };
  };

  const handleEventChangeDate = (arg: EventChangeArg): void => {
    if (arg.event.id.toString() !== '0') {
      toast.error(t('calendarTimeline.errors.notEditable'));
      getLinks(getCurrentDateRange());
      return;
    }
    if (onEventChangeDate && arg.event.start && arg.event.end) {
      onEventChangeDate({
        dtstart: arg.event.start.toISOString(),
        dtend: arg.event.end.toISOString(),
      });
    }
  };

  const handleCalendarEmptyClick = (arg: DateSelectArg): void => {
    if (onSelectRange && arg.start && arg.end) {
      onSelectRange({
        dtstart: arg.start.toISOString(),
        dtend: arg.end.toISOString(),
      });
    }
  };

  React.useEffect(() => {
    getLinks(getCurrentDateRange());
  }, [dateRange, baseObject, sourceBaseObject]);

  const handleDateChange = (arg: DatesSetArg): void => {
    if (arg.start && arg.end) {
      getLinks({
        dtstart: arg.start.toISOString(),
        dtend: arg.end.toISOString(),
      });
    }
  };

  const calendarTitle = React.useMemo(() => {
    return calendar?.current?.getApi().view.title ?? '';
  }, [isLoaded, baseObject, sourceBaseObject]);

  return (
    <Loader isDataLoaded={isLoaded}>
      <div className={'flex flex-col h-full '}>
        <div
          className={
            ' font-medium text-gray-800 mb-4 flex item-center justify-between gap-3 '
          }
        >
          {t('addLinkForm.planningOf', {
            object: baseObject?.title ?? sourceBaseObject?.title,
          })}
          <div className={'flex items-center gap-1'}>
            <Icon icon={{ name: 'calendar' }} />
            {calendarTitle}
          </div>
        </div>
        <CalendarTimeline
          ref={calendar}
          events={getEvents()}
          headerToolbar={{
            start: 'prev,next,today',
            end: 'timeGridDay,timeGridWeek,dayGridMonth',
          }}
          initialView={'timeGridWeek'}
          initialDate={initialDate}
          availableViews={[AvailableView.CALENDAR]}
          eventChange={handleEventChangeDate}
          select={handleCalendarEmptyClick}
          scrollTimeReset={false}
          onDateChange={handleDateChange}
        />
      </div>
    </Loader>
  );
};
