import type { Reducer } from 'redux';
const DEFAULT_COLOR = '#6b7280';
import {
  type BaseAppActionType,
  type BaseAppState,
  FETCH_ACTIVEMODULES,
  FETCH_APPLOADING,
  FETCH_LEGEND,
  FETCH_SETTINGS,
  FETCH_THEME,
  SET_CURRENT_VIEW,
  SET_FOCUSED_BASEOBJECT,
  SET_IS_AUTO_OPENING_FILE,
  SET_IS_MENU_COLLAPSED,
  SET_LAST_VIEW_AS_CURRENT,
  SET_MODULE_STORE,
  SET_ONLINE,
} from './types';

// initial state
const initialState: BaseAppState = {
  isMenuCollapsed: false,
  appLoading: false,
  appOnline: true,
  settings: {
    defaultColor: DEFAULT_COLOR,
    settingsLoading: true,
  },
  theme: 'light',
  currentView: {
    current: undefined,
    viewHistory: [],
  },
  module: {
    activeModule: undefined,
    focusedObject: undefined,
    legend: undefined,
    hideSetOnMap: false,
  },
  isAutoOpeningFile: false,
};

// Object Reducer

export const appReducer: Reducer<BaseAppState, BaseAppActionType> = (
  state: BaseAppState | undefined,
  action: BaseAppActionType,
): BaseAppState => {
  if (!state) return initialState;
  switch (action.type) {
    case FETCH_ACTIVEMODULES:
      return {
        ...state,
        module: {
          ...state.module,
          activeModule: action.payload,
        },
      };
    case SET_IS_MENU_COLLAPSED:
      return {
        ...state,
        isMenuCollapsed: action.payload.isMenuCollapsed,
      };
    case FETCH_THEME:
      return {
        ...state,
        theme: action.payload,
      };
    case FETCH_APPLOADING:
      return {
        ...state,
        appLoading: action.payload,
      };
    case FETCH_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          settingsLoading: false,
          ...action.payload,
        },
      };

    case SET_MODULE_STORE:
      return {
        ...state,
        module: {
          ...state.module,
          ...action.payload,
        },
      };
    case FETCH_LEGEND:
      return {
        ...state,
        module: {
          ...state.module,
          legend: action.payload,
        },
      };
    case SET_ONLINE:
      return {
        ...state,
        appOnline: action.payload,
      };
    case SET_FOCUSED_BASEOBJECT:
      return {
        ...state,
        module: {
          ...state.module,
          focusedObject: action.payload,
        },
      };
    case SET_CURRENT_VIEW:
      return {
        ...state,
        currentView: {
          current: action.payload,
          viewHistory: state.currentView.current
            ? [
                state.currentView.current,
                ...(state.currentView.viewHistory ?? []),
              ]
            : state.currentView.viewHistory,
        },
      };
    case SET_LAST_VIEW_AS_CURRENT:
      return {
        ...state,
        currentView: {
          current: state.currentView.viewHistory?.at(0),
          viewHistory: state.currentView.viewHistory?.slice(1) ?? [],
        },
      };
    case SET_IS_AUTO_OPENING_FILE:
      return { ...state, isAutoOpeningFile: action.payload };
    default:
      return state;
  }
};
