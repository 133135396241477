import React from 'react';

import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import {
  AcceptButton,
  RefuseButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { DangerAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts';
import { SuccessAlert } from '@smack/core/components/DataDisplay/Alerts/Alerts/Components/Success';
import { onRejectNotification } from '@smack/core/components/DataDisplay/Alerts/NotificationRejectionAlert';
import { useNavigation } from '@smack/core/hooks/useNavigation/useNavigation';
import { DateUtils } from '@smack/core/utils/DateUtils';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

interface IProps {
  object: BaseObject | undefined;
  mobile?: boolean;
  reload?: () => void;
}

export const ObjectAsNotificationContentPanel = (
  props: IProps,
): JSX.Element => {
  const { object, reload } = props;
  const [t] = useTranslation();
  const [isDisabled, setIsDisabled] = React.useState(false);
  const [loadingButton, setLoadingButton] = React.useState<
    null | 'accept' | 'reject'
  >(null);
  const navigate = useNavigation();
  const onAccept = (): void => {
    if (!object?.id) return;
    setLoadingButton('accept');
    setIsDisabled(true);
    object
      .accept()
      .then((res) => {
        const url = res?.frontEndpoint;
        if (url) {
          navigate(url, { replace: true });
        } else {
          if (reload) reload();
        }
      })
      .catch(() => {
        toast.error(t('baseObjectPanel.acceptRejectError'));
        setIsDisabled(false);
      })
      .finally(() => setLoadingButton(null));
  };

  if (!object?.category?.isNotification || !object?.category.isProcessable)
    return <></>;

  if (object.notificationState?.state === 'ACCEPTED') {
    const user = object.notificationState.user?.getFullName();
    return (
      <SuccessAlert
        className="rounded-none"
        title={t('baseObjectPanel.accepted')}
      >
        <Trans
          t={t}
          i18nKey={'baseObjectPanel.acceptDescription'}
          components={{
            bold: <span className={'font-bold'} />,
            small: <span className={'text-xs'} />,
          }}
          values={{
            user: user,
            dateFromNow: DateUtils.getFromNowText(
              new Date(object.notificationState.modifiedAt),
            ),
            date: DateUtils.getDateTimeString(
              new Date(object.notificationState.modifiedAt),
            ),
          }}
        />
      </SuccessAlert>
    );
  }

  if (object.notificationState?.state === 'REJECTED') {
    const description = object.notificationState.description;
    const user = object.notificationState.user?.getFullName();
    return (
      <DangerAlert
        icon={{ name: 'times-circle' }}
        className="rounded-none"
        title={t('baseObjectPanel.rejected')}
      >
        <div>
          <Trans
            t={t}
            i18nKey={'baseObjectPanel.rejectDescription'}
            components={{
              bold: <span className={'font-bold'} />,
              small: <span className={'text-xs'} />,
            }}
            values={{
              user: user,
              dateFromNow: DateUtils.getFromNowText(
                new Date(object.notificationState.modifiedAt),
              ),
              date: DateUtils.getDateTimeString(
                new Date(object.notificationState.modifiedAt),
              ),
            }}
          />

          <p className={'mt-2'}>
            {t('baseObjectPanel.reasonRejection', {
              reason: description,
            })}
          </p>
        </div>
      </DangerAlert>
    );
  }

  const onReject = (description?: string): void => {
    if (!object?.id) return;
    setLoadingButton('reject');
    setIsDisabled(true);
    object
      .reject(description)
      .then(() => {
        if (reload) reload();
      })
      .catch(() => {
        toast.error(t('baseObjectPanel.acceptRejectError'));
        setIsDisabled(false);
      })
      .finally(() => setLoadingButton(null));
  };
  const handleReject = (): void => {
    if (object?.category?.notificationSupportsDescription) {
      onRejectNotification(onReject);
    } else {
      onReject();
    }
  };

  return (
    <div className="w-full relative">
      {object && (
        <div className="flex items-center gap-2 justify-between py-2 px-3 border-b">
          <RefuseButton
            onClick={handleReject}
            className="[&&]:bg-red-600 text-white dark:bg-red-700 [&&]:hover:bg-red-700 dark:hover:bg-red-800"
            disabled={isDisabled}
            isLoading={loadingButton === 'reject'}
          />
          <AcceptButton
            onClick={onAccept}
            className="[&&]:bg-green-600 text-white [&&]:dark:hover:bg-green-800 [&&]:dark:bg-green-700 [&&]:hover:bg-green-700"
            disabled={isDisabled}
            isLoading={loadingButton === 'accept'}
          />
        </div>
      )}
    </div>
  );
};

ObjectAsNotificationContentPanel.defaultProps = {
  mobile: false,
};
