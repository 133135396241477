import type { Comment } from '@smack/core/api/models/comments/Comment';
import type { CommentUpdate } from '@smack/core/api/models/comments/CommentUpdate';
import type { User } from '@smack/core/api/models/users/User';
import { DiffWords } from '@smack/core/components/DataDisplay/DiffWords';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { format } from 'date-fns';
import { t } from 'i18next';
import React, { type Dispatch, type SetStateAction } from 'react';

interface IProps {
  comment: Comment;
  updates: CommentUpdate[];
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

export const ModalCommentUpdateHistory = (props: IProps): JSX.Element => {
  const { comment, updates, open, onClose } = props;

  const getVersionLabel = (
    updatedAt: Date,
    updatedBy: User | undefined,
    index: number,
  ): string => {
    let label = `${index.toString()} - `;
    const date = format(updatedAt, t('dateTime.format') || 'yyyy-MM-dd HH:mm');
    if (updatedBy) {
      label += t('onDateByUser', { date: date, user: updatedBy.getFullName() });
    } else {
      label += date;
    }
    return label;
  };

  const commentUpdatesAsOptions = ((): Option<number>[] => {
    const options: Option<number>[] = [];
    updates.forEach((update, index) =>
      options.push({
        label: getVersionLabel(update.updatedAt, update.getUser(), index + 1),
        value: index,
      }),
    );
    options.push({
      label: getVersionLabel(
        comment.modifiedAt,
        comment.getLastModifiedBy(),
        updates.length + 1,
      ),
      value: updates.length,
    });
    return options;
  })();

  const [option, setOption] = React.useState<Option>(
    commentUpdatesAsOptions[0],
  );
  const [version, setVersion] = React.useState<number>(0);

  return (
    <div data-testid="comment-history-modal">
      <Modal
        title={t('comments.history')}
        icon={{ name: 'rectangle-history' }}
        color="#dbeafe"
        iconColor="#60a5fa"
        open={open}
        onClose={onClose}
      >
        <div className="mb-2 dark:text-gray-100 text-gray-800">
          <label htmlFor="version-select" className="font-medium">
            {t('comments.version')}
          </label>
          <SelectInput
            id="version-select"
            options={commentUpdatesAsOptions}
            onChange={(selected): void => {
              if (
                selected &&
                !Array.isArray(selected) &&
                typeof selected.value === 'number'
              ) {
                setVersion(selected.value);
                setOption(selected);
              }
            }}
            value={option}
          />
        </div>
        <div className="text-sm text-gray-500 dark:text-gray-300">
          {version === 0
            ? t('comments.initialVersion')
            : t('comments.updateDiffCaption')}
        </div>
        <div className="max-w-full lg:max-w-[700px] dark:text-gray-200 p-2 mt-3 border rounded-md">
          {version === 0 ? (
            updates[version]?.content
          ) : version === updates.length ? (
            <DiffWords
              oldValue={updates[version - 1].content || ''}
              newValue={comment.comment || ''}
            />
          ) : (
            <DiffWords
              oldValue={updates[version - 1].content || ''}
              newValue={updates[version].content || ''}
            />
          )}
        </div>
      </Modal>
    </div>
  );
};
