interface IProps {
  title: string;
  children: string | JSX.Element;
}

export const DescriptionListElement = (props: IProps): JSX.Element => {
  const { title, children } = props;

  return (
    <div
      className={
        'odd:bg-gray-50 even:bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 dark:odd:bg-neutral-700 dark:even:bg-view'
      }
    >
      <dt className="text-sm font-medium text-gray-500 dark:text-gray-200">
        {title}
      </dt>
      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 ml-36 dark:text-gray-200">
        {children}
      </dd>
    </div>
  );
};
