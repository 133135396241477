import {
  type IPagination,
  RESTClient,
} from '@smack/core/api/clients/rest/RESTClient';
import { type ILog, Log, LogAction } from '@smack/core/api/models/logs/Log';
import { BaseObjectLogCreationFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/BaseObjectLogsFeed/CreationFeed';
import { BaseObjectLogDeleteFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/BaseObjectLogsFeed/DeleteFeed';
import { BaseObjectLogUpdateFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/BaseObjectLogsFeed/UpdateFeed';
import { LogFeed } from '@smack/core/components/DataDisplay/Feeds/LogsFeed/LogsFeed';
import type { AxiosResponse } from 'axios';

/**
 *  interface of ITask log content
 *
 * @export
 * @interface IBaseObjectLogContent
 */
export interface IBaseObjectLogContent {
  id: number;
  baseobject?: {
    id: number;
    code: string;
    frontEndpoint: string;
    title: string;
  };
  commentId?: number;
}

/**
 * interface Of IBaseObjectLog
 *
 * @export
 * @interface IBaseObjectLog
 * @extends {ILog}
 */
export interface IBaseObjectLog extends ILog {
  content?: IBaseObjectLogContent;
}

/**
 *  class Task log
 *
 * @export
 * @class BaseObjectLog
 * @extends {Log}
 */
export class BaseObjectLog extends Log {
  content?: IBaseObjectLogContent;

  /**
   * // Creates an instance of BaseObjectLog.
   * @param {IBaseObjectLog} data
   * @memberof BaseObjectLog
   */
  constructor(data: IBaseObjectLog) {
    super(data);
    this.content = data.content;
  }

  /**
   *
    Get the task logs for a baseObject id.
   * @static
   * @param {number} BaseObjectId
   * @return {*}  {Promise<BaseObjectLog[]>}
   * @memberof BaseObjectLog
   */
  static getAllBaseObjectLogsById(
    BaseObjectId: number,
  ): Promise<BaseObjectLog[]> {
    return RESTClient.get<{ data: { results: IBaseObjectLog[] } }>(
      `/objects/baseobject-logs?baseobjects=${BaseObjectId}`,
    ).then((res) => res?.data?.results.map((r) => new BaseObjectLog(r)));
  }

  /**
   *
    Get the task logs for a baseObject id.
   * @static
   * @param {number} BaseObjectId
   * @return {*}  {Promise<BaseObjectLog[]>}
   * @memberof BaseObjectLog
   */
  static getAllBaseObjectLogs(): Promise<BaseObjectLog[]> {
    return RESTClient.get<{ data: { results: IBaseObjectLog[] } }>(
      '/objects/baseobject-logs',
    ).then((res) => res?.data?.results.map((r) => new BaseObjectLog(r)));
  }

  /**
   *
    Get the paginated task logs for a baseObject from id.
   * @static
   * @param {number} BaseObjectId
   * @return {*}  {Promise<{results: BaseObjectLog[], count: number}>}
   * @memberof BaseObjectLog
   */
  static getPaginateBaseObjectLogsById(
    BaseObjectId: number,
    limit: number,
    offset: number,
  ): Promise<{ results: BaseObjectLog[]; count: number }> {
    return RESTClient.get<{
      data: { results: IBaseObjectLog[]; count: number };
    }>(
      `/objects/baseobject-logs?baseobjects=${BaseObjectId}&limit=${limit}&offset=${offset}`,
    ).then((res) => {
      return {
        results: res?.data?.results.map((r) => new BaseObjectLog(r)),
        count: res?.data?.count,
      };
    });
  }

  static getPaginateBaseObjectLogs(
    limit = 20,
  ): Promise<IPagination<IBaseObjectLog>> {
    return RESTClient.get<AxiosResponse<Promise<IPagination<IBaseObjectLog>>>>(
      '/objects/baseobject-logs',
      {
        'hide-seen': 1,
        limit: limit,
      },
    ).then((res) => {
      return res.data;
    });
  }

  /**
   * switch for the type of the log
   *
   * @param {{ first?: boolean; last?: boolean }} params
   * @return {*}  {JSX.Element}
   * @memberof BaseObjectLog
   */
  getLogByAction(params: { first?: boolean; last?: boolean }): JSX.Element {
    switch (this.action) {
      case LogAction.CREATE:
        return <BaseObjectLogCreationFeed {...params} log={this} />;
      case LogAction.UPDATE:
        return <BaseObjectLogUpdateFeed {...params} log={this} />;

      case LogAction.DELETE:
        return <BaseObjectLogDeleteFeed {...params} log={this} />;

      default:
        return <LogFeed {...params} log={this} />;
    }
  }
}
