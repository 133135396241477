import React from 'react';
import { RRuleGenerator } from './Components/RRuleGenerator';

import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { DateTimeRangeInput } from '@smack/core/components/DataInput/DateRangeInput/DateTimeRangeInput';
import { useBaseObjectFormContextContext } from '@smack/core/components/ViewRenderer/renderers/BaseObjectFormViewRenderer/context/BaseObjectFormContext';
import { parseISO } from 'date-fns';

export interface RecurrenceInputValue {
  dtstart?: string;
  dtend?: string;
  rrule?: string;
  update?: string;
  updateFromSchedule?: string | number;
}
export interface IRRuleProps {
  value?: RecurrenceInputValue;
  onChange?: (data: RecurrenceInputValue) => void;
  id?: string;
  error?: string;
  includeDtStart?: boolean;
  allowEmptyRRule?: boolean;
  isClearable?: boolean;
  withRRule?: boolean;
}

/**
 * RRule input components
 * @param props
 * @returns JSX.ELEMENT
 */
export const RecurrenceInput: React.FC<IRRuleProps> = ({
  id,
  error,
  value,
  onChange,
  allowEmptyRRule = true,
  includeDtStart = true,
  isClearable = true,
  withRRule = true,
}) => {
  const { setFormRRuleInputValue } = useBaseObjectFormContextContext();

  const handleChange = (data: RecurrenceInputValue): void => {
    if (onChange) onChange(data);
    if (setFormRRuleInputValue) setFormRRuleInputValue(data);
  };

  React.useEffect(() => {
    if (setFormRRuleInputValue) setFormRRuleInputValue(value ?? {});
  }, [value]);

  const setRRule = (val?: string): void => {
    if (!withRRule) return;
    if (!onChange) return;
    if (value) {
      handleChange({ ...value, rrule: val });
    }
  };

  const getStart = (): Date | undefined => {
    if (value?.dtstart) {
      return parseISO(value.dtstart);
    }
  };
  const onDelete = (): void => {
    handleChange({});
  };

  return (
    <div id={id ?? ''}>
      <div className=" gap-3 items-center py-2 flex">
        <DateTimeRangeInput
          value={{
            startDate: value?.dtstart ? new Date(value?.dtstart) : undefined,
            endDate: value?.dtend ? new Date(value?.dtend) : undefined,
          }}
          onChange={(val): void => {
            handleChange({
              ...value,
              dtstart: val.startDate?.toISOString(),
              dtend: val.endDate?.toISOString(),
            });
          }}
        />
        {isClearable && value?.dtstart && (
          <Icon
            onClick={onDelete}
            icon={{ name: 'times' }}
            className={
              'text-gray-500   cursor-pointer opacity-60 hover:opacity-100 '
            }
          />
        )}
      </div>
      {withRRule && value?.dtend && value?.dtstart && (
        <RRuleGenerator
          dtStart={getStart()}
          value={value?.rrule}
          onChange={setRRule}
          allowEmpty={allowEmptyRRule}
          includeDtStart={includeDtStart}
        />
      )}
      {error && <p className="text-red-500 text-xs">{error}</p>}
    </div>
  );
};
