import type { Group } from '@smack/core/api/models/users/Group';
import { Badge } from '@smack/core/components/DataDisplay/Badges/Badge/Badge';
import { DataFieldWrapper } from '@smack/core/components/DataDisplay/DataFieldWrapper';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import type React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  group?: Group;
  categoryId?: number;
}

export const GroupInfoModal: React.FC<IProps> = ({
  open,
  setOpen,
  group,
  categoryId,
}) => {
  const [t] = useTranslation();

  const users = group?.users.length ? (
    group?.users.map((user) => (
      <p key={user.id} className="block text-sm text-gray-900   ">
        {user.getFullName()}
      </p>
    ))
  ) : (
    <p>-</p>
  );

  return (
    <Modal
      icon={{ name: 'users' }}
      title={group?.label}
      open={open}
      onClose={setOpen}
    >
      <div data-testid="group-info-modal" className="p-4 min-w-[500px]">
        <DataFieldWrapper label={t('rightsPage.readRight')}>
          <span className="block  text-sm text-gray-900">
            <Badge
              color={
                group?.readableCategories?.includes(categoryId ?? 0) ||
                group?.writableCategories?.includes(categoryId ?? 0) ||
                group?.shareableCategories?.includes(categoryId ?? 0) ||
                group?.manageableCategories?.includes(categoryId ?? 0)
                  ? 'green'
                  : 'red'
              }
            >
              {group?.readableCategories?.includes(categoryId ?? 0) ||
              group?.writableCategories?.includes(categoryId ?? 0) ||
              group?.shareableCategories?.includes(categoryId ?? 0) ||
              group?.manageableCategories?.includes(categoryId ?? 0)
                ? t('rightsPage.yes')
                : t('rightsPage.no')}
            </Badge>
          </span>
        </DataFieldWrapper>
        <DataFieldWrapper label={t('rightsPage.writeRight')}>
          <span className="block  text-sm text-gray-900   ">
            <Badge
              color={
                group?.writableCategories?.includes(categoryId ?? 0) ||
                group?.shareableCategories?.includes(categoryId ?? 0) ||
                group?.manageableCategories?.includes(categoryId ?? 0)
                  ? 'green'
                  : 'red'
              }
            >
              {group?.writableCategories?.includes(categoryId ?? 0) ||
              group?.shareableCategories?.includes(categoryId ?? 0) ||
              group?.manageableCategories?.includes(categoryId ?? 0)
                ? t('rightsPage.yes')
                : t('rightsPage.no')}
            </Badge>
          </span>
        </DataFieldWrapper>
        <DataFieldWrapper label={t('rightsPage.manageRight')}>
          <span className="block  text-sm text-gray-900">
            <Badge
              color={
                group?.manageableCategories?.includes(categoryId ?? 0)
                  ? 'green'
                  : 'red'
              }
            >
              {group?.manageableCategories?.includes(categoryId ?? 0)
                ? t('rightsPage.yes')
                : t('rightsPage.no')}
            </Badge>
          </span>
        </DataFieldWrapper>
        <DataFieldWrapper label={t('rightsPage.users')}>
          <div className="max-h-52 overflow-y-auto w-full gap-1 flex flex-col">
            {users}
          </div>
        </DataFieldWrapper>
      </div>
    </Modal>
  );
};
