import { DateInput } from '@smack/core/components/DataInput/DateInput';
import { NumberInput } from '@smack/core/components/DataInput/NumberInput/NumberInput';
import { RecurenceEnd } from '@smack/core/components/DataInput/RecurrenceInput/Utils';
import { cva } from 'class-variance-authority';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { type Options, RRule } from 'rrule';

interface IProps {
  value?: RRule;
  onChange: (value: RRule) => void;
}

export const EndRRule = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { value, onChange } = props;

  const handleChange = (val: Partial<Options>): void => {
    onChange(new RRule({ ...value?.origOptions, ...val }));
  };

  const getValue = (): number => {
    if (value?.options?.count) return RecurenceEnd.AFTER;
    if (value?.options?.until) return RecurenceEnd.ONDATE;
    return RecurenceEnd.NEVER;
  };

  const setValue = (val: RecurenceEnd): void => {
    if (val === RecurenceEnd.NEVER) handleChange({ until: null, count: null });
    if (val === RecurenceEnd.AFTER) handleChange({ until: null, count: 1 });
    if (val === RecurenceEnd.ONDATE)
      handleChange({ until: new Date(), count: null });
  };

  const radioButtonClassNames =
    'h-5 w-5 mr-3 border-[2px] text-blue-500 focus:ring-blue-500';

  const pVariants = cva('text-sm text-gray-600', {
    variants: {
      width: {
        none: '',
        w12: 'w-12',
        w24: 'w-24',
      },
    },
    defaultVariants: {
      width: 'none',
    },
  });

  return (
    <div className="mt-6">
      <p className=" text-gray-600 dark:text-text">
        {t('recurrenceInput.isEnding')}
      </p>
      <div className=" mt-3 mb-3 flex flex-col gap-2">
        <div className="flex items-center gap-3 h-[38px]">
          <input
            type="radio"
            onChange={(): void => setValue(0)}
            checked={getValue() === 0}
            className={radioButtonClassNames}
          />
          <div>
            <p className={pVariants({ width: 'w24' })}>
              {t('recurrence.never')}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <input
            type="radio"
            onChange={(): void => setValue(1)}
            checked={getValue() === 1}
            className={radioButtonClassNames}
          />
          <p className={pVariants({ width: 'w12' })}>{t('recurrence.after')}</p>
          <NumberInput
            size="small"
            disabled={getValue() !== 1}
            value={value?.options.count || 1}
            onChange={(val): void => {
              if (val) {
                handleChange({ count: val });
              }
            }}
            min={1}
          />
          <p className={pVariants({ width: 'none' })}>
            {t('recurrenceInput.executions')}
          </p>
        </div>
        <div className="flex items-center gap-3">
          <input
            type="radio"
            onChange={(): void => setValue(2)}
            checked={getValue() === 2}
            className={radioButtonClassNames}
          />
          <p className={pVariants({ width: 'w12' })}>Le</p>
          <DateInput
            value={
              value?.options.until?.toISOString() ?? new Date().toISOString()
            }
            classNames={getValue() !== 2 ? '!opacity-50' : ''}
            datepickerProps={{
              disabled: getValue() !== 2,
            }}
            hidetime
            onChange={(val): void =>
              handleChange({ until: parseISO(val as string) })
            }
          />
        </div>
      </div>
    </div>
  );
};
