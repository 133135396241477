import type { IconName } from '@fortawesome/fontawesome-common-types';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { AffixChip } from '@smack/core/components/DataInput/AffixChip/AffixChip';
import React from 'react';

interface ICheckboxInputProps {
  label?: string;
  name?: string;
  value?: boolean;
  onChange?: (value: boolean) => void;
  onClick?: (event: React.MouseEvent) => void;
  className?: {
    container?: string;
    subContainer?: string;
    label?: string;
    labelContainerClassName?: string;
  };
  children?: JSX.Element;
  disabled?: boolean;
  prefix?: React.ReactNode;
  prefixIcon?: {
    name: IconName | undefined;
    className: string;
  };
  suffixElement?: React.ReactNode;
  suffix?: React.ReactNode;
  id?: string;
}

/**
 * Return CheckBoxInputComponents
 * @param props ICheckboxInputProps
 * @param ref Reference
 * @returns JSX.Element
 */
const CheckboxInputRender: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ICheckboxInputProps
> = (
  {
    label,
    onChange,
    onClick,
    value,
    name,
    className,
    disabled,
    children,
    prefixIcon,
    suffixElement,
    prefix,
    suffix,
    id,
    ...otherProps
  },
  ref,
) => {
  const inputNode = (
    <input
      id={id ?? name}
      name={name}
      checked={value}
      onClick={(e): void => {
        if (onClick) onClick(e);
      }}
      onChange={(e): void => {
        if (onChange) onChange(e.target.checked);
      }}
      type="checkbox"
      disabled={disabled}
      className="focus:ring-blue-500 h-4 w-4 text-blue-500 border-gray-300 rounded"
      ref={ref}
      {...otherProps}
    />
  );

  return (
    <div className={`relative flex items-start ${className?.container ?? ''}`}>
      <div
        className={`flex items-stretch self-center ${
          className?.subContainer ?? ''
        }`}
      >
        {prefixIcon ? (
          <div
            className={`absolute left-2 group-hover:opacity-70 transition-opacity ${prefixIcon.className}`}
          >
            <Icon icon={{ name: prefixIcon.name }} />
          </div>
        ) : null}
        {prefix ? <AffixChip type="prefix">{prefix}</AffixChip> : null}

        {prefix || suffix ? (
          <div className="p-2 border border-gray-300">{inputNode}</div>
        ) : (
          inputNode
        )}
        {suffix ? <AffixChip type="suffix">{suffix}</AffixChip> : null}
      </div>
      {label || children ? (
        <div
          className={`ml-2 text-sm ${className?.labelContainerClassName ?? ''}`}
        >
          <label
            htmlFor={id ?? name}
            className={`font-medium select-none ${className?.label ?? ''} ${
              disabled ? 'text-gray-500' : 'text-gray-700'
            }`}
          >
            {label}
          </label>
          {children}
        </div>
      ) : null}
      {suffixElement ? suffixElement : null}
    </div>
  );
};

CheckboxInputRender.displayName = 'CheckboxInput';

export const CheckboxInput = React.forwardRef(CheckboxInputRender);
