import type { BaseObjectSubmitAttribute } from '@smack/core/components/ViewRenderer/interfaces';
import type { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';

export interface ErrorsFormat {
  code: string;
  detail: string;
  meta?: {
    field?: string;
  };
}

export interface AttributeErrorsFormat {
  [attributeId: number]: ErrorsFormat[];
}

export interface ApiErrorOutput {
  code: number;
  status: string;
  errors: ErrorsFormat[];
}

export class ApiError implements ApiErrorOutput {
  public readonly code: number;
  public readonly status: string;
  public readonly errors: ErrorsFormat[];

  constructor(data: ApiErrorOutput) {
    this.code = data.code;
    this.status = data.status;
    this.errors = data.errors;
  }

  private getSplitFieldError(error: ErrorsFormat): string[] {
    if (!error.meta?.field) return [];
    return error.meta.field.split('.');
  }

  getErrorsByAttributesIdFromBaseObjectPayload = (payload: {
    attributes: BaseObjectSubmitAttribute[];
  }): AttributeErrorsFormat => {
    const output: AttributeErrorsFormat = {};

    for (const error of this.errors) {
      const splitErrorField = this.getSplitFieldError(error);
      const attribute: BaseObjectSubmitAttribute =
        payload[splitErrorField.at(0) ?? '']?.[splitErrorField.at(1) ?? ''];
      if (!attribute) continue;
      if (output[attribute.id]) {
        output[attribute.id].push(error);
      } else {
        output[attribute.id] = [error];
      }
    }
    return output;
  };

  toastAllErrors() {
    for (const error of this.errors) {
      if (error.detail) {
        toast.error(error.detail);
      }
    }
  }

  static toastAllErrorsOnCatchAxiosErrors(error: AxiosError<ApiErrorOutput>) {
    const errorData = error.response?.data;
    if (errorData) {
      const apiError = new ApiError(errorData);
      apiError.toastAllErrors();
    }
    return Promise.reject(error);
  }

  static interceptCanceledErrors<T>(
    err: AxiosError<T>,
    callback?: (err: AxiosError<T>) => void,
  ): Promise<AxiosError> | undefined {
    if (err.code === 'ERR_CANCELED') {
      // Do nothing
      return;
    }

    if (callback) {
      callback(err);
    } else {
      return Promise.reject(err);
    }
  }
}
