import i18next from '@smack/core/utils/i18n/i18n';
import {
  differenceInMinutes,
  format,
  formatDistance,
  intervalToDuration,
} from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import humanizeDuration from 'humanize-duration';

/**
 * DATE UTILITY CLASS
 */
export class DateUtils {
  /**
   * Get the current date and time
   * @returns Date
   */
  static getNow(): Date {
    return new Date();
  }

  /**
   * Format a date to 'DD/MM/YYYY HH:mm'
   * @param date Date
   * @returns string
   */
  static getDateTimeString(date?: Date): string {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy HH:mm');
  }

  /**
   * Format a date using the full format from i18n
   * @param date Date
   * @returns string
   */
  static getDateTimeStringFull(date?: Date): string {
    if (!date) return '';
    const formatString = i18next.t('dateTime.fullFormat');
    const locale = navigator.language.startsWith('fr') ? fr : enUS;

    return format(date, formatString, { locale });
  }

  /**
   * Format a date to 'DD/MM/YYYY'
   * @param date Date
   * @returns string
   */
  static getDateString(date?: Date): string {
    if (!date) return '';
    return format(date, 'dd/MM/yyyy');
  }

  /**
   * Convert a date to ISO string with timezone
   * @param date Date
   * @returns string
   */
  static toISOStringWithTimezone(date = new Date()): string {
    return format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  }

  /**
   * Get a humanized string for the time from now (e.g., "1 day ago")
   * @param date Date
   * @param status string
   * @param prefix boolean
   * @param pastOnly boolean
   * @param firstMinuteRename string
   * @returns string
   */
  static getFromNowText(
    date?: Date,
    status?: string,
    prefix = false,
    pastOnly = false,
    firstMinuteRename = '',
  ): string {
    if (!date) return '';
    const now = DateUtils.getNow();

    if (pastOnly && date > now) date = now;

    const fromNow = formatDistance(date, now, { addSuffix: prefix });

    if (firstMinuteRename) {
      const minDiff = differenceInMinutes(now, date);
      if (minDiff >= 0 && minDiff < 1)
        return `${status || ''} ${firstMinuteRename}`;
    }

    return `${status || ''} ${fromNow}`;
  }

  /**
   * Parse a DB duration string (e.g., "265 20:23:22") into milliseconds
   * @param duration string
   * @returns number | undefined
   */
  static parseDuration(duration?: string): number | undefined {
    if (!duration) return undefined;

    const [daysPart, timePart] = duration.split(' ');
    const [hours, minutes, seconds] = timePart?.split(':').map(Number) || [];
    const days = Number.parseInt(daysPart, 10) || 0;

    return (
      (((days * 24 + (hours || 0)) * 60 + (minutes || 0)) * 60 +
        (seconds || 0)) *
      1000
    );
  }

  /**
   * Get a formatted date range string
   * @param start Date
   * @param stop Date
   * @param prefix boolean
   * @param withTime boolean
   * @returns string
   */
  static getDateRangeLocalString(
    start: Date,
    stop: Date,
    prefix = true,
    withTime = true,
  ): string {
    const local = i18next.t('dateTime.dateLocale');
    const translationData = {
      startDate: start.toLocaleDateString(local, { dateStyle: 'long' }),
      endDate: stop.toLocaleDateString(local, { dateStyle: 'long' }),
      startTime: withTime
        ? start.toLocaleTimeString(local, { timeStyle: 'short' })
        : '',
      endTime: withTime
        ? stop.toLocaleTimeString(local, { timeStyle: 'short' })
        : '',
    };
    const isSameDay = translationData.startDate === translationData.endDate;
    const translationContext = [
      prefix ? 'prefix' : 'noPrefix',
      withTime ? 'time' : 'noTime',
      isSameDay ? 'sameDate' : 'notSameDate',
    ].join('|');
    return i18next.t('dateTime.range', {
      ...translationData,
      context: translationContext,
    });
  }

  /**
   * returns a ISO formatted method trimming years and month as date-fns parseISODuration does not allow it
   * start Date
   * stop Date
   */
  static getDuration(start: Date, stop: Date): string {
    if (!start || !stop) return 'PT0S';

    const duration = intervalToDuration({ start, end: stop });
    const { days, hours, minutes, seconds } = duration;

    return `P${days}DT${hours}H${minutes}M${seconds}S`;
  }

  static convertDurationToObject(duration: string): {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
  } {
    const cleanedDuration = duration.startsWith('-')
      ? duration.substring(1)
      : duration;
    const regex = /P(?:(\d+)D)?(?:T(?:(\d+)H)?(?:(\d+)M)?(?:(\d+)S)?)?/;
    const match = regex.exec(cleanedDuration);

    if (!match) {
      throw new Error('Invalid duration format');
    }

    // Extract the values or default them to zero
    const days = match[1] ? Number.parseInt(match[1]) : 0;
    const hours = match[2] ? Number.parseInt(match[2]) : 0;
    const minutes = match[3] ? Number.parseInt(match[3]) : 0;
    const seconds = match[4] ? Number.parseInt(match[4]) : 0;

    // Return the object with days, hours, minutes, and seconds
    return { days, hours, minutes, seconds };
  }

  /**
   * Get humanized duration from milliseconds
   * @param ms number
   * @returns string
   */
  static getHumanizeDurationFromMilliseconds(ms: number): string {
    return humanizeDuration(ms, {
      language: i18next.resolvedLanguage,
      units: ['y', 'mo', 'w', 'd', 'h', 'm'],
      round: true,
    });
  }

  /**
   * Get a humanized string from a DB duration string
   * @param duration string
   * @returns string | undefined
   */
  static getHumanizeDuration(duration?: string): string | undefined {
    if (!duration) return undefined;
    const parsedDuration = DateUtils.parseDuration(duration);
    if (!parsedDuration) return undefined;
    return DateUtils.getHumanizeDurationFromMilliseconds(parsedDuration);
  }

  static getHumanizeDurationFromISO(isoDuration: string): string {
    const match = isoDuration.match(
      /P(?:([-+]?\d+)D)?T?(?:([-+]?\d+)H)?(?:([-+]?\d+)M)?(?:([-+]?\d+)S)?/,
    );

    if (!match) return '';

    const [, days, hours, minutes, seconds] = match.map((val) =>
      val ? Number.parseInt(val, 10) : 0,
    );

    if (days) return `${days} ${days === 1 ? 'day' : 'days'}`;
    if (hours) return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    if (minutes) return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    if (seconds) return `${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;

    return '0 seconds';
  }
}
