import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import {
  Category,
  type ICategory,
} from '@smack/core/api/models/categories/Category';
import { CommentsManager } from '@smack/core/api/models/comments';
import { MediasManager } from '@smack/core/api/models/medias';
import {
  NotificationCounter,
  type NotificationCounterApiOutput,
} from '@smack/core/api/models/objects/NotificationCounter/NotificationCounter';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { IListElementAction } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseListElement';
import type { BaseObjectListElementProps } from '@smack/core/components/DataDisplay/Lists/ListElements/BaseObjectListElement';
import type { ILegend } from '@smack/core/store/app/types';
import { isObject } from '@smack/core/utils';
import {
  FiltersQueryParams,
  type IFilters,
  type QueryParamsMode,
  getParamsFromFilters,
  queryParamsByMode,
} from '@smack/core/utils/Filters';
import type { IAddLinkFormOutput } from '@smack/core/views/oldViewsToSort/Layouts/Forms/AddLinks/AddLinkForm/AddLinkForm';
import type { AxiosResponse } from 'axios';
import qs from 'qs';
import type { ReactNode } from 'react';

export interface BaseObjectBottomAttributes {
  value?: string;
  icon?: IconField;
  rightIcon?: IconField;
  tooltip?: string;
}

export interface BaseObjectBadgeAttribute {
  value?: string;
  color?: string;
  icon?: IconField;
}

export interface BaseObjectDisplayAttributes {
  title?: string;
  subtitle?: string | ReactNode;
  badge?: BaseObjectBadgeAttribute;
  bottomRight?: BaseObjectBottomAttributes;
  bottomLeft?: BaseObjectBottomAttributes;
}

export interface ICommonBaseObject extends BaseObjectDisplayAttributes {
  id: number;
  code?: string;
  label?: string;
  category?: ICategory;
  description?: string;
  address?: string;
  color?: string;
  startAt?: Date;
  endAt?: Date;
  // Not "model fields" but custom fields for representation
  title?: string;
  subtitle?: string;
  frontEndpoint?: string;
  linkId?: number;
  bookmarkId?: number;
  isWritable?: boolean;
  scheduleId?: number;
}

/**
 * Abstract CommonBaseObject class for BaseObjectModel.
 * Has only static common utility methods and non-static list related methods.
 *
 * @export
 * @class BaseObject
 */
export abstract class CommonBaseObject<IBaseObjectT extends ICommonBaseObject>
  implements ICommonBaseObject
{
  static objectPaginationSize = 20;

  mediasManager: MediasManager;

  commentsManager: CommentsManager;

  id: number;

  code?: string;

  label?: string;

  category?: Category;

  description?: string;

  address?: string;

  color?: string;

  startAt?: Date;

  endAt?: Date;

  scheduleId?: number;

  // Not "model fields" but custom fields for representation
  #title?: string;

  #subtitle?: string;

  bottomLeft?: BaseObjectBottomAttributes;

  bottomRight?: BaseObjectBottomAttributes;

  badge?: BaseObjectBadgeAttribute;

  frontEndpoint?: string;

  linkId?: number;

  isWritable?: boolean;

  constructor(data: IBaseObjectT) {
    this.mediasManager = new MediasManager(
      `/objects/baseobjects/${data.id || ''}`,
    );
    this.commentsManager = new CommentsManager(
      `/objects/baseobjects/${data.id || ''}`,
    );

    this.id = data.id;
    this.code = data.code;
    this.label = data.label;
    if (data.category) {
      this.category = new Category(data.category);
    }
    this.description = data.description;
    this.address = data.address;
    this.color = data.color;
    if (data.startAt) this.startAt = new Date(data.startAt);
    if (data.endAt) this.endAt = new Date(data.endAt);
    this.#title = data.title;
    this.#subtitle = data.subtitle;
    this.bottomRight = data.bottomRight;
    this.bottomLeft = data.bottomLeft;
    this.badge = data.badge;
    this.frontEndpoint = data.frontEndpoint;
    this.linkId = data.linkId;
    this.isWritable = data.isWritable;
    this.scheduleId = data.scheduleId;
  }

  static getStringifiedQueryParamsFromFilter = (
    mode: QueryParamsMode,
    rootCategory?: Category,
    parentCategory?: Category,
    filters?: IFilters,
    links?: { linkGroupId: number; sourceBaseObjectId: number },
    limit = CommonBaseObject.objectPaginationSize,
    offset?: number,
  ): string => {
    const params = Object.fromEntries(
      Object.entries(
        getParamsFromFilters(filters ?? {}, queryParamsByMode[mode], {
          links: links,
          limit,
          offset,
          parentCategory: parentCategory?.id,
        }),
      ).map(([key, value]) => [
        key,
        // We don't want qs to encode objects
        isObject(value) ? JSON.stringify(value) : value,
      ]),
    );
    return qs.stringify(params, { arrayFormat: 'brackets' });
  };

  /**
   * Get BaseObject legend
   *
   * @static
   * @param {Category} rootCategory
   * @param {Category} parentCategory
   * @param {IFilters} [filters]
   * @param {number} [offset]
   * @return {*}  {Promise<{
   *     data: {
   *       count: number;
   *       next: string;
   *       previous: string;
   *       results: ILegend[];
   *     };
   *   }>}
   * @memberof CommonBaseObject
   */
  static getBaseObjectLegend(
    parentCategory?: Category,
    filters?: IFilters,
    signal?: AbortSignal,
  ): Promise<{
    data: {
      count: number;
      next: string;
      previous: string;
      results: ILegend[];
    };
  }> {
    return RESTClient.get<{
      data: {
        count: number;
        next: string;
        previous: string;
        results: ILegend[];
      };
    }>(
      '/objects/baseobjects/legend',
      getParamsFromFilters(
        filters ?? {},
        queryParamsByMode.map.filter(
          (a) => a !== FiltersQueryParams.PAGINATION,
        ),
        {
          parentCategory: parentCategory?.id,
        },
      ),
      undefined,
      signal,
    );
  }

  static getNotificationCounters(): Promise<NotificationCounter[]> {
    return RESTClient.get<{
      data: { results: NotificationCounterApiOutput[] };
    }>('/objects/notifications/counters').then((res) => {
      return res.data.results.map(
        (notificationCounter) => new NotificationCounter(notificationCounter),
      );
    });
  }

  getListElement(
    action?: IListElementAction,
    active = false,
  ): BaseObjectListElementProps {
    return {
      ...this,
      action,
      active,
      title: this.title,
      subtitle: this.subtitle,
      date: this.startAt,
      icon: this.category?.icon,
      link: this.frontEndpoint,
      key: this.id,
      isLoader: false,
    };
  }

  // TODO: Strong-type create and patch baseobject API input
  /**
   * Creation baseobject request
   * @static
   * @param {{
   *     [key: string]: any;
   *   }} data
   * @return {*}  {Promise<{ id: number }>}
   * @memberof BaseObject
   */
  static createBaseObject(
    data: Record<string, unknown>,
  ): Promise<{ id: number }> {
    return RESTClient.post<{ results: { id: number } }>(
      data,
      '/objects/baseobjects',
    ).then((res) => res.data.results);
  }

  static patchBaseObject(
    baseObjectId: number,
    data: Record<string, unknown>,
  ): Promise<{ id: number }> {
    return RESTClient.patch<{ results: { id: number } }>(
      data,
      `/objects/baseobjects/${baseObjectId}`,
    ).then((res) => res.data.results);
  }

  static patchBaseObjectBatch(
    categoryId: number,
    data: Record<string, unknown>,
    filters?: IFilters,
  ): Promise<AxiosResponse> {
    return RESTClient.patch<AxiosResponse>(data, '/objects/baseobjects', {
      category: categoryId,
      ...(filters
        ? getParamsFromFilters(filters, [
            FiltersQueryParams.SEARCH,
            FiltersQueryParams.ATTRIBUTE_FILTER,
            FiltersQueryParams.DATE_RANGE,
            FiltersQueryParams.CATEGORY_IN,
          ])
        : {}),
    });
  }

  addLink(data: IAddLinkFormOutput): Promise<AxiosResponse<void>[]> {
    const promises: Promise<AxiosResponse<void>>[] = [];

    for (const baseobject of data.baseObjects) {
      promises.push(
        RESTClient.post<void>(
          {
            linkGroupId: data.linkGroup.id,
            sourceBaseobjectId: this.id,
            targetBaseobjectId: baseobject.id,
            datetimeRange: data.datetimeRange,
            weight: data.weight,
            objectDependantLinkGroupId: data.objectDependantLinkGroup,
            attributes: data.attributes,
          },
          '/objects/links',
        ),
      );
    }

    return Promise.all(promises);
  }

  removeLink(linkId: number): Promise<void> {
    return RESTClient.delete(`/objects/links/${linkId}`);
  }

  get title() {
    return this.#title || '-';
  }

  get subtitle() {
    return this.#subtitle || '-';
  }
}
