import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { IconButton } from '@smack/core/components/Actions/Buttons/Button';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { useTranslation } from 'react-i18next';
import { shouldColumnBeChecked } from './utils';

export const Column = ({
  column,
  hiddenColumns,
  onCheckboxChange,
  columnPinning,
  onPinningChange,
}) => {
  const { t } = useTranslation();
  const { id, accessorKey, header } = column;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
      className="sortable-item flex items-center p-2 [&&]:border-b-[1px] last:border-0 pl-3"
    >
      <CheckboxInput
        data-testid={`column:${id}`}
        key={`column:${id}`}
        value={shouldColumnBeChecked(column, hiddenColumns)}
        onChange={(value: boolean) => onCheckboxChange([accessorKey], !value)}
        prefixIcon={{
          name: 'grip-dots',
          className: '[&&]:pl-2',
        }}
        className={{
          container: 'p-1 pl-5 w-full',
          subContainer: '[&&]:pl-6',
          label: 'text-text',
          labelContainerClassName: 'flex grow self-center',
        }}
        label={header}
        suffixElement={
          <>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                onPinningChange(accessorKey, 'left');
              }}
              icon={{ name: 'arrow-left-to-line', familyStyle: 'far' }}
              title={
                columnPinning.left.includes(accessorKey)
                  ? t('objectTable.unpin')
                  : t('objectTable.pinLeft')
              }
              className={`${
                columnPinning.left.includes(accessorKey)
                  ? '[&]:bg-gray-300 [&]:hover:bg-gray-300 mr-1'
                  : 'mr-1'
              }`}
            />
            <IconButton
              onClick={() => {
                onPinningChange(accessorKey, 'right');
              }}
              icon={{ name: 'arrow-right-to-line', familyStyle: 'far' }}
              title={
                columnPinning.right.includes(accessorKey)
                  ? t('objectTable.unpin')
                  : t('objectTable.pinRight')
              }
              className={
                columnPinning.right.includes(accessorKey)
                  ? '[&]:bg-gray-300 [&]:hover:bg-gray-300'
                  : ''
              }
            />
          </>
        }
      />
    </div>
  );
};

export default Column;
