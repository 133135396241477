import {
  Attribute,
  type IAttributeApiOutput,
} from '@smack/core/api/models/categories/Attribute/Attribute';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { TabularBaseObject } from '@smack/core/api/models/objects/NewBaseObject/TabularBaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import {
  type IViewElement,
  ViewElement,
} from '@smack/core/api/models/views/ViewElement/ViewElement';
import { DisplayedFieldType } from '@smack/core/api/models/views/ViewElement/enums';
import type { ViewSection } from '@smack/core/api/models/views/ViewSection/ViewSection';
import type {
  DnDElementProps,
  DndSectionsProps,
} from '@smack/core/components/DataDisplay/Lists/DnDList/NestedLists';
import type { ICellType } from '@smack/core/components/DataDisplay/Table/Table';
import { ViewElementRenderer } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRenderer';
import { attributeFieldsByType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeFieldsByType/AttributeFieldsByType';
import type { CellContext, Column, ColumnDef } from '@tanstack/react-table';
import type React from 'react';
import type { CSSProperties } from 'react';

export type ColumnPinning = {
  left: string[];
  right: string[];
};

type ReorderColumnsOutput = {
  newSections: DndSectionsProps<DnDElementProps>[];
  newColumnOrder: string[];
};

export const RenderCell = (
  cellProps: CellContext<ICellType, unknown>,
  viewElement: ViewElement,
): React.ReactNode => {
  if (!cellProps.getValue()) return <>-</>;
  const attribute: Attribute = new Attribute(
    cellProps.getValue() as IAttributeApiOutput,
  );
  let displayValue = attributeFieldsByType.find(
    (d) => d.type === viewElement.fieldType,
  );
  if (!displayValue) {
    displayValue = attributeFieldsByType.find(
      (d) => d.type === DisplayedFieldType.READ_TEXT,
    );
  }

  return displayValue?.render(attribute, attribute.values, {
    viewElement,
    viewUsage: ViewUsage.TABLE,
    baseObject: cellProps.row.original.baseObject,
  });
};

export const getColumns = (
  sections: ViewSection[],
  columnWidth?: Record<string, number>,
  showSectionHeaders = false,
  useViewElRenderer = false,
  enforcedColumnSize?: number | undefined,
): ColumnDef<ICellType>[] => {
  const data: ColumnDef<ICellType>[] = [];
  if (!sections?.length) return data;
  sections.forEach((section) => {
    const elementsColumns: ColumnDef<ICellType>[] = [];
    const sectionData: ColumnDef<ICellType> = {
      header: section.label,
      columns: elementsColumns,
    };
    section.viewElements?.forEach((viewElement) => {
      if (!viewElement.attribute) return;

      let size: number | undefined = undefined;
      if (columnWidth?.[viewElement.id.toString()]) {
        size = columnWidth[viewElement.id.toString()];
      }

      if (enforcedColumnSize) {
        size = enforcedColumnSize;
      }

      const col: ColumnDef<ICellType> = {
        header: viewElement.label,
        accessorKey: viewElement.id.toString(),
        cell: (cellProps) =>
          useViewElRenderer ? (
            <ViewElementRenderer
              props={{}}
              viewElement={
                new ViewElement(cellProps.getValue() as IViewElement)
              }
            />
          ) : (
            RenderCell(cellProps, viewElement)
          ),
      };

      if (size) col.size = size;
      elementsColumns.push(col);
    });
    if (showSectionHeaders) {
      data.push(sectionData);
    } else {
      data.push(...elementsColumns);
    }
  });
  return data;
};

export const GetObjectsData = (
  objects: TabularBaseObject[],
  sections: ViewSection[],
  activeobjectId?: string,
  activeScheduleId?: string,
): ICellType[] => {
  const data: ICellType[] = [];
  objects?.forEach((object) => {
    let isActive = object.id.toString() === activeobjectId;
    if (activeScheduleId) {
      isActive = object.scheduleId?.toString() === activeScheduleId;
    }
    const cell = {
      printableId: `${object.id}${
        object.scheduleId ? `/${object.scheduleId.toString()}` : ''
      }`,
      baseObject: object as BaseObject,
      frontEndpoint: object.frontEndpoint,
      isRawActive: isActive,
    };
    // TODO when back end ready use baseobject viewElement to fill the cell
    for (const section of sections) {
      for (const viewElement of section.viewElements) {
        if (viewElement.attribute) {
          cell[viewElement.id] = object.attributes?.find(
            (att) => att.id === viewElement.attribute?.id,
          );
        }
      }
    }
    data.push(cell);
  });
  return data;
};

export const getCumulativeWidth = (
  column: Column<ICellType>,
  table,
  columnPinning: Record<string, string[]>,
): number => {
  const isPinned = column.getIsPinned();
  if (!isPinned) return 0;

  const pinnedColumnIds = columnPinning[String(isPinned)] || [];
  const columnIndex = column.getPinnedIndex();
  const isLeafColumn = (column: Column<ICellType>): boolean =>
    !column.columns || column.columns.length === 0;

  const leafPinnedColumnIds = pinnedColumnIds.filter((columnId) => {
    const pinnedColumn = table.getColumn(columnId);
    return pinnedColumn && isLeafColumn(pinnedColumn);
  });

  return leafPinnedColumnIds
    .slice(0, columnIndex)
    .reduce((totalWidth, columnId) => {
      const pinnedColumn = table.getColumn(columnId);
      return totalWidth + (pinnedColumn?.getSize() || 0);
    }, 0);
};

export const getCommonPinningStyles = (
  column: Column<ICellType>,
  width,
  pinnedColumns,
  table,
): CSSProperties => {
  const isPinned = column.getIsPinned();
  const cumulativeWidth = getCumulativeWidth(column, table, pinnedColumns);
  const pinnedLeftColumns = pinnedColumns.left || [];
  const isLastLeftPinnedColumn =
    isPinned === 'left' &&
    column.getPinnedIndex() === pinnedLeftColumns.length - 1;
  const pinnedRightColumns = pinnedColumns.right || [];
  const isFirstRightPinnedColumn =
    isPinned === 'right' &&
    column.getPinnedIndex() === pinnedRightColumns.length - 1;

  return {
    boxShadow: isLastLeftPinnedColumn
      ? '-4px 0 4px -4px gray inset'
      : isFirstRightPinnedColumn
        ? '4px 0 4px -4px gray inset'
        : undefined,
    left: isPinned === 'left' ? `${cumulativeWidth}px` : undefined,
    right: isPinned === 'right' ? `${cumulativeWidth}px` : undefined,
    position: isPinned ? 'sticky' : 'relative',
    width: `${width}px`,
    zIndex: isPinned ? 1 : 0,
    backgroundColor: isPinned ? 'white' : 'transparent',
  };
};
