import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import {
  ButtonGroup,
  ToolBar,
  WysiwygInput,
} from '@smack/core/components/DataInput/WysiwygInput/WysiwygInput';
import React, { type FormEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

export interface IProps {
  content: string;
  onSubmit: (content: string) => Promise<void>;
  onCancel: () => void;
}

export const CommentUpdateForm: React.FC<IProps> = ({
  content,
  onSubmit,
  onCancel,
}) => {
  const [t] = useTranslation();

  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [isModified, setIsModified] = React.useState(false);
  const [commentContent, setCommentContent] = React.useState(content);

  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (isSubmitting) return;
    setIsSubmitting(true);
    onSubmit(commentContent).finally(() => setIsSubmitting(false));
  };

  const onHandleChange = (value: string): void => {
    setIsModified(value !== content);
    setCommentContent(value);
  };

  return (
    <form onSubmit={handleSubmit} data-testid="comment-update-form">
      <WysiwygInput
        defaultValue={content}
        name="comment"
        width="100%"
        height={200}
        toolbar={
          new ToolBar([
            new ButtonGroup(['undo', 'redo']),
            new ButtonGroup(['bold', 'italic']),
            new ButtonGroup(['bullist', 'numlist']),
            new ButtonGroup(['link']),
          ])
        }
        onChange={onHandleChange}
      />
      <div className="h-12 flex justify-end items-center px-1 gap-2">
        <CancelButton
          title={t('')}
          onClick={onCancel}
          data-testid="CommentUpdateFormCancelButton"
        />
        <SaveButton
          type="submit"
          isLoading={isSubmitting}
          disabled={isSubmitting || !isModified}
        />
      </div>
    </form>
  );
};
