import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { ViewUsage } from '@smack/core/api/models/views/BaseObjectView/enum';
import { AutoBaseObjectView } from '@smack/core/components/ViewRenderer/AutoBaseObjectView';
import { useScheduleId } from '@smack/core/hooks/useScheduleId/useScheduleId';
import { BaseObjectPanelFollowsRouter } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/Follows';
import { BaseObjectPanelLinksRouter } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/Links';
import { BaseObjectPanelMediasRouter } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Routes/Medias';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

interface IProps {
  baseObject: BaseObject;
  hasPreview?: boolean;
}

export const BaseObjectPanelRouter = (props: IProps): JSX.Element => {
  const { baseObject, hasPreview } = props;
  const { search } = useLocation();
  const [scheduleId] = useScheduleId();

  return (
    <Routes>
      <Route
        index
        key={'index'}
        element={
          <Navigate
            replace
            to={(hasPreview ? 'preview' : 'details') + search}
          />
        }
      />
      <Route
        path="preview/*"
        element={
          hasPreview ? (
            <AutoBaseObjectView
              baseObject={baseObject}
              viewUsage={ViewUsage.PREVIEW}
            />
          ) : (
            <Navigate replace to={`../details${search}`} />
          )
        }
      />
      <Route
        path="details/*"
        element={
          <AutoBaseObjectView
            scheduleId={scheduleId}
            baseObject={baseObject}
            viewUsage={ViewUsage.DETAILS}
          />
        }
      />
      <Route
        path="links/*"
        element={<BaseObjectPanelLinksRouter baseObject={baseObject} />}
      />
      <Route
        path="medias/*"
        element={<BaseObjectPanelMediasRouter baseObject={baseObject} />}
      />
      <Route
        path="follows/*"
        element={<BaseObjectPanelFollowsRouter baseObject={baseObject} />}
      />
    </Routes>
  );
};
