import type { TaskLog } from '@smack/core/api/models/tasks/TaskLog';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { type ILogFeedProps, LogFeed } from '../../LogsFeed';

export const TaskLogDateFeed = (props: ILogFeedProps<TaskLog>): JSX.Element => {
  const { log } = props;

  const { t } = useTranslation();

  const getFieldName = (): string => {
    const field = log?.content?.taskField;
    switch (field) {
      case 'start_at':
        return 'logs.dateFields.startAt';
      case 'end_at':
        return 'logs.dateFields.endAt';
      case 'deadline_at':
        return 'logs.dateFields.deadlineAt';
      default:
        return '';
    }
  };

  const getLogContent = (): string => {
    const oldField = log?.content?.taskFieldOldValue;
    const newField = log?.content?.taskFieldNewValue;

    if (newField) {
      return t(
        oldField
          ? 'logs.action.modifiedDateField'
          : 'logs.action.addedDateField',
        {
          fieldName: t(getFieldName()),
          newValue: format(new Date(newField), t('logs.dateFormat')),
          oldValue: oldField
            ? format(new Date(oldField), t('logs.dateFormat'))
            : undefined,
        },
      );
    }

    return t('logs.action.modifiedDateFieldWithoutDates', {
      fieldName: t(getFieldName()),
    });
  };

  return (
    <LogFeed {...props} icon={{ name: 'calendar' }}>
      <span className="text-sm font-normal"> {getLogContent()}</span>
    </LogFeed>
  );
};
