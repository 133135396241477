import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { Attribute } from '@smack/core/api/models/categories/Attribute/Attribute';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import type { Reminder } from '@smack/core/api/models/reminders/Reminder';
import {
  type IMessage,
  MessageType,
  type ReminderRule,
} from '@smack/core/api/models/reminders/ReminderRule';
import { Group } from '@smack/core/api/models/users/Group';
import { User } from '@smack/core/api/models/users/User';
import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Icon } from '@smack/core/components/DataDisplay/Icon/Icon';
import { CheckboxInput } from '@smack/core/components/DataInput/CheckboxInput';
import { DateInput } from '@smack/core/components/DataInput/DateInput';
import { Input } from '@smack/core/components/DataInput/Input/Input';
import { RRuleGenerator } from '@smack/core/components/DataInput/RecurrenceInput/Components/RRuleGenerator';
import { SelectInput } from '@smack/core/components/DataInput/SelectInput/SelectInput';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import { TextAreaInput } from '@smack/core/components/DataInput/TextAreaInput';
import { AttributeType } from '@smack/core/components/ViewRenderer/renderers/ViewElementRenderer/ViewElementRendererByType/AttributeViewElementRenderer/AttributeValueFromType/AttributeValueFromType';
import type { AppState } from '@smack/core/store';
import { isObject } from '@smack/core/utils';
import { ReminderDurationField } from '@smack/core/views/oldViewsToSort/Layouts/LeftPanel/DetailsPanel/Pages/Reminder/components/DurationField';
import { parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

export interface IReminderFormOutput {
  subject?: string;
  content?: string;
  delay?: string;
  delayFromAttributeId?: number;
  datetime?: string;
  recurrence?: string;
  isMail?: boolean;
  isWebappPush?: boolean;
  userRecipients?: { user: number }[];
  groupRecipients?: { group: number }[];
}

interface IProps {
  baseObject?: BaseObject;
  reminderRule?: ReminderRule;
  reminder?: Reminder;
  onSubmit: (data: IReminderFormOutput) => void;
  onClose: () => void;
}

/**
 * Reminder rule form components
 * @param props IProps
 * @returns JSX.Element
 */
export const ReminderForm = (props: IProps): JSX.Element => {
  const [t] = useTranslation();
  const { onSubmit, onClose, baseObject, reminderRule, reminder } = props;
  const {
    watch,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { isSubmitted, errors },
  } = useForm();
  const { current } = useSelector((app: AppState) => app.User);
  const isCategoryAdmin =
    current?.isAdmin || baseObject?.category?.isManageable || false;
  const [attributes, setAttributes] = React.useState<Attribute[]>([]);
  const [searchUser, setSearchUser] = React.useState<string>('');
  const [groupOptions, setGroupOptions] = React.useState<Option<number>[]>([]);
  const [groupSearchOptions, setGroupSearchOptions] = React.useState<
    Option<number>[]
  >([]);
  const [whenChoice, setWhenChoice] = React.useState(0);
  const [dt, setDt] = React.useState(
    reminderRule?.delayFromValue || reminder?.reminderDatetime,
  );

  const mail = watch('isMail');
  const webAppPush = watch('isWebappPush');
  const isChannelSelected = React.useMemo(
    // NOTE: undefined || undefined returns undefined, not 'false'
    () => Boolean(mail) || Boolean(webAppPush),
    [mail, webAppPush],
  );

  const onSubmitForm = (form: {
    [key: string]:
      | string
      | undefined
      | { value: string }
      | Record<string | number, null>[];
  }): void => {
    if (!isChannelSelected) return;
    const newvalue: IReminderFormOutput = {};
    for (const key of Object.keys(form)) {
      let val = form[key];
      // NOTE: prevent to send 'null' value (raise a backend error)
      // (specifically for 'recurrence' field)
      if (val === null) val = undefined;
      if (Array.isArray(val)) {
        newvalue[key] = val.map((r) => {
          switch (key) {
            case 'userRecipients':
              return { user: r.value };
            case 'groupRecipients':
              return { group: r.value };
            default:
              break;
          }
        });
      } else if (isObject(val) && val.value) {
        newvalue[key] = val.value;
      } else {
        newvalue[key] = val as string;
      }
    }
    if (whenChoice === 0) {
      newvalue.datetime = undefined;
      newvalue.recurrence = undefined;
    } else {
      newvalue.delay = undefined;
      newvalue.delayFromAttributeId = undefined;
    }
    onSubmit(newvalue);
  };

  React.useEffect(() => {
    if (baseObject?.category?.id) {
      Attribute.getByTypeAndCategoryId(
        baseObject?.category?.id,
        AttributeType.DATETIME,
      ).then(setAttributes);
    }
    const category = baseObject?.category;
    if (category?.id) {
      Group.getGroupsFromCategoryPermissionId(category.id).then((groups) => {
        const options = groups.map((g) => {
          return {
            label: g.label,
            value: g.id,
          };
        });
        setGroupOptions(options);
        setGroupSearchOptions(options);
      });
    }
  }, [baseObject]);

  const onQuit = (): void => {
    setWhenChoice(0);
    reset();
    onClose();
  };

  const getAttributes = (): Option[] => {
    return attributes?.map((a) => {
      return {
        label: a.label,
        value: a.id,
      };
    });
  };

  const searchGroups = (value: string): void => {
    const lowerValue = value.toLowerCase();
    const options = groupOptions.filter((option) => {
      return option.label.toString().toLowerCase().includes(lowerValue);
    });
    setGroupSearchOptions(options);
  };

  const setValuesFromMessages = (messages?: IMessage[]): void => {
    if (!messages) return;

    const userRecipients: Option[] = [];
    const groupRecipients: Option[] = [];
    messages.forEach((m) => {
      if (m.type === MessageType.EMAIL) setValue('isMail', true);
      if (m.type === MessageType.WEBAPP_PUSH) setValue('isWebappPush', true);

      m.recipients.forEach((r) => {
        if (r.user) {
          const user = new User(r.user);
          if (!userRecipients.find((v) => v.value === user.id)) {
            userRecipients.push({
              label: user.getFullName(),
              value: user.id,
            });
          }
        } else if (r.group) {
          const group = new Group(r.group);
          if (!groupRecipients.find((v) => v.value === group.id)) {
            groupRecipients.push({
              label: group.label,
              value: group.id,
            });
          }
        }
      });
    });
    setValue('userRecipients', userRecipients);
    setValue('groupRecipients', groupRecipients);
  };

  React.useEffect(() => {
    if (reminderRule) {
      setValue('subject', reminderRule.subject);
      setValue('content', reminderRule.content);
      setValue('datetime', reminderRule.delayFromValue);
      setValue('recurrence', reminderRule.recurrence);
      setValue('delay', reminderRule.delay);
      setValue(
        'delayFromAttributeId',
        getAttributes().find(
          (a) => a.value === reminderRule.delayFromAttribute?.id,
        ),
      );
      setValuesFromMessages(reminderRule.messages);

      if (reminderRule.recurrence) {
        setWhenChoice(1);
      } else {
        setWhenChoice(0);
      }
    } else if (reminder) {
      setValue('subject', reminder.subject);
      setValue('content', reminder.content);
      setValue('datetime', reminder.reminderDatetime.toISOString());

      setValuesFromMessages(reminder.messages);

      setWhenChoice(1);
    }
  }, [reminder, reminderRule, attributes]);

  const [userSearchOptions, setUserSearchOptions] = useState<Option[]>([]);
  const [isUserInfiniteScrollOver, setIsUserInfiniteScrollOver] =
    useState<boolean>(false);
  const [isUserLoading, setIsUserLoading] = useState<boolean>(false);

  const handleUserSearch = (value: string) => {
    setSearchUser(value);
    User.getUsers(searchUser, 10).then((users) => {
      setUserSearchOptions(users.map((u) => u.toOption()));
      setIsUserInfiniteScrollOver(users.length === 0);
    });
  };

  const handleUserInfiniteScroll = () => {
    if (!isUserInfiniteScrollOver) {
      setIsUserLoading(true);
      User.getUsers(searchUser, 10, undefined, userSearchOptions.length).then(
        (users) => {
          setUserSearchOptions([
            ...userSearchOptions,
            ...users.map((u) => u.toOption()),
          ]);
          setIsUserInfiniteScrollOver(users.length === 0);
          setIsUserLoading(false);
        },
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <p className="block mt-5 text-sm font-medium text-gray-700 dark:text-gray-200 mb-1">
        {t('reminders.form.when')}
      </p>
      <div className="pl-8">
        <div className="flex items-center my-4">
          <input
            type="radio"
            onChange={(): void => setWhenChoice(0)}
            checked={whenChoice === 0}
            className="h-5 w-5 mr-3 border-gray-500 border-[2px] text-blue-500 focus:ring-blue-500"
            data-testid="choice0"
          />
          <div
            onClick={(): void => {
              if (whenChoice !== 0) {
                setWhenChoice(0);
              }
            }}
            className={`flex items-center gap-3 ${
              whenChoice !== 0 ? 'opacity-30' : ''
            }`}
          >
            <Controller
              name="delay"
              control={control}
              rules={{ required: whenChoice === 0 }}
              render={({ field }): JSX.Element => (
                <ReminderDurationField
                  onChange={field.onChange}
                  value={field.value}
                />
              )}
            />

            <Controller
              name="delayFromAttributeId"
              control={control}
              rules={{ required: whenChoice === 0 }}
              render={({ field }): JSX.Element => (
                <SelectInput
                  className="w-[200px]"
                  options={getAttributes()}
                  disabled={whenChoice !== 0}
                  {...field}
                />
              )}
            />
          </div>
        </div>
        {/* Display delay error only if selected */}
        {whenChoice === 0 && (
          <p className="text-red-500 text-sm">
            {/* Both delay and attribute fields */}
            {errors.delay &&
              errors.delayFromAttributeId &&
              t('reminders.form.fieldRequired')}
            {/* Only delay */}
            {errors.delay &&
              !errors.delayFromAttributeId &&
              t('reminders.form.delayFieldRequired')}
            {/* Only delay attribute */}
            {!errors.delay &&
              errors.delayFromAttributeId &&
              t('reminders.form.delayFromAttributeFieldRequired')}
          </p>
        )}
        <div className="flex items-center justify-center">
          <div className="border-b text-sm text-gray-600 flex-grow" />
          <p className="mx-3 text-gray-500 text-sm">{t('reminders.form.or')}</p>
          <div className="border-b text-sm text-gray-600 flex-grow" />
        </div>
        <div className="flex items-center my-5">
          <input
            type="radio"
            onChange={(): void => setWhenChoice(1)}
            checked={whenChoice === 1}
            className="h-5 w-5 mr-3  border-gray-500 border-[2px] text-blue-500 focus:ring-blue-500"
            data-testid="choice1"
          />
          <div
            onClick={(): void => {
              if (whenChoice !== 1) {
                setWhenChoice(1);
              }
            }}
            className={`flex items-center gap-3 ${
              whenChoice !== 1 ? 'opacity-30' : ''
            }`}
          >
            <Controller
              name="datetime"
              control={control}
              rules={{ required: whenChoice === 1 }}
              render={({ field }): JSX.Element => {
                return (
                  <DateInput
                    value={field.value}
                    placeholder={t('reminders.form.onDate')}
                    error={
                      errors.reminderDatetime
                        ? t('reminders.form.fieldRequired')
                        : ''
                    }
                    onChange={(val): void => {
                      setDt(val ? parseISO(val) : undefined);
                      field.onChange(val);
                    }}
                  />
                );
              }}
            />
            {!!dt && (
              <Controller
                name="recurrence"
                control={control}
                render={({ field }): JSX.Element => (
                  <RRuleGenerator
                    onChange={field.onChange}
                    value={field.value}
                    dtStart={dt ? new Date(dt) : undefined}
                    includeDtStart={false}
                  />
                )}
              />
            )}
          </div>
        </div>
        {/* Display datetime error only if selected */}
        {whenChoice === 1 && errors.datetime && (
          <p className="text-red-500 text-sm">
            {t('reminders.form.fieldRequired')}
          </p>
        )}
      </div>

      <p className="block mt-5 text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
        {t('reminders.form.how')}
      </p>
      <div className="pl-8">
        <div className="mb-4">
          <Controller
            control={control}
            defaultValue={false}
            name="isWebappPush"
            render={({ field }): JSX.Element => (
              <CheckboxInput
                {...field}
                label={t('reminders.form.isWebAppPush')}
              />
            )}
          />
        </div>
        <div className="mb-4">
          <Controller
            control={control}
            defaultValue={false}
            name="isMail"
            render={({ field }): JSX.Element => (
              <CheckboxInput {...field} label={t('reminders.form.isMail')} />
            )}
          />
        </div>
      </div>
      {!isChannelSelected && isSubmitted && (
        <p className="text-sm text-red-500">
          {t('reminders.form.oneChannelRequired')}
        </p>
      )}

      {isChannelSelected && (
        <div id="content-recipients-container">
          <p className="block mt-5 text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            {t('reminders.form.who')}
          </p>
          <div className="pl-8 max-w-xl flex gap-3">
            <Controller
              control={control}
              name="userRecipients"
              render={({ field }): JSX.Element => (
                <SelectInput
                  {...field}
                  multiple
                  onSearch={handleUserSearch}
                  onLastOptionVisible={handleUserInfiniteScroll}
                  options={userSearchOptions}
                  isLoading={isUserLoading}
                  placeholder={t('reminders.form.userRecipients')}
                  needFilter={false}
                />
              )}
            />
            <Controller
              control={control}
              name="groupRecipients"
              render={({ field }): JSX.Element => (
                <SelectInput
                  {...field}
                  multiple
                  onSearch={searchGroups}
                  disabled={!isCategoryAdmin}
                  className={isCategoryAdmin ? '' : 'opacity-70'}
                  options={groupSearchOptions}
                  placeholder={t('reminders.form.groupRecipients')}
                  needFilter={false}
                />
              )}
            />
            {!isCategoryAdmin && (
              <span className="content-center">
                <Icon
                  icon={{
                    name: 'circle-info',
                    familyStyle: 'fal',
                  }}
                  title={t('reminders.form.groupRecipientNoPermissionMessage')}
                  className="content-center"
                />
              </span>
            )}
          </div>
          <p className="block mt-5 text-sm font-medium text-gray-700 dark:text-gray-200 mb-2">
            {t('reminders.form.what')}
          </p>
          <div className="pl-8">
            <div className="mb-4">
              <Controller
                name="subject"
                control={control}
                defaultValue={baseObject?.title ?? ''}
                render={({ field }): JSX.Element => (
                  <Input
                    {...field}
                    placeholder={t('reminders.form.subject')}
                    error={
                      errors.label ? t('reminders.form.fieldRequired') : ''
                    }
                  />
                )}
              />
            </div>
            <div className="mb-4">
              <Controller
                name="content"
                control={control}
                defaultValue={t('reminders.content', {
                  title: baseObject?.title ?? '',
                })}
                render={({ field }): JSX.Element => (
                  <TextAreaInput
                    {...field}
                    placeholder={t('reminders.form.content')}
                  />
                )}
              />
            </div>
          </div>
        </div>
      )}

      <div className="flex items-center justify-end">
        <CancelButton onClick={onQuit} className="mr-2" />
        <SaveButton type="submit" data-testid="reminderFormCreateButton" />
      </div>
    </form>
  );
};
