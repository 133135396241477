import { captureException } from '@sentry/react';
import { DefaultGeoJSON } from '@smack/core/api/models/categories/Category';
import type { ParentCategory } from '@smack/core/api/models/categories/Category/ParentCategory';
import type { SubCategory } from '@smack/core/api/models/categories/Category/SubCategory';
import { useCategoryPreferencesByView } from '@smack/core/hooks/preferences/useCategoryPreferencesByView/useCategoryPreferencesByView';
import { useMapBounds } from '@smack/core/hooks/useMapBounds';
import { useSearchParams } from '@smack/core/hooks/useSearchParams';
import { ViewName } from '@smack/core/hooks/views/types';
import { useActiveView } from '@smack/core/hooks/views/useActiveView/useActiveView';
import { setDefaultFilters } from '@smack/core/store/app/actions';
import { useActiveCategories } from '@smack/core/utils/ActiveCategories';
import { FiltersSerializer, type IFilters } from '@smack/core/utils/Filters';
import React from 'react';
import type { DecodedValueMap } from 'use-query-params';

export interface IUseActiveFiltersOutput {
  filters: IFilters | undefined;
  setFilters: (filters: IFilters) => void;
}

export const useActiveFilters = (): IUseActiveFiltersOutput => {
  const [search, setSearch] = useSearchParams();
  const [rootCategory, setCategory, subSetCategory] = useActiveCategories();
  const bound = useMapBounds({ map: window.Hyvilo.Utils.MainMap });
  const [activeView] = useActiveView();
  const [preferences, setPreferences] = useCategoryPreferencesByView();

  // NOTE: New Filters should be included in this list
  const updateDependencies = [
    search.filtersSearch,
    search.filtersShowGeom,
    search.filtersCategoryIn,
    search.filtersCurrentCategoryId,
    search.filtersReportState,
    search.filtersViewedNotifications,
    search.filtersPeriod,
    search.filtersDraw,
    search.filtersContainingFeatures,
    search.filtersScreen,
    search.filtersFilteringLayer,
    search.filtersVisualizedAttributes,
    search.filtersFilteredAttributes,
    search.filtersSort,
    search.filtersAttributeSort,
    search.filtersAttributes,
    search.filtersDateRange,
  ];

  const getInitialGeoJsonShape = (): DefaultGeoJSON => {
    return (
      subSetCategory?.displayMapShapeDefault ??
      (setCategory ?? rootCategory)?.displayMapShapeDefault ??
      DefaultGeoJSON.Geometry
    );
  };

  const getCurrentFilterCategory = ():
    | SubCategory
    | ParentCategory
    | undefined => {
    const subcat = subSetCategory;
    if (
      !rootCategory ||
      !setCategory ||
      setCategory.parentCategoryId !== rootCategory.id
    )
      return;

    if (activeView?.id === ViewName.Table) {
      // TODO: refactor this
      return subcat;
    }
    return setCategory;
  };

  const getBounds = React.useMemo(() => {
    if (!bound || !search.filtersScreen) return;
    return bound;
  }, [bound, search.filtersScreen]);

  const initialFilters = (): IFilters => {
    const cat = getCurrentFilterCategory();
    if (!cat) return setDefaultFilters();
    const prefFilters = preferences?.filters;

    if (prefFilters && Object.keys(prefFilters).length > 0) {
      prefFilters.currentCategoryId = cat.id;
      return prefFilters;
    }
    return setDefaultFilters(cat);
  };

  const getFiltersFromSearch = (showGeom: boolean): IFilters | undefined => {
    const filters: IFilters | undefined = {};
    if (!search.filtersCurrentCategoryId) return filters;
    filters.search = search.filtersSearch;
    filters.showGeom = showGeom
      ? (search.filtersShowGeom as IFilters['showGeom'])
      : true;
    filters.categories = search.filtersCategoryIn as IFilters['categories'];
    filters.currentCategoryId =
      search.filtersCurrentCategoryId as IFilters['currentCategoryId'];
    filters.reportState = search.filtersReportState as IFilters['reportState'];
    filters.viewedNotifications =
      search.filtersViewedNotifications as IFilters['viewedNotifications'];
    filters.period = search.filtersPeriod as IFilters['period'];
    filters.draw = search.filtersDraw as IFilters['draw'];
    filters.containingFeatures =
      search.filtersContainingFeatures as IFilters['containingFeatures'];
    filters.screen = search.filtersScreen as IFilters['screen'];
    filters.filteringLayer =
      search.filtersFilteringLayer as IFilters['filteringLayer'];
    filters.visualizedAttributes =
      search.filtersVisualizedAttributes as IFilters['visualizedAttributes'];
    filters.filteredAttributes =
      search.filtersFilteredAttributes as IFilters['filteredAttributes'];
    filters.sort = search.filtersSort as IFilters['sort'];
    filters.attributeSort =
      search.filtersAttributeSort as IFilters['attributeSort'];
    filters.attributes = search.filtersAttributes as IFilters['attributes'];
    filters.daterange = search.filtersDateRange as IFilters['daterange'];
    try {
      return FiltersSerializer.parse(filters) as IFilters;
    } catch (e) {
      console.error(e);
      captureException(e);
      return initialFilters();
    }
  };

  const outputFilters = React.useMemo(
    () =>
      getFiltersFromSearch(getInitialGeoJsonShape() === DefaultGeoJSON.Point),
    [...updateDependencies, getBounds],
  );

  const handleSaveFiltersOnPref = (newFilters: IFilters): void => {
    const cat = getCurrentFilterCategory();
    if (!cat) return;
    setPreferences({
      ...preferences,
      tableInitialOffset: 0,
      filters: newFilters,
    });
  };

  const handleSetFilters = React.useCallback(
    (filters_data: IFilters) => {
      const newFilters = {
        filtersSearch: filters_data.search,
        filtersCategoryIn: filters_data.categories,
        filtersCurrentCategoryId: filters_data.currentCategoryId,
        filtersReportState: filters_data.reportState,
        filtersViewedNotifications: filters_data.viewedNotifications,
        filtersPeriod: filters_data.period,
        filtersDraw: filters_data.draw,
        filtersContainingFeatures: filters_data.containingFeatures,
        filtersScreen: filters_data.screen,
        filtersFilteringLayer: filters_data.filteringLayer,
        filtersVisualizedAttributes: filters_data.visualizedAttributes,
        filtersFilteredAttributes: filters_data.filteredAttributes,
        filtersSort: filters_data.sort,
        filtersAttributeSort: filters_data.attributeSort,
        filtersAttributes: filters_data.attributes,
        filtersDateRange: filters_data.daterange,
        filtersShowGeom: filters_data.showGeom,
      };
      setSearch(newFilters as DecodedValueMap<never>);
      handleSaveFiltersOnPref(filters_data);
    },
    [...updateDependencies, setSearch],
  );

  React.useEffect(() => {
    if (!getCurrentFilterCategory()) return;
    if (outputFilters?.currentCategoryId !== getCurrentFilterCategory()?.id) {
      handleSetFilters(initialFilters());
    }
  }, [setCategory, subSetCategory]);

  return {
    filters: outputFilters,
    setFilters: handleSetFilters,
  };
};
