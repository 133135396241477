import { User } from '@smack/core/api/models/users/User';
import type { IUser } from '@smack/core/store/users/types';
import { parseISO } from 'date-fns'; // Import necessary date-fns functions

/**
 * Log Model
 */

export enum LogAction {
  RETRIEVE = 'RETRIEVE',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
  PROCESS = 'PROCESS',
}

/**
 * Log data interface
 *
 * @export
 * @interface ILog
 */
export interface ILog {
  id: number;
  action?: LogAction;
  logAt: string;
  user?: IUser;
  contentType?: string;
  contentTitle?: string;
}

/**
 * Log Class
 *
 * @export
 * @class Log
 */
export class Log {
  id: number;

  action?: LogAction;

  logAt: Date;

  user?: IUser;

  contentType?: string;

  contentTitle?: string;

  /**
   * Creates an instance of Log.
   *
   * @param {ILog} data
   * @memberof Log
   */
  constructor(data: ILog) {
    this.id = data.id;
    this.action = data.action;
    this.user = data.user;
    this.contentType = data.contentType;
    this.contentTitle = data.contentTitle;
    this.logAt = parseISO(data.logAt);
  }

  /**
   * Gets the user object from the log.
   * @returns {User | undefined} - the user object log.
   */
  getUser(): User | undefined {
    if (this.user) return new User(this.user);
  }
}
