import {
  CancelButton,
  SaveButton,
} from '@smack/core/components/Actions/Buttons/Button';
import { Modal } from '@smack/core/components/DataDisplay/Modals/Modal/Modal';
import { TextAreaInput } from '@smack/core/components/DataInput/TextAreaInput';
import { spawnModal } from '@smack/core/utils/modal';
import Color from 'color';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClose: (skipCustom?: boolean) => void;
  onSave?: (description: string) => void;
  actionPrompt?: string;
}
/**
 * NotificationRejection Pop Up Components For choose beetween 'all' | 'next' | 'this'
 * @param props IProps
 * @returns JSX.Elements
 */
const NotificationRejectionAlert = (props: IProps): JSX.Element => {
  const { onClose, onSave, actionPrompt } = props;
  const [descriptionsave, setdescriptionsave] = React.useState<string>('');
  const [t] = useTranslation();
  return (
    <Modal
      open={true}
      onClose={onClose}
      icon={{ name: 'times' }}
      iconColor={Color('#fee2e2').darken(0.5).hex()}
      color={'#fee2e2'}
      title={t('baseObjectPanel.rejectTitle', {
        action: actionPrompt ?? t('baseObjectPanel.reject'),
      })}
    >
      <div className="my-5">
        <TextAreaInput
          className="max-w-none min-w-[300px]"
          label={t('baseObjectPanel.rejectTextLabel')}
          value={descriptionsave}
          onChange={setdescriptionsave}
        />
      </div>
      <div className="py-1 flex justify-end">
        <SaveButton
          onClick={(): void => {
            if (onSave) onSave(descriptionsave);
            onClose(true);
          }}
          className="mr-2"
        />

        <CancelButton onClick={(): void => onClose()} />
      </div>
    </Modal>
  );
};

export const onRejectNotification = (
  onSave: (description?: string) => void,
  handleCancel?: () => void,
  actionPrompt?: string,
): void => {
  spawnModal({
    render: ({ onClose }: IProps): JSX.Element => {
      return (
        <NotificationRejectionAlert
          onClose={(notCanceled?: boolean): void => {
            if (handleCancel && !notCanceled) {
              handleCancel();
            }
            onClose();
          }}
          onSave={onSave}
          actionPrompt={actionPrompt}
        />
      );
    },
    // Escape does not trigger the custom on close methods, so disable it for now
  });
};
