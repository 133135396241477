import { Tab } from '@headlessui/react';
import {
  Icon,
  type IconField,
} from '@smack/core/components/DataDisplay/Icon/Icon';
import type { AppState } from '@smack/core/store';
import type { ReactNode } from 'react';
import { useSelector } from 'react-redux';

export interface ITab<TabId extends string> {
  id: TabId;
  label: ReactNode;
  icon?: IconField;
  disabled?: boolean;
}

export interface IProps<TabId extends string> {
  tabs: ITab<TabId>[];
  activeTabId: TabId extends infer U ? U : never;
  onChangeTab: (id: TabId) => void;
}

export function TabController<TabId extends string>({
  tabs,
  activeTabId,
  onChangeTab,
}: IProps<TabId>) {
  const settings = useSelector((state: AppState) => state.App.settings);

  const defaultColor = settings?.color ?? settings?.defaultColor;
  const color = settings?.primaryBackgroundColor ?? defaultColor;

  const activeTabIndexById: number =
    tabs.findIndex((tab) => tab.id === activeTabId) ?? 0;

  const onHandleTabChange = (index: number): void => {
    const tab = tabs.at(index);
    if (!tab) return;
    onChangeTab(tab.id);
  };

  return (
    <Tab.Group selectedIndex={activeTabIndexById} onChange={onHandleTabChange}>
      <Tab.List className="flex gap-4 border-b-2 border-gray-400 text-gray-500 font-medium dark:border-gray-200 dark:text-gray-300">
        {tabs.map((tab) => (
          <Tab
            key={tab.id}
            // NOTE: '-mb-0.5' -> Trick to overlap container border and tab border
            className="-mb-0.5 px-2 py-2 flex items-center gap-2 border-b-2 border-transparent hover:border-gray-500 hover:text-gray-700 hover:cursor-pointer dark:hover:border-gray-100 dark:hover:text-gray-100 ui-selected:text-[color:var(--active-color)] ui-selected:border-[color:var(--active-color)] disabled:opacity-60 disabled:cursor-not-allowed"
            style={{
              '--active-color': color,
            }}
            disabled={tab.disabled}
          >
            {tab?.icon && <Icon icon={tab.icon} />}
            <span className="capitalize">{tab.label}</span>
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}
