/*
  Model for LinkRecommendation
*/

import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import type { BaseObject } from '@smack/core/api/models/objects/NewBaseObject/BaseObject/BaseObject';
import { env } from '@smack/core/env';
import axios from 'axios';
import type { MultiPoint } from 'geojson';

export interface ILinkRecommendation {
  id: number;
  label: string;
  totalWeight?: number;
  datetimeRange?: {
    startAt?: string;
    endAt?: string;
  };
  links?: ILinkBaseObject[];
  availabilityTypesLabels?: string[];
  employmentTypesLabels?: string[];
}

export interface ILinkBaseObject<T = ILinkRecommendationBaseobjects> {
  id: string;
  baseobject: T;
  datetimeRange: {
    startAt?: string;
    endAt?: string;
  };
  weight: number;
}

export interface IlinkRecommendationPostBody {
  sourceBaseobject: number;
  links: ILinkBaseObject<number>[];
}

export interface ILinkRecommendationAttribute<T = string> {
  id: number;
  value: T;
}

export interface ILinkRecommendationBaseobjects {
  id: number;
  title: ILinkRecommendationAttribute;
  overlappingMissions?: ILinkRecommendationAttribute<number>;
  overlappingProvisionalMissions?: ILinkRecommendationAttribute<number>;
  mobilisableResource?: ILinkRecommendationAttribute<number>;
  readinessCategories?: ILinkRecommendationAttribute<
    { value: string; color: string }[]
  >;
  employmentCategories?: ILinkRecommendationAttribute<
    { value: string; color: string }[]
  >;
  unavailabilityCategories?: ILinkRecommendationAttribute<
    { value: string; color: string }[]
  >;
  locations?: ILinkRecommendationAttribute<MultiPoint>;
  duration?: number;
  distance?: number;
}

export interface OverlapingBaseObject {
  id: number;
  title: string;
  datetimeRange: {
    startAt: string;
    endAt: string;
  };
}

export class LinkRecommendation implements ILinkRecommendation {
  id: number;

  label: string;

  totalWeight?: number;

  datetimeRange?: {
    startAt?: string;
    endAt?: string;
  };

  links?: ILinkBaseObject[];

  availabilityTypesLabels?: string[];

  employmentTypesLabels?: string[];

  constructor(data: ILinkRecommendation) {
    this.id = data.id;
    this.label = data.label;
    this.totalWeight = data.totalWeight;
    this.datetimeRange = data.datetimeRange;
    this.links = data.links;
    this.availabilityTypesLabels = data.availabilityTypesLabels;
    this.employmentTypesLabels = data.employmentTypesLabels;
  }

  static getLinkRecommendationByCategoryId(
    categoryId: number,
  ): Promise<LinkRecommendation[]> {
    return RESTClient.get<{ data: { results: ILinkRecommendation[] } }>(
      `/recommendations/link-recommendations?category=${categoryId}`,
    ).then((response) =>
      response.data?.results.map(
        (data: ILinkRecommendation) => new LinkRecommendation(data),
      ),
    );
  }

  static getDistanceFromLocation(
    initiallocation: BaseObject['location'],
    linkbaseObjects: ILinkRecommendationBaseobjects[],
  ): Promise<ILinkRecommendationBaseobjects[]> {
    return new Promise((acc) => {
      const nolocations: number[] = [];
      const locations = linkbaseObjects.map((linkbaseObject, index) => {
        if (linkbaseObject.locations?.value?.coordinates.length) {
          return linkbaseObject.locations.value.coordinates[0].join(',');
        }
        nolocations.push(index);
        return '0,0';
      });
      if (!locations.length || !initiallocation?.geometry.coordinates.length)
        return acc(linkbaseObjects);
      axios
        .get<{ durations: [number[]]; distances: [number[]] }>(
          `${
            env.VITE_NAVIGATION_URL
          }/table/v1/driving/${initiallocation?.geometry.coordinates.join(
            ',',
          )};${locations.join(
            ';',
          )}?annotations=duration,distance&sources=0&skip_waypoints=true`,
        )
        .then((response) => {
          const durations: number[] = response.data.durations[0];
          durations.shift();
          const distances: number[] = response.data.distances[0];
          distances.shift();
          const newLinkBaseObjects = linkbaseObjects.map(
            (linkbaseObject, index) => {
              if (nolocations.includes(index)) return linkbaseObject;
              return {
                ...linkbaseObject,
                duration: durations[index],
                distance: distances[index],
              };
            },
          );
          acc(newLinkBaseObjects);
        })
        .catch(() => {
          acc(linkbaseObjects);
        });
    });
  }

  getLinkableBaseObjects(
    sourceBaseObjectId: number,
    initiallocation: BaseObject['location'],
  ): Promise<ILinkRecommendationBaseobjects[]> {
    return RESTClient.get<{
      data: { results: ILinkRecommendationBaseobjects[] };
    }>(
      `/recommendations/link-recommendations/${this.id}/baseobjects?source-baseobject=${sourceBaseObjectId}`,
    ).then((response) => {
      return LinkRecommendation.getDistanceFromLocation(
        initiallocation,
        response.data?.results,
      );
    });
  }

  static getLinkRecommendation(
    id: number,
    baseobjectId?: number,
  ): Promise<LinkRecommendation> {
    return RESTClient.get<{ data: { results: ILinkRecommendation } }>(
      `/recommendations/link-recommendations/${id}${
        baseobjectId ? `?source-baseobject=${baseobjectId}` : ''
      }`,
    ).then((response) => new LinkRecommendation(response.data.results));
  }

  postLinkRecommendation(data: IlinkRecommendationPostBody): Promise<void> {
    return RESTClient.put<void>(
      data,
      `/recommendations/link-recommendations/${this.id}/link-list`,
    );
  }

  overlappingMissions(
    baseobjectId: number,
    sourceBaseObjectId: number,
    overlappingAttributeId: number,
  ): Promise<OverlapingBaseObject[]> {
    return RESTClient.get<{ data: { results: OverlapingBaseObject[] } }>(
      `/recommendations/link-recommendations/${this.id}/baseobjects/${baseobjectId}/attributes/${overlappingAttributeId}/baseobjects?source-baseobject=${sourceBaseObjectId}`,
    ).then((response) => response.data.results);
  }
}
