import type { IconName } from '@fortawesome/fontawesome-common-types';
import { RESTClient } from '@smack/core/api/clients/rest/RESTClient';
import { UserPreferences } from '@smack/core/api/models/users/User/UserPreferences/UserPreferences';
import { BooleanBadge } from '@smack/core/components/DataDisplay/Badges/BooleanBadge';
import type { IconField } from '@smack/core/components/DataDisplay/Icon/Icon';
import type { Option } from '@smack/core/components/DataInput/SelectInput/components/type';
import type { IGroups, IUser } from '@smack/core/store/users/types';
import { DateUtils } from '@smack/core/utils/DateUtils';
import axios, { type AxiosError, type AxiosResponse } from 'axios';
import i18next, { t } from 'i18next';

export type MfaType = 'OTP';
export type MfaDeliveryType = 'EMAIL' | 'SMS';

export interface MfaMethod {
  type: MfaType;
  delivery: MfaDeliveryType;
}

export interface IMountedFilesystem {
  externalFilesystemId: string | number;
  mountedPath: string;
}

export class User {
  id: number;

  uuid?: string;

  pk?: number;

  email: string;

  mobile: string;

  firstName: string;

  lastName: string;

  isCustomerAdmin: boolean;

  isCustomerSuperadmin: boolean;

  isSuperuser: boolean;

  isAdmin?: boolean;

  hasDeskAccess: boolean;

  hasImportApiRights: boolean;

  avatar: string;

  lastLogin: Date;

  createdAt: Date;

  group?: { edges: { node: IGroups }[] };

  preferencesManager: UserPreferences;

  mountedFilesystems?: IMountedFilesystem[];

  constructor(user: IUser) {
    this.id = user.id;
    this.pk = user.pk;
    this.uuid = user.uuid;
    this.email = user.email;
    this.mobile = user.mobile;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.hasDeskAccess = user.hasDeskAccess;
    this.isCustomerAdmin = user.isCustomerAdmin;
    this.isCustomerSuperadmin = user.isCustomerSuperadmin;
    this.isSuperuser = user.isSuperuser;
    this.isAdmin = user.isAdmin;
    this.hasImportApiRights = user.hasImportApiRights;
    this.lastLogin = user.lastLogin;
    this.createdAt = new Date(user.createdAt);
    this.avatar = user.avatar;
    this.group = user.group;
    this.mountedFilesystems = user.mountedFilesystems;
    this.preferencesManager = new UserPreferences(
      user.preferences ?? {},
      this.id,
    );
  }

  /**
   * Get the full name of an user
   * @returns string
   */
  getFullName(): string {
    if (!this.firstName && !this.lastName) return t('noNameUser');
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * get an default icon for the user
   * @returns string
   */
  getUserIcon(): IconField {
    if (!this.firstName) return { name: 'user' };
    return { name: (this.firstName[0]?.toLowerCase() as IconName) ?? 'user' };
  }

  toOption(): Option {
    return {
      label: this.getFullName(),
      value: this.id,
    };
  }

  static patchUser(data: Partial<IUser>): Promise<AxiosResponse<void>> {
    return RESTClient.patch(data, '/users/users/me');
  }

  static login(
    email: string,
    password: string,
  ): Promise<
    AxiosResponse<{
      refresh: string | undefined;
      access: string | undefined;
      code: string | undefined;
      mfaOnlyAuthorizationToken: string | undefined;
      notificationType: MfaDeliveryType | undefined;
    }>
  > {
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      deviceId = crypto.randomUUID();
      localStorage.setItem('deviceId', deviceId);
    }
    return RESTClient.post({ email, password, deviceId }, '/auth/jwt/create');
  }

  static getMfaMethods(): Promise<{
    data: { default: MfaMethod; available: MfaMethod[] };
  }> {
    return axios.get(`${RESTClient.getApiUrlEntryPoint()}/auth/mfa`, {
      headers: {
        Authorization: `Bearer ${
          localStorage.getItem('mfaOnlyAccessToken') ?? ''
        }`,
      },
    });
  }

  static sendOtp(delivery: MfaDeliveryType): Promise<
    AxiosResponse<{
      results: {
        mfaOnlyAuthorizationToken: string;
      };
    }>
  > {
    return axios.post(
      `${RESTClient.getApiUrlEntryPoint()}/auth/otp/send`,
      { delivery },
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem('mfaOnlyAccessToken') ?? ''
          }`,
        },
      },
    );
  }

  static verifyMfa(
    mfaSecret: string,
    isTrusted = false,
  ): Promise<{
    data: { refresh: string; access: string };
  }> {
    return axios.post(
      `${RESTClient.getApiUrlEntryPoint()}/auth/mfa/verify`,
      { type: 'OTP', mfaSecret, isTrusted },
      {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem('mfaOnlyAccessToken') ?? ''
          }`,
        },
      },
    );
  }

  /**
   * refresh the token
   * if expired remove the token and reload the page
   */
  static verifyToken(onSuccess: () => void, onError: (e: Error) => void): void {
    if (!localStorage.getItem('access')) {
      User.refreshToken(onSuccess, onError);
      return;
    }
    RESTClient.post(
      { token: localStorage.getItem('access') },
      '/auth/jwt/verify',
    )
      .then(() => {
        onSuccess();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          localStorage.removeItem('access');
          User.refreshToken(onSuccess, onError);
        } else {
          onError(error);
        }
      });
  }

  static refreshToken(
    onSuccess: () => void,
    onError: (e: Error) => void,
  ): void {
    if (!localStorage.getItem('refresh')) {
      onError(new Error('There is no refresh token'));
      return;
    }
    RESTClient.post<{ refresh: string; access: string }>(
      { refresh: localStorage.getItem('refresh') },
      '/auth/jwt/refresh',
    )
      .then((res) => {
        localStorage.setItem('access', res.data.access);
        localStorage.setItem('refresh', res.data.refresh);
        onSuccess();
      })
      .catch((error: AxiosError) => {
        if (error.response?.status === 401) {
          localStorage.removeItem('refresh');
        }
        onError(error);
      });
  }

  saveBaseObjectLogsState(
    lastLogState: 'READ' | 'HIDE',
    lastBaseobjectLog: number,
  ): Promise<void> {
    return new Promise((acc, rej) => {
      return RESTClient.patch(
        {
          last_log_state: lastLogState,
          last_baseobject_log: lastBaseobjectLog,
        },
        '/users/users/me',
      ).then((res) => {
        if (res?.status === 200) {
          return acc();
        }
        return rej();
      });
    });
  }

  static logout(): Promise<AxiosResponse<void>> {
    return RESTClient.post<void>({}, '/users/users/logout').finally(
      (): void => {
        localStorage.removeItem('access');
        localStorage.removeItem('refresh');
        location.replace('/login');
      },
    );
  }

  /**
   * to get title of profilUserAvatar to display
   */
  getTitleUser(): string {
    if (this.firstName ?? this.lastName) {
      return `${this.firstName ?? ''} ${this.lastName ?? ''}`;
    }
    return this.email;
  }

  /**
   * to get information to different of profilUserAvatar
   */
  getInfoForUserDetails(): {
    label: string;
    value: string | JSX.Element | null;
    displayLineIsTrue?: boolean;
  }[] {
    return [
      {
        label: i18next.t('userPanel.firstName'),
        value: this.firstName ?? '-',
      },
      {
        label: i18next.t('userPanel.lastName'),
        value: this.lastName ?? '-',
      },
      {
        label: i18next.t('userPanel.email'),
        value: this.email || '-',
      },
      {
        label: i18next.t('userPanel.phone'),
        value: this.mobile || '-',
      },
      {
        label: i18next.t('userPanel.creationDate'),
        value: DateUtils.getDateTimeString(this.createdAt),
      },
      {
        label: i18next.t('userPanel.admin'),
        value: <BooleanBadge isTrue={this.isCustomerAdmin} />,
        displayLineIsTrue: this.isCustomerAdmin,
      },
      {
        label: i18next.t('userPanel.clientSuperadmin'),
        value: <BooleanBadge isTrue={this.isCustomerSuperadmin} />,
        displayLineIsTrue: this.isCustomerSuperadmin,
      },
      {
        label: i18next.t('userPanel.hyviloSuperadmin'),
        value: <BooleanBadge isTrue={this.isSuperuser} />,
        displayLineIsTrue: this.isSuperuser,
      },
      {
        label: i18next.t('userPanel.apiImportRights'),
        value: <BooleanBadge isTrue={this.hasImportApiRights} />,
        displayLineIsTrue: this.hasImportApiRights,
      },
    ];
  }

  static getMe(): Promise<User> {
    return RESTClient.get<{ data: { results: IUser } }>('/users/users/me').then(
      (res) => {
        return new User(res.data.results);
      },
    );
  }

  static getUsers(
    search?: string,
    limit?: number,
    abort?: AbortSignal,
    offset?: number,
  ): Promise<User[]> {
    return RESTClient.get<{ data: { results: IUser[] } }>(
      '/users/users',
      {
        q: search ?? '',
        limit: limit ?? 200,
        offset: offset ?? 0,
      },
      undefined,
      abort,
    ).then((res) => res.data.results.map((u) => new User(u)));
  }

  static resetPasswordConfirm(
    uid: string,
    token: string,
    newPassword: string,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post(
      { uid, token, newPassword },
      '/users/users/reset_password_confirm',
    );
  }

  static activateAccount(
    uid: string,
    token: string,
    password: string,
  ): Promise<AxiosResponse<void>> {
    return RESTClient.post({ uid, token, password }, '/users/users/activation');
  }
}
